<template>
    <div class="launch-page-stage-row launch-page-row">
        <div class="launch-page-container launch-page-stage">
            <div class="launch-page-stage-teaser">
                <h1>{{ teaserHeadline }}</h1>
                <h3 v-html="teaserCopy"/>
                <ButtonPrimary
                    v-if="teaserButtonCaption !== null"
                    @trigger="onTeaserButtonClick"
                    :caption="teaserButtonCaption"
                    class="btn-white"
                />
            </div>
            <div class="launch-unit-card">
                <img class="" :src="cardImageUrl"/>
                <div class="launch-unit-card-body" v-if="cardHeadline !== null || cardText !== null">
                    <h3>{{ cardHeadline }}</h3>
                    <p v-if="cardText">
                        {{ cardText }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ButtonSecondary from "@/Vue/Common/ButtonSecondary";

    export default {
        name: "PageLmsXApiLaunchStage",
        components: {ButtonSecondary},
        props: {
            cardImageUrl: {
                type: String,
            },
            cardHeadline: {
                type: String,
                default: null,
            },
            cardText: {
                type: String,
                default: null,
            },
            teaserHeadline: {
                type: String,
            },
            teaserCopy: {
                type: String,
            },
            teaserButtonCaption: {
                type: String,
                default: null,
            }
        },

        methods: {

            onTeaserButtonClick(e) {
                e.target.blur();
                this.$emit('onTeaserButtonClicked', e);
            }

        },
    }
</script>

<style scoped lang="scss">

    $xapi-launch-page-margin: 24px;
    $xapi-launch-page-max-width: 1200px;
    $xapi-launch-min-column-width: 350px;
    $xapi-launch-column-gap: 24px 48px;

    .launch-page-container {
        width: 100%;
        max-width: $xapi-launch-page-max-width;
        display: flex;
        margin: 0 auto;
        padding: 0 $xapi-launch-page-margin 0 $xapi-launch-page-margin;
    }

    .launch-page-stage-row {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 0;

        &::after {
            background: var(--color-primary) url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='48' width='1920'%3E%3Cpath d='M0 .3v48h1920v-7.2s-530.7 10.2-1010.7 0S0 .3 0 .3z' fill='%23fff'/%3E%3C/svg%3E") no-repeat bottom;
            background-size: 100%;
            content: "";
            top: 0;
            bottom: 25px;
            left: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            transform: scaleX(-1);
            z-index: -1;
        }
    }

    .launch-page-stage {
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 0 $xapi-launch-page-margin;
        gap: $xapi-launch-column-gap;

        .launch-page-stage-teaser {
            flex-basis: $xapi-launch-min-column-width;
            color: var(--color-white);

            .btn {
                margin-top: 20px;
            }
        }
    }

</style>

import {parseDate, trans} from '@/Utility/Helpers';
import Tenant from '@/Models/Tenant/Tenant';
import {TenantRole} from "@/Models/Tenant/TenantRole";
import type {UserRole} from "@/Models/User/UserRole";

export default class User {

    static get constructorName() {
        return 'User';
    }

    public readonly uid: string;

    public readonly email: string;
    public readonly firstname: string | null;
    public readonly lastname: string | null;

    /**
     * Full url pointing to the users profile image.
     */
    public readonly image: string;

    /**
     * Full url pointing to the users character avatar url.
     */
    public readonly avatar_url: string | null;

    public readonly role: UserRole;

    public readonly created_at: Date;
    public readonly updated_at: Date;

    /**
     * List of user permissions/scopes for the current tenant.
     */
    public readonly permissions: string[];

    /**
     * Current tenant.
     */
    public tenant: Tenant | null;

    /**
     * This users role in the current tenant.
     */
    public readonly tenant_role: TenantRole | null;

    public selected: boolean = false;

    constructor(attributes: any = {}) {
        this.uid = attributes.uid;
        this.email = attributes.email;
        this.firstname = attributes.firstname || null;
        this.lastname = attributes.lastname || null;
        this.image = attributes.image;
        this.avatar_url = attributes.avatar_url || null;
        this.role = attributes.role;
        this.created_at = parseDate(attributes.created_at)!!;
        this.updated_at = parseDate(attributes.updated_at)!!;
        this.permissions = Array.from(attributes.permissions || []);
        this.tenant = (attributes.tenant instanceof Object) ? new Tenant(attributes.tenant) : null;
        this.tenant_role = (attributes.tenant_role instanceof Object) ? new TenantRole(attributes.tenant_role) : null;

        // hide selected property in serialization
        Object.defineProperty(this, 'selected', {enumerable: false, writable: true});
    }

    /**
     * Get the constructor name from the instance's class
     */
    get constructorName(): string {
        return User.constructorName;
    }

    get fullName(): string {
        return (this.firstname && this.lastname) ? `${this.firstname} ${this.lastname}` : trans('labels.unknown_user');
    }

    /**
     * Does the user have a specific permission?
     */
    hasPermission(permission: string): boolean {
        return this.permissions.some(p => p === permission);
    }

    /**
     * Is the given tenant the users current tenant?
     */
    isCurrentTenant(tenant: Tenant): boolean {
        return this.tenant?.uid === tenant.uid;
    }

    hasAnyRole(...roles: UserRole[]): boolean {
        return roles.includes(this.role);
    }

    compareByLastname(other: User): number {
        return this.lastname?.localeCompare(other.lastname || '') || 0
    }
}

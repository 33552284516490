<template>
    <div id="inspector" v-not-focusable class="inspector-content">
        <header class="inspector-header">
            <Icon name="icon_courses" class="inspector-panel-header-icon" />
            {{ trans('labels.inspector.course_properties') }}
        </header>

        <!-- Panels for editing object properties -->
        <div :key="'InspectorPanels' + renderTimestamp" v-not-focusable class="panels">
            <div v-if="course" :key="'PanelEditCourse'+course.uid" class="inspector-panel panel-edit-course">

                <!-- Preview image -->
                <InspectorItemPreviewImage
                    :editable="true"
                    :src="previewUrl"
                    @change="onChangePreviewImage"
                />

                <!-- Title -->
                <div class="property property-title">
                    <TextInput
                        :error-msg="trans('errors.course.title')"
                        :maxlength="50"
                        :model="course"
                        property="title"
                        :placeholder="trans('courses.create.course_title_placeholder')"
                        :required="true"
                        @change="onChangeProperty"
                    />
                </div>

                <!-- Policy -->
                <div class="property property-type">
                    <Dropdown
                        class="no-wrap"
                        :key="'dropdownCoursePolicy'+renderTimestamp"
                        :disabled="!isPolicyEditableByUser"
                        :label="trans('labels.policy')"
                        :model="course"
                        property="policy"
                        :options="getOptionsForPolicy"
                        @select="onChangeProperty"
                    />
                </div>

                <!-- Description -->
                <div class="property property-description">
                    <TextInput
                        :label="trans('labels.description')"
                        :maxlength="600"
                        :model="course"
                        property="description"
                        :placeholder="trans('courses.create.course_description_placeholder')"
                        :required="false"
                        type="textarea"
                        @change="onChangeProperty"
                    />
                </div>

                <div class="property property-users">
                    <p class="info-changes-visible-immediately">{{ trans('courses.edit.info_changes_visible_immediately') }}</p>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    // Import VueJS components:
    import InspectorItemPreviewImage from '@/Vue/Inspector/Items/InspectorItemPreviewImage.vue';

    // Import classes:
    import Course from "@/Models/Course/Course";
    import CoursePermissionPolicy from "@/Models/Course/CoursePermissionPolicy";
    import DropdownOption from "@/Utility/DropdownOption";
    import {permission, sortArrayByProperty, trans} from "@/Utility/Helpers";

    export default {
        name: 'SidepanelEditCourse',
        emits: [
            'change',
        ],
        components: {
            InspectorItemPreviewImage
        },
        props: {
            course: {
                type: Course,
                default: null,
            },
        },
        data() {
            return {
                renderTimestamp: null,                          // Helper for forcing re-rendering
            }
        },
        computed: {

            /**
             * Check if the user has the necessary permissions to edit the training policy.
             *
             * @returns {boolean}
             */
            isPolicyEditableByUser() {
                return this.getOptionsForPolicy.some(option =>
                    (
                        this.course.policy === option.value
                        && option.disabled === false
                    )
                );
            },

            /**
             * Options for Policy dropdown
             *
             * @returns {DropdownOption[]}
             */
            getOptionsForPolicy() {
                const options = [];

                CoursePermissionPolicy.getPermissionPolicyMapping().forEach(([policyPermission, policy]) => {
                    const isAllowedInTenant = !policy.hasToExistInsideAssetDefaultTenant ||
                        window.currentUser.tenant.is_default_asset_tenant;

                    const isDisabled = !permission(policyPermission) ||
                        !(policy instanceof CoursePermissionPolicy) ||
                        !isAllowedInTenant;

                    options[options.length] = new DropdownOption({
                        caption: trans('unit_policies.' + policy.constructor.type),
                        value: policy.constructor.type,
                        disabled: isDisabled,
                    });
                });

                return sortArrayByProperty(options, 'caption', false);
            },

            previewUrl() {
                if (this.course.preview.startsWith('data:')) {
                    // do not append anything to user selected images
                    return this.course.preview;
                }

                // Append last updated millis of course to avoid caching a changed course image with same url.
                // This construct can be removed as soon as we have unique urls per image revision.
                return `${this.course.preview}?t=${this.course.updated_at.getTime()}`
            },
        },
        methods: {

            /**
             * Refresh the UI
             */
            refresh() {
                // Force re-render of the component:
                this.renderTimestamp = (new Date()).toString();
                return this;
            },

            /**
             * Change handler for preview image
             */
            onChangePreviewImage(data) {
                this.course.preview = data.result;
                this.course.preview_thumbnail = data.result;
                this.course.previewImageForUpload = (data.files instanceof FileList && data.files.length >= 1) ? data.files[0] : null;
                this.$emit('change', this.course);
            },

            /**
             * Change handler for properties
             */
            onChangeProperty() {
                this.$emit('change', this.course);
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <div class="trigger-property property">
        <header>{{ trans('authoring.when_object_gets_reactivated') }}</header>
        <Checkbox
            :model="trigger"
            property="cancel_when_activated"
            layout="checkbox-right"
            @change="onTriggerChanged"
            :caption="trans('authoring.cancel_actions')"
        />
    </div>
</template>

<script>

import Trigger                  from '@/Models/UnitData/Triggers/Trigger';

export default {
    name: 'PanelTriggerPropertyCancelWhenActivated',
    emits: [
        'change',
    ],
    props: {
        trigger: Trigger,
    },
    methods: {

        /**
         * Change handler for trigger properties
         *
         */
        onTriggerChanged() {
            this.$emit('change', this.trigger);
            return this;
        },

    },
}
</script>

<style lang="scss" scoped>

</style>

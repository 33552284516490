<template>
    <div class="page-import-list-item">
        <div class="page-import-list-item-info">

            <span v-for="unit in content.units" class="page-import-list-item-info-description">
                <Collapsible
                    class="page-import-list-item-info-description-collapsible"
                    :initialCollapsed="true"
                >
                    <template #header>
                        <div class="page-import-list-item-info-description-collapsible-header">
                            <h3>{{ unit.title }}</h3>
                            <StatusLabel :config="new StatusLabelConfig({caption: Object.keys(unit.assets).length + ' ' + trans('labels.assets')})" />
                            <StatusLabel v-if="unit.policy !== UnitPermissionPolicyStandard.type" :config="new StatusLabelConfig({type: 'policy', caption: unit.policy})" />
                        </div>
                    </template>

                    <template #body>
                        <textarea
                            :value="Object.values(unit.assets).map(asset => `${trans('assets.types.'+asset.type)}: ${asset.title} [${asset.uid}]`).join('\n')"
                            class="form-control logs"
                            rows="5"
                            readonly
                        ></textarea>
                    </template>
                </Collapsible>
            </span>

            <span class="buttons">
                <ButtonPrimary
                    @trigger="onClickImport"
                    :caption="isImported ? 'labels.imported' : 'labels.import'"
                    :disabled="isImported"
                />

                <h4 class="page-import-list-item-info-title">{{ package }}</h4>
            </span>

            <div
                v-if="logs"
                class="page-import-list-item-command-output"
                :class="{ error: hasErrors, success: !hasErrors }"
            >
                <Collapsible
                    class="page-import-list-item-command-output-collapsible"
                >
                    <template #header>
                        <span><b>{{ trans('labels.logs') }}</b></span>
                    </template>

                    <template #body>
                        <textarea
                            :value="logs.join('\n')"
                            class="form-control logs"
                            rows="5"
                            readonly
                        ></textarea>
                    </template>
                </Collapsible>
            </div>
        </div>
    </div>

</template>

<script>
import ButtonSecondary from "@/Vue/Common/ButtonSecondary";
import ButtonPrimary from "@/Vue/Common/ButtonPrimary";
import Collapsible from "@/Vue/Common/Collapsible.vue";
import StatusLabel from "@/Vue/Common/StatusLabel.vue";
import StatusLabelConfig from "@/Utility/StatusLabelConfig";
import {UnitPermissionPolicyStandard} from "@/Models/Unit/UnitPermissionPolicy";
import {inject} from "vue";
import {unitServiceKey} from "@/Vue/Bootstrap/InjectionKeys";

export default {
    name: "PageImportListItem",
    components: {
        StatusLabel,
        Collapsible,
        ButtonPrimary,
        ButtonSecondary,
    },
    props: {
        content: {
            type: Object,
        },
        package: {
            type: String,
        },
    },
    data() {
        return {
            unitService: inject(unitServiceKey),
            logs: null,
            hasErrors: false,
        };
    },
    computed: {
        UnitPermissionPolicyStandard() {
            return UnitPermissionPolicyStandard
        },
        StatusLabelConfig() {
            return StatusLabelConfig
        },
        isImported() {
            return Object.values(this.content.units).some(unit => unit.exists);
        },
    },
    methods: {
        onClickImport() {
            this.hasErrors = false;
            this.logs = null;

            this.unitService.importUnit(this.package)
                .then(logs => {
                    this.logs = logs;
                })
                .catch(error => {
                    this.hasErrors = true;

                    // Handle Validation errors
                    if (error.response && error.response.data) {
                        this.logs = error.response.data.toString().split('\n');
                    } else {
                        this.$root.showErrorDialog(error);
                    }
                });
        },
    }
}
</script>

<style lang="scss">

.page-import-list-item {
    background: var(--color-white);
    border-radius: var(--card-border-radius);
    overflow: hidden;
    transition: box-shadow 0.15s ease-in-out;

    padding: 20px;

    .page-import-list-item-info-title {
        color: var(--color-anthracite30);
        align-self: center;
    }

    .buttons {
        margin-top: var(--typo-spacing-default);
    }

    .page-import-list-item-info-description {
        &>span {
            display: block;
        }
    }

    .page-import-list-item-info-description-collapsible-header {
        display: flex;
        align-items: center;
        gap: 10px;

        h3 {
            margin: 0;
        }
    }

    .page-import-list-item-info-description-collapsible,
    .page-import-list-item-command-output-collapsible {
        .collapsible-header {
            padding: 8px var(--sidepanel-padding) 8px 22px;
        }
        .collapsible-header::before {
            left: 6px;
        }
    }

    textarea {
        font-family: var(--font-family-mono-regular);
        font-size: var(--font-size-small);
        white-space: pre;
        padding: 8px;
        min-height: 120px;
    }

    & + .page-import-list-item {
        margin-top: 20px;
    }

    .page-import-list-item-command-output {
        margin-top: 20px;

        &.error {
            textarea {
                border-color: var(--color-signal);
            }
        }

        &.success {
            textarea {
                border-color: var(--color-item-green);
            }
        }
    }
}

</style>

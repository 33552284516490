<template>
    <div id="inspector" class="inspector-content" v-not-focusable v-shortcuts>
        <header :class="'inspector-header module module-' + sceneObject.subtype">
            <Icon name="icon_variable" class="inspector-panel-header-icon" />
            {{ trans('labels.inspector.module_properties') }}
        </header>
        <div class="panels" v-not-focusable :key="'InspectorPanels' + refreshUI + sceneObject.uid">
            <div :class="'inspector-panel panel-module module-' + sceneObject.subtype">

                <!-- Title -->
                <div class="property property-title">
                    <TextInput
                        :model="sceneObject"
                        property="title"
                        @change="onChangeProperty"
                        :required="true"
                        :maxlength="50"
                        :placeholder="trans('authoring.module_title_placeholder')"
                        :error-msg="trans('errors.module.title')"
                    />
                </div>

                <!-- Visibility state -->
                <div v-if="!sceneObject.isGlobal" class="property property-visibility">
                    <Checkbox
                        :model="sceneObject"
                        property="visible"
                        layout="checkbox-right"
                        @change="onChangeProperty"
                        :caption="trans('authoring.activate_when_scene_loaded')"
                    />
                </div>
            </div>

            <PanelVariables
                :sceneObject="sceneObject"
                @change="onChangeProperty"
            />

            <PanelTriggers
                :key="'panel-triggers-'+sceneObject.uid"
                :sceneObject="sceneObject"
                @change="onChangeTriggers"
            />
        </div>

        <div class="buttons">
            <!-- Btn: Duplicate -->
            <ButtonCustom :disabled="!canDuplicateSceneObject" v-tooltip="'buttons.authoring.module.duplicate'" icon="icon_duplicate" caption="labels.duplicate" @trigger="onClickDuplicateSceneObject" />

            <!-- Btn: Remove -->
            <ButtonCustom v-tooltip="'buttons.authoring.module.delete'" icon="icon_delete" caption="labels.remove" @trigger="onClickRemoveFromSelectedScene" />
        </div>

        <!-- Modal dialog for module remove confirmation -->
        <ModalApplyCancel
            event-type="MODAL_REMOVE_MODULE_FROM_SCENE"
            :title="trans('modals.remove_module_from_scene.title')"
            :description="trans('modals.remove_module_from_scene.description')"
        />
    </div>
</template>

<script>
// Import VueJS components:
import ModalApplyCancel        from '@/Vue/Modals/ModalApplyCancel.vue';
import PanelTriggers            from '@/Vue/Inspector/PanelTriggers.vue';
import PanelVariables           from '@/Vue/Inspector/PanelVariables.vue';


// Import classes:
import EventType                        from '@/Utility/EventType';
import { shortId }                      from '@/Utility/Helpers';
import { SceneObjectModuleVariable }    from '@/Models/UnitData/SceneObjects/SceneObject';

export default {
    name: 'InspectorSceneObjectModuleVariable',
    emits: [
        'change',
    ],
    components: {
        PanelVariables,
        ModalApplyCancel,
        PanelTriggers,
    },
    props: {
        sceneObject: {
            type: SceneObjectModuleVariable,
            default: null
        }
    },
    data() {
        return {
            refreshUI: shortId(),                   // Helper for refreshing UI elements
            shortcuts: new Map([
                ['Duplicate.global.prevent', this.onShortcutDuplicate],
                ['Delete.global', this.onShortcutDelete],
                ['Replace.global.prevent', null],
            ])
        }
    },
    computed: {

        canDuplicateSceneObject() {
            return !this.sceneObject.hasReachedMaxCount;
        },
    },
    methods: {

        /**
         * Common property change handler
         */
        onChangeProperty() {
            this.$emit('change', this.sceneObject);
            return this;
        },

        /**
         * Update the triggers on the scene object whenever a change happens
         *
         * @param {Trigger[]} triggers      // List of Trigger objects
         */
        onChangeTriggers(triggers) {
            //console.log('Inspector: Triggers have been updated on SceneObject', this.sceneObject);
            this.$globalEvents.emit(EventType.INSPECTOR_SCENE_OBJECT_UPDATED, this.sceneObject);

            // Update scene objectives:
            this.$globalEvents.emit(EventType.INSPECTOR_TRIGGERS_UPDATED, this.sceneObject, triggers);
            return this;
        },

        /**
         * Click handler for scene object duplicate button
         */
        onClickDuplicateSceneObject() {
            this.$globalEvents.emit(EventType.INSPECTOR_DUPLICATE_SCENE_OBJECT, this.sceneObject);
            return this;
        },

        /**
         * Click handler for the remove button
         */
        onClickRemoveFromSelectedScene() {
            // Show modal dialog:
            this.$globalEvents.emit(EventType.MODAL_REMOVE_MODULE_FROM_SCENE_SHOW, this.sceneObject);
            return this;
        },

        /**
         * Shortcut handler
         *
         * @param {CustomEvent} e
         */
        onShortcutDelete(e) {
            return this.onClickRemoveFromSelectedScene();
        },

        /**
         * Shortcut handler
         *
         * @param {CustomEvent} e
         */
        onShortcutDuplicate(e) {
            // Duplicate selected scene object if allowed:
            return this.canDuplicateSceneObject ? this.onClickDuplicateSceneObject() : this;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>

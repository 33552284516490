import {trans} from "@/Utility/Helpers/trans";
import {Posture} from "@/Models/UnitData/SceneObjects/Posture";
import {CharacterGender} from "@/Models/Asset/CharacterGender";

type CharacterConfiguration = { gender: CharacterGender, posture: Posture };

export class CharacterAnimation {

    public static readonly All: CharacterAnimation[] = [
        new CharacterAnimation(
            "disbelief",
            [
                {gender: CharacterGender.Masculine, posture: Posture.Sitting},
            ]
        ),
        new CharacterAnimation(
            "banging_fist",
            [
                {gender: CharacterGender.Masculine, posture: Posture.Sitting},
            ]
        ),
    ].sort((a1, a2) => a1.caption.localeCompare(a2.caption));


    public readonly type: string;
    public readonly caption: string;
    private readonly allowedConfigurations: CharacterConfiguration[];

    constructor(type: string, allowedConfigurations: CharacterConfiguration[]) {
        this.type = type;
        this.allowedConfigurations = allowedConfigurations;
        this.caption = trans('commands.control_character_animation.animation_types.' + this.type);
    }

    allowedFor(gender: CharacterGender | null, posture: Posture | null): boolean {
        if (gender === null || posture === null) {
            return false;
        }

        return this.allowedConfigurations.some(config => config.gender === gender && config.posture === posture);
    }
}

<template>
    <Draggable
        :groups="dragGroups"
        :style="gridAreaForCell"
        :class="cellClasses"
        :disabled="disabled"
        :value="sceneObject"
        :data-uid="sceneObject.uid"
        :title="titleAttribute"
        @click="onClickThisCell"
    >

        <!-- Global scene object -->
        <template v-if="isGlobal">

            <!-- Wrapper needed for correct positioning -->
            <span class="sticky-wrapper">

                <!-- Caption -->
                <span class="caption">

                    <!-- Icon -->
                    <Icon :name="svgIconReference" />

                    {{ caption }}

                    <!-- Invalid State Icon -->
                    <Icon v-if="!sceneObject.isValid" name="icon_invalid" class="icon-invalid" />

                </span>
            </span>

            <!-- Individual cells for each scene column -->
            <span v-for="(scene, index) in unitData.scenes" :key="'togglevis'+sceneObject.uid+index" :class="columnCellClasses(scene)">
                <Icon :name="sceneObject.isHiddenInScene(scene) ? 'icon_show-object' : 'icon_hide-object'" class="icon-toggle-visible" @click.stop="onClickChangeVisibilityForScene(scene)" />
            </span>

        </template>

        <!-- Scene object -->
        <template v-else>

            <!-- Icon -->
            <Icon :name="svgIconReference" />

            <!-- Caption -->
            <span class="caption">
                {{ caption }}
            </span>

            <!-- Invalid State Icon -->
            <Icon v-if="!sceneObject.isValid" name="icon_invalid" class="icon-invalid" />

        </template>

    </Draggable>
</template>

<script>
    // Import classes:
    import EventType                from '@/Utility/EventType';
    import { trans }                from '@/Utility/Helpers';
    import Asset                    from '@/Models/Asset/Asset';
    import SceneObject, { BaseSceneObjectAsset, BaseSceneObjectHotspot, BaseSceneObjectModule } from '@/Models/UnitData/SceneObjects/SceneObject';
    import TrainingScene from "@/Models/UnitData/Scenes/TrainingScene";
    import UnitData from "@/Models/UnitData/UnitData";

    export default {
        name: 'SceneObjectCell',
        emits: [
            'select-cell',
            'click-visible',
        ],
        props: {
            row: {                          // Row position within the grid
                type: Number,
                default: 0
            },
            sceneObject: {                  // SceneObject model associated with this cell
                type: SceneObject,
                default: null
            },
            disabled: {                     // Disabled state
                type: Boolean,
                default: false
            },
            selection: {                    // Selected object(s) from the parent component
                type: [Object, String],
                default: null
            }
        },
        computed: {

            /**
             * @returns {TrainingScene|null}
             */
            trainingScene() {
                return this.sceneObject?.getParent(TrainingScene) || null;
            },

            /**
             * @returns {UnitData}
             */
            unitData() {
                return this.sceneObject.getParent(UnitData);
            },

            /**
             * @returns {Boolean}
             */
            isGlobal() {
                return this.sceneObject.isGlobal;
            },

            /**
             * Draggable groups (revision UID for global objects and scene UID for scenes)
             *
             * @returns {string[]}
             */
            dragGroups() {
                return [this.trainingScene ? this.trainingScene.uid : this.unitData.uid];
            },

            /**
             * SVG icon name reference
             *
             * @returns {String}
             */
            svgIconReference() {
                return (this.sceneObject.icon || 'icon_asset');
            },

            /**
             * Caption text
             *
             * @returns {String}
             */
            caption() {
                if (typeof this.sceneObject.title === 'string' && this.sceneObject.title.length >= 1) {
                    return this.sceneObject.title;
                }

                if (this.sceneObject instanceof BaseSceneObjectAsset) {
                    return this.sceneObject.asset instanceof Asset ? this.sceneObject.asset.title : trans('labels.asset_unknown');
                }

                if (this.sceneObject instanceof BaseSceneObjectHotspot) {
                    return trans('labels.hotspot');
                }

                if (this.sceneObject instanceof BaseSceneObjectModule) {
                    return trans('labels.module');
                }

                return trans('labels.unknown');
            },

            /**
             * Grid area for a cell to be used in style attribute
             *
             * @returns {String}
             */
            gridAreaForCell() {
                if (this.isGlobal === true)
                {
                    return `grid-area: ${this.row + 1} / 1 / span 1 / span ${this.unitData.scenesCount};`;
                }
                return `grid-area: ${this.row + 1} / ${(this.sceneObject.isGroupChild ? 1 : (this.trainingScene.order + 1))} / span 1 / span 1;`;
            },

            /**
             * CSS classes for the scene object cell
             *
             * @returns {String}
             */
            cellClasses() {
                const classes = ['scene-object-cell'];
                if (this.isGlobal === true) { classes[classes.length] = 'global'; }
                if (this.disabled === true) { classes[classes.length] = 'disabled'; }
                if (this.selected === true) { classes[classes.length] = 'selected'; }
                if (this.sceneObject.isGroupChild) { classes[classes.length] = 'is-group-child'; }
                if (!this.sceneObject.isValid) { classes[classes.length] = 'is-invalid'; }
                classes[classes.length] = (this.sceneObject.isEnvironmentAsset === true) ? 'environment' : this.sceneObject.type;
                //classes[classes.length] = `${this.sceneObject.type}-${this.sceneObject.subtype}`; // @NOTE: No need for the subtype so far
                return classes.join(' ');
            },

            /**
             * Selected state
             *
             * @returns {Boolean}
             */
            selected() {
                return this.selection instanceof SceneObject && this.selection.uid === this.sceneObject.uid;
            },

            /**
             * Get title attribute for tooltips
             *
             * @returns {String}
             */
            titleAttribute() {
                return this.caption.length > 18 ? this.caption : null;
            },
        },
        methods: {

            /**
             * CSS classes for the column cells within a global scene object
             *
             * @param {TrainingScene} scene
             * @returns {String}
             */
            columnCellClasses(scene) {
                const classes = ['cell-column'];
                if (this.sceneObject.isHiddenInScene(scene) === true) { classes[classes.length] = 'is-hidden'; }
                if (scene.order === 0) { classes[classes.length] = 'is-first'; }
                if (scene.order === this.unitData.scenesCount - 1) { classes[classes.length] = 'is-last'; }
                return classes.join(' ');
            },

            /**
             * Click handler for this cell
             *
             * @param {MouseEvent} e
             */
            onClickThisCell(e) {
                if (this.disabled === false)
                {
                    // Prevent other click handlers from executing:
                    e.stopPropagation();

                    // Close triggers side panel (if it was opened by another grid cell):
                    if (this.$globalEvents.isEventTargetDescendantOfSelector(e, '.dropdown, .checkbox') === false)
                    {
                        this.$globalEvents.emit(EventType.SIDEPANEL_TRIGGERS_HIDE);
                    }

                    // Send selection event to the grid if the cell is not selected already:
                    if (this.selected === false)
                    {
                        this.$emit('select-cell', this.sceneObject);
                    }
                }
                return this;
            },

            /**
             * Click handler for changing the visibility within a scene
             *
             * @param {TrainingScene} scene
             */
            onClickChangeVisibilityForScene(scene) {
                this.$emit('click-visible', this.sceneObject, scene);
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .scene-object-cell {
        position: relative;
        flex-grow: 1;
        align-self: center;
        max-width: 100%;
        height: 100%;
        padding: 0 12px 0 12px;
        cursor: pointer;
        color: var(--font-color-light);
        background-color: var(--color-anthracite40);
        border-color: var(--color-anthracite40);

        // For inner alignments:
        display: flex;
        flex-direction: row;
        align-items: center;

        &:hover {
            background-color: var(--color-anthracite20);
            border-color: var(--color-anthracite20);
        }

        &.disabled {
            cursor: default !important;
            opacity: 0.2;

            .icon-toggle-visible {
                display: none;
                pointer-events: none;
            }
        }

        .icon {
            pointer-events: none;
        }

        .caption {
            position: relative;
            display: inline-block;
            //flex-grow: 1; // @NOTE: Disabled for the invalid state icon to appear right next to the caption
            padding: 0 0 0 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            pointer-events: none;
        }

        &.global {
            padding: 0;

            .caption {
                position: sticky;
                display: flex;
                align-items: center;
                padding: 0;
                flex-grow: 0;
                height: 100%;
                line-height: 34px;
                left: 12px;

                .icon {
                    margin-right: 9px;
                }
            }
        }

        // Colors for asset cells:
        &.asset:hover,
        &.asset.selected,
        &.asset.selected:hover {
            background-color: var(--color-item-green-hover);
            border-color: var(--color-item-green-hover);
        }
        &.asset,
        &.asset.disabled,
        &.asset.disabled:hover {
            background-color: var(--color-item-green);
            border-color: var(--color-item-green);
        }

        // Colors for environment asset cells:
        &.environment:hover,
        &.environment.selected,
        &.environment.selected:hover {
            background-color: var(--color-item-blue-hover);
            border-color: var(--color-item-blue-hover);
        }
        &.environment,
        &.environment.disabled,
        &.environment.disabled:hover {
            background-color: var(--color-item-blue);
            border-color: var(--color-item-blue);
        }

        // Colors for hotspot cells:
        &.hotspot:hover,
        &.hotspot.selected,
        &.hotspot.selected:hover {
            background-color: var(--color-item-yellow-hover);
            border-color: var(--color-item-yellow-hover);
        }
        &.hotspot,
        &.hotspot.disabled,
        &.hotspot.disabled:hover {
            background-color: var(--color-item-yellow);
            border-color: var(--color-item-yellow);
        }

        // Colors for module/widget cells:
        &.module:hover,
        &.module.selected,
        &.module.selected:hover,
        &.widget:hover,
        &.widget.selected,
        &.widget.selected:hover {
            background-color: var(--color-item-grey-hover);
            border-color: var(--color-item-grey-hover);
        }
        &.module,
        &.module.disabled,
        &.module.disabled:hover,
        &.widget,
        &.widget.disabled,
        &.widget.disabled:hover {
            background-color: var(--color-item-grey);
            border-color: var(--color-item-grey);
        }
    }

    .sticky-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        flex-basis: 100%;
        max-width: 100%;
        margin-right: -100%;
        height: 100%;
        padding-left: 12px;
        pointer-events: none;

        .caption {
            display: flex;
            align-items: center;
            max-width: 100%;
        }
    }

    .cell-column {
        position: relative;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
        padding: 0 2px 0 1px;   // @NOTE: To "fix" the visible gap in background colors
        height: 100%;
        overflow: visible;
        vertical-align: middle;

        // For inner alignments:
        display: flex;
        flex-direction: row;
        align-items: center;

        &.is-first {
            padding-left: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &.is-last {
            padding-right: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        .icon-toggle-visible {
            position: absolute;
            right: 12px;
            top: 6px;
            display: inline-block;
            opacity: 0;
            transition: opacity 0.2s ease;
            cursor: pointer;
            pointer-events: auto;
            opacity: 0;
        }

        &.is-hidden {
            background-color: rgba(255, 255, 255, 0.3);
        }

        &:not(.is-last):after {
            content: '';
            pointer-events: none;
            position: absolute;
            right: 0;
            margin-right: -1px;
            display: inline-block;
            height: 100%;
            width: 1px;
            border-right: 2px dotted rgba(255, 255, 255, 0.5);
        }

        &:hover {
            .icon-toggle-visible {
                opacity: 1;
            }
        }
    }

    .children {
        .scene-object-cell {
            &.global {
                .caption {
                    left: 20px;
                }
            }
        }

        .cell-column {
            &.is-first {
                margin-right: -8px;
                right: 8px;

                .icon-toggle-visible {
                    right: 20px;
                }
            }
        }
    }
</style>

<template>
    <Modal
        ref="modal"
        :show-close-button="false"
        :title="trans('modals.duplicate_trainingscene.title')"
        :user-closable="true"
        event-type="MODAL_DUPLICATE_UNIT_SCENE"
        @show="onShow"
    >
        <TextInput
            :error-msg="trans('errors.trainingscene.title')"
            :initial-value="newTitle"
            :maxlength="50"
            :placeholder="trans('units.create.scene_title_placeholder')"
            :required="true"
            @change="onChangeTitle"
        />
        <p class="description"
           v-html="trans('modals.duplicate_trainingscene.description')">
        </p>
        <template #buttons>
            <ButtonSecondary
                caption="modals.duplicate_unit.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                :disabled="!isValid"
                caption="modals.duplicate_unit.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import {trans} from '@/Utility/Helpers';
import EventType from '@/Utility/EventType';
import Modal from '@/Vue/Modals/Modal.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import type TrainingScene from '@/Models/UnitData/Scenes/TrainingScene';
import {defineComponent} from 'vue';

export default defineComponent({
    components: {
        TextInput,
        ButtonPrimary,
        ButtonSecondary,
        Modal
    },

    data() {
        return {
            /**
             * Reference to the TrainingScene for which this dialog is being shown.
             */
            trainingScene: null as TrainingScene | null,

            /**
             * Title of the duplicated training scene
             */
            newTitle: '',
        }
    },

    computed: {
        isValid() {
            return this.newTitle.length > 0 && this.newTitle.length <= 50;
        },

        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    },

    methods: {
        trans,

        onShow(trainingScene: TrainingScene) {
            this.trainingScene = trainingScene;
            this.newTitle = this.trainingScene.title + ' ' + trans('modals.duplicate_trainingscene.copy_suffix');
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            if (!this.isValid) {
                return;
            }

            this.$globalEvents.emit(EventType.MODAL_DUPLICATE_UNIT_SCENE_APPLY, this.trainingScene, this.newTitle);
            this.modal.hide();
        },

        onChangeTitle(newTitle: string) {
            this.newTitle = newTitle;
        }
    },
});
</script>

<style lang="scss" scoped>

</style>

import {cloneDeep} from 'lodash';
import {parseDate, uuid4} from '@/Utility/Helpers';
import AssetType from '@/Models/Asset/AssetType';
import AssetPolicy, {
    AssetPolicySample,
    AssetPolicyStandard,
    AssetPolicyTemplate,
    AssetPolicyUseOnly
} from '@/Models/Asset/AssetPolicy';
import AssetFile from '@/Models/Asset/AssetFile';
import {CharacterGender} from "@/Models/Asset/CharacterGender";

/**
 * AssetType to Asset subclass mapping
 *
 * @param  {String} type
 * @return {*|undefined}
 */
export function getAssetClassFromType(type) {
    const assetMapping = new Map ([
        [AssetType.Unknown.type,               UnknownAsset],
        [AssetType.CharacterModel3D.type,      CharacterModel3DAsset],
        [AssetType.Image.type,                 ImageAsset],
        [AssetType.EnvironmentImage.type,      EnvironmentImageAsset],
        [AssetType.Sound.type,                 SoundAsset],
        [AssetType.SoundTts.type,              TtsSoundAsset],
        [AssetType.Video.type,                 VideoAsset],
        [AssetType.EnvironmentVideo.type,      EnvironmentVideoAsset],
        [AssetType.Model3D.type,               Model3DAsset],
        [AssetType.EnvironmentModel3D.type,    EnvironmentModel3DAsset],
        [AssetType.Text.type,                  TextAsset],
    ]);

    return assetMapping.get(type);
}

export default class Asset
{
    static get constructorName() { return 'Asset'; }

    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        attributes = (attributes instanceof Object && !(attributes instanceof Array)) ? attributes : {};

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        ['preview_upload_file', 'selected'].forEach(attribute => Object.defineProperty(this, attribute, {enumerable: false, writable: true}));

        // Check for mandatory properties:
        if (typeof attributes.type !== 'string' || AssetType.isValidType(attributes.type) === false)
        {
            console.warn('Asset->constructor(): Invalid data.', attributes);
            throw new TypeError('Asset->constructor(): Property "type" has to be set on Asset. Must be a valid type from AssetType class.');
        }

        // Populate the model:
        this.uid = attributes.uid || uuid4();                               // Unique ID

        this.title = attributes.title || null;                              // Title text
        this.description = attributes.description || null;                  // Description text
        this.attribution = attributes.attribution || null;                  // Attribution text
        this.content_description = attributes.content_description || null;  // Content description text
        this.type = attributes.type || null;                                // Type from AssetType
        this.policy = attributes.policy;                                    // AssetPolicy
        this.archived = (typeof attributes.archived === 'boolean') ? attributes.archived : false; // Archived state

        this.preview = attributes.preview || null;                          // Preview image URL
        this.thumbnail = attributes.thumbnail || null;                      // Thumbnail image URL

        this.bundle = (typeof attributes.bundle === 'boolean') ? attributes.bundle : false;       // AssetBundle state

        // Timestamps
        this.created_at = parseDate(attributes.created_at || null);
        this.updated_at = parseDate(attributes.updated_at || null);

        // Tenancy
        this.owned_by_tenant = attributes.owned_by_tenant || null;          // Owner tenant of this asset (uid)
        this.tenants = attributes.tenants || null;                          // List of tenant-uids that have access to this asset

        // Runtime State
        this.selected = (typeof attributes.selected === 'boolean') ? attributes.selected : false; // Selected state
        this.fetched_at = parseDate(attributes.fetched_at || null);         // Last fetched from API date

        // Upload
        this.preview_upload_file = attributes.preview_upload_file || null;  // Preview image File instance for uploading (hidden)
    }

    /**
     * Get the constructor name from the instance's class
     *
     * @returns {String}
     */
    get constructorName() {
        return this.constructor.constructorName;
    }

    /**
     * Is this asset an environment asset?
     *
     * @returns {Boolean}
     */
    get isEnvironment()
    {
        return (this.type.toLowerCase().indexOf('environment') >= 0);
    }

    /**
     * Asset icon for this asset
     *
     * @returns {Object}
     */
    get assetIcon()
    {
        return this.constructor.getAssetIconByType(this.type);
    }

    /**
     * AssetPolicy for this asset
     *
     * @returns {AssetPolicy}
     */
    get assetPolicy()
    {
        return AssetPolicy.getAssetPolicyForType(this.policy);
    }

    /**
     * AssetType for this asset
     *
     * @returns {AssetType|Null}
     */
    get assetType()
    {
        return AssetType.getByTypeName(this.type);
    }

    /**
     * Title of the AssetType
     *
     * @returns {String|Null}
     */
    get assetTypeTitle()
    {
        return (this.assetType !== null) ? this.assetType.title : null;
    }

    /**
     * Whether preview is supported for this asset
     *
     * @returns {Boolean}
     */
    get supportsPreview()
    {
        return (
            this.assetType.supportsPreview &&
            !this.bundle
        );
    }

    /**
     * Checks if the asset supports user uploaded preview images
     *
     * @returns {Boolean}
     */
    get supportsPreviewImage() {
        const assetTypesWithPreviewImage = {
            [AssetType.Model3D.type]: AssetType.Model3D.preview,
            [AssetType.EnvironmentModel3D.type]: AssetType.EnvironmentModel3D.preview,
            [AssetType.CharacterModel3D.type]: AssetType.CharacterModel3D.preview,
        };
        return this.assetType.type in assetTypesWithPreviewImage;
    }

    /**
     * Checks if the asset has a user uploaded preview image
     *
     * @returns {Boolean}
     */
    get hasPreviewImage() {
        const assetTypesWithPreviewImage = {
            [AssetType.Model3D.type]: AssetType.Model3D.preview,
            [AssetType.EnvironmentModel3D.type]: AssetType.EnvironmentModel3D.preview,
            [AssetType.CharacterModel3D.type]: AssetType.CharacterModel3D.preview,
        };
        return (
            this.assetType.type in assetTypesWithPreviewImage &&
            !this.preview.includes(assetTypesWithPreviewImage[this.assetType.type])
        );
    }

    /**
     * Get preview image for uploading
     *
     * @returns {File|Null}
     */
    get previewImageForUpload() {
        return this.preview_upload_file || null;
    }

    /**
     * Set preview image for uploading
     *
     * @param {File} file
     */
    set previewImageForUpload(file) {
        this.preview_upload_file = (file instanceof File) ? file : null;
    }

    /**
     * Check if the asset is of a given type
     *
     * @param {AssetType} assetType
     * @returns {Boolean}
     */
    typeOf(assetType) {
        return (
            assetType instanceof AssetType &&
            this.type === assetType.type
        );
    }

    /**
     * Asset type specific SVG icon
     *
     * @param {String} assetType    // Type from AssetType (e.g. 'environment_image')
     * @returns {Object}
     */
    static getAssetIconByType(assetType)
    {
        const cssClasses = 'asset-icon icon ';
        const type = AssetType.all.find(t => t.type === assetType) || null;
        let icon = AssetType.Unknown.icon;
        if (type !== null)
        {
            icon = type.icon;
        }
        return { class: cssClasses, icon: '#' + icon };
    }

    /**
     * @returns {AssetFile[]}
     */
    get fileList() {
        return [];
    }

    /**
     * @return {boolean}
     */
    get isArchived() {
        return this.archived === true;
    }

    /**
     * Checks if this asset is available for the user's library
     *
     * @returns {boolean}
     */
    get isInUserLibrary() {
        if (this.isArchived) {
            return false;
        }
        if (this.isFromFreeLibrary) {
            return this.isObtainedByTenant;
        }
        return true;
    }

    /**
     * Checks if this asset is from the store
     *
     * @returns {boolean}
     */
    get isFromFreeLibrary() {
        return this.policy === AssetPolicyUseOnly.type;
    }

    /**
     * Checks if this asset has been obtained by the current users tenant
     *
     * @return {boolean}
     */
    get isObtainedByTenant()
    {
        if (window.currentUser && window.currentUser.tenant)
        {
            return this.tenants.includes(window.currentUser.tenant.uid);
        }
        return false;
    }

    /**
     * Checks if this asset is a sample asset
     *
     * @returns {boolean}
     */
    get isSampleAsset() {
        return this.policy === AssetPolicySample.type;
    }

    /**
     * Checks if this asset is a template asset
     *
     * @returns {boolean}
     */
    get isTemplateAsset() {
        return this.policy === AssetPolicyTemplate.type;
    }

    /**
     * Checks if this asset is a standard asset
     *
     * @returns {boolean}
     */
    get isStandardAsset() {
        return this.policy === AssetPolicyStandard.type;
    }

    /**
     * Create a new command with the given CommandType or type string
     *
     * @param  {AssetType|String} assetType
     * @param  {Object} attributes
     * @return {Asset}
     */
    static createWithType(assetType, attributes = null) {
        if (!(attributes instanceof Object)) {attributes = {};}
        const assetClass = getAssetClassFromType(assetType.type || assetType);

        // Merge default attributes:
        if (assetClass !== undefined && assetClass.defaultAttributes instanceof Object) {
            attributes = {
                ...assetClass.defaultAttributes, ...attributes
            };
        }

        // Enforce the 'type' that is provided by commandType:
        attributes = {
            ...attributes,
            ...{
                type: assetType.type || assetType,
            }
        };

        return Asset.createFromAttributes(attributes);
    }

    /**
     * Create a new asset from the given attributes
     *
     * @param  {Object} attributes
     * @return {Asset}
     */
    static createFromAttributes(attributes = {}) {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        const clonedAttributes = (attributes instanceof Object) ? cloneDeep(attributes) : new Object(null);
        const className = getAssetClassFromType(clonedAttributes.type) || Asset;
        return new className(clonedAttributes);
    }
}

export class UnknownAsset extends Asset {}

export class TextAsset extends Asset {}

export class AbstractVideoAsset extends Asset
{
    constructor(attributes = {})
    {
        if (new.target === AbstractVideoAsset) {
            throw new TypeError(`Cannot construct AbstractVideoAsset instances directly`);
        }

        super(attributes);

        this.videos = attributes.videos.map(video => new AssetFile(video));
    }

    /**
     * @inheritDoc
     */
    get fileList() {
        return this.videos;
    }
}

export class VideoAsset extends AbstractVideoAsset {}

export class EnvironmentVideoAsset extends AbstractVideoAsset {}

export class AbstractImageAsset extends Asset
{
    constructor(attributes = {})
    {
        if (new.target === AbstractVideoAsset) {
            throw new TypeError(`Cannot construct AbstractImageAsset instances directly`);
        }

        super(attributes);

        this.images = attributes.images.map(image => new AssetFile(image));
    }

    /** @inheritDoc */
    get fileList() {
        return this.images;
    }
}

export class ImageAsset extends AbstractImageAsset {}

export class EnvironmentImageAsset extends AbstractImageAsset {}

export class SoundAsset extends Asset
{
    constructor(attributes = {})
    {
        super(attributes);

        this.sounds = attributes.sounds.map(sound => new AssetFile(sound));
    }

    /** @inheritDoc */
    get fileList() {
        return this.sounds;
    }
}

export class TtsSoundAsset extends Asset
{
    constructor(attributes = {})
    {
        super(attributes);

        this.sounds = attributes.sounds.map(sound => new AssetFile(sound));
    }

    /** @inheritDoc */
    get fileList() {
        return this.sounds;
    }
}

export class AbstractModel3DAsset extends Asset
{
    constructor(attributes = {})
    {
        if (new.target === AbstractVideoAsset) {
            throw new TypeError(`Cannot construct AbstractModel3DAsset instances directly`);
        }

        super(attributes);

        /**
         * @type {AssetFile[]}
         */
        this.files = attributes.files.map(file => new AssetFile(file));
    }

    /** @inheritDoc */
    get fileList() {
        return this.files;
    }
}

export class Model3DAsset extends AbstractModel3DAsset {}

export class EnvironmentModel3DAsset extends AbstractModel3DAsset {}

export class CharacterModel3DAsset extends AbstractModel3DAsset {
    constructor(attributes = {})
    {
        super(attributes);

        /**
         * @type {CharacterGender|null}
         */
        this.character_gender = attributes.character_gender || null;
    }

    get isMale() {
        return this.character_gender === CharacterGender.Masculine;
    }
}

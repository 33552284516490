<template>
    <div
        :data-loading="isLoading"
        class="container">
        <ArtisanCommandListItem
            v-for="(command,) in commands"
            :command="command"
        />

        <ModalProgress/>
        <ModalNotification/>
    </div>
</template>

<script>
import ArtisanCommandListItem from "@/Vue/Admin/ArtisanCommandListItem";
import {inject} from "vue";
import {manageServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
import EventType from "@/Utility/EventType";
import ModalNotification from "@/Vue/Modals/ModalNotification.vue";
import ModalProgress from "@/Vue/Modals/ModalProgress.vue";

export default {
    name: "ArtisanCommands",
    components: {
        ModalProgress,
        ModalNotification,
        ArtisanCommandListItem,
    },

    props: {
        'commands': {
            type: Object,
        }
    },

    data() {
        return {
            manageService: inject(manageServiceKey),
        }

    },
    mounted() {
    },

    computed: {

        isLoading() {
            if (this.manageService.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        }

    },

    methods: {},
}
</script>

<style scoped>

</style>

import {cloneDeep} from 'lodash';
import AbstractDataObject from '@/Models/AbstractDataObject';
import {deleteUidReferences, getObjectUids, updateUidReferences, uuid4} from '@/Utility/Helpers';
import Command from '@/Models/UnitData/Commands/Command';
import CommandType from '@/Models/UnitData/Commands/CommandType';
import ExecutionType from '@/Models/UnitData/Triggers/ExecutionType';
import TriggerType from '@/Models/UnitData/Triggers/TriggerType';
import KeyboardKey from '@/Utility/KeyboardKey';
import RetriggerType from '@/Models/UnitData/Triggers/RetriggerType';
import SceneObjectType from '@/Models/UnitData/SceneObjects/SceneObjectType';
import {TriggerSubtype} from "@/Models/UnitData/Triggers/TriggerSubtype";
import SceneObject from "@/Models/UnitData/SceneObjects/SceneObject";
import UnitData from "@/Models/UnitData/UnitData";

export default class Trigger extends AbstractDataObject
{
    static get constructorName() { return 'Trigger'; }

    /**
     * Maximum count for triggers on a scene object
     *
     * @var {Number}
     */
    static get MaximumCountPerSceneObject() {
        return 99;
    }

    /**
     * Constructor
     *
     * @param {Object} attributes                  // Properties data
     * @param {AbstractDataObject | null} parent   // Parent object reference
     */
    constructor(attributes = {}, parent = null) {

        super(parent);

        if (new.target === Trigger) {
            throw new TypeError(`Cannot construct Trigger instances directly`);
        }

        // Hidden attributes (not enumerable which makes them "hidden" so they don't get stored in the database when sent to the API):
        // @NOTE: Don't use any of the parent's properties in this (or any child) constructor as they may not exist (be undefined) yet!
        ['originalUid'].forEach(attribute => Object.defineProperty(this, attribute, {
            enumerable: false,
            writable: true
        }));

        // Check for valid type:
        if (attributes.event === null || !TriggerType.isValidType(attributes.event)) {
            console.warn('Trigger->constructor(): Invalid data.', attributes);
            throw new TypeError('Trigger->constructor(): Property "event" has to be set on TriggerType. Must be a valid type from TriggerType class.');
        }

        // Populate the model:
        this.uid = attributes.uid || uuid4();
        this.originalUid = this.uid;
        this.event = attributes.event;
        this.subtype = this.initSubtype(attributes);
        this.commands = this.initCommands(attributes);
        this.is_objective = (typeof attributes.is_objective === 'boolean') ? attributes.is_objective : false;
        this.execution_type = attributes.execution_type || ExecutionType.Linear.type;
        this.execution_params = attributes.execution_params || null;
        this.when_retriggered = this.initWhenRetriggered(attributes);
    }

    /**
     * Icon identifier name
     *
     * @returns {String}
     */
    get icon() {
        return this.triggerType.icon;
    }

    /**
     * Does the trigger have any commands?
     *
     * @returns {Boolean}
     */
    get hasCommands() {
        return (this.commandsCount > 0);
    }

    /**
     * Does the trigger contain a specific command?
     *
     * @param {Command} command
     * @returns {Boolean}
     */
    hasCommand(command) {
        return (
            this.hasCommands &&
            this.commands.some(c => c.uid === command.uid || c.hasCommand(command))
        );
    }

    /**
     * @param {Object} attributes
     * @returns {Command[]}
     */
    initCommands(attributes){
        return (attributes.commands || []).map(c => Command.createFromAttributes(c, this));
    }

    /**
     * @param {Object} attributes
     * @returns {string|*}
     */
    initWhenRetriggered(attributes) {
        return attributes.when_retriggered || RetriggerType.CancelAndRestart.type;
    }

    /**
     * @param {Object} attributes
     * @returns {string|*}
     */
    initSubtype(attributes) {
        // Let cleanUpData() handle the migration
        if (attributes.subtype === undefined) {
            return undefined;
        }

        if (TriggerType.getByTypeName(attributes.event).subtypes.length === 0) {
            return null;
        }

        if (!TriggerType.isValidType(attributes.event, attributes.subtype)) {
            console.warn('Trigger->initSubtype(): Invalid data.', attributes);
            throw new TypeError('Trigger->initSubtype(): Property "event" + "subtype" must be a valid combination.');
        }

        return attributes.subtype;
    }

    /**
     * Get the count of commands
     *
     * @returns {Number}
     */
    get commandsCount() {
        return (this.commands instanceof Array) ? this.commands.length : 0;
    }

    /**
     * Get the count of commands grouped by type
     *
     * @returns {Map<String, Number>}
     */
    get commandsCountByType() {
        const countByType = new Map(CommandType.all.map(c => [c.type, 0]));
        this.commands.forEach(c => countByType.set(c.type, (countByType.get(c.type) + 1 || 1)));
        return countByType;
    }

    /**
     * Get supported CommandTypes for this trigger
     *
     * @returns {CommandType[]}
     */
    get supportedCommandTypes() {
        // Get additional restrictions from parent object:
        if (this.parent !== null && this.parent.supportedCommandTypes instanceof Array)
        {
            const allowedCommandTypes = this.parent.supportedCommandTypes.map(sct => sct.type);
            return (this.triggerType.commands || []).filter(ct => allowedCommandTypes.includes(ct.type));
        }
        return [...(this.triggerType.commands || [])];
    }

    /**
     * Get supported ExecutionTypes for this trigger
     *
     * @returns {ExecutionType[]}
     */
    get executionTypes() {
        const triggerType = this.triggerType;
        return (triggerType !== null && triggerType.executionTypes instanceof Array) ? [...triggerType.executionTypes] : [];
    }

    /**
     * Get the ExecutionType for this trigger
     *
     * @returns {ExecutionType|null}
     */
    get executionType() {
        return ExecutionType.getByTypeName(this.execution_type);
    }

    /**
     * Has this type of Trigger reached its maximum allowed count on its parent object?
     *
     * @returns {Boolean}
     */
    get hasReachedMaxCount() {
        let triggers;
        let maxCount;
        // Check maximum count on parent scene object:
        const parentSceneObject = this.getParent(SceneObject);
        if (parentSceneObject !== null)
        {
            triggers = parentSceneObject.triggers || [];
            maxCount = this.triggerType.maxCountPerSceneObject || -1;

            // Check maximum limit for all triggers:
            if (triggers.length >= Trigger.MaximumCountPerSceneObject)
            {
                return true;
            }
        }
        // Check maximum count on parent training scene:
// @NOTE: Disabled since we're currently not using any triggers on scenes and there's no maxCountPerScene definition yet!
//        else if (this.getParent(TrainingScene) !== null)
//        {
//            triggers = this.getParent(TrainingScene).triggers || [];
//            maxCount = this.triggerType.maxCountPerScene || -1;
//        }
        // No limitation is set if the trigger has no parent:
        else
        {
            console.warn('Trigger->hasReachedMaxCount(): Unable to check maximum count because parent is not a SceneObject or TrainingScene');
            return false;
        }

        // Count triggers on the parent object:
        return (maxCount >= 0 && triggers.filter(t => t.event === this.event).length >= maxCount);
    }

    /**
     * Check if the trigger is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        // @NOTE: Override this method on subclasses to make sure a trigger only uses valid data
        // All commands must be valid:
        return this.commands.every(c => c.isValid);
    }

    /**
     * Get the TriggerType for this trigger
     *
     * @returns {TriggerType|null}
     */
    get triggerType() {
        return TriggerType.getByTypeName(this.event);
    }

    /**
     * Check if the trigger is of a given type
     *
     * @param {TriggerType} triggerType
     * @returns {Boolean}
     */
    typeOf(triggerType) {
        return (triggerType instanceof TriggerType && this.event === triggerType.type);
    }

    /**
     * Clean up data (e.g. remove forbidden commands)
     *
     * @returns {Boolean}   // true if anything was changed, false otherwise
     */
    cleanUpData() {
        let hasChanged = false;

        // Migrate old triggers without subtype that were created with unit-data-version < 3.0.0
        if (this.subtype === undefined && TriggerType.getByTypeName(this.event).subtypes.length === 0) {
            console.info('Trigger->cleanUpData(): Set initial trigger subtype.', this.subtype, this);
            this.subtype = null;
            hasChanged = true;
        }

        // Remove invalid subtype:
        if (this.subtype && !TriggerType.isValidType(this.event, this.subtype))
        {
            console.info('Trigger->cleanUpData(): Removing invalid trigger subtype.', this.subtype, this);
            this.subtype = null;
            hasChanged = true;
        }

        // Remove objective property for triggers on global objects:
        if (this.is_objective && this.isGlobal)
        {
            console.info('Trigger->cleanUpData(): Removing objective property from global trigger.', this);
            this.is_objective = false;
            hasChanged = true;
        }

        // Fix invalid execution type:
        if (!this.executionTypes.some(et => et.type === this.execution_type))
        {
            console.info('Trigger->cleanUpData(): Resetting invalid execution type and parameters.', this.execution_type, this.execution_params, this);
            this.execution_type = ExecutionType.Linear.type;
            this.execution_params = null;
            hasChanged = true;
        }

        // Clean up commands:
        if (this.hasCommands)
        {
            // Remove forbidden commands:
            const allowedCommandTypes = this.supportedCommandTypes.map(ct => ct.type);
            this.commands = this.commands.filter(c => {
                if (!allowedCommandTypes.includes(c.type))
                {
                    console.info('Trigger->cleanUpData(): Removing forbidden command.', c, this);
                    hasChanged = true;
                    return false;
                }
                return true;
            });

            // Clean up the remaining commands:
            if (this.commands.filter(c => c.cleanUpData()).length > 0) {hasChanged = true;}
        }
        return hasChanged;
    }

    /**
     * Duplicate
     *
     * @NOTE: Since duplicating is recursive, the UID mapping must only be updated from the parent-most object that was duplicated!
     *        Any calls to duplicate() on child elements therefore must use false for the updateUidMapping parameter!
     *
     * @param {Boolean} updateUidMapping        // Whether to update all UID references for child elements
     * @returns {Trigger}
     */
    duplicate(updateUidMapping = true) {
        const duplicated = Trigger.createFromAttributes(this, this.parent);
        duplicated.uid = uuid4();

        // Create new instances for child objects:
        duplicated.commands = duplicated.commands.map(c => c.duplicate(false));
        if (duplicated.value !== null && duplicated.typeOf(TriggerType.OnKeyPress) === true)
        {
            duplicated.value = new KeyboardKey(duplicated.value);
        }

        // Update UID references for all child objects of the duplicated object:
        if (updateUidMapping === true) {updateUidReferences(duplicated);}

        return duplicated;
    }

    /**
     * Remove a given command
     *
     * @param {Command} command
     */
    removeCommand(command) {
        if (command instanceof Command && this.hasCommands === true)
        {
            const removeAtIndex = this.commands.findIndex(c => c.uid === command.uid);
            if (removeAtIndex >= 0)
            {
                this.commands.splice(removeAtIndex, 1);

                // Delete all UID references across the entire unit:
                deleteUidReferences(this.getParent(UnitData), getObjectUids(command));
            }
        }
        return this;
    }

    /**
     * Merge another trigger into this one
     *
     * @param {Trigger} trigger
     */
    mergeTrigger(trigger) {

        // Show console warning if merging different trigger types:
        if (trigger.event !== this.event)
        {
            console.warn('Trigger->mergeTrigger(): Merging trigger of a different type.', this, trigger);
        }

        // Merge tags (if allowed on the trigger):
        if (this.hasOwnProperty('tags'))
        {
            this.tags = [...new Set([...this.tags || [], ...trigger.tags || []])];
        }

        // Merge commands:
        this.mergeCommands(trigger.commands);

        // Change the original UID to the new trigger so we can prevent merging the same trigger again:
        this.originalUid = trigger.originalUid;

        return this;
    }

    /**
     * Merge a single command into this trigger and return the merged command on success
     *
     * @param {Command} command                 // The command to be inserted
     * @param {Command} insertAfterCommand      // Optional command after which the new command should be inserted
     * @returns {Command|Null}
     */
    mergeCommand(command, insertAfterCommand = null) {
        return this.mergeCommands([command], insertAfterCommand)[0] || null;
    }

    /**
     * Merge a list of commands into this trigger and return the successfully merged commands
     *
     * @param {Command[]} commands         // List of commands to be inserted
     * @param {Command} insertAfterCommand      // Optional command after which the new commands should be inserted
     * @returns {Command[]}                // List of successfully merged commands
     */
    mergeCommands(commands, insertAfterCommand = null) {
        if (!(commands instanceof Array))
        {
            return [];
        }

        // Only use commands that are allowed for this trigger's type:
        const allowedCommandTypes = this.supportedCommandTypes.map(ct => ct.type);
        const commandsToMerge = commands
            .filter(c => c instanceof Command && allowedCommandTypes.includes(c.type))
            .map(c => c.duplicate(true))
            .reduce((filtered, command) => {
                // Set parent first or the maximum count check won't work (and it has to be set anyway when inserting the command):
                command.parent = this;
                if (!command.hasReachedMaxCount)
                {
                    filtered.push(command);
                }
                return filtered;
            }, []);

        // Return early if there's no valid commands to be merged:
        if (commandsToMerge.length === 0)
        {
            return [];
        }

        // Determine position where to insert the new commands:
        const insertIndex = (insertAfterCommand instanceof Command) ? (this.commands.findIndex(c => c.uid === insertAfterCommand.uid) + 1) || this.commandsCount : this.commandsCount;

        // Insert duplicated instances of all commands since UIDs have to be unique for all objects:
        this.commands.splice.apply(
            this.commands,
            [
                insertIndex,
                0
            ].concat(commandsToMerge)
        );

        // Clean up data:
        this.cleanUpData();

        return commandsToMerge;
    }

    /**
     * Create a new trigger with the given TriggerType.
     * Sets the default attributes and handles any migration issues when converting a trigger to a new type.
     *
     * @param {TriggerType} triggerType
     * @param {Object} attributes
     * @param {Object} parent
     * @returns {Trigger}
     */
    static createWithType(triggerType, attributes = null, parent = null) {
        if (!(attributes instanceof Object)) {attributes = {};}
        const triggerClass = getTriggerClassFromType(triggerType.type);

        // Merge default attributes:
        if (triggerClass !== null && triggerClass.defaultAttributes instanceof Object) {
            attributes = {
                ...triggerClass.defaultAttributes, ...attributes
            };
        }

        // Enforce the 'event'-name that is provided by triggerType:
        attributes = {
            ...attributes,
            ...{
                event: triggerType.type,
            }
        };

        // Clear tags if the TriggerType doesn't allow any:
        if (triggerType.allowTags === false) {
            attributes.tags = [];
        }

        return Trigger.createFromAttributes(attributes, parent);
    }

    /**
     * Create a new command from given attributes
     *
     * @param {Object} attributes
     * @param parent
     * @returns {Trigger}
     */
    static createFromAttributes(attributes = {}, parent = null) {
        // Clone the incoming data to avoid manipulation of variable references in memory:
        const clonedAttributes = (attributes instanceof Object) ? cloneDeep(attributes) : new Object(null);
        const className = getTriggerClassFromType(clonedAttributes.event) || Trigger;
        return new className(clonedAttributes, parent);
    }
}

/*
|--------------------------------------------------------------------------
| Trigger Subclasses
|--------------------------------------------------------------------------
*/
export class ClickTrigger extends Trigger {
    constructor(attributes = {}, parent = null) {
        super(attributes, parent);

        this.migrateWhenRetriggered(attributes);
    }

    migrateWhenRetriggered(attributes) {
        const parentSceneObject = this.getParent(SceneObject);
        if (
            typeof attributes.uid !== 'undefined'
            && typeof attributes.when_retriggered === 'undefined'
            && parentSceneObject !== null
            && (
                parentSceneObject.typeOf(SceneObjectType.Hotspot)
                || parentSceneObject.typeOf(SceneObjectType.Hotspots.Transparent)
            )
        ) {
            this.when_retriggered = RetriggerType.Cancel.type;
        }
    }
}

export class CueTrigger extends Trigger {
    constructor(attributes = {}, parent = null) {
        super(attributes, parent);

        this.title = attributes.title || null;
    }
}

export class GazeTrigger extends Trigger {
    initWhenRetriggered(attributes) {
        return attributes.when_retriggered || RetriggerType.IgnoreAndContinue.type;
    }
}

export class ActivateTrigger extends Trigger {
    constructor(attributes = {}, parent = null) {
        super(attributes, parent);

        this.cancel_when_deactivated = (typeof attributes.cancel_when_deactivated === 'boolean') ? attributes.cancel_when_deactivated : true;
    }
}

export class DeactivateTrigger extends Trigger {
    constructor(attributes = {}, parent = null) {
        super(attributes, parent);

        this.cancel_when_activated = (typeof attributes.cancel_when_activated === 'boolean') ? attributes.cancel_when_activated : true;
    }
}

/*
|--------------------------------------------------------------------------
| Distance Triggers
|--------------------------------------------------------------------------
*/
export class BaseTriggerDistance extends Trigger {

    constructor(attributes = {}, parent = null) {
        super(attributes, parent);

        if (new.target === BaseTriggerDistance) {
            throw new TypeError(`Cannot construct BaseTriggerDistance instances directly`);
        }

        this.distance = attributes.distance || 0.5;     // Only used when subtype is distance
        this.tags = attributes.tags || [];
    }

    /** @inheritDoc */
    static get defaultAttributes() {
        return {
            'subtype': TriggerSubtype.Collider,
        };
    }

    initSubtype(attributes) {
        // "Mark" old distance triggers so that they will be updated in cleanUpData(), trigger a notification and enable
        // the save button.
        if (attributes.subtype === undefined) {
            return undefined;
        }

        if (TriggerType.isValidType(attributes.event, attributes.subtype)) {
            return attributes.subtype;
        }

        // Fallback to default subtype if trigger + subtype combination is not valid
        return BaseTriggerDistance.defaultAttributes.subtype;
    }

    /**
     * Check if the object is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        return (typeof this.distance === 'number' && this.distance > 0) && super.isValid;
    }

    /**
     * @inheritDoc
     */
    cleanUpData() {
        let hasChanged = super.cleanUpData();

        // Migrate old triggers that have no subtype (unit data version < 3.0.0):
        if (this.subtype === undefined) {
            const migrationSubtype = TriggerSubtype.Distance;
            console.info('BaseTriggerDistance->cleanUpData(): Migrating empty trigger subtype to default value.', this.subtype, migrationSubtype, this);
            this.subtype = migrationSubtype;
            hasChanged = true;

        // Reset invalid subtype:
        } else if (!TriggerType.isValidType(this.event, this.subtype)) {
            const defaultSubtype = BaseTriggerDistance.defaultAttributes.subtype;
            console.info('BaseTriggerDistance->cleanUpData(): Resetting invalid trigger subtype to default value.', this.subtype, defaultSubtype, this);
            this.subtype = defaultSubtype;
            hasChanged = true;
        }

        // Reset default distance:
        if ((isNaN(this.distance) || this.distance < 0) && (this.subtype === TriggerSubtype.Distance))
        {
            console.info('BaseTriggerDistance->cleanUpData(): Resetting invalid distance to default value.', this.distance, 0.5, this);
            this.distance = 0.5;
            hasChanged = true;
        }

        return hasChanged;
    }
}

export class EnterTrigger extends BaseTriggerDistance {}

export class LeaveTrigger extends BaseTriggerDistance {}

/*
|--------------------------------------------------------------------------
| Keypress Triggers
|--------------------------------------------------------------------------
*/
export class BaseTriggerKeypress extends Trigger {
    constructor(attributes = {}, parent = null) {
        super(attributes, parent);

        if (new.target === BaseTriggerKeypress) {
            throw new TypeError(`Cannot construct BaseTriggerKeypress instances directly`);
        }

        try {
            this.value = attributes.value ? new KeyboardKey(attributes.value) : null;
        } catch (error) {
            this.value = null;
        }

    }

    /**
     * Check if the object is valid
     *
     * @returns {Boolean}
     */
    get isValid() {
        return (this.value instanceof KeyboardKey) && super.isValid;
    }
}

export class KeypressTrigger extends BaseTriggerKeypress {}

export class SpectatorKeypressTrigger extends BaseTriggerKeypress {}

/*
|--------------------------------------------------------------------------
| Connection Triggers
|--------------------------------------------------------------------------
*/
export class ConnectionPathCompleteTrigger extends Trigger {
    constructor(attributes = {}, parent = null) {
        super(attributes, parent);

        // Reference to the path it reacts to
        this.value = attributes.value || null;
    }
}

export class ConnectionPathCompleteAllTrigger extends Trigger {}

export class ConnectionPathCompleteUnknownTrigger extends Trigger {}

/*
|--------------------------------------------------------------------------
| TriggerType to Trigger Mapping
|--------------------------------------------------------------------------
|
*/

/**
 * Get the Trigger class for the specified event name
 *
 * @param {String} event
 * @returns {Trigger}
 */
export function getTriggerClassFromType(event) {
    return triggerTypeToTriggerMapping.has(event) ? triggerTypeToTriggerMapping.get(event) : Trigger;
}

/**
 * TriggerType to Trigger mapping
 *
 * @type {Map<string|*, Trigger>}
 */
const triggerTypeToTriggerMapping = new Map([
    [TriggerType.OnActivate.type, ActivateTrigger],
    [TriggerType.OnClick.type, ClickTrigger],
    [TriggerType.OnConnectionPathComplete.type, ConnectionPathCompleteTrigger],
    [TriggerType.OnConnectionPathCompleteAll.type, ConnectionPathCompleteAllTrigger],
    [TriggerType.OnConnectionPathCompleteUnknown.type, ConnectionPathCompleteUnknownTrigger],
    [TriggerType.OnCue.type, CueTrigger],
    [TriggerType.OnDeactivate.type, DeactivateTrigger],
    [TriggerType.OnEnter.type, EnterTrigger],
    [TriggerType.OnGaze.type, GazeTrigger],
    [TriggerType.OnKeyPress.type, KeypressTrigger],
    [TriggerType.OnLeave.type, LeaveTrigger],
    [TriggerType.OnSpectatorKeyPress.type, SpectatorKeypressTrigger],
]);

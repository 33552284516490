<template>
    <Collapsible
        class="inspector-panel panel-transform"
        :initial-collapsed="true"
    >

        <template #header>
            {{ trans('authoring.transform') }}
        </template>

        <template #body>
            <PanelScale
                v-if="showPanelScale"
                :scale="transform.scale"
                @change="onChangeProperty"
            />
            <PanelPosition
                v-if="showPanelPosition"
                :position="transform.position"
                @change="onChangeProperty"
            />

            <Checkbox
                v-for="component in placeableComponents"
                :model="component"
                property="placed"
                layout="checkbox-right"
                @change="onChangeProperty"
                :caption="trans('authoring.placed')"
            />
            <PanelRotation
                v-if="showPanelRotation"
                :rotation="transform.rotation"
                @change="onChangeProperty"
            />
        </template>

    </Collapsible>
</template>

<script>
    import PanelPosition from '@/Vue/Inspector/PanelPosition';
    import PanelScale from '@/Vue/Inspector/PanelScale';
    import PlaceableComponent from "@/Models/Unity/Components/PlaceableComponent";
    import SceneObject from "@/Models/UnitData/SceneObjects/SceneObject";
    import PanelRotation from "@/Vue/Inspector/PanelRotation.vue";

    export default {
        name: 'PanelPlaceableTransform',
        emits: [
            'change',
        ],
        components: {
            PanelRotation,
            PanelPosition,
            PanelScale
        },
        props: {
            sceneObject: {
                type: SceneObject,
            },
            showPanelScale: {
                type: Boolean,
                default: true,
            },
            showPanelPosition: {
                type: Boolean,
                default: true,
            },
            showPanelRotation: {
                type: Boolean,
                default: true,
            },
        },

        beforeMount() {
            // Make sure there's always at least one placeable component:
            if (this.placeableComponents.length === 0) {
                this.sceneObject.components.push(new PlaceableComponent());
            }
        },

        computed: {
            placeableComponents() {
                return this.sceneObject.getComponentsByType(PlaceableComponent.type);
            },
            transform() {
                return this.sceneObject.transform;
            },
        },

        methods: {
            onChangeProperty() {
                this.$emit('change', this.sceneObject);
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>

<template>
    <div id="sidepanel-triggers" class="sidepanel-content" v-if="isVisible" v-shortcuts.global.stop>
        <header>
            <Icon name="icon_doublearrow_right" class="icon-close" @click="onClickCloseSidepanel" />
            {{ trans('labels.triggers') }}
        </header>

        <ul class="selectable-list" v-not-focusable>
            <li v-for="(trigger, index) in triggers" :class="classes(trigger)" :key="'trigger'+index" @click="selectTrigger(trigger)">
                <Icon :name="trigger.icon" class="round bg" />
                <section class="info">
                    <h4 class="title">{{ trigger.title }}</h4>
                    <p class="description">{{ trigger.description }}</p>
                </section>
            </li>
        </ul>
    </div>
</template>

<script>

    // Import classes:
    import EventType                from '@/Utility/EventType';
    import TriggerType              from '@/Models/UnitData/Triggers/TriggerType';

    export default {
        name: 'SidepanelTriggers',
        props: {
            isVisible:  {                   // Whether the content is visible (set by the parent component)
                type: Boolean,
                default: false
            },
            triggers: {                     // List of available TriggerTypes
                type: Array,
                default: TriggerType.all
            }
        },
        data() {
            return {
                properties: null,           // Optional properties set by the show event
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                shortcuts: new Map([
                    ['Escape', this.onClickCloseSidepanel]
                ])
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.SIDEPANEL_TRIGGERS_SHOW, this.onShow);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.SIDEPANEL_TRIGGERS_SHOW, this.onShow);
        },
        methods: {

            /**
             * Click handler for close button
             *
             * @param {CustomEvent|KeyboardEvent|MouseEvent} e
             */
            onClickCloseSidepanel(e) {
                this.$globalEvents.emit(EventType.SIDEPANEL_TRIGGERS_CANCEL);
                return this;
            },

            /**
             * CSS classes
             *
             * @param {Trigger} trigger
             * @returns {String}
             */
            classes(trigger) {
                let css = '';
                if (trigger.enabled === false)
                {
                    css += ' disabled';
                }
                return css;
            },

            /**
             * Select a specific trigger to be assigned to a scene object
             *
             * @param {Trigger} trigger
             */
            selectTrigger(trigger) {
                if (trigger.enabled)
                {
                    this.$globalEvents.emit(EventType.SIDEPANEL_TRIGGERS_SELECT, trigger, this.properties);
                }
                return this;
            },

            /**
             * Cancel trigger selection
             */
            cancelTrigger() {
                this.$globalEvents.emit(EventType.SIDEPANEL_TRIGGERS_CANCEL);
                return this;
            },

            /**
             * Set any custom properties that may have been send with the SIDEPANEL_TRIGGERS_SHOW event
             *
             * @param {Object} properties
             */
            onShow(properties = null) {
                this.previousFocusElement = document.activeElement;
                this.properties = properties;
                return this;
            }
        },
        watch: {
            isVisible(newValue, oldValue) {
                if (newValue === true)
                {
                    this.previousFocusElement = document.activeElement;
                }
                else
                {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <div class="behaviour-properties">
        <div v-if="shouldShowPropertyEnabled" class="property behaviour-property behaviour-property-dropdown">
            <Dropdown
                :label="trans('authoring.running_state')"
                :model="command.properties"
                property="enabled"
                @select="onChangePropertyEnabled"
                :options="targetOptionsForPropertyEnabled"
                :required="true"
                class="no-wrap"
            />
        </div>
    </div>
</template>

<script>
// Import helpers and functions:
import { targetOptionsForPropertyEnabled }  from '@/Models/UnitData/Behaviours/BehaviourHelpers';

// Import classes:
import {BehaviourChangeCommand} from '@/Models/UnitData/Commands/Command';

export default {
    name: 'PanelCommandBehaviourChangeCollidable',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: BehaviourChangeCommand,
            default: null
        }
    },

    beforeMount() {
        // Set default values:
        if (typeof this.command.properties.enabled !== 'boolean')
        {
            this.command.properties.enabled = true;
            this.$emit('change', this.command);
        }
    },

    computed: {
        shouldShowPropertyEnabled() {
            return (this.command.object !== null && this.command.behaviour !== null);
        },

        targetOptionsForPropertyEnabled() {
            return targetOptionsForPropertyEnabled();
        },
    },

    methods: {
        /**
         *  Change handler for enabled property
         */
        onChangePropertyEnabled() {
            this.$emit('change', this.command);
            return this;
        },
    },
}
</script>

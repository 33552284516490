<template>
    <div class="number-input-container" v-shortcuts.stop>
        <input
            class="number-input"
            ref="inputElement"
            type="number"
            :min="minValue"
            :max="maxValue"
            :step="'any'"
            required
            @change="onChange"
            @keydown="onKeyDown"
            v-model="value"
            :placeholder="placeholder"/>
        <button class="number-input-btn number-input-btn-add" @click="increase" tabindex="-1">
            <Icon name="icon_arrow-up" class="icon-arrow" />
        </button>
        <button class="number-input-btn number-input-btn-substract" @click="decrease" tabindex="-1">
            <Icon name="icon_arrow-down" class="icon-arrow" />
        </button>
    </div>

</template>

<script>
import KeyboardKey from '@/Utility/KeyboardKey';

export default {
    name: 'NumberInput',
    emits: [
        'change',
    ],
    props: {
        // Initial number value (either use this or model+property!)
        initialValue: {
            type: Number,
            default: 0
        },
        // Associated model reference
        model: {
            type: Object,
            default: null
        },
        // Property name from the associated model that should be modified
        property: {
            type: String,
            default: null
        },
        // Placeholder text
        placeholder: {
            type: String,
            default: null
        },
        // minimum allowed number value
        minValue: {
            type: Number,
            default: Number.MIN_VALUE
        },
        // maximum allowed number value
        maxValue: {
            type: Number,
            default: Number.MAX_VALUE
        },
        // step for increments / decrements
        step: {
            type: [ Number, String ],
            default: 1,
            validator: item => item === 'any' || !isNaN(item)
        },
        precision: {
            type: Number,
            default: 2,
        }
    },
    beforeMount() {
        // Check properties
        if (this.model !== null && this.property === null) {
            console.warn('NumberInput->beforeMount(): Property :model="" is set but no property="" name is given.', this);
        }
        if (this.model !== null && this.initialValue !== 0) {
            console.warn('NumberInput->beforeMount(): Both :model="" and :initial-value="" are set. You should use just one of them.', this);
        }

        // Set internal values
        this.value = (this.model && typeof this.model[this.property] === 'number') ? this.model[this.property] : this.initialValue;
        this.previousValue = this.value;
    },
    data() {
        return {
            value: null,
            previousValue: null,
            shortcuts: new Map([
                ['Duplicate.prevent', null],            // Prevent browser behaviour
                ['Save.prevent', null],                 // Prevent browser behaviour
                ['Any', null]                           // Allow any other shortcut but stop propagation
            ])
        }
    },
    methods: {
        /**
         * Change handler for number input
         *
         * @param {KeyboardEvent} e
         */
        onChange(e) {
            // Check if input value is valid
            if (!this.$refs.inputElement.validity.valid) {
                // If not valid, fall back to previous or default value
                this.value = !isNaN(this.previousValue) ? this.previousValue : this.initialValue;
            }

            let newValue = parseFloat(this.value);

            if (!isNaN(newValue) && newValue !== this.previousValue) {
                // Value has changed - emit changed
                newValue = parseFloat(newValue.toFixed(this.precision));

                // Make sure we always save numeric values
                this.value = newValue;
                this.previousValue = this.value;

                // Update model value if given
                if (this.model !== null && this.property !== null) {
                    this.model[this.property] = newValue;
                }

                this.$emit('change', newValue, e);
            }

            return this;
        },

        /**
         * @param {KeyboardEvent} e
         */
        increase(e) {
            if (isNaN(this.value)) {
                return;
            }

            const step = (this.step === 'any') ? 1.0 : this.step;
            const newValue = parseFloat(
                (parseFloat(this.value) + step).toFixed(this.precision)
            );

            if (newValue <= this.maxValue) {
                this.value = newValue;
                this.onChange(e);
            }
        },

        /**
         * @param {KeyboardEvent} e
         */
        decrease(e) {
            if (isNaN(this.value)) {
                return;
            }

            const step = (this.step === 'any') ? 1.0 : this.step;
            const newValue = parseFloat(
                (parseFloat(this.value) - step).toFixed(this.precision)
            );

            if (newValue >= this.minValue) {
                this.value = newValue;
                this.onChange(e);
            }
        },

        /**
         * @param {KeyboardEvent} e
         */
        onKeyDown(e) {
            switch (KeyboardKey.findByEvent(e)) {
                case KeyboardKey.UpArrow:
                    e.preventDefault();
                    this.increase(e)
                    break;
                case KeyboardKey.DownArrow:
                    e.preventDefault();
                    this.decrease(e)
                    break;
                default:
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

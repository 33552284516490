<template>
    <div :class="cellClasses" :style="gridAreaForCells" />
</template>

<script>
    export default {
        name: 'BackgroundCells',
        props: {
            columns: {                      // Number of columns to display
                type: Number,
                default: 0
            },
            columnStart: {                  // Offset at which to start the columns
                type: Number,
                default: 0
            },
            rows: {                         // Number of rows to display
                type: Number,
                default: 0
            },
            rowStart: {                     // Offset at which to start the rows
                type: Number,
                default: 0
            },
            selected: {                     // Selected state
                type: Boolean,
                default: false
            }
        },
        computed: {

            /**
             * CSS classes for a specific cell in a scene
             *
             * @returns {String}
             */
            cellClasses() {
                const classes = ['background-cells'];
                if (this.selected === true) { classes[classes.length] = 'selected'; }
                return classes.join(' ');
            },

            /**
             * Grid cells area for style attribute
             *
             * @returns {String}
             */
            gridAreaForCells() {
                return `grid-area: ${(this.rowStart + 1)} / ${(this.columnStart + 1)} / span ${this.rows} / span ${this.columns};`;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>

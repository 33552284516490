import {formatBytes} from "@/Utility/Helpers";

export default class AssetFile {

    /**
     * @param {Object} attributes
     */
    constructor(attributes = {}) {

        /**
         * @type {string}
         */
        this.uid = attributes.uid;

        /**
         * @type {string}
         */
        this.filename = attributes.filename;

        /**
         * @type {number}
         */
        this.filesize = attributes.filesize;

        /**
         * @type {string}
         */
        this.mimetype = attributes.mimetype;
    }

    get humanReadableFileSize() {
        return formatBytes(this.filesize.toString(), 1);
    }
}

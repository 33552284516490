<template>

    <div class="variable-properties">

        <TextInput
            class="variable-property"
            :key="'textInputVariableName'+variable.uid"
            :placeholder="trans('variables.variable_name_placeholder')"
            :model="variable"
            property="name"
            :maxlength="24"
            @change="onChangeVariable"
        />

        <component
            v-if="componentForVariable(variable)"
            :is="componentForVariable(variable)"
            :variable="variable"
            @change="onChangeVariable"
        />
    </div>
</template>

<script>
import Variable                             from '@/Models/UnitData/Variables/Variable';
import { getVariableComponentName }         from '@/Models/UnitData/Variables/VariableHelpers';

export default {
    name: 'PanelVariable',
    emits: [
        'change',
    ],
    props: {
        variable : {                      // The variable object to be edited
            type: Variable,
            default: null
        }
    },
    methods: {
        /**
         * Get the component name for the panel
         *
         * @param {Variable} variable
         * @returns {String|null}
         */
        componentForVariable(variable) {
            return getVariableComponentName(variable);
        },

        /**
         * Change handler for the variable settings
         *
         * @param {Variable} variable
         */
        onChangeVariable(variable) {
            this.$emit('change', variable);
            return this;
        }
    }
}
</script>

/*jshint esversion: 6 */

import SceneObjectType from '@/Models/UnitData/SceneObjects/SceneObjectType';

export function getInspectorComponentName(type, subtype = null)
{
    const mapping = new Map ([
        [SceneObjectType.TypeOfAsset, new Map ([
            [SceneObjectType.Assets.CharacterModel3D.subtype, 'InspectorSceneObjectAsset'],
            [SceneObjectType.Assets.EnvironmentImage.subtype, 'InspectorSceneObjectAsset'],
            [SceneObjectType.Assets.EnvironmentModel3D.subtype, 'InspectorSceneObjectAsset'],
            [SceneObjectType.Assets.EnvironmentVideo.subtype, 'InspectorSceneObjectAsset'],
            [SceneObjectType.Assets.Image.subtype, 'InspectorSceneObjectAsset'],
            [SceneObjectType.Assets.Model3D.subtype, 'InspectorSceneObjectAsset'],
            [SceneObjectType.Assets.Sound.subtype, 'InspectorSceneObjectAsset'],
            [SceneObjectType.Assets.SoundTts.subtype, 'InspectorSceneObjectAsset'],
            [SceneObjectType.Assets.Text.subtype, 'InspectorSceneObjectAssetText'],
            [SceneObjectType.Assets.Video.subtype, 'InspectorSceneObjectAsset'],
        ])],
        [SceneObjectType.TypeOfGroup, new Map ([
            [SceneObjectType.Group.subtype, 'InspectorSceneObjectGroup'],
        ])],
        [SceneObjectType.TypeOfHotspot, new Map ([
            [SceneObjectType.Hotspots.Generic.subtype, 'InspectorSceneObjectHotspot'],
            [SceneObjectType.Hotspots.Transparent.subtype, 'InspectorSceneObjectHotspot'],
        ])],
        [SceneObjectType.TypeOfModule, new Map ([
            [SceneObjectType.Modules.Connection.subtype, 'InspectorSceneObjectModuleConnection'],
            [SceneObjectType.Modules.Helper.subtype, 'InspectorSceneObjectModuleHelper'],
            [SceneObjectType.Modules.Input.subtype, 'InspectorSceneObjectModule'],
            [SceneObjectType.Modules.Intro.subtype, 'InspectorSceneObjectModuleOverlay'],
            [SceneObjectType.Modules.Keypad.subtype, 'InspectorSceneObjectModule'],
            [SceneObjectType.Modules.Outro.subtype, 'InspectorSceneObjectModuleOverlay'],
            [SceneObjectType.Modules.Overlay.subtype, 'InspectorSceneObjectModuleOverlay'],
            [SceneObjectType.Modules.Script.subtype, 'InspectorSceneObjectModule'],
            [SceneObjectType.Modules.Universal.subtype, 'InspectorSceneObjectModule'],
            [SceneObjectType.Modules.Variable.subtype, 'InspectorSceneObjectModuleVariable'],
        ])],
    ]);

    return mapping.has(type) && mapping.get(type).has(subtype) ? mapping.get(type).get(subtype) : null;
}

<template>
    <div class="command-settings">
        <!-- Select scene object as target -->
        <Dropdown
            :initial-value="command.target"
            @select="onSelectTriggerInvokeTarget"
            :options="getSceneObjectTargetDropdownOptionsForCommand"
            :required="true"
            :deselected-caption="trans('authoring.select_sceneobject')"
        />

        <!-- Select trigger from selected scene object -->
        <Dropdown
            v-if="command.target"
            :key="'triggerinvoke'+command.target"
            :initial-value="command.value"
            @select="onSelectTriggerInvokeValue"
            :options="dropdownOptionsForTriggerInvokeValues"
            :required="true"
            :deselected-caption="trans('authoring.select_trigger')"
        />

        <!-- Wait for completion checkbox -->
        <Checkbox
            :key="'awaitcommandcompletion'"
            :model="command"
            property="await_completion"
            layout="checkbox-right"
            @change="onChangeCommand"
            :caption="trans('authoring.await_completion')"
        />
    </div>
</template>

<script>
// Import helpers and functions:
import { getSceneObjectTargetDropdownOptionsForCommand, getCaptionForSceneObjectTrigger }     from '@/Models/UnitData/Commands/CommandHelpers';

// Import classes:
import DropdownOption           from '@/Utility/DropdownOption';
import {TriggerInvokeCommand} from '@/Models/UnitData/Commands/Command';
import SceneObject from "@/Models/UnitData/SceneObjects/SceneObject";
import UnitData from "@/Models/UnitData/UnitData";

export default {
    name: 'PanelCommandInvokeTrigger',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: TriggerInvokeCommand,
            default: null
        }
    },
    computed: {
        /**
         * Get options for trigger invoke value dropdown
         *
         * @returns {DropdownOption[]}
         */
        dropdownOptionsForTriggerInvokeValues() {
            const options = [];
            let targetObject = null;
            let triggers = [];

            // Get triggers for the selected object:
            if (this.command.target === TriggerInvokeCommand.TargetSelf)
            {
                targetObject = this.command.getParent(SceneObject);
            }
            else
            {
                targetObject = this.command.getParent(UnitData).findSceneObjectByUid(this.command.target);
            }
            triggers = (targetObject !== null) ? targetObject.triggers || [] : [];

            if (triggers.length >= 1)
            {
                triggers.forEach(t => {
                    options[options.length] = new DropdownOption({
                        caption: getCaptionForSceneObjectTrigger(targetObject, t, false, false),
                        icon: t.triggerType.icon,
                        disabled: false,
                        value: t.uid
                    });
                });
            }
            return options;
        },

        /**
         * Get options for target scene object selection dropdown
         *
         * @returns {DropdownOption[]}
         */
        getSceneObjectTargetDropdownOptionsForCommand() {
            return getSceneObjectTargetDropdownOptionsForCommand(this.command);
        },

    },
    methods: {

        /**
         * Select handler for trigger invoke target dropdown
         *
         * @param {String} target
         */
        onSelectTriggerInvokeTarget(target) {
            if (target !== this.command.target)
            {
                // Reset the value:
                this.command.value = null;
                this.command.target = target;
                this.$emit('change', this.command);
            }
            return this;
        },

        /**
         * Select handler for trigger invoke value dropdown
         *
         * @param {String} value
         */
        onSelectTriggerInvokeValue(value) {
            if (value !== this.command.value)
            {
                this.command.value = value;
                this.$emit('change', this.command);
            }
            return this;
        },

        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },
    }
}
</script>

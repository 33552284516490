<template>
    <Draggable
        groups="training-scene"
        :value="scene.order"
        :class="cellClasses"
        :style="gridAreaForCell"
        :data-uid="scene.uid"
        :title="getTooltipText"
        @drop="onDropSceneChangeOrder"
        @click.stop="onSelectTrainingScene"
    >
        <span class="scene-number">{{ scene.indexFormatted }}</span>
        <span class="scene-name">{{ scene.title }}</span>

        <!-- Invalid State Icon -->
        <Icon v-if="!scene.isValid" name="icon_invalid" class="icon-invalid" />
    </Draggable>
</template>

<script>

    // Import classes:
    import TrainingScene            from '@/Models/UnitData/Scenes/TrainingScene';
    import UnitData from "@/Models/UnitData/UnitData";

    export default {
        name: 'HeaderCell',
        emits: [
            'change-scene-order',
            'select-training-scene',
        ],
        props: {
            scene: {                        // Initial data from the scene that was loaded
                type: TrainingScene,
                default: null
            },
            selection: {                    // Selected object(s) from the parent component
                type: [Object, String],
                default: null
            }
        },
        computed: {

            /**
             * @returns {UnitData}
             */
            unitData() {
                return this.scene.getParent(UnitData);
            },

            /**
             * CSS classes for the header cell in the scene
             *
             * @returns {String}
             */
            cellClasses() {
                const classes = ['header-cell'];
                if (this.shouldHighlightCell) { classes[classes.length] = 'highlighted'; }
                if (this.selected === true) { classes[classes.length] = 'selected'; }
                if (!this.scene.isValid === true) { classes[classes.length] = 'is-invalid'; }
                return classes.join(' ');
            },

            shouldHighlightCell() {
                return (
                    this.scene.selected === true &&
                    this.selection !== null
                );
            },

            /**
             * @returns {String|null}
             */
            getTooltipText() {
                return (this.scene.title === null || this.scene.title.length < 28) ? null : `${this.scene.indexFormatted} ${this.scene.title}`;
            },

            /**
             * Grid area for a cell to be used in style attribute
             *
             * @returns {String}
             */
            gridAreaForCell() {
                return `grid-area: 1 / ${(this.scene.order + 1)} / span 1 / span 1;`;
            },

            /**
             * Selected state
             *
             * @returns {Boolean}
             */
            selected() {
                return this.selection instanceof TrainingScene && this.selection.uid === this.scene.uid;
            }
        },
        methods: {

            /**
             * Click handler for selecting this scene
             */
            onSelectTrainingScene(e) {
                this.$emit('select-training-scene', this.scene, e);
                return this;
            },

            /**
             * Drop handler for changing the scene order
             *
             * @param {MouseEvent} e
             */
            onDropSceneChangeOrder(e) {
                e.preventDefault();
                if (!(e.dataDraggable instanceof Object) || !(e.dataDropTarget instanceof Object)) {
                    console.warn('HeaderCell->onDropSceneChangeOrder(): Invalid dragging data.', e);
                    return this;
                }
                const currentIndex = e.dataDraggable?.value || 0;
                const scene = this.unitData.scenes[currentIndex];
                const targetIndex = this.scene.order;//e.dataDropTarget.value;
                let hasChanged = false;

                if (targetIndex > currentIndex)
                {
                    this.unitData.scenes.splice(targetIndex + 1, 0, scene);
                    this.unitData.scenes.splice(currentIndex, 1);
                    hasChanged = true;
                }
                else if (targetIndex < currentIndex)
                {
                    this.unitData.scenes.splice(targetIndex, 0, scene);
                    this.unitData.scenes.splice(currentIndex + 1, 1);
                    hasChanged = true;
                }
                //console.log('onDrop', e, targetIndex, scene, currentIndex);
                if (hasChanged === true)
                {
                    // Update scene order:
                    this.unitData.updateScenesOrder();

                    this.$emit('change-scene-order');
                }
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .header-cell {
        display: flex;
        align-items: baseline;
        padding: 9px 7px 7px 11px;
        cursor: pointer;

        &.selected {
            box-shadow: 0 4px 6px rgba(0,0,0,0.5);
        }

        &.is-invalid {
            font-style: italic;
        }

        .scene-number {
            margin-right: 7px;
            pointer-events: none;
        }

        .scene-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            pointer-events: none;
            // @NOTE This line fixes italic text being cut off in invalid state
            padding-right: 1px;
        }
    }

</style>

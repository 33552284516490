<template>
    <div class="command-settings">
        <PanelCommandLearningRecordVerb
            :key="'PanelCommandLearningRecordVerb_'+key"
            :command="command"
            @change="onChangeCommand"
            @changeProperties="onChangeCommandProperties"
        />

        <PanelCommandLearningRecordObject
            :key="'PanelCommandLearningRecordObject_'+key"
            :command="command"
            @change="onChangeCommand"
            @changeProperties="onChangeCommandProperties"
        />

        <Collapsible
            :initial-collapsed="isResultInitiallyCollapsed"
        >
            <template #header>
                {{ trans('commands.learning_record.labels.optional_settings') }}
            </template>

            <template #body>
                <PanelCommandLearningRecordResult
                    :key="'PanelCommandLearningRecordResult'"
                    :command="command"
                    @change="onChangeCommandProperties"
                />
            </template>
        </Collapsible>
    </div>
</template>

<script>
// Import classes:
import {LearningRecordCommand} from '@/Models/UnitData/Commands/Command';
import PanelCommandLearningRecordResult from "@/Vue/Inspector/Commands/PanelCommandLearningRecordResult.vue";
import Dropdown from "@/Vue/Common/Dropdown.vue";
import Collapsible from "@/Vue/Common/Collapsible.vue";
import PanelCommandLearningRecordVerb from "@/Vue/Inspector/Commands/PanelCommandLearningRecordVerb.vue";
import PanelCommandLearningRecordObject from "@/Vue/Inspector/Commands/PanelCommandLearningRecordObject.vue";
import {shortId} from "@/Utility/Helpers";

export default {
    name: 'PanelCommandLearningRecord',
    components: {
        Collapsible,
        Dropdown,
        PanelCommandLearningRecordObject,
        PanelCommandLearningRecordResult,
        PanelCommandLearningRecordVerb,
    },
    emits: [
        'change',
    ],
    props: {
        command: {
            type: LearningRecordCommand,
            default: null
        }
    },

    data() {
        return {
            key: shortId(),
            isResultInitiallyCollapsed: !(Object.keys(this.command.statement?.result || []).length > 0),
        }
    },

    computed: {},

    methods: {

        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },

        /**
         * Change handler for the command properties
         *
         * @param {LearningRecordCommand} command
         */
        onChangeCommandProperties(command) {
            this.$emit('change', this.command);
            this.key = shortId();
            return this;
        },

    },
}
</script>

<template>
    <Modal
        id="dialog-save-unit-changes"
        ref="modal"
        :show-close-button="false"
        :title="trans('modals.save_unit_changes.title')"
        :user-closable="true"
        event-type="MODAL_SAVE_UNIT_CHANGES"
        @hide="onHide"
        @show="onShow"
    >
        <p class="description" v-html="trans('modals.save_unit_changes.description')"></p>

        <template #buttons>
            <ButtonSecondary
                caption="modals.save_unit_changes.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonSecondary
                caption="modals.save_unit_changes.dismiss"
                class="btn-dismiss"
                @trigger="onClickDismiss"
            />
            <ButtonPrimary
                caption="modals.save_unit_changes.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import EventType from '@/Utility/EventType';
import Unit from '@/Models/Unit/Unit';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent} from 'vue';
import {trans} from '@/Utility/Helpers';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';

type ModalData = {
    unit: Unit | null,
};

export default defineComponent({
    components: {
        ButtonPrimary,
        ButtonSecondary,
        Modal
    },
    data(): ModalData {
        return {
            /**
             * Reference to the Unit for which this dialog is being shown
             */
            unit: null as Unit | null,
        }
    },
    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    },
    methods: {
        trans,

        onShow(unit: Unit) {
            this.unit = unit;
        },

        onHide() {
            this.unit = null;
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_SAVE_UNIT_CHANGES_APPLY, this.unit);
        },

        onClickDismiss() {
            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_SAVE_UNIT_CHANGES_DISMISS, this.unit);
        }
    }
});
</script>

<style lang="scss" scoped>

</style>

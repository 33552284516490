<template>
    <nav class="tabs">
        <ButtonCustom
            v-for="(tab, index) in tabsArray"
            :key="'tab' + index"
            class="tab"
            :tabindex="tab.active ? -1 : 0"
            :class="{ active: tab.active, disabled: tab.disabled }"
            @trigger="activateTab(tab)"
        >
            {{ tab.title }}
        </ButtonCustom>
    </nav>
</template>

<script lang="ts">
import ITab from "@/Utility/Tabs/ITab";
import {defineComponent, PropType, toRaw} from "vue";

export default defineComponent({
    name: 'Tabs',

    emits: {
        change: (_: ITab) => true
    },

    props: {
        tabs: {
            type: Object as PropType<Record<string, ITab>> | PropType<ITab[]>,
            required: true,
        }
    },

    mounted() {
        this.activateStartTab();
    },

    computed: {
        tabsArray() {
            if (this.tabs instanceof Array) {
                return this.tabs;
            } else {
                return Object.values(this.tabs);
            }
        }
    },

    methods: {

        activateStartTab() {
            const activeTab = this.tabsArray.find(tab => tab.active && !tab.disabled);

            if (activeTab === undefined) {
                const firstEnabledTab = this.tabsArray.find(tab => !tab.disabled);

                if (firstEnabledTab === undefined) {
                    throw new Error('no tab found to activate');
                }

                this.activateTab(firstEnabledTab);
            } else {
                this.activateTab(activeTab, true);
            }
        },

        activateTab(tab: ITab, skipActiveValidation = false): void {
            if (tab.disabled) {
                return;
            }

            if (tab.active && !skipActiveValidation) {
                return;
            }

            this.tabsArray.forEach(tab => {
                tab.active = false;
            });

            tab.active = true;

            this.$emit('change', toRaw(tab));
        },
    },
});
</script>

<style lang="scss" scoped>

</style>

<template>
    <Modal
        ref="modal"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :user-closable="true"
        event-type="MODAL_CHANGE_UNIT_TYPE"
        @hide="onHide"
        @show="onShow"
    >
        <p class="title">{{ trans('modals.change_unit_type.title') }}</p>
        <h2 v-if="unitType" class="training-type">{{ unitType.title }}</h2>
        <p class="description" v-html="trans('modals.change_unit_type.description')"></p>

        <Checkbox
            :caption="trans('modals.change_unit_type.keep_locations')"
            :model="this"
            layout="checkbox-right"
            property="keepAssetLocations"
        />

        <template #buttons>
            <ButtonSecondary
                caption="modals.change_unit_type.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                caption="modals.change_unit_type.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">
import EventType from '@/Utility/EventType';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent} from 'vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import Checkbox from '@/Vue/Common/Checkbox.vue';
import {trans} from '@/Utility/Helpers';
import UnitType from '@/Models/UnitData/UnitType';

export default defineComponent({
    components: {
        Checkbox,
        ButtonPrimary,
        ButtonSecondary,
        Modal
    },

    data() {
        return {
            /**
             * Reference to the UnitType for which this dialog is being shown.
             */
            unitType: null as UnitType | null,

            /**
             * Checkbox state for whether the asset locations should be reset
             */
            keepAssetLocations: true,
        }
    },

    methods: {
        trans,

        onShow(unitType: UnitType) {
            this.unitType = unitType;
            this.keepAssetLocations = true;
        },

        onHide() {
            this.unitType = null;
            this.keepAssetLocations = false;
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_CHANGE_UNIT_TYPE_APPLY, this.unitType, this.keepAssetLocations);
        },
    },

    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    }
});
</script>

<style lang="scss" scoped>
dialog {

    :deep(.description) {
        margin-bottom: 10px;

        em {
            color: var(--color-signal);
            font-style: normal;
        }
    }

    .training-type {
        margin: 0 0 20px 0;
    }
}
</style>

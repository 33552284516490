<template>
    <div class="panel-trigger-header">
        <!-- Icon -->
        <span
            class="trigger-change-btn"
            @click.stop
        >
            <Icon
                v-if="trigger.triggerType.canUserChangeType && showAsReactions === false"
                :name="trigger.icon"
                class="round bg icon-trigger"
                @click.stop="onClickChangeTrigger"
            />
            <Icon
                v-else
                :name="trigger.icon"
                class="round bg icon-trigger disabled"
            />
        </span>

        <!-- Title Editing -->
        <component
            v-if="getCustomTitleComponentForTrigger"
            :is="getCustomTitleComponentForTrigger"
            :trigger="trigger"
            :isHelperModule="isHelperModule"
            :title="getTriggerCaption"
            @change="onTriggerChanged"
        />
        <span
            v-else
            @click.stop
            class="caption"
        >
            {{ getTriggerCaption }}
        </span>

        <!-- Delete Icon -->
        <Icon
            v-if="trigger.triggerType.canUserDelete"
            name="icon_delete"
            class="icon-delete"
            @click.stop="onClickRemoveTrigger(trigger)"
        />

        <!-- Delete confirm overlay -->
        <OverlayConfirmRemove
            v-if="overlayConfirmRemoveIsVisible"
            @confirm="onConfirmRemove()"
            @cancel="onCancelRemove()"
        />
    </div>
</template>

<script>

import OverlayConfirmRemove                             from '@/Vue/Inspector/OverlayConfirmRemove.vue';
import PanelTriggerCueCustomTitle                       from '@/Vue/Inspector/Triggers/Headers/PanelTriggerCueCustomTitle.vue';
import PanelTriggerKeypressCustomTitle                  from '@/Vue/Inspector/Triggers/Headers/PanelTriggerKeypressCustomTitle.vue';

import { trans }                                        from '@/Utility/Helpers';
import { getInspectorCustomTitleComponentForTrigger }   from '@/Models/UnitData/Triggers/TriggerHelpers';

import EventType                                        from '@/Utility/EventType';
import Trigger                                          from '@/Models/UnitData/Triggers/Trigger';
import TriggerType                                      from '@/Models/UnitData/Triggers/TriggerType';
import SceneObject from "@/Models/UnitData/SceneObjects/SceneObject";

export default {
    name: 'PanelTriggerHeader',

    emits: [
        'change',
        'remove',
        'showConfirmRemovalDialogChanged',
    ],

    components: {
        OverlayConfirmRemove,
        PanelTriggerKeypressCustomTitle,
        PanelTriggerCueCustomTitle,
    },

    props: {
        isHelperModule: Boolean,
        trigger: Trigger,
        showConfirmRemovalDialog: {
          type: Boolean,
          default() {
              return false;
          }
        },
        showAsReactions: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },

    data() {
        return {
            shouldShowConfirmRemovalDialog: false,
        };
    },

    mounted() {
        this.shouldShowConfirmRemovalDialog = this.showConfirmRemovalDialog;
    },

    computed: {

        overlayConfirmRemoveIsVisible() {
            return this.shouldShowConfirmRemovalDialog && this.trigger.triggerType.canUserDelete;
        },

        sceneObject() {
            return this.trigger.getParent(SceneObject);
        },

        /**
         * Get the caption for the trigger headline
         *
         * @returns {String}
         */
        getTriggerCaption() {
            let caption = trans('triggers.' + this.trigger.event.replace('on_', ''));

            // Add colon for keypress triggers:
            if (this.trigger.typeOf(TriggerType.OnKeyPress) === true)
            {
                caption += ':';
            }
            // Use custom translation string for spectator keypress trigger:
            else if (this.trigger.typeOf(TriggerType.OnSpectatorKeyPress) === true)
            {
                caption = trans('triggers.spectator_keypress_label');
                caption += ':';
            }
            // Add indexing to path complete triggers:
            else if (this.trigger.typeOf(TriggerType.OnConnectionPathComplete) === true && this.trigger.value !== null)
            {
                const parentSceneObject = this.trigger.getParent(SceneObject);
                const pathIndex = (parentSceneObject?.hasConnections) ? (parentSceneObject.connections.findIndex(c => c.name === this.trigger.value) + 1) : 0;
                caption = trans('triggers.connection_path_complete_index', {
                    index: (pathIndex >= 1) ? pathIndex : ''
                });
            }

            return caption;
        },

        /**
         * Get the placeholder text for the edit title input
         *
         * @returns {String}
         */
        getTriggerTitlePlaceholder() {
            return this.isHelperModule ? trans('labels.reaction') : this.trigger.triggerType.title;
        },

        /**
         * Get the custom title component to display instead of the default title for the given trigger.
         *
         * @returns {null|String}
         */
        getCustomTitleComponentForTrigger() {
            return (this.trigger !== null) ? getInspectorCustomTitleComponentForTrigger(this.trigger.event) : null;
        },
    },

    methods: {

        /**
         * Click handler for trigger change
         *
         */
        onClickChangeTrigger() {
            let triggerTypes = (this.sceneObject !== null) ? this.sceneObject.supportedTriggerTypes : [];
            const triggers = this.sceneObject.getTriggersSortedByType();

            // Don't show cue triggers for helper modules:
            if (this.isHelperModule && !this.showAsReactions) {
                triggerTypes = triggerTypes.filter(t => t.type !== TriggerType.OnCue.type);
            }

            // Disable any triggers in the side panel if the maximum count is reached on the object:
            triggerTypes.forEach(t => {
                t.enabled = !(
                    !t.defaultEnabled
                    || (
                        t.maxCountPerSceneObject !== null
                        && t.maxCountPerSceneObject >= 0
                        && triggers[t.type] instanceof Array
                        && triggers[t.type].length >= t.maxCountPerSceneObject
                    )
                );
            });

            this.$globalEvents.emit(EventType.SIDEPANEL_TRIGGERS_SET, triggerTypes);
            this.$globalEvents.emit(EventType.SIDEPANEL_TRIGGERS_SHOW, this.trigger);
            return this;
        },

        /**
         * Click handler for trigger remove button. Present the removal dialog
         *
         */
        onClickRemoveTrigger() {
            this.shouldShowConfirmRemovalDialog = true;
            this.$emit('showConfirmRemovalDialogChanged', true);
            return this;
        },

        /**
         * Confirm handler for trigger remove confirmation dialog
         *
         */
        onConfirmRemove() {
            this.shouldShowConfirmRemovalDialog = false;
            this.$emit('showConfirmRemovalDialogChanged', false);
            this.$emit('remove', this.trigger);
            return this;
        },

        /**
         * Cancel handler for trigger remove confirmation dialog
         *
         */
        onCancelRemove() {
            this.shouldShowConfirmRemovalDialog = false;
            this.$emit('showConfirmRemovalDialogChanged', false);
            return this;
        },

        /**
         * Change handler for trigger properties
         *
         */
        onTriggerChanged() {
            this.$emit('change', this.trigger);
            return this;
        },
    },
    watch: {
        showConfirmRemovalDialog: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.shouldShowConfirmRemovalDialog = newValue;
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .panel-trigger-header {
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
    }
</style>

import type Course from "@/Models/Course/Course";
import type PagingMetadata from "@/Models/PagingMetadata";

export default class CoursePage {

    constructor(
        public readonly courseList: Course[],
        public readonly pagingMetadata: PagingMetadata
    ) {
    }

}

<template>
    <div class="objective-list-item" :data-ref-uid="sceneObject?.uid || 'no-ref'">
        <div v-if="showPositionInSequence" class="number">{{ positionInSequence }}</div>
        <div class="title" :title="titleForTooltip">
            <Icon :name="icon"/>
            {{ title }}
        </div>
    </div>
</template>

<script>
    import { getCaptionForSceneObjectTrigger }  from '@/Models/UnitData/Commands/CommandHelpers';
    import SceneObjectives                      from '@/Models/UnitData/Scenes/Objectives/SceneObjectives';
    import SceneObjectivesSequenceType          from '@/Models/UnitData/Scenes/Objectives/SceneObjectivesSequenceType';
    import TrainingScene from "@/Models/UnitData/Scenes/TrainingScene";
    import Icon from "@/Vue/Common/Icon.vue";

    export default {
        name: 'PanelObjectivesListItem',
        components: {
            Icon
        },
        props: {
            objectives: {
                type: SceneObjectives,
            },
            triggerUid: {
                type: String,
            },
            positionInSequence: {
                type: Number,
            },
        },

        computed: {

            icon() {
                if (this.sceneObject === null) {
                    return null;
                }

                if (this.triggerType === null) {
                    return 'icon_triggers';
                }

                return this.triggerType.icon;
            },

            sceneObjectAndTrigger() {
                return this.getSceneObjectAndTriggerFromTriggerUID(this.triggerUid);
            },

            sceneObject() {
                return this.sceneObjectAndTrigger.sceneObject;
            },

            showPositionInSequence() {
                return this.objectives.sequence === SceneObjectivesSequenceType.Linear.type
            },

            title() {
                if (this.sceneObject === null) {
                    return null;
                }

                return getCaptionForSceneObjectTrigger(this.sceneObject, this.trigger, true);
            },

            titleForTooltip() {
                return this.title.length > 18 ? this.title : '';
            },

            trigger() {
                return this.sceneObjectAndTrigger !== null ? this.sceneObjectAndTrigger.trigger : null;
            },

            triggerType() {
                return this.trigger !== null ? this.trigger.triggerType : null;
            },
        },

        methods: {

            /**
             * Get scene object and trigger from trigger UID
             *
             * @param {String} triggerUID
             * @returns {Object|null}
             */
            getSceneObjectAndTriggerFromTriggerUID(triggerUID) {
                const sceneObject = this.objectives?.getParent(TrainingScene)?.allSceneObjects.find(o => o.hasTrigger({uid: triggerUID})) || null;
                return (sceneObject === null) ? null : {
                    sceneObject: sceneObject,
                    trigger: sceneObject.triggers.find(t => t.uid === triggerUID),
                };
            },
        }
    }
</script>

<style lang="scss" scoped>

    .objective-list-item {
        display: flex;
        flex-grow: 1;
        align-items: center;
        overflow: hidden;
    }

    .number {
        flex-shrink: 0;
        flex-grow: 0;
        text-align: center;
    }

    .title {
        flex-grow: 1;
    }

</style>

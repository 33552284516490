import Condition, {
    ObjectActiveCondition,
    ObjectiveCompletedCondition,
    ScriptCondition,
    TriggerIsRunningCondition,
    VariableCondition,
} from '@/Models/UnitData/Conditions/Condition';

export function getPropertyComponentForCondition(condition: Condition): string | null
{
    const mapping = new Map ([
        [ObjectActiveCondition.type, 'PanelConditionObjectActive'],
        [ObjectiveCompletedCondition.type, 'PanelConditionObjectiveCompleted'],
        [ScriptCondition.type, 'PanelConditionScript'],
        [TriggerIsRunningCondition.type, 'PanelConditionTriggerIsRunning'],
        [VariableCondition.type, 'PanelConditionVariable'],
    ]);

    const component = mapping.get(condition.type);

    return component !== undefined ? component : null;
}

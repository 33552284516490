<template>
    <Modal
        id="dialog-replace-asset"
        ref="modal"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="trans('modals.replace_asset.title')"
        :user-closable="true"
        event-type="MODAL_REPLACE_ASSET"
        @hide="onHide"
        @show="onShow"
    >
        <p class="description"
           v-html="trans('modals.replace_asset.description', {asset: asset?.title || '', count: count})"></p>
        <RadioButtons
            ref="radiobuttons"
            :buttons="buttons"
            initial-value="only_one"
        />

        <template #buttons>
            <ButtonSecondary
                caption="modals.replace_asset.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                caption="modals.replace_asset.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import {trans} from '@/Utility/Helpers';
import EventType from '@/Utility/EventType';
import RadioButtonConfig from '@/Utility/RadioButtonConfig';
import RadioButtons from '@/Vue/Common/RadioButtons.vue';
import Modal from '@/Vue/Modals/Modal.vue';
import Asset from '@/Models/Asset/Asset';
import {defineComponent} from 'vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';

export default defineComponent({
    components: {
        ButtonPrimary,
        ButtonSecondary,
        Modal,
        RadioButtons
    },
    data() {
        return {
            callback: null as Function | null,
            count: 0 as number,
            asset: null as Asset | null,
            buttons: [
                new RadioButtonConfig({
                    value: 'only_one',
                    caption: trans('modals.replace_asset.option_only_one'),
                }),
                new RadioButtonConfig({
                    value: 'all',
                    caption: trans('modals.replace_asset.option_all'),
                }),
            ],
        }
    },
    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    },
    methods: {
        trans,

        onShow(asset: Asset, count: number, callback: Function) {
            this.asset = asset;
            this.count = count;
            this.callback = callback;
            this.buttons[1].caption = trans('modals.replace_asset.option_all', {count: this.count});
        },

        onHide() {
            this.asset = null;
            this.callback = null;
            this.count = 0;
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            this.modal.hide();
            const radioButtons = this.$refs.radiobuttons as typeof RadioButtons;

            this.$globalEvents.emit(EventType.MODAL_REPLACE_ASSET_APPLY, radioButtons.selectedValue);

            if (this.callback) {
                this.callback(radioButtons.selectedValue);
            }
        },
    }
});
</script>

<style lang="scss" scoped>

#dialog-replace-asset {
    width: fit-content;
    max-width: 470px;

    .radiobutton {
        display: block;
    }

    .radiobutton + .radiobutton {
        margin-top: var(--typo-spacing-default);
    }
}

</style>

<template>
    <nav class="pagination">

        <template v-for="page in numberOfPages" :key="page">

            <span v-if="page === currentPage"
                  class="page-link active">
                {{ page }}
            </span>

            <a v-else
               class="page-link"
               href="#"
               @click="onPageClick(page)">
                {{ page }}
            </a>

        </template>
    </nav>
</template>

<script>

    export default {
        name: 'Pagination',
        emits: [
            'click',
        ],
        props: {
            /**
             * Currently selected page; Index is based on 1.
             */
            currentPage: {
                type: Number,
                default: 1
            },
            /**
             * Total number of pages to display.
             */
            numberOfPages: {
                type: Number,
                default: 1
            },
        },
        methods: {

            /**
             * Called when a page link has been clicked by the user.
             * @param {number} page
             */
            onPageClick(page) {
                this.$emit('click', page);
            }

        },
    }
</script>

<style lang="scss">
</style>

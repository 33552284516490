<template>

    <footer>
        <div class="launch-page-container footer-stage">
            <ButtonSecondary
                caption="lms_xapi.launch_page.footer.contact"
                :href="contactUrl"
                target="_blank"
            />
        </div>
    </footer>

</template>

<script>
    import ButtonSecondary from "@/Vue/Common/ButtonSecondary";

    export default {
        name: "PageLmsXApiLaunchFooter",
        components: {ButtonSecondary},
        props: {
            contactUrl: {
                type: String,
                default: 'https://www.3spin-learning.com/en/contact',
            },
        },
    }
</script>

<style scoped lang="scss">

    $xapi-launch-page-margin: 24px;
    $xapi-launch-page-max-width: 1200px;

    .launch-page-container {
        width: 100%;
        max-width: $xapi-launch-page-max-width;
        display: flex;
        margin: 0 auto;
        padding: 0 $xapi-launch-page-margin 0 $xapi-launch-page-margin;
    }

    footer .footer-stage {
        display: flex;
        justify-content: flex-end;
        padding: 32px 0;
    }

</style>

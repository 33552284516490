<template>
    <Dropdown
        :key="keyForDropdownOptions"
        :initial-value="condition.objective"
        @select="onSelectObjective"
        :options="dropDownOptionsForConditionObjective"
        :required="true"
        :deselected-caption="trans('authoring.select_sceneobject')"
    />
</template>

<script>
import { trans }                            from '@/Utility/Helpers';
import { getCaptionForSceneObjectTrigger }  from '@/Models/UnitData/Commands/CommandHelpers';
import Command                              from '@/Models/UnitData/Commands/Command';
import { ObjectiveCompletedCondition }      from '@/Models/UnitData/Conditions/Condition';
import DropdownOption                       from '@/Utility/DropdownOption';
import DropdownOptionGroup                  from '@/Utility/DropdownOptionGroup';
import SceneObject                          from '@/Models/UnitData/SceneObjects/SceneObject';
import { shortId }                          from '@/Utility/Helpers';
import TrainingScene from "@/Models/UnitData/Scenes/TrainingScene";
import UnitData from "@/Models/UnitData/UnitData";

export default {
    name: 'PanelConditionObjectiveCompleted',
    emits: [
        'change',
    ],
    props: {
        command: Command,
        condition: ObjectiveCompletedCondition,
    },
    data() {
        return {
            refreshUI: shortId(),
        }
    },
    computed: {

        /**
         * Get options for condition type dropdown
         *
         * @returns {DropdownOption[]}
         */
        dropDownOptionsForConditionObjective() {
            const options = [];
            const currentScene = this.command.getParent(TrainingScene);

            // Get all triggers grouped by scenes (current scene being first):
            [currentScene].concat(this.command.getParent(UnitData).scenes).forEach((s, si) => {
                if (s === null || (currentScene !== null && si >= 1 && s.uid === currentScene.uid))
                {
                    return;
                }
                const group = new DropdownOptionGroup({
                    caption: (currentScene !== null && currentScene.uid === s.uid) ? trans('authoring.target_this_scene') : s.indexAndTitleFormatted,
                    showGroupNameInCaption: true,
                    isSeparator: true,
                    collapsible: true,
                    referenceUid: s.uid,
                });
                s.triggers.filter(t => t.is_objective === true).forEach(t => {
                    group.options.push(new DropdownOption({
                        caption: t.title || t.triggerType.title,
                        disabled: false,
                        value: t.uid,
                        icon: t.icon,
                        referenceUid: s.uid,
                    }));
                });
                s.allSceneObjects.filter(o => o instanceof SceneObject && o.hasTriggers).forEach(o => {
                    o.triggers.filter(t => t.is_objective === true).forEach(t => {
                        group.options.push(new DropdownOption({
                            caption: getCaptionForSceneObjectTrigger(o, t, true),
                            disabled: false,
                            value: t.uid,
                            icon: t.icon,
                            referenceUid: o.uid,
                        }));
                    });
                });
                if (group.options.length >= 1)
                {
                    options.push(group);
                }
            });

            return options;
        },

        keyForDropdownOptions() {
            return 'panel-condition-objective-completed_objectives_' + this.refreshUI;
        },
    },
    methods: {

        /**
         * Select handler for the objective
         *
         * @param {String} value
         */
        onSelectObjective(value) {
            if (value !== this.condition.objective)
            {
                this.condition.objective = value;
                this.$emit('change', this.condition);
            }
            return this;
        },

    },

    watch: {
        dropDownOptionsForConditionObjective: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.refreshUI = shortId();
            }
        },
    },

}
</script>

<style lang="scss" scoped>

</style>

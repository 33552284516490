import { trans } from '@/Utility/Helpers';
import FilterCategory from '@/Filters/FilterCategory';
import {CoursePermissionPolicySample, CoursePermissionPolicyStandard} from "@/Models/Course/CoursePermissionPolicy";

/**
 * Filter definitions
 */
const FilterDefinitions = {

    MyCourses: {
        title: trans('labels.my_courses'),
        callback(elements, options = null) {
            return elements.filter(e => e.policy = CoursePermissionPolicyStandard.type);
        },
        paramName: 'standard',
    },

    SpacesAndSamples: {
        title: trans('labels.spaces_and_samples'),
        callback(elements, options = null) {
            return elements.filter(e => e.policy = CoursePermissionPolicySample.type);
        },
        paramName: 'sample',
    },
};

export default class CourseFilters
{
    static get MyCourses()          { return new FilterCategory(FilterDefinitions.MyCourses); }
    static get SpacesAndSamples()   { return new FilterCategory(FilterDefinitions.SpacesAndSamples); }

    /**
     * Constructor
     */
    constructor()
    {
        if (this.constructor === CourseFilters)
        {
            throw new TypeError('Static class "CourseFilters" cannot be instantiated directly.');
        }
    }
}

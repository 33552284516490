<template>
    <div class="command-settings" v-shortcuts.stop>

    </div>
</template>

<script>
// Import classes:
import {WorldAnchorResetCommand} from '@/Models/UnitData/Commands/Command';

export default {
    name: 'PanelCommandWorldAnchorReset',
    props: {
        command: {                      // The command object to be edited
            type: WorldAnchorResetCommand,
            default: null
        }
    },
    data() {
        return {
            shortcuts: new Map([
                ['Duplicate.prevent', null],            // Prevent browser behaviour
                ['Save.prevent', null],                 // Prevent browser behaviour
                ['Any', null]                           // Allow any other shortcut but stop propagation
            ])
        }
    },
}
</script>

<style lang="scss" scoped>
    .command-settings {
      margin-top: -12px;
    }
</style>

<template>
    <div class="trigger-property property property-tags">
        <TagList
            class="no-wrap"
            :suggestions="getTagSuggestions"
            :model="trigger"
            property="tags"
            @change="onTriggerChanged"
        />
    </div>
</template>

<script>

import Trigger                  from '@/Models/UnitData/Triggers/Trigger';
import { compareAlphabetical }  from '@/Utility/Helpers';
import UnitData from "@/Models/UnitData/UnitData";

export default {
    name: 'PanelTriggerPropertyTags',
    emits: [
        'change',
    ],
    props: {
        trigger: Trigger,
    },
    computed: {

        /**
         * Get tag suggestions
         *
         * @returns {string[]}
         */
        getTagSuggestions() {
            return [
                ...new Set([
                    ...this.trigger.triggerType.tags,
                    ...this.trigger.getParent(UnitData).getTagsFromSceneObjects()
                ])
            ].sort(compareAlphabetical);
        },
    },
    methods: {

        /**
         * Change handler for trigger properties
         *
         */
        onTriggerChanged() {
            this.$emit('change', this.trigger);
            return this;
        },

    },
}
</script>

<style lang="scss" scoped>

</style>

<template>
    <Modal
        ref="modal"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="trans('modals.select_import_user_csv.title')"
        :user-closable="true"
        event-type="MODAL_SELECT_IMPORT_USER_CSV"
    >
        <p>{{ trans('modals.select_import_user_csv.description_introduction') }}</p>

        <p class="tenant-role-header">
            {{ trans('modals.select_import_user_csv.description_valid_tenant_roles') }}
        </p>
        <ul class="tenant-role-list">
            <li v-for="tenantRole in validTenantRoles" :key="tenantRole">{{tenantRole}}</li>
        </ul>

        <p v-html="sampleFileParagraphHtml"></p>

        <template #buttons>
            <ButtonSecondary
                caption="labels.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                caption="modals.select_import_user_csv.apply"
                class="btn-apply"
                @trigger="onClickNext"
            />
        </template>

    </Modal>
</template>

<script lang="ts">

import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent, inject} from 'vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import {trans} from '@/Utility/Helpers';
import UserImport from '@/Models/User/Import/UserImport';
import {userServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import EventType from '@/Utility/EventType';
import {TenantRole} from '@/Models/Tenant/TenantRole';

export default defineComponent({
    components: {
        ButtonSecondary,
        ButtonPrimary,
        Modal
    },
    data() {
        return {
            userImport: null as UserImport | null,
            userService: inject(userServiceKey)!,
        }
    },
    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },

        sampleFileParagraphHtml() {
            const sampleFileUrl = '/files/3spin_learning_user_import_sample.csv';
            return trans('modals.select_import_user_csv.description_sample_csv', {'url': sampleFileUrl}, false);
        },

        validTenantRoles() {
            return Object.values<string>(TenantRole.Name);
        },
    },
    methods: {
        trans,

        async onClickNext() {
            let input = document.createElement('input');
            input.type = 'file';
            input.accept = '.csv';

            input.addEventListener('change', async () => {
                const file = input.files![0];

                try {
                    // read selected file
                    const csvText = await file.text();

                    // show next dialog step
                    this.$globalEvents.emit(EventType.MODAL_IMPORT_USERS_SHOW, file.name, UserImport.fromCsvText(csvText));
                } catch (e) {
                    this.$root?.showErrorDialog(e);
                } finally {
                    this.modal.hide();
                }
            });

            input.click();
        },

        onClickCancel() {
            this.modal.hide();
        },
    }
});
</script>

<style lang="scss" scoped>

.tenant-role-header {
    margin-bottom: 2px;
}

.tenant-role-list {
    margin-bottom: 10px;
    list-style-position: inside;
    font-family: var(--font-family-condensed-demibold);
}

</style>

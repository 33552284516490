<template>
    <div v-for="feature in unavailableFeaturesNeeded" class="feature-not-available">
        <Icon name="icon_error"/>
        <p :title="tooltip(feature)"
           v-html="text(feature)"></p>
    </div>
</template>

<script lang="ts">

    import {defineComponent, inject, PropType} from "vue";
    import {featureRepositoryKey} from "@/Vue/Bootstrap/InjectionKeys";
    import Icon from "@/Vue/Common/Icon.vue";
    import {trans} from "@/Utility/Helpers/trans";
    import AbstractDataObject from "@/Models/AbstractDataObject";
    import {Feature} from "@/Models/Features/Feature";
    import Command from "@/Models/UnitData/Commands/Command";

    export default defineComponent({
        components: {
            Icon
        },

        props: {
            dataObject: {
                type: Object as PropType<AbstractDataObject>,
                required: true,
            },
        },

        data() {
            return {
                featureRepository: inject(featureRepositoryKey),
            }
        },

        computed: {
            unavailableFeaturesNeeded() {
                return this.dataObject.entitlementsNeeded.filter(feature => this.featureRepository?.inactive(feature));
            },

            dataObjectText() {
                if (this.dataObject instanceof Command) {
                    return trans('features.not_available.action_not_executed');
                } else {
                    return '';
                }
            },
        },

        methods: {
            trans,

            tooltip(feature: Feature) {
                const tooltip = Feature.getNotAvailableTooltip(feature);
                return `${tooltip} ${this.dataObjectText}`;
            },

            text(feature: Feature) {
                const text = Feature.getNotAvailableText(feature);
                return `${text} ${this.dataObjectText}`;
            },
        },
    });
</script>

<style lang="scss">
    .feature-not-available {
        display: flex;
        flex-direction: row;
        gap: 6px;
        margin-bottom: var(--typo-spacing-default);

        svg.icon {
            flex-basis: 20px;
            width: 20px;
            height: 20px;
            color: var(--color-item-yellow);
        }

        p {
            display: inline-block;
            color: var(--color-item-yellow);
            font-family: var(--font-family-condensed);
            font-size: var(--font-size-small);
            line-height: var(--line-height-small);

            em {
                font-family: inherit;
                font-style: normal;
            }

        }
    }
</style>

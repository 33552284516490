<template>
    <span :class="cssClasses" v-shortcuts>

        <!-- Label -->
        <label v-if="label" :for="uid">{{ label }}</label>

        <!-- Tag text input -->
        <input v-if="!disabled" ref="input" :id="uid" type="text" v-bind="attributes" @keyup="onKeyUp" @focus="onFocusInput" @blur="onBlur" />

        <!-- Error messages -->
        <span v-if="hasErrors && errorMsg" v-html="errorMsg" class="error-msg"></span>

        <!-- Suggestions tag list -->
        <span v-if="filteredSuggestions.length >= 1" class="tags tags-suggestions">
            <span v-for="(tag, index) in filteredSuggestions"
                v-focusable
                :key="'tag-suggestion-' + index + '-' + tag"
                class="tag tag-suggestion"
                :data-tag-add="tag"
                @click="onClickAddTag(tag, $event)"
                @blur="onBlur">{{ tag }}</span>
        </span>

        <!-- Tag list -->
        <span v-if="tags.length >= 1" class="tags">
            <span v-for="(tag, index) in tags"
                v-focusable
                :key="'tag-' + index + '-' + tag"
                ref="tag"
                :data-tag-delete="index"
                class="tag"
                @blur="onBlur">{{ tag }}<Icon v-if="!disabled" name="icon_close" class="icon-delete" @click="onClickRemoveTag(index, $event)" />
            </span>
        </span>

    </span>
</template>

<script>
    // Import classes:
    import { compareAlphabetical, shortId, trans } from '@/Utility/Helpers';
    import Icon from "@/Vue/Common/Icon.vue";

    const regexInvalidCharacters = /[^-_,.: \w]|['!?="/\\]/g;
    const regexSeparators = /[-_,.: ]/g;

    export default {
        name: 'TagList',
        components: {
            Icon
        },
        emits: [
            'change',
        ],
        props: {
            initialTags: {          // Initial list of tags (either use this or model+property!)
                type: Array,
                default() {
                    return [];
                }
            },
            suggestions: {          // List of tags for suggestion when the user is typing
                type: Array,
                default() {
                    return [];
                }
            },
            model: {                // Associated model reference
                type: Object,
                default: null
            },
            property: {             // Property name from the associated model that should be modified
                type: String,
                default: null
            },
            disabled: {             // Disabled state
                type: Boolean,
                default: false
            },
            required: {             // Required state
                type: Boolean,
                default: false
            },
            label: {                // Optional label text
                type: String,
                default: trans('labels.tags')
            },
            maxlength: {            // Maximum string length
                type: Number,
                default: 16
            },
            placeholder: {          // Placeholder text
                type: String,
                default: trans('labels.tag_placeholder')
            },
            errorMsg: {             // Error message text
                type: String,
                default: trans('labels.tag_error_required')
            },
        },
        data() {
            return {
                uid: shortId('textinput'),                  // A unique identifier for HTML id="" attribute
                tags: [],                                   // Tag list that is being modified
                filteredSuggestions: [],                    // List of filtered tag suggestions
                errors: {},                                 // Validation errors
                shortcuts: new Map([
                    ['Enter.prevent.stop', this.onShortcutEnter],   // Enter shortcut
                    ['Delete.stop', this.onShortcutDelete], // Delete shortcut
                    ['Duplicate.prevent.stop', null],       // Prevent browser behaviour
                    ['Save.prevent', null],                 // Prevent browser behaviour
                    ['Reload', null],                       // Allow reloading
                    ['Any.stop', null]                      // Allow any other shortcut but stop propagation
                ])
            }
        },
        computed: {

            /**
             * Additional attributes (e.g. for validation)
             *
             * @returns {Object}
             */
            attributes() {
                const attrs = {};

                // Maxlength:
                if (this.maxlength !== null && this.maxlength >= 1)
                {
                    attrs['maxlength'] = this.maxlength;
                }

                // Placeholder:
                if (this.placeholder !== null)
                {
                    attrs['placeholder'] = this.placeholder;
                }

                // Required:
                if (this.required === true)
                {
                    attrs['required'] = 'required';
                }

                // Disabled state:
                if (this.disabled === true)
                {
                    attrs['disabled'] = 'disabled';
                }

                return attrs;
            },

            /**
             * CSS classes for the checkbox
             *
             * @returns {String}
             */
            cssClasses() {
                const classes = ['tag-list'];

                // Has label:
                if (this.label) {
                    classes.push('has-label');
                }

                // Enabled/disabled state:
                classes.push((this.disabled === true) ? 'disabled' : 'enabled');

                // Required state:
                if (this.required === true)
                {
                    classes.push('required');
                }

                // Empty state:
                if (this.tags === null || this.tags.length === 0)
                {
                    classes.push('is-empty');
                }

                // Error state:
                if (this.hasErrors === true)
                {
                    classes.push('error');
                }

                return classes.join(' ');
            },

            /**
             * Validation
             *
             * @returns {Boolean}
             */
            hasErrors() {
                // Only trigger this after the component is being mounted:
                if (this.$refs.input === undefined)
                {
                    return false;
                }

                // Required?
                if (this.required === true && this.tags.length === 0)
                {
                    this.errors.required = true;
                }
                else
                {
                    delete this.errors.required;
                }

                // Maxlength?
                if (this.maxlength !== null && this.$refs.input.value.length > this.maxlength)
                {
                    this.errors.maxlength = true;
                }
                else
                {
                    delete this.errors.maxlength;
                }

                return (Object.keys(this.errors).length > 0);
            }
        },
        mounted() {

            // Check properties
            if (this.model !== null && this.property === null) {
                console.warn('TagList->mounted(): Property :model="" is set but no property="" name is given.', this);
            }
            if (this.model !== null && this.initialTags.length > 0) {
                console.warn('TagList->mounted(): Both :model="" and :initial-tags="" are set. You should use just one of them.', this);
            }

            // Set initial value:
            if (this.model !== null && this.property !== null && this.model[this.property] instanceof Array)
            {
                this.tags = this.model[this.property].sort(compareAlphabetical);
            }
            else
            {
                this.tags = (this.initialTags instanceof Array) ? this.initialTags.sort(compareAlphabetical) : [];
            }
        },
        methods: {

            /**
             * Get tag from input value
             *
             * @returns {String}
             */
            getTagValueFromInput() {

                if ([null, ''].includes(this.$refs.input.value)) {
                    return '';
                }

                // Get input value and remove illegal characters:
                const tag = this.$refs.input.value.replace(regexInvalidCharacters, '').replace(/\s+/g, ' ').trim();

                // Crop the tag length to maximum:
                return (this.maxlength >= 1) ? tag.substring(0, this.maxlength) : tag;
            },

            normalizeTag(tag) {
                return tag.toLowerCase().replace(regexSeparators, '').trim();
            },

            /**
             * Update filtered tag suggestions and return a tag if there's a match
             *
             * @param {String} tag
             * @returns {String}
             */
            getUpdatedFilteredSuggestion(tag = '') {

                // Filter suggestions list:
                if (tag.trim().length === 0)
                {
                    this.filteredSuggestions = this.suggestions;
                }
                else
                {
                    const searchTag = this.normalizeTag(tag);
                    this.filteredSuggestions = this.suggestions.filter(
                        t => t.includes(tag)
                            || (
                                searchTag !== '' && this.normalizeTag(t).includes(searchTag)
                            )
                    );

                    // Find match from suggestions:
                    const suggestionTag = (searchTag === '') ? null : this.filteredSuggestions.find(t => this.normalizeTag(t) === searchTag) || null;

                    // Continue using the suggested tag to avoid multiple tags with different uppercase/lowercase values in the final array:
                    if (suggestionTag !== null)
                    {
                        tag = suggestionTag;
                    }
                }

                // Remove duplicated values:
                this.filteredSuggestions = [...new Set(this.filteredSuggestions)].sort(compareAlphabetical);

                // Remove already existing tags from suggestions:
                const normalizedTags = this.tags.map(t => this.normalizeTag(t));
                this.filteredSuggestions = this.filteredSuggestions.filter(
                    t => !normalizedTags.includes(this.normalizeTag(t))
                );

                return tag;
            },

            onFocusInput() {
                // Show suggested tags when focusing on the component
                this.getUpdatedFilteredSuggestion(this.getTagValueFromInput());
            },

            onBlur(e) {
                // Clear the suggestions when user leaves the component
                if (!this.$el.contains(e.relatedTarget)) {
                    this.filteredSuggestions = [];
                }
            },

            /**
             * Keyup handler
             *
             * @param {KeyboardEvent} e
             */
            onKeyUp(e) {

                // Do nothing if disabled:
                if (this.disabled === true)
                {
                    return this;
                }

                // Close on Escape key:
                if (e.code === 'Escape')
                {
                    this.$refs.input.blur();
                    this.filteredSuggestions = [];
                    return this;
                }

                // Get input value and remove illegal characters:
                let tag = this.getTagValueFromInput();

                // Update input value if characters were removed:
                if (tag !== e.target.value.trim()) {
                    e.target.value = tag;
                }

                // Update filtered suggestions and get matching tag if there is one:
                tag = this.getUpdatedFilteredSuggestion(tag);

                // Create a new tag on [Enter]:
                if (e.code === 'Enter' && tag !== '')
                {
                    const normalizedTag = this.normalizeTag(tag);

                    // Clear the input value:
                    this.$refs.input.value = '';

                    // Clear the tag suggestions:
                    this.getUpdatedFilteredSuggestion();

                    // Add the tag to the list if it doesn't exist yet:
                    if (
                        !this.tags.some(
                            t => (
                                t === tag
                            ) || (
                                normalizedTag !== '' && this.normalizeTag(t) === normalizedTag
                            )
                        )
                    )
                    {
                        this.tags.push(tag);
                        this.tags.sort(compareAlphabetical);

                        // Trigger change event:
                        this.onChange(e);
                    }
                }
                return this;
            },

            /**
             * Click handler to add a tag from the suggestions list
             *
             * @param {String} tag
             * @param {MouseEvent} e
             */
            onClickAddTag(tag, e) {
                if (this.disabled) {
                    return this;
                }
                const normalizedTag = this.normalizeTag(tag);

                // Add the tag to the list if it doesn't exist yet:
                if (
                    !this.tags.some(
                        t => (
                            t === tag
                        ) || (
                            normalizedTag !== '' && this.normalizeTag(t) === normalizedTag
                        )
                    )
                )
                {
                    this.tags.push(tag);
                    this.tags.sort(compareAlphabetical);

                    // Trigger change event:
                    this.onChange(e);
                }

                // Set focus back on the input
                this.$refs.input.focus();

                return this;
            },

            /**
             * Click handler to remove a tag
             *
             * @param {Number} index
             * @param {MouseEvent|CustomEvent} e
             */
            onClickRemoveTag(index, e) {
                if (this.disabled === false)
                {
                    this.tags.splice(index, 1);
                    this.onChange(e);
                    this.$refs.input.focus();
                }
                return this;
            },

            /**
             * Change handler
             *
             * @param {KeyboardEvent|MouseEvent|CustomEvent} e
             */
            onChange(e) {
                if (this.disabled) {
                    return this;
                }

                // Update the filtered suggestions:
                this.getUpdatedFilteredSuggestion(this.getTagValueFromInput());

                // Update model property:
                if (this.model && this.property && this.model.hasOwnProperty(this.property)) {
                    this.model[this.property] = [...this.tags];
                }

                // Trigger change event on the parent:
                this.$emit('change', this.tags, e);

                return this;
            },

            /**
             * Shortcut handler for the Enter key
             *
             * @param {CustomEvent} e
             */
            onShortcutEnter(e) {
                const tag = e.target.dataset.tagAdd || null;
                if (tag !== null)
                {
                    return this.onClickAddTag(tag, e);
                }
                return this;
            },

            /**
             * Shortcut handler for Delete keys
             *
             * @param {CustomEvent} e
             */
            onShortcutDelete(e) {
                const index = e.target.dataset.tagDelete || null;
                if (index !== null)
                {
                    // Remove the tag:
                    this.onClickRemoveTag(index, e);

                    // Set focus on the previous tag:
                    this.$nextTick(() => (this.$refs.tag[Math.max(0, index-1)] || this.$refs.input).focus());

                }
                return this;
            }
        },
        watch: {

            initialTags()
            {
                if (this.model === null)
                {
                    this.tags = (this.initialTags instanceof Array) ? this.initialTags : [];
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <Collapsible class="inspector-panel panel-waypoints">
        <template #header>
            {{ trans('labels.waypoints') }} ({{ countWaypointsTotal }})
        </template>
        <template #body>

            <!-- Waypoints grouped by scenes -->
            <Collapsible
                v-for="group in waypointsByScenes"
                :key="'waypointgroup'+(group.scene ? group.scene.uid : 'global')"
                class="waypoints-group"
                :data-ref-uid="group.scene?.uid || 'no-ref'"
            >
                <template #header>
                    {{ group.scene ? `${trans('labels.scene')} ${group.scene.indexAndTitleFormatted}` : trans('labels.global') }} ({{ group.waypoints.length }})
                </template>
                <template #body>

                    <!-- Waypoints -->
                    <div v-for="(waypoint, wi) in group.waypoints" :key="'waypoint'+waypoint.uid" class="waypoint">
                        <Icon name="icon_environment" class="icon-waypoint" />

                        <!-- Title Editing -->
                        <TextInput
                            :model="waypoint"
                            property="title"
                            @change="onChangeWaypoint"
                            :maxlength="50"
                            :placeholder="trans('labels.waypoint') + ' ' + (wi + 1)"
                            :error-msg="trans('errors.waypoints.title')"
                        />

                        <Icon name="icon_delete" class="icon-delete" @click.stop="onClickRemoveWaypoint(waypoint)" />

                        <!-- Delete confirm overlay -->
                        <OverlayConfirmRemove
                            v-if="isRemoveConfirmDialogVisible === waypoint.uid"
                            @confirm="onConfirmWaypointRemove(group, waypoint)"
                            @cancel="onCancelWaypointRemove(waypoint)"
                        />
                    </div>

                </template>
            </Collapsible>

        </template>
    </Collapsible>
</template>

<script>
    // Import VueJS components:
    import OverlayConfirmRemove     from '@/Vue/Inspector/OverlayConfirmRemove.vue';

    // Import classes:
    import SceneObject              from '@/Models/UnitData/SceneObjects/SceneObject';
    import WaypointCollection       from '@/Models/Unity/WaypointCollection';
    import UnitData from "@/Models/UnitData/UnitData";

    export default {
        name: 'PanelWaypoints',
        emits: [
            'change',
        ],
        components: {
            OverlayConfirmRemove
        },
        props: {
            sceneObject: {                  // Selected SceneObject that the waypoints are assigned to
                type: SceneObject,
                default: null
            }
        },
        data() {
            return {
                isRemoveConfirmDialogVisible: null          // Whether a remove confirmation dialog is visible for a specific object (UID)
            }
        },
        computed: {

            /**
             * Get the total waypoint count
             *
             * @returns {Number}
             */
            countWaypointsTotal() {
                return (this.sceneObject !== null && this.sceneObject.waypoints instanceof WaypointCollection) ? this.sceneObject.waypoints.waypointsCount : 0;
            },

            /**
             * Get the waypoints grouped by scenes
             *
             * @returns {Object[]}
             */
            waypointsByScenes() {
                const waypoints = [{
                    scene: null,
                    waypoints: this.sceneObject.waypoints.global
                }];

                this.sceneObject.getParent(UnitData).scenes.forEach(s => {
                    waypoints.push({
                        scene: s,
                        waypoints: this.sceneObject.waypoints[s.uid] || []
                    });
                });

                return waypoints.filter(w => w.waypoints.length >= 1);
            }
        },
        methods: {

            /**
             * Click handler for waypoint remove button
             *
             * @param {Waypoint} waypoint
             */
            onClickRemoveWaypoint(waypoint) {
                this.isRemoveConfirmDialogVisible = waypoint.uid;
                return this;
            },

            /**
             * Confirm handler for trigger remove confimartion dialog
             *
             * @param {Object} group
             * @param {Waypoint} waypoint
             */
            onConfirmWaypointRemove(group, waypoint) {
                this.isRemoveConfirmDialogVisible = null;
                // Remove the waypoint:
                group.waypoints = group.waypoints.filter(w => w.uid !== waypoint.uid);
                this.sceneObject.waypoints[group.scene ? group.scene.uid : 'global'] = group.waypoints;
                this.$emit('change', this.sceneObject);
                return this;
            },

            /**
             * Cancel handler for trigger remove confimartion dialog
             *
             * @param {Waypoint} waypoint
             */
            onCancelWaypointRemove(waypoint) {
                this.isRemoveConfirmDialogVisible = null;
                return this;
            },

            /**
             * Change handler for a specific waypoint
             */
            onChangeWaypoint() {
                this.$emit('change', this.sceneObject);
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

    .waypoint {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 5px;
    }

</style>

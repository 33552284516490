<template>
    <main
        id="layout-main"
        ref="layout-main"
        class="model-viewer-index"
        :data-loading="isLoading"
        @drop.prevent="onDrop"
        @dragover.prevent="onDragover"
        v-shortcuts.global
    >
        <PageHeader
            :page-title="pageTitle"
            :page-subtitle="pageSubtitle"
            :buttons="headerButtons"
            :show-back-button="true"
        />

        <input
            ref="fileInput"
            class="file-input"
            type="file"
            webkitdirectory
            @change="onFileInputChanged"
        />

        <div class="model-viewer">

            <ThreejsModelRenderer
                ref="threejs-model-renderer"
                :model="model"
                :highlighted-object="highlightedObject"
                :highlighted-animation="highlightedAnimation"
                :auto-rotate-on-start="false"
            />

            <WidgetModelLayers
                :key="key"
                v-show="shouldShowLayerWidget"
                :scene="model"
                @mouseOverNode="onMouseOverNode"
                @mouseOutNode="onMouseOutNode"
                @hide="onClickHideWidget"
            />
            <ModelAnimationsWidget
                :key="key"
                v-show="shouldShowAnimationWidget"
                :animations="model && model.animations ? model.animations : null"
                @mouseOverAnimation="onMouseOverAnimation"
                @mouseOutAnimation="onMouseOutAnimation"
                @hide="onClickHideWidget"
            />
        </div>

        <h3 class="file-name centered">{{fileName}}</h3>

        <div
            v-if="showLoadingIndicator || showInstructions"
            class="loader centered">
            <LoadingIndicator v-if="showLoadingIndicator"/>
            <section class="instructions" v-if="showInstructions">
                <h3 v-html="trans('assets.model_viewer.drag_and_drop_instructions')"></h3>
            </section>
        </div>

        <!-- Asset create dialog -->
        <ModalCreateAsset
            :policies="assetPolicies"
            @success="onAssetImported"/>

        <ModalProgress/>
        <ModalNotification/>
        <ModalConfirmModelConversionIssues/>
    </main>
</template>

<script>
// Helpers
import {downloadFileFromUrl, sleep, trans,} from "@/Utility/Helpers";
import ThreejsModelLoader from "@/Utility/Threejs/ThreejsModelLoader";
import {parseUserFiles} from "@/Utility/Helpers/parseUserFiles";

// Classes
import EventType from "@/Utility/EventType";
import PageHeaderButton from "@/Utility/PageHeaderButton";
import AssetType from "@/Models/Asset/AssetType";

// Components
import WidgetModelLayers from "@/Vue/Modals/AssetPreview/WidgetModelLayers";
import LoadingIndicator from "@/Vue/Common/LoadingIndicator";
import ModalNotification from "@/Vue/Modals/ModalNotification";
import ModelAnimationsWidget from "@/Vue/Modals/AssetPreview/WidgetModelAnimations.vue";
import ThreejsModelRenderer from "@/Vue/Modals/AssetPreview/ThreejsModelRenderer.vue";
import ModalCreateAsset from "@/Vue/Modals/ModalCreateAsset.vue";
import ModalConfirmModelConversionIssues from "@/Vue/Modals/ModalConfirmModelConversionIssues.vue";
import {AssetPolicyStandard} from "@/Models/Asset/AssetPolicy";
import ModalProgress from "@/Vue/Modals/ModalProgress.vue";

export default {
        name: "PageModelViewer",

        components: {
            ModalProgress,
            ModalConfirmModelConversionIssues,
            ModalCreateAsset,
            ThreejsModelRenderer,
            ModelAnimationsWidget,
            LoadingIndicator,
            WidgetModelLayers,
            ModalNotification,
        },

        props: {
            assetPolicies: {
                type: Array,
                default() {
                  return [AssetPolicyStandard.type];
                }
            },
        },

        data() {
            return {
                id: 0,
                pageTitle: trans('assets.model_viewer.title'),
                pageSubtitle: trans('assets.model_viewer.subtitle'),
                fileName: null,
                layerWidgetShouldBeVisible: true,
                animationWidgetShouldBeVisible: false,
                isLoading: false,
                model: null,
                highlightedObject: null,
                highlightedAnimation: null,

                /**
                 * Shortcut mapping to methods
                 * @type Map
                 */
                shortcuts: new Map([
                    ['OS+L.prevent', this.onToggleLayerWidget],
                    ['Ctrl+L.prevent', this.onToggleLayerWidget],
                    ['OS+I.prevent', this.onToggleAnimationWidget],
                    ['Ctrl+I.prevent', this.onToggleAnimationWidget],
                    ['OS+O.prevent', this.onOpenFiles],
                    ['Ctrl+O.prevent', this.onOpenFiles],
                    ['Escape.prevent', this.onCloseWidgets],
                ]),
            }
        },

        mounted() {
            this.$globalEvents.on(EventType.HEADER_NAVIGATION_BUTTON_CLICK, this.onClickHeaderNav);
        },

        beforeUnmount() {
            this.$globalEvents.off(EventType.HEADER_NAVIGATION_BUTTON_CLICK, this.onClickHeaderNav);
        },

        computed: {

            headerButtons() {
                const commonButtons = {
                    test: new PageHeaderButton({
                        caption: trans('labels.open'),
                        icon: 'icon_folder_open',
                        callback: this.onOpenFiles,
                        disabled: this.isLoading,
                        tooltip: 'tooltips.buttons.tools.model_viewer.open_directory',
                    }),
                };

                const modelButtons = (this.model === null) ? {} : {
                    toggleLayers: new PageHeaderButton({
                        caption: trans('labels.layers'),
                        icon: 'icon_layers',
                        callback: this.onToggleLayerWidget,
                        tooltip: 'tooltips.buttons.assets.show_layers',
                    }),
                    toggleAnimations: new PageHeaderButton({
                        caption: trans('labels.animations'),
                        icon: 'icon_animation',
                        callback: this.onToggleAnimationWidget,
                        disabled: !this.modelHasAnimations,
                        tooltip: this.modelHasAnimations ? 'tooltips.buttons.assets.show_animations' : 'tooltips.buttons.assets.does_not_support_animations',
                    }),
                    reset: new PageHeaderButton({
                        caption: trans('labels.reset'),
                        icon: 'icon_world-setup',
                        callback: this.resetControls,
                    }),
                    clear: new PageHeaderButton({
                        caption: trans('labels.clear'),
                        icon: 'icon_delete',
                        callback: this.unloadOldModel,
                    }),
                    download: new PageHeaderButton({
                        caption: trans('labels.download'),
                        icon: 'icon_download',
                        callback: this.downloadGlbWithUserFeedback,
                    }),
                    import: new PageHeaderButton({
                        caption: trans('labels.import'),
                        style: 'button',
                        callback: this.importGlb,
                    }),
                };

                return {...commonButtons, ...modelButtons};
            },

            key() {
                return 'widgetGltfLayers_' + this.id;
            },

            showInstructions() {
                return this.model === null && this.isLoading === false;
            },

            showLoadingIndicator() {
                return this.isLoading;
            },

            shouldShowLayerWidget() {
                return this.model !== null && this.layerWidgetShouldBeVisible === true && !this.showLoadingIndicator;
            },

            shouldShowAnimationWidget() {
                return this.model !== null && this.animationWidgetShouldBeVisible === true && !this.showLoadingIndicator;
            },

            modelHasAnimations() {
                return this.model && this.model.animations && Array.isArray(this.model.animations) && this.model.animations.length > 0;
            },
        },

        methods: {

            /**
             * Click handler for header navigation buttons that delegates the action to the button callback method
             *
             * @param {PageHeaderButton} buttonConfig
             */
            onClickHeaderNav(buttonConfig) {
                if (buttonConfig.callback === null) {
                    return this;
                }

                buttonConfig.callback.call(this, buttonConfig);
                return this;
            },

            onClickHideWidget() {
                this.animationWidgetShouldBeVisible = false;
                this.layerWidgetShouldBeVisible = false;
            },

            /**
             * @param {Event} e
             */
            async onFileInputChanged(e) {
                const fileName = e.target.files[0].webkitRelativePath.split('/')[0];
                await this.loadFiles(e.target.files, fileName, trans('errors.asset.open_model_file_not_found'));

                // clear input field value, so the same model can be uploaded later on
                e.target.value = null;
            },

            /**
             * @param {DragEvent} e
             */
            onDragover(e) {
                e.dataTransfer.dropEffect = 'copy';
            },

            /**
             * @param {DragEvent} e
             */
            async onDrop(e) {
                const transferItem = e.dataTransfer.items[0];
                const fileName = transferItem.webkitGetAsEntry().name;

                await this.loadFiles(e.dataTransfer.items, fileName, trans('errors.asset.drag_drop_model_file_not_found'));
            },

            /**
             * @param {DataTransferItem|DataTransferItemList|FileList} files
             * @param {String} fileName
             * @param {String} noModelFoundMessage
             */
            async loadFiles(files, fileName, noModelFoundMessage) {
                let fileToLoad;

                const userFiles = await parseUserFiles(files);

                try {
                    this.onLoadingStarted();

                    fileToLoad = userFiles.find(entry =>
                        (entry.isDroppedFile && entry.isGlbFile) || // single dropped glb file
                        (entry.isDroppedFile && entry.isFbxFile) || // single dropped fbx file
                        (entry.isDroppedFile && entry.isObjFile) || // single dropped obj file
                        (entry.isFileInRootLevelDirectory && entry.isGlbFile) || // glb in root directory
                        (entry.isFileInRootLevelDirectory && entry.isGltfFile) || // gltf in root directory
                        (entry.isFileInRootLevelDirectory && entry.isFbxFile) || // fbx in root directory
                        (entry.isFileInRootLevelDirectory && entry.isObjFile) // obj in root directory
                    );

                    if (fileToLoad === undefined) {
                        // noinspection ExceptionCaughtLocallyJS
                        throw new Error(noModelFoundMessage);
                    }
                } catch (error) {
                    this.onLoadModelFileError(error);
                    return;
                }

                this.unloadOldModel();

                this.fileName = fileName;

                await this.parseModelFile(fileToLoad, userFiles)
                    .then(() => {
                        if (!fileToLoad.isGlbFile && !fileToLoad.isGltfFile) {
                            // difficult to convert - display warning
                            this.$globalEvents.emit(EventType.MODAL_CONFIRM_MODEL_CONVERSION_ISSUES_SHOW);
                        }
                    });
            },

            onModelParseError(error) {
                this.onLoadingEnded();
                this.$root.showErrorDialog(error.message);
            },

            /**
             * @param {Group} model
             */
            onModelParseSuccess(model) {
                this.model = model;
                this.onLoadingEnded();
            },

            onLoadingEnded() {
                this.isLoading = false;
            },

            onLoadingStarted() {
                this.isLoading = true;
            },

            /**
             * @param {Error} error
             */
            onLoadModelFileError(error) {
                this.onLoadingEnded();
                this.$root.showErrorDialog(error.message);
            },

            /**
             * @param {Asset} asset
             */
            onAssetImported(asset) {
                this.$toast.success(trans('assets.modals.import_success', {
                    title: asset.title
                }));
            },

            /**
             * @param {UserFile} fileToLoad content to load
             * @param {UserFile[]} files other related files that might be relevant for loading
             */
            async parseModelFile(fileToLoad, files) {
                const loader = new ThreejsModelLoader(fileToLoad, files);
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));

                try {
                    const model = await loader.load();
                    this.onModelParseSuccess(model);
                } catch (error) {
                    this.onModelParseError(error);
                } finally {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                }
            },

            onMouseOutNode() {
                this.highlightedObject = null;
            },

            onMouseOverNode(obj) {
                this.highlightedObject = obj;
            },

            onMouseOutAnimation() {
                this.highlightedAnimation = null;
            },

            onMouseOverAnimation(animation) {
                this.highlightedAnimation = animation;
            },

            onOpenFiles() {
                this.$refs.fileInput.click();
            },

            onToggleLayerWidget() {
                this.layerWidgetShouldBeVisible = !this.layerWidgetShouldBeVisible;

                if (this.layerWidgetShouldBeVisible) {
                    this.animationWidgetShouldBeVisible = false;
                }
            },

            onToggleAnimationWidget() {
                this.animationWidgetShouldBeVisible = !this.animationWidgetShouldBeVisible;

                if (this.animationWidgetShouldBeVisible) {
                    this.layerWidgetShouldBeVisible = false;
                }
            },

            onCloseWidgets(e) {
                if (this.layerWidgetShouldBeVisible || this.animationWidgetShouldBeVisible) {
                    e.stopPropagation();
                }

                this.layerWidgetShouldBeVisible = false;
                this.animationWidgetShouldBeVisible = false;
            },

            resetControls() {
                return this.$refs["threejs-model-renderer"].resetView();
            },

            unloadOldModel() {
                this.fileName = null;
                this.model = null;
            },

            importGlb() {
                try {
                    /**
                     * @type {ModalCreateAssetShowEventParameters}
                     */
                    const createAssetEvent = {
                        assetTypes: [
                            AssetType.Model3D,
                            AssetType.EnvironmentModel3D,
                        ],
                        initialAssetTitle: this.fileName,
                        initialPreviewImageUrl: this.$refs["threejs-model-renderer"].getPreviewImageUrl(),
                        assetFileDownloader: () => this.getGlbFile(),
                    };
                    this.$globalEvents.emit(EventType.MODAL_CREATE_ASSET_SHOW, createAssetEvent);
                } catch (error) {
                    this.$root.showErrorDialog(error.message);
                }
            },

            async downloadGlbWithUserFeedback() {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));

                // delay a bit, so the dialog has time to show up
                await sleep(100);

                try {
                    await this.downloadGlb();
                } catch (error) {
                    this.$root.showErrorDialog(error.message);
                } finally {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                }
            },

            async downloadGlb() {
                const glbFile = await this.getGlbFile();

                const fileUrl = URL.createObjectURL(glbFile);
                downloadFileFromUrl(fileUrl, glbFile.name);
                URL.revokeObjectURL(fileUrl);
            },

            /**
             * @return {Promise<File>}
             */
            async getGlbFile() {
                return this.$refs["threejs-model-renderer"].getGlbFile(this.getDownloadGlbFileName());
            },

            getDownloadGlbFileName() {
                const name = this.fileName.replace(new RegExp('\\.[a-z]{3}$'), '');
                return `${name}.glb`;
            },
        },
    }
</script>

<style lang="scss">
    .model-viewer-index {

        .file-input {
            display: none;
        }

        .widget-model-animations,
        .widget-model-layers {
            left: 50px;
        }

        .model-viewer {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .instructions {

            h3 {
                line-height: 140%;
            }

            code {
                font-family: var(--font-family-mono-bold);
                color: var(--color-primary);
            }
        }

        .centered {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        .file-name {
            bottom: 8px;
            top: auto;
        }

        .loader {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
        }

        // TODO: Remove when bootstrap is removed
        a.btn.disabled {
            pointer-events: auto;
        }
    }

</style>

<template>
    <div class="container"
         v-if="canReadFeatures"
         :data-loading="isLoading"
         :data-saving="isSaving">

        <div class="row justify-content-left">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">{{ headline }}</div>

                    <div class="card-body">
                        <table class="feature-list">
                            <tr>
                                <th>{{ trans('labels.feature') }}</th>
                                <th>{{ trans('labels.created_at') }}</th>
                                <th></th>
                            </tr>

                            <LocalFeatureListItem
                                v-for="feature in features"
                                :key="feature.uid"
                                :feature="feature"
                                :can-delete="canDeleteFeatures"
                                @click-remove="onRemoveClicked"
                            />

                            <tr v-if="features.length === 0">
                                <td colspan="3" class="cell-no-items">
                                    <NoItemsAvailable />
                                </td>
                            </tr>

                            <AddLocalFeatureForm
                                v-if="canCreateFeatures"
                                :feature-names="unusedFeatureNames"
                                @click-add="onAddClicked"
                            />
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <ModalProgress v-if="enableDialogs" />
        <ModalNotification v-if="enableDialogs"/>

    </div>
</template>

<script>
import EventType from "@/Utility/EventType";
import AuthorizationError from "@/Errors/AuthorizationError";
import LocalFeatureListItem from "@/Vue/Features/Local/LocalFeatureListItem.vue";
import {permission, sortArrayByProperty, trans} from "@/Utility/Helpers";
import AddLocalFeatureForm from "@/Vue/Features/Local/AddLocalFeatureForm.vue";
import ModalNotification from "@/Vue/Modals/ModalNotification.vue";
import {Permission} from "@/Models/User/Permission";
import NoItemsAvailable from "@/Vue/Search/NoItemsAvailable.vue";
import {Feature} from "@/Models/Features/Feature";
import {inject} from "vue";
import {localFeatureServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
import ModalProgress from "@/Vue/Modals/ModalProgress.vue";

export default {
    name: "ManageLocalFeaturesForm",

    components: {
        ModalProgress,
        NoItemsAvailable,
        ModalNotification,
        AddLocalFeatureForm,
        LocalFeatureListItem,
    },

    props: {
        /**
         * Will display tenant specific features if set to a tenant uid.
         * Set it to null to manage instance features only.
         */
        tenantUid: {
            type: String,
            default: null
        },
        /**
         * Names of all the features that are known to this instance.
         */
        featureNames: {
            type: Array,
            default: Feature.all().map(feature => Feature.getName(feature))
        },
        /**
         * If false, the parent page has to provide error, loading and saving dialog.
         */
        enableDialogs: {
            type: Boolean,
            default: true
        },
    },

    data() {
        return {
            /**
             * @type {LocalFeatureService}
             */
            featureService: inject(localFeatureServiceKey),

            /**
             @type {LocalFeature[]}
             */
            features: [],
        }
    },

    mounted() {
        if (this.canReadFeatures) {
            this.fetchFeatures();
        }
    },

    computed: {

        /**
         * @return {Boolean}
         */
        canReadFeatures() {
            return permission(Permission.LocalFeaturesRead());
        },

        /**
         * @return {Boolean}
         */
        canCreateFeatures() {
            return permission(Permission.LocalFeaturesCreate());
        },

        /**
         * @return {Boolean}
         */
        canDeleteFeatures() {
            return permission(Permission.LocalFeaturesDelete());
        },

        /**
         * @returns {Boolean}
         */
        isLoading() {
            if (this.featureService.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        /**
         * @returns {Boolean}
         */
        isSaving() {
            if (this.featureService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        /**
         * @return {String}
         */
        headline() {
            return trans(
                this.tenantUid === null ?
                    'features.local.manage.headline_instance' :
                    'features.local.manage.headline_tenant'
            )
        },

        /**
         * @return String[] list of feature names not yet taken
         */
        unusedFeatureNames() {
            return this.featureNames
                .filter(name => this.features.every(existingFeature => existingFeature.name !== name));
        }
    },

    methods: {

        fetchFeatures() {
            this.featureService
                .fetchLocalFeatures()
                .then(this.onFeaturesChanged)
                .catch(this.onErrorApi);
        },

        onFeaturesChanged() {
            this.features = sortArrayByProperty(
                this.featureService.features.filter(feature => feature.tenant_uid === this.tenantUid),
                'name'
            );
        },

        /**
         * Error handler for API errors
         * @param {String} error
         */
        onErrorApi(error) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root.forceLogout;
            }
            this.$root.showErrorDialog(error);
        },

        /**
         * @param {LocalFeature} feature
         */
        onRemoveClicked(feature) {
            this.featureService
                .deleteLocalFeature(feature.uid)
                .then(this.onFeaturesChanged)
                .catch(this.onErrorApi);
        },

        /**
         * @param {string} featureName
         */
        onAddClicked(featureName) {
            this.featureService
                .createLocalFeature(featureName, this.tenantUid)
                .then(this.onFeaturesChanged)
                .catch(this.onErrorApi);
        },
    },
}
</script>

<style scoped lang="scss">

.feature-list {
    width: 100%;

    .cell-no-items {
        padding: 16px;
    }
}

</style>

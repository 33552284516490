<template>
    <div class="learning-record-properties" v-shortcuts.stop>

        <label v-if="(hasPropertyCompletion || hasPropertySuccess || hasPropertyResponse)">
            {{ trans('commands.learning_record.labels.result') }}:
        </label>

        <!-- completed -->
        <div v-if="hasPropertyCompletion" class="property learning-record-property learning-record-property-dropdown">
            <Icon name="icon_close" class="icon-delete" @click="removeProperty('completion')" />
            <Dropdown
                :label="trans('commands.learning_record.labels.result_properties.completion')"
                :key="'statement_result_completion_'+command.uid+'_'+command.statement.result.completion"
                :model="command.statement.result"
                property="completion"
                @select="onChangeProperty"
                :options="targetOptionsForPropertyCompletion"
                :required="true"
                class="no-wrap"
            />
        </div>

        <!-- success -->
        <div v-if="hasPropertySuccess" class="property learning-record-property learning-record-property-dropdown">
            <Icon name="icon_close" class="icon-delete" @click="removeProperty('success')" />
            <Dropdown
                :label="trans('commands.learning_record.labels.result_properties.success')"
                :key="'statement_result_success_'+command.uid+'_'+command.statement.result.success"
                :model="command.statement.result"
                property="success"
                @select="onChangeProperty"
                :options="targetOptionsForPropertySuccess"
                :required="true"
                class="no-wrap"
            />
        </div>

        <!-- response -->
        <div v-if="hasPropertyResponse" class="property learning-record-property learning-record-property-dropdown">
            <Icon name="icon_close" class="icon-delete" @click="removeProperty('response')" />
            <TextInput
                class="no-wrap"
                :key="'statement_result_response_'+command.uid"
                :model="command.statement.result"
                property="response"
                :required="!command.isValid"
                :maxlength="100"
                :label="trans('commands.learning_record.labels.result_properties.response')"
                @change="onChangeProperty"
            />
        </div>

        <label v-if="hasPropertyScore && (hasPropertyScoreMin || hasPropertyScoreMax || hasPropertyScoreRaw || hasPropertyScoreScaled)">
            {{ trans('commands.learning_record.labels.result_properties.score') }}:
        </label>

        <div v-if="hasPropertyScoreMin" class="property learning-record-property learning-record-property-dropdown">
            <Icon name="icon_close" class="icon-delete" @click="removeScoreProperty('min')" />
            <TextInput
                v-tooltip="trans('commands.learning_record.labels.result_score_properties.min.type')"
                class="no-wrap"
                :key="'statement_result_score_min_'+command.uid"
                :model="command.statement.result.score"
                property="min"
                :required="!command.isValid"
                :maxlength="100"
                placeholder="0.0"
                :label="trans('commands.learning_record.labels.result_score_properties.min.name')"
                @change="onChangeProperty"
            />
        </div>

        <div v-if="hasPropertyScoreMax" class="property learning-record-property learning-record-property-dropdown">
            <Icon name="icon_close" class="icon-delete" @click="removeScoreProperty('max')" />
            <TextInput
                v-tooltip="trans('commands.learning_record.labels.result_score_properties.max.type')"
                class="no-wrap"
                :key="'statement_result_score_max_'+command.uid"
                :model="command.statement.result.score"
                property="max"
                :required="!command.isValid"
                :maxlength="100"
                placeholder="100.0"
                :label="trans('commands.learning_record.labels.result_score_properties.max.name')"
                @change="onChangeProperty"
            />
        </div>

        <div v-if="hasPropertyScoreRaw" class="property learning-record-property learning-record-property-dropdown">
            <Icon name="icon_close" class="icon-delete" @click="removeScoreProperty('raw')" />
            <TextInput
                v-tooltip="trans('commands.learning_record.labels.result_score_properties.raw.type')"
                class="no-wrap"
                :key="'statement_result_score_raw_'+command.uid"
                :model="command.statement.result.score"
                property="raw"
                :required="!command.isValid"
                :maxlength="100"
                placeholder="50.0"
                :label="trans('commands.learning_record.labels.result_score_properties.raw.name')"
                @change="onChangeProperty"
            />
        </div>

        <div v-if="hasPropertyScoreScaled" class="property learning-record-property learning-record-property-dropdown">
            <Icon name="icon_close" class="icon-delete" @click="removeScoreProperty('scaled')" />
            <TextInput
                v-tooltip="trans('commands.learning_record.labels.result_score_properties.scaled.type')"
                class="no-wrap"
                :key="'statement_result_score_scaled_'+command.uid"
                :model="command.statement.result.score"
                property="scaled"
                :required="!command.isValid"
                :maxlength="100"
                placeholder="0.0"
                :label="trans('commands.learning_record.labels.result_score_properties.scaled.name')"
                @change="onChangeProperty"
            />
        </div>

        <!-- Dropdown for new/unassigned property -->
        <div v-if="getPropertyOptionsForResultObject.length > 0" class="btn-add-with-dropdown">
            <!-- @TODO: Change the dropdown component to have a "button" style instead of using a fake button -->
            <ButtonCustom
                v-not-focusable
                icon="icon_add"
                :class="(!command.isValid ? 'required' : '')"
                :caption="trans('commands.learning_record.labels.add_result')"
                :disabled="getPropertyOptionsForResultObject.length === 0"
            />
            <Dropdown
                :key="keyForAddNewPropertyDropdown"
                @select="onSelectNewProperty"
                :options="getPropertyOptionsForResultObject"
                :required="true"
                :deselected-caption="trans('commands.learning_record.tooltips.add_result')"
                :disabled="getPropertyOptionsForResultObject.length === 0"
            />
        </div>
    </div>
</template>

<script>
// Import classes:
import {LearningRecordCommand} from '@/Models/UnitData/Commands/Command';
import DropdownOption           from '@/Utility/DropdownOption';
import { trans }                from '@/Utility/Helpers';
import ButtonCustom from "@/Vue/Common/ButtonCustom.vue";
import Dropdown from "@/Vue/Common/Dropdown.vue";
import Icon from "@/Vue/Common/Icon.vue";
import TextInput from "@/Vue/Common/TextInput.vue";

export default {
    name: 'PanelCommandLearningRecordResult',
    components: {TextInput, Icon, Dropdown, ButtonCustom},
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: LearningRecordCommand,
            default: null
        }
    },

    data() {
        return {
            shortcuts: new Map([
                ['Backspace', null],                    // Allow backspace key
            ])
        }
    },

    computed: {

        /**
         * @returns {String}
         */
        keyForAddNewPropertyDropdown() {
            const suffix = this.command.statement.result ? Object.keys(this.command.statement.result).length : '';
            return 'dropdown-' + this.command.uid + '-' + suffix;
        },

        /**
         * Get property options for the dropdown
         *
         * @returns {DropdownOption[]}
         */
        getPropertyOptionsForResultObject() {
            const addedProperties = this.command.statement?.result ? Object.keys(this.command.statement.result) : [];
            const resultOptions = [
                'completion',
                'success',
                'response',
            ].filter(property => !addedProperties.includes(property))
            .map(property =>
                new DropdownOption({
                    caption: trans('commands.learning_record.labels.result_properties.' + property),
                    disabled: false,
                    value: property,
                })
            );

            const addedScoreProperties = this.command.statement?.result?.score ? Object.keys(this.command.statement.result.score) : [];

            const scoreOptions = [
                'min',
                'max',
                'raw',
                'scaled',
            ].filter(property => !addedScoreProperties.includes(property))
            .map(property =>
                new DropdownOption({
                    caption: trans('commands.learning_record.labels.result_score_properties.' + property + '.name'),
                    title: trans('commands.learning_record.labels.result_score_properties.' + property + '.description'),
                    disabled: false,
                    value: property,
                })
            );

            let options = [
                ...resultOptions,
            ];

            if (resultOptions.length > 0) {
                options = [
                    new DropdownOption({
                        isSeparator: true,
                        caption: trans('commands.learning_record.labels.result'),
                    }),
                    ...options,
                ];
            }

            if (scoreOptions.length > 0) {
                options = [
                    ...options,
                    new DropdownOption({
                        isSeparator: true,
                        caption: trans('commands.learning_record.labels.result_properties.score'),
                    }),
                ];
            }

            options = [
                ...options,
                ...scoreOptions,
            ];

            return options;
        },

        /**
         * @returns {Boolean}
         */
        hasPropertyCompletion() {
            return typeof this.command?.statement?.result?.completion === 'boolean';
        },

        /**
         * @returns {Boolean}
         */
        hasPropertySuccess() {
            return typeof this.command?.statement?.result?.success === 'boolean';
        },

        /**
         * @returns {Boolean}
         */
        hasPropertyResponse() {
            return (
                typeof this.command?.statement?.result?.response === 'string'
                || (
                    typeof this.command?.statement?.result?.response === 'object'
                    && this.command?.statement?.result?.response === null
                )
            );
        },

        hasPropertyScore() {
            return (
                typeof this.command?.statement?.result?.score === 'object'
                && this.command?.statement?.result?.score !== null
            );
        },

        hasPropertyScoreRaw() {
            return typeof this.command?.statement?.result?.score?.raw === 'string';
        },

        hasPropertyScoreMin() {
            return typeof this.command?.statement?.result?.score?.min === 'string';
        },

        hasPropertyScoreMax() {
            return typeof this.command?.statement?.result?.score?.max === 'string';
        },

        hasPropertyScoreScaled() {
            return typeof this.command?.statement?.result?.score?.scaled === 'string';
        },

        statementHasPropertyResult() {
            return typeof this.command?.statement?.result === 'object' && this.command?.statement?.result !== null;
        },

        targetOptionsForPropertyCompletion() {
            return [
                // ON:
                new DropdownOption({
                    caption: trans('labels.yes').toUpperCase(),
                    disabled: false,
                    value: true,
                }),

                // OFF:
                new DropdownOption({
                    caption: trans('labels.no').toUpperCase(),
                    disabled: false,
                    value: false,
                })
            ];
        },

        targetOptionsForPropertySuccess() {
            return [
                // ON:
                new DropdownOption({
                    caption: trans('labels.yes').toUpperCase(),
                    disabled: false,
                    value: true,
                }),

                // OFF:
                new DropdownOption({
                    caption: trans('labels.no').toUpperCase(),
                    disabled: false,
                    value: false,
                })
            ];
        }
    },

    methods: {

        /**
         *  Change handler for the enabled property
         */
        onChangeProperty() {
            this.$emit('change', this.command);
            return this;
        },

        /**
         * Select handler for new properties
         *
         * @param {String} property
         */
        onSelectNewProperty(property) {
            // Make sure command.statement.result is an object before adding a new property
            if (!this.statementHasPropertyResult) {
                this.command.statement.result  = new Object(null);
            }

            // Booleans
            if (['completion', 'success'].includes(property)) {
                this.command.statement.result[property] = true;
            }

            // Strings
            if (['response'].includes(property)) {
                this.command.statement.result[property] = '';
            }

            // score - strings
            if (['min', 'max', 'raw', 'scaled'].includes(property)) {
                if (!this.hasPropertyScore) {
                    if (this.statementHasPropertyResult) {
                        this.command.statement.result = new Object(null);
                    }
                    this.command.statement.result.score = new Object(null);
                }
                this.command.statement.result.score[property] = '';
            }

            this.$emit('change', this.command);

            return this;
        },

        /**
         * Remove a property by its name
         *
         * @param {String} property
         */
        removeProperty(property) {

            delete this.command.statement.result[property];

            // Reset properties to null if it is empty
            if (Object.keys(this.command.statement.result).length === 0) {
                this.command.statement.result = new Object(null);
            }

            this.$emit('change', this.command);
            return this;
        },

        /**
         * Remove a property by its name
         *
         * @param {String} property
         */
        removeScoreProperty(property) {

            delete this.command.statement.result?.score[property];

            // Reset properties to null if it is empty
            if (Object.keys(this.command.statement.result.score).length === 0) {
                this.command.statement.result.score = new Object(null);
            }

            this.$emit('change', this.command);
            return this;
        },
    },
}
</script>

<template>
    <div class="panel-card property-logoimage">

        <header>{{ trans('authoring.logo_image') }}</header>

        <!-- Button for adding an image -->
        <ButtonPrimary v-if="sceneObject.logo_uid === null" icon="icon_add" class="btn-add-asset" caption="labels.add_image" @trigger="onClickAddLogoImage" />

        <!-- The assigned logo image -->
        <div v-else class="assigned-asset">
            <div v-if="assignedLogoImage" class="preview preview-image-pointer" @click="onClickShowAssetPreview(assignedLogoImage)">
                <Icon :name="assignedLogoImage.assetType.previewIcon" />
                <img :src="assignedLogoImage.preview" alt="" />
            </div>
            <div class="info">
                <span class="title">
                    {{ (assignedLogoImage) ? assignedLogoImage.title : trans('labels.asset_unknown') }}
                </span>
            </div>
            <div class="btn-delete">
                <Icon name="icon_delete" class="icon-delete" @click="onClickRemoveLogoImage" />
            </div>

            <!-- Delete confirm overlay -->
            <OverlayConfirmRemove v-if="isRemoveConfirmDialogVisible !== null && isRemoveConfirmDialogVisible === sceneObject.logo_uid" @confirm="onConfirmLogoImageRemove" @cancel="onCancelLogoImageRemove" />
        </div>

    </div>
</template>

<script>

import Asset                    from '@/Models/Asset/Asset';
import AssetType                from '@/Models/Asset/AssetType';
import CommandTargetType        from '@/Models/UnitData/Commands/CommandTargetType';
import EventType                from '@/Utility/EventType';
import OverlayConfirmRemove     from '@/Vue/Inspector/OverlayConfirmRemove.vue';
import SceneObject              from '@/Models/UnitData/SceneObjects/SceneObject';
import UnitRevision from "@/Models/Unit/UnitRevision";

export default {
    name: 'SceneObjectPropertyLogoImage',
    emits: [
        'change',
    ],
    components: {
        OverlayConfirmRemove,
    },
    props: {
        sceneObject: {
            type: SceneObject,
            default: null,
        }
    },
    data() {
        return {
            isRemoveConfirmDialogVisible: null,     // Whether a remove confirmation dialog is visible for a specific object (UID)
        }
    },
    computed: {

        /**
         * @returns {UnitRevision|null}
         */
        unitRevision() {
            return this.sceneObject?.getParent(UnitRevision) || null;
        },

        /**
         * Get data for the assigned logo image
         *
         * @returns {Asset|null}
         */
        assignedLogoImage() {
            if (this.sceneObject !== null && this.unitRevision !== null && typeof this.sceneObject.logo_uid === 'string' && this.unitRevision.assets[this.sceneObject.logo_uid] instanceof Asset)
            {
                return this.unitRevision.assets[this.sceneObject.logo_uid];
            }
            return null;
        },
    },
    methods: {

        /**
         * Click handler for add logo button
         */
        onClickAddLogoImage() {
            // Determine which types of assets can be selected:
            this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_FILTERS_SET, CommandTargetType.Image.filters);
            this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_SHOW, {
                callback: this.onSelectLogoImage,
                assetTypes: [
                    AssetType.Image,
                ],
                clearWordFilter: true,
            });
            return this;
        },

        /**
         * Click handler for remove logo button
         */
        onClickRemoveLogoImage() {
            this.isRemoveConfirmDialogVisible = this.sceneObject.logo_uid;
            return this;
        },

        /**
         * Show Asset preview modal for asset
         *
         * @param {Asset} asset
         */
        onClickShowAssetPreview(asset) {
            if (asset.supportsPreview)
            {
                this.$globalEvents.emit(EventType.MODAL_ASSET_PREVIEW_SHOW, asset);
            }
            return this;
        },

        /**
         * Confirm handler for logo remove confirmation dialog
         */
        onConfirmLogoImageRemove() {
            this.isRemoveConfirmDialogVisible = null;
            // Remove the logo image reference:
            this.sceneObject.logo_uid = null;
            this.$emit('change', this.sceneObject);
            return this;
        },

        /**
         * Cancel handler for logo remove confirmation dialog
         */
        onCancelLogoImageRemove() {
            this.isRemoveConfirmDialogVisible = null;
            return this;
        },

        /**
         * Select handler for assigning a logo image
         *
         * @param {Asset} asset
         */
        onSelectLogoImage(asset) {
            // Add the selected asset to the unit:
            this.unitRevision.addAssets(asset);
            // Set the logo_uid value:
            this.sceneObject.logo_uid = asset.uid;
            this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_HIDE);
            this.$emit('change', this.sceneObject);
            return this;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>

import {Permission} from '@/Models/User/Permission';
import {permission} from '@/Utility/Helpers';
import type User from "@/Models/User/User";
import type Tenant from "@/Models/Tenant/Tenant";

/**
 * Policy for checking user abilities and permissions.
 * Method names for abilities should map to permissions.
 *
 * e.g. Permission.TenantsUpdate() = 'tenants:update' => update() {}
 *
 * Policies can be registered in Gate instances:
 *
 * e.g. window.gate.policy('Tenant', new TenantPolicy);
 *
 * A registered policy can then be used via the gate. The gate chooses which policy to use based on the provided model
 * and the registered policies.
 *
 * e.g. window.gate.allows(Permission.ability(Permission.TenantsUpdate()), tenant);
 *
 */
export default class TenantPolicy {

    /**
     * Determine whether the user can read the tenant.
     */
    read(user: User, tenant: Tenant): boolean {
        if (permission(Permission.TenantsReadAny())) {
            return true;
        }

        if (!user.isCurrentTenant(tenant)) {
            return false;
        }

        return permission(Permission.TenantsRead());
    }

    /**
     * Determine whether the user can update the tenant.
     */
    update(user: User, tenant: Tenant): boolean {
        if (permission(Permission.TenantsUpdateAny())) {
            return true;
        }

        if (!user.isCurrentTenant(tenant)) {
            return false;
        }

        return permission(Permission.TenantsUpdate());
    }

    /**
     * Determine whether the user can delete the tenant.
     */
    delete(user: User, tenant: Tenant): boolean {
        if (tenant.isAnyDefaultTenant) {
            return false;
        }

        return permission(Permission.TenantsDeleteAny());
    }
}

<template>

</template>

<script>

import User from "@/Models/User/User";
import {Feature} from "@/Models/Features/Feature";
import {featureRepositoryKey, trackingServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
import {inject} from "vue";

export default {
    name: "Userguiding",

    props: {
        currentUser: {
            type: User,
            default: new User(window.currentUser)
        },
        appData: {
            type: Object,
            default: window.appData
        },
    },

    data() {
        return {
            featureRepository: inject(featureRepositoryKey),
            trackingService: inject(trackingServiceKey),
            eventMap: new Map([
                ['onload', this.handleOnload],
                ['onIdentificationComplete', this.handleOnIdentificationComplete],
                ['onPreviewEnd', this.handleOnPreviewEnd],
            ])
        }
    },

    mounted() {
        this.initUserGuiding();

        if (this.currentUser !== null) {
            this.registerEvents();
        }
    },

    methods: {

        registerEvents() {
            for (const [eventName, handler] of this.eventMap) {
                window.userGuidingLayer.push({
                    event: eventName,
                    fn: handler
                });
            }
        },

        handleOnload() {
            const userInfo = {
                firstname: this.currentUser.firstname,
                lastname: this.currentUser.lastname,
                created_at: this.currentUser.created_at,
                role: this.currentUser.role,
                tenant_role: this.currentUser.tenant_role.name,
            };

            const instanceInfo = {
                domain: (new URL(this.appData.URL_HOME)).hostname,
                release: this.appData.APP_RELEASE,
                version: this.appData.APP_VERSION,
            };

            const features = {};
            Feature.all().forEach(feature => {
                features[Feature.getName(feature)] = this.featureRepository.active(feature);
            });

            window.userGuiding.identify(this.currentUser.uid, {
                ...userInfo,
                ...instanceInfo,
                ...features,
            });
        },

        handleOnIdentificationComplete() {
            // It doesn't work to just launch the resource center immediately, as the framework hasn't identified
            // the user yet...
            setTimeout(() => {
                window.userGuiding.launchResourceCenter(914);
            }, 2000);
        },

        handleOnPreviewEnd(data) {
            // Ignore canceled guides
            if (!data.completed) {
                return;
            }

            const guide = this.getGuideById(data.guideId);

            if (guide === null) {
                console.error(`Userguiding guide with id ${data.guideId} not found. This should not happen.`)
                return;
            }

            this.trackingService.trackTourEvent({
                tour_id: guide.id.toString(),
                tour_name: guide.title,
                event_type: 'tour_completed'
            });
        },

        initUserGuiding() {
            (function (g, u, i, d, e, s) {
                g[e] = g[e] || [];
                const f = u.getElementsByTagName(i)[0];
                const k = u.createElement(i);
                k.async = true;
                k.src = 'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js';
                f.parentNode.insertBefore(k, f);
                if (g[d]) return;
                const ug = g[d] = {q: []};
                ug.c = function (n) {
                    return function () {
                        ug.q.push([n, arguments])
                    };
                };
                const m = ['previewGuide', 'finishPreview', 'track', 'triggerNps', 'hideChecklist', 'launchChecklist'];
                for (let j = 0; j < m.length; j += 1) {
                    ug[m[j]] = ug.c(m[j]);
                }
            })(window, document, 'script', 'userGuiding', 'userGuidingLayer', '442513661ID');
        },

        /**
         * @param {number} id
         * @return {Object|null}
         */
        getGuideById(id) {
            const guides = window.userGuiding.getAppState().api.guides;
            return guides.find(guide => guide.id === id) || null;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

<template>
    <div
        :data-loading="isLoading"
        class="container">

        <PageImportListItem
            v-for="(content, packageName) in contentPackages"
            :content="content"
            :package="packageName"
        />

        <ModalProgress>
            <p class="description" v-html="trans('units.import.importing_text')"></p>
        </ModalProgress>
        <ModalNotification/>
    </div>
</template>

<script>
    import {shortId,trans} from "@/Utility/Helpers";
    import EventType from "@/Utility/EventType";
    import PageImportListItem from "@/Vue/Import/PageImportListItem.vue";
    import ModalNotification from "@/Vue/Modals/ModalNotification.vue";
    import {inject} from "vue";
    import {unitServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
    import ModalProgress from "@/Vue/Modals/ModalProgress.vue";

    export default {
        name: "PageImportList",
        components: {
            ModalProgress,
            ModalNotification,
            PageImportListItem,
        },

        props: {
            packages: {
                type: Object,
                default: {},
            },
            url: {
                type: String,
                default: '/api/units/import/',
            },
        },

        data() {
            return {
                uid: shortId('page-import-list'),
                contentPackages: {},
                unitService: inject(unitServiceKey),
            }
        },

        beforeMount() {
            this.contentPackages = this.packages;
        },

        computed: {
            isLoading() {
                if (this.unitService.isLoading) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('units.import.importing'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>

import { trans } from '@/Utility/Helpers/trans';

/**
 * Tooltips for HTML elements
 */
export default {

    /**
     * Install
     *
     * @param app             // VueJS instance
     */
    install(app)
    {
        app.directive(
            'tooltip',
            {
                beforeMount: this.createTooltip,
                updated: this.createTooltip,
            }
        );
    },

    /**
     * Create a tooltip
     *
     * @param {HTMLElement} el
     * @param {Object} binding
     * @param {Object} vnode
     * @param {Object} prevVnode
     */
    createTooltip(el, binding, vnode, prevVnode = null)
    {
        // Show error and cancel if no value is given:
        if (binding.value === undefined)
        {
            console.error('Tooltips->createTooltip(): Missing string value for v-tooltip directive', vnode);
            return;
        }

        // Show warning and cancel for incompatible elements:
        if (el instanceof SVGElement)
        {
            // @NOTE: title attribute doesn't work for SVGs
            console.warn('Tooltips->createTooltip(): Ignoring v-tooltip directive on incompatible SVG element', vnode);
            return;
        }

        // Get tooltip text from binding value:
        let tooltipText = (typeof binding.value === 'function') ? binding.value() : binding.value;

        // Show error and cancel for invalid tooltip texts:
        if (tooltipText !== null && typeof tooltipText !== 'string')
        {
            console.error('Tooltips->createTooltip(): Invalid value for v-tooltip directive', tooltipText, vnode);
            return;
        }

        // Look for a translated value:
        if (typeof tooltipText === 'string')
        {
            tooltipText =
                trans('tooltips.' + tooltipText, {}, false, false)
                || trans(tooltipText, {}, false, false)
                || tooltipText;

            // Trim spaces:
            tooltipText = tooltipText.trim();
        }

        // Remove title attribute if the text is empty:
        if (tooltipText === null || tooltipText === '')
        {
            el.removeAttribute('title');
            return;
        }

        // Set title attribute:
        el.setAttribute('title', tooltipText);
    }
};

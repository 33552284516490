<template>
    <div class="command-settings">
        <Dropdown
            :model="command"
            property="value"
            @select="onChangeCommand"
            :options="dropdownOptionsForHelperAnimationValues"
            :required="true"
            :deselected-caption="trans('authoring.select_expression')"
        />
    </div>
</template>

<script>
// Import helpers and functions:
import { sortArrayByProperty, trans } from '@/Utility/Helpers';

// Import classes:
import CommandType              from '@/Models/UnitData/Commands/CommandType';
import DropdownOption           from '@/Utility/DropdownOption';
import {HelperAnimationPlayCommand} from '@/Models/UnitData/Commands/Command';

export default {
    name: 'PanelCommandHelperAnimationPlay',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: HelperAnimationPlayCommand,
            default: null
        }
    },
    computed: {
        /**
         * Get options for Helper Companion animation value dropdown
         *
         * @returns {DropdownOption[]}
         */
        dropdownOptionsForHelperAnimationValues() {
            const options = [];
            const commandType = CommandType.HelperAnimationPlay;
            commandType.possibleValues.forEach(v => {
                options[options.length] = new DropdownOption({
                    caption: trans('commands.helper.animations.' + v),
                    disabled: false,
                    value: v
                });
            });
            return sortArrayByProperty(options, 'caption', false);
        },
    },
    methods: {
        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },
    }
}
</script>

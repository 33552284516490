<template>
    <div class="command-settings">
        <FeatureNotAvailable :data-object="command"/>
        <TextInput
            :model="command"
            property="prompt"
            :required="!command.isValid"
            type="textarea"
            :maxlength="500"
            :placeholder="trans('commands.helper.prompt.placeholder')"
            @change="onChangeCommand"
        />
    </div>
</template>

<script>
// Import helpers and functions:
//

// Import classes:
import {HelperPromptCommand} from '@/Models/UnitData/Commands/Command';
import TextInput from "@/Vue/Common/TextInput.vue";
import FeatureNotAvailable from "@/Vue/Features/FeatureNotAvailable.vue";

export default {
    name: 'PanelCommandHelperPrompt',
    components: {
        TextInput,
        FeatureNotAvailable,
    },
    emits: [
        'change',
    ],
    props: {
        command: {
            type: HelperPromptCommand,
            default: null
        }
    },
    methods: {
        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },
    }
}
</script>

<template>
    <Modal :remove-main-slot-content-when-invisible="true"
           :show-close-button="true"
           :title="trans('modals.install_app.title')"
           event-type="MODAL_INSTALL_APP">

        <div class="dialog-installation-wrapper">

            <ModalInstallationCellApp
                logoName="logo_windows"
                :title="trans('modals.install_app.apps.windows.title')"
                :description="trans('modals.install_app.apps.windows.description')"
                :buttonCaption="trans('modals.install_app.apps.windows.button.caption')"
                :buttonHref="trans('modals.install_app.apps.windows.button.href')"
                :linkCaption=" trans('modals.install_app.apps.windows.link.caption')"
                :linkHref="trans('modals.install_app.apps.windows.link.href')"
            />

            <ModalInstallationCellApp
                logoName="logo_windows"
                :title="trans('modals.install_app.apps.hololens.title')"
                :description="trans('modals.install_app.apps.hololens.description')"
                :buttonCaption="trans('modals.install_app.apps.hololens.button.caption')"
                :buttonHref="trans('modals.install_app.apps.hololens.button.href')"
                :linkCaption=" trans('modals.install_app.apps.hololens.link.caption')"
                :linkHref="trans('modals.install_app.apps.hololens.link.href')"
            />

            <ModalInstallationCellApp
                logoName="logo_meta"
                :title="trans('modals.install_app.apps.oculus_consumer.title')"
                :description="trans('modals.install_app.apps.oculus_consumer.description')"
                :buttonCaption="trans('modals.install_app.apps.oculus_consumer.button.caption')"
                :buttonHref="trans('modals.install_app.apps.oculus_consumer.button.href')"
                :linkCaption=" trans('modals.install_app.apps.oculus_consumer.link.caption')"
                :linkHref="trans('modals.install_app.apps.oculus_consumer.link.href')"
            />

            <ModalInstallationCellAppQuestBusiness
                logoName="logo_meta"
                :title="trans('modals.install_app.apps.oculus_business.title')"
                :buttonCaption="trans('modals.install_app.apps.oculus_business.button.caption')"
                :buttonHref="trans('modals.install_app.apps.oculus_business.button.href')"
                :linkCaption=" trans('modals.install_app.apps.oculus_business.link.caption')"
                :linkHref="trans('modals.install_app.apps.oculus_business.link.href')"
            />

            <ModalInstallationCellApp
                logoName="logo_pico"
                :title="trans('modals.install_app.apps.pico.title')"
                :description="trans('modals.install_app.apps.pico.description')"
                :buttonCaption="trans('modals.install_app.apps.pico.button.caption')"
                :buttonHref="trans('modals.install_app.apps.pico.button.href')"
                :linkCaption=" trans('modals.install_app.apps.pico.link.caption')"
                :linkHref="trans('modals.install_app.apps.pico.link.href')"
            />

            <ModalInstallationCellApp
                logoName="logo_vive"
                :title="trans('modals.install_app.apps.vive_focus.title')"
                :description="trans('modals.install_app.apps.vive_focus.description')"
                :buttonCaption="trans('modals.install_app.apps.vive_focus.button.caption')"
                :buttonHref="trans('modals.install_app.apps.vive_focus.button.href')"
                :linkCaption=" trans('modals.install_app.apps.vive_focus.link.caption')"
                :linkHref="trans('modals.install_app.apps.vive_focus.link.href')"
            />

        </div>
    </Modal>
</template>

<script lang="ts">

import ModalInstallationCellApp from '@/Vue/Modals/Installation/ModalInstallationCellApp.vue';
import ModalInstallationCellAppQuestBusiness from '@/Vue/Modals/Installation/ModalInstallationCellAppQuestBusiness.vue';
import Modal from '@/Vue/Modals/Modal.vue';
import {trans} from '@/Utility/Helpers/trans';
import {defineComponent} from 'vue';

export default defineComponent({
    components: {
        Modal,
        ModalInstallationCellAppQuestBusiness,
        ModalInstallationCellApp,
    },
    methods: {
        trans
    },
});
</script>

<style lang="scss" scoped>

dialog {
    width: 1024px;
    padding: var(--modal-padding);

    .dialog-installation-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        grid-column-gap: 48px;
        grid-row-gap: 32px;
    }
}

@media screen and (max-width: 480px) {
    .dialog {
        padding: var(--modal-padding);
    }

    .dialog-installation-wrapper {
        display: flex;
        flex-direction: column;
    }
}

@media screen and (min-width: 784px) {
    .dialog-installation-wrapper {
        grid-row-gap: 24px;
    }
}

</style>

<template>
    <div :class="cellClasses" :style="gridAreaForCell" @click="onClickThisCell">

        <!-- Icon -->
        <Icon name="icon_objectives" />

        <!-- Caption -->
        <span class="caption">
            {{ trans('labels.objectives') }}
        </span>

        <!-- Invalid State Icon -->
        <Icon v-if="!scene.objectives.isValid" name="icon_invalid" class="icon-invalid" />

    </div>
</template>

<script>
    // Import classes:
    import SceneObjectives          from '@/Models/UnitData/Scenes/Objectives/SceneObjectives';
    import TrainingScene            from '@/Models/UnitData/Scenes/TrainingScene';

    export default {
        name: 'ObjectivesCell',
        emits: [
            'select-cell',
        ],
        props: {
            scene: {                        // TrainingScene model
                type: TrainingScene,
                default: null
            },
            selection: {                    // Selected object(s) from the parent component
                type: [Object, String],
                default: null
            }
        },
        computed: {

            /**
             * Selected state
             *
             * @returns {Boolean}
             */
            selected() {
                return this.selection instanceof SceneObjectives && this.selection.parent.uid === this.scene.uid;
            },

            /**
             * Grid area for a cell to be used in style attribute
             *
             * @returns {String}
             */
            gridAreaForCell() {
                return `grid-area: 2 / ${(this.scene.order + 1)} / span 1 / span 1;`;
            },

            /**
             * CSS classes for the objectives cell in the scene
             *
             * @returns {String}
             */
            cellClasses() {
                const classes = ['objectives-cell'];
                if (this.selected === true) { classes[classes.length] = 'selected'; }
                if (!this.scene.objectives.isValid) { classes[classes.length] = 'is-invalid'; }
                return classes.join(' ');
            }
        },
        methods: {

            /**
             * Click handler for this cell
             *
             * @param {MouseEvent} e
             */
            onClickThisCell(e) {
                if (!this.selected)
                {
                    // Prevent other click handlers from executing:
                    e.stopPropagation();

                    // Send selection event to the grid:
                    this.$emit('select-cell', this.scene.objectives);
                }
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .objectives-cell {
        position: relative;
        flex-grow: 1;
        align-self: center;
        max-width: 100%;
        height: 100%;
        padding: 0 12px 0 12px;
        cursor: pointer;

        // For inner alignments:
        display: flex;
        flex-direction: row;
        align-items: center;

        color: var(--font-color-light);
        background-color: var(--color-anthracite);
        border-color: var(--color-anthracite);

        .icon {
            flex-grow: 0;
            flex-shrink: 0;
            pointer-events: none;
        }

        .caption {
            position: relative;
            //flex-grow: 1; // @NOTE: Disabled for the invalid state icon to appear right next to the caption
            padding: 0 0 0 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            pointer-events: none;
        }

        &:hover,
        &.selected,
        &.selected:hover {
            background-color: var(--color-anthracite80);
            border-color: var(--color-anthracite80);
        }
    }

</style>

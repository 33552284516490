<template>
    <div v-shortcuts.stop class="command-settings">
        <Dropdown
            :key="'dropdown-variable-operation-'+command.uid"
            :deselected-caption="trans('authoring.select_variable')"
            :initial-value="command.variable"
            :model="command"
            property="variable"
            :options="targetOptionsForVariableModuleSceneObjects"
            :required="true"
            @select="onSelectVariable"
        />

        <div v-if="shouldShowOperationDropdown" class="variable-property-operation">

            <Dropdown
                :key="'operation-for-'+command.variable"
                :deselected-caption="trans('authoring.select_operation')"
                :disabled="targetOptionsForVariableOperations.length <= 1"
                :model="command.operation"
                property="type"
                :options="targetOptionsForVariableOperations"
                :required="true"
                class="variable-property-operation-dropdown"
                @select="onSelectOperationOperator"
            />

            <template v-if="shouldShowOperandValueComponent">
                <component
                    v-if="componentForOperand"
                    :is="componentForOperand"
                    :operand="command.operation.operand"
                    class="variable-property-operand-value"
                    @change="onChangeOperandValue"
                />

                <Icon
                    class="icon-delete"
                    name="icon_close"
                    @click="onClickRemoveOperand()"
                />
            </template>
            <Dropdown
                v-else-if="shouldShowOperandDropdown"
                :key="'operation-operand-for-'+command.variable"
                :deselected-caption="trans('variables.select')"
                :model="command.operation"
                property="operand"
                :options="targetOptionsForVariableOperationOperands"
                :required="true"
                class="variable-property-operand-dropdown"
                @select="onSelectOperationOperand"
            />

        </div>
    </div>
</template>

<script>

    // Import classes:
    import {VariableOperationCommand} from '@/Models/UnitData/Commands/Command';
    import {
        defaultOperationForVariableType,
        dropdownOptionsForVariables,
        getAvailableOperationOptionsForVariableOfType,
        getAvailableOperandOptionsForVariableOfType,
        getOperandComponentNameFromOperation,
        getVariable,
        getVariableModuleUid
    } from '@/Models/UnitData/Variables/VariableHelpers';
    import {
        NumberValueOperand,
    } from '@/Models/UnitData/Variables/Operand';
    import VariableOperation from '@/Models/UnitData/Variables/VariableOperation';
    import UnitData from "@/Models/UnitData/UnitData";

    export default {
        name: 'PanelCommandVariableOperation',
        emits: [
            'change',
        ],
        props: {
            command: {                      // The command object to be edited
                type: VariableOperationCommand,
                default: null
            }
        },

        data() {
            return {
                shortcuts: new Map([
                    ['Duplicate.prevent', null],            // Prevent browser behaviour
                    ['Save.prevent', null],                 // Prevent browser behaviour
                    ['Any', null]                           // Allow any other shortcut but stop propagation
                ])
            }
        },

        beforeMount() {
            this.command.cleanUpData();
        },

        computed: {
            targetVariableIsSet() {
                return this.command.variable !== null && this.command.object !== null;
            },

            targetVariable() {
                if (!this.targetVariableIsSet) {
                    return null;
                }

                return getVariable(this.command.getParent(UnitData), this.command.object, this.command.variable);
            },

            /**
             * Should the select operation dropdown be visible?
             */
            shouldShowOperationDropdown() {
                return this.targetVariableIsSet && this.targetVariable !== null && this.targetOptionsForVariableOperations.length >= 1;
            },

            /**
             * Should the select operand dropdown be visible?
             */
            shouldShowOperandDropdown() {

                return this.targetVariableIsSet && this.command.operation !== null &&
                    (this.command.operation.operand === null || this.command.operation.operand.type !== NumberValueOperand.Type);
            },

            /**
             * Should the select operand value be visible?
             */
            shouldShowOperandValueComponent() {

                const operation = this.command.operation;

                return operation !== null && operation.type
                    && operation.operand !== null && operation.operand.type === NumberValueOperand.Type;
            },

            /**
             * Get all valid scene objects with variables (current scene and global) as dropdown options.
             */
            targetOptionsForVariableModuleSceneObjects() {
                return dropdownOptionsForVariables(this.command);
            },

            /**
             * Get the operations of the selected variable as dropdown options
             */
            targetOptionsForVariableOperations() {
                if (!this.targetVariableIsSet || this.targetVariable === null) {
                    return [];
                }

                return getAvailableOperationOptionsForVariableOfType(this.targetVariable.type);
            },

            /**
             * Get the operation operands of the selected variable as dropdown options
             */
            targetOptionsForVariableOperationOperands() {
                if (!this.targetVariableIsSet || this.targetVariable === null) {
                    return [];
                }

                return getAvailableOperandOptionsForVariableOfType(this.command, this.targetVariable.type);
            },

            /**
             * Get the name of the component that should be rendered for selecting an operand.
             */
            componentForOperand() {
                return getOperandComponentNameFromOperation(this.command.operation);
            },
        },
        methods: {

            /**
             *  Remove the Operand of the command's operation
             */
            onClickRemoveOperand() {

                // Set the operation selected in the Dropdown
                this.command.operation.operand = null;

                // Trigger change event for the command
                this.$emit('change', this.command);

                return this;
            },

            /**
             *  Change handler for the command
             */
            onSelectOperationOperator(value) {

                // Set the operation selected in the Dropdown
                const newOperation = VariableOperation.createWithType(value, this.command.operation || {}, this.command);

                this.command.operation = newOperation;

                // Trigger change event for the command
                this.$emit('change', this.command);

                return this;
            },

            /**
             *  Change handler for the comparison operand
             *
             * @param {Operand} value
             */
            onSelectOperationOperand(value) {

                value.parent = this.command.operation;
                this.command.operation.operand = value;
                this.$emit('change', this.command);

                return this;
            },

            /**
             *  Change handler for the variable
             */
            onSelectVariable(value) {

                this.command.variable = value;

                // Update the objects uid
                const objectUid = getVariableModuleUid(this.command.getParent(UnitData), value);
                this.command.object = objectUid;

                // Only reset the operation when the variable type changed:
                const targetVariable = this.command.targetVariable;
                const operation = this.command.operation;
                if (operation === null || operation.type.indexOf(targetVariable.type) !== 0)     // @NOTE: This only works when comparison type name starts with variable type (e.g. "number_subtract" starts with type "number")
                {
                    // Reset the operation that was set on the command because not all operations can have the same
                    // properties, and we only want to save properties that are possible for the operation.
                    this.resetOperation();
                }

                // Trigger change event
                this.$emit('change', this.command);

                return this;
            },

            onChangeOperandValue() {
                // bubble changes in operation.operand up to command
                this.$emit('change', this.command);
            },

            /**
             * Reset to basic operation object of the command. Necessary because not all operations have the same properties.
             */
            resetOperation() {
                const defaultOperation = defaultOperationForVariableType(this.targetVariable.type);
                defaultOperation.operand = null;
                defaultOperation.parent = this.command;
                this.command.operation = defaultOperation;
                this.$emit('change', this.command);

                return this;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>

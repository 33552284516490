<template>
    <Modal
        id="dialog-unit-history"
        event-type="MODAL_UNIT_HISTORY"
        :title="trans('modals.unit_history.title')"
        :show-close-button="true"
        :user-closable="true"
    >
        <ul class="selectable-list" v-not-focusable>

            <!-- Revisions -->
            <template
                v-for="(revision, index) in unit.revisions"
                :key="'history'+revision.uid"
            >
                <li
                    :class="(!revision.isCompatible ? 'incompatible' : '')"
                    v-focusable
                    @click.capture.stop="onSelectRevision(revision)"
                    @keyup.enter="onSelectRevision(revision)"
                >
                    {{ (index === 0) ? trans('labels.current_version') : getDateForRevision(revision) }}
                    {{ (revision.platform !== 'wms') ? '(App)' : '' }}
                    {{ getAuthorNameForRevision(revision) }}
                    <StatusLabel
                        v-for="labelConfig in getLabelsForRevision(revision)"
                        :key="'page-header-statuslabel'+(labelConfig.type || labelConfig.caption)"
                        :config="labelConfig"
                    />
                </li>

            </template>
        </ul>
        <p class="description" v-html="trans('modals.unit_history.description')"></p>
    </Modal>
</template>

<script lang="ts">

import moment from 'moment';
import Unit from '@/Models/Unit/Unit';
import type UnitRevision from '@/Models/Unit/UnitRevision';
import {trans} from '@/Utility/Helpers';
import StatusLabelConfig from '@/Utility/StatusLabelConfig';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent} from 'vue';
import EventType from '@/Utility/EventType';
import StatusLabel from '@/Vue/Common/StatusLabel.vue';

export default defineComponent({
    components: {
        StatusLabel,
        Modal
    },
    emits: {
        'select': (_: UnitRevision) => true,
    },
    props: {
        /**
         * Unit for which to show the revisions history
         */
        unit: {
            type: Unit,
            default: null
        }
    },
    methods: {
        trans,

        onSelectRevision(revision: UnitRevision) {
            this.$globalEvents.emit(EventType.MODAL_UNIT_HISTORY_HIDE);
            this.$emit('select', revision);
        },

        getDateForRevision(revision: UnitRevision): string {
            return moment(revision.updated_at).format(trans('units.history.date_format_with_time'));
        },

        getAuthorNameForRevision(revision: UnitRevision): string {
            const authorName = revision.owner ? revision.owner.fullName : trans('labels.unknown_author');
            return `${trans('labels.by')} ${authorName}`;
        },

        getLabelsForRevision(revision: UnitRevision): StatusLabelConfig[] {
            const labels: StatusLabelConfig[] = [];

            if (revision.isReleased) {
                labels.push(
                    new StatusLabelConfig({
                        type: revision.isLatestReleasedRevision ? 'status' : 'disabled',
                        caption: 'released'
                    })
                );
            }

            return labels;
        },
    }
});
</script>

<style lang="scss" scoped>

#dialog-unit-history {

    width: min-content;

    .selectable-list {
        max-height: 70vh;

        li {
            min-width: 320px;
            padding: 6px 0;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;

            &:hover,
            &:focus,
            &:active {
                color: var(--color-primary-hover);
                background-color: unset;
            }

            &.incompatible:not(:hover, :focus, :active) {
                color: var(--color-anthracite40);
                background-color: transparent;
            }

            &.disabled {
                cursor: default;
            }
        }
    }

    .status-label {
        margin: 0 0 0 10px;
    }

    .description {
        padding-top: 16px;
        font-size: var(--font-size-small);
        border-top: 2px solid var(--background-color-light);
        white-space: nowrap;
    }
}
</style>

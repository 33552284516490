<template>
    <div class="command-settings" v-shortcuts.stop>
        <div class="wait-command-input number-input-wrapper">
            <label>{{ trans('labels.duration') }}
                <span class="wait-unit">(sec)</span>
            </label>
            <NumberInput
                :model="command"
                property="value"
                @change="onChange"
                :minValue="0.01"
                :maxValue="86400"
                :step="0.1"
                :precision="2"
                :placeholder="trans('labels.duration')"
            />
        </div>
    </div>
</template>

<script>
// Import classes:
import {WaitCommand} from '@/Models/UnitData/Commands/Command';

export default {
    name: 'PanelCommandWait',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: WaitCommand,
            default: null
        }
    },
    data() {
        return {
            shortcuts: new Map([
                ['Duplicate.prevent', null],            // Prevent browser behaviour
                ['Save.prevent', null],                 // Prevent browser behaviour
                ['Any', null]                           // Allow any other shortcut but stop propagation
            ])
        }
    },
    methods: {
        /**
         * Change handler for number input
         *
         * @param {Number} waitValue
         */
        onChange(waitValue) {
            this.$emit('change', this.command);
            return this;
        },
    }
}
</script>

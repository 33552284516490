<template>
    <div id="inspector" class="inspector-content" v-not-focusable v-shortcuts>
        <header class="inspector-header asset asset-text">
            <Icon name="icon_asset-text" class="inspector-panel-header-icon" />
            {{ trans('labels.inspector.text_properties') }}
        </header>
        <div class="panels" v-not-focusable :key="'InspectorPanels' + refreshUI">
            <div class="inspector-panel panel-text">

                <!-- Asset title header -->
                <div class="property property-title">
                    <TextInput
                        :model="sceneObject"
                        property="title"
                        @change="onChangeProperty"
                        :maxlength="50"
                        :placeholder="trans('authoring.asset_title_placeholder')"
                        :error-msg="trans('errors.asset.title')"
                    />
                </div>

                <Collapsible :key="'asset_basics_' + sceneObject.uid + '_collapsible'">
                    <template #header>
                        {{ trans('labels.basics') }}
                    </template>
                    <template #body>
                        <!-- Visibility state -->
                        <div v-if="!sceneObject.isGlobal" class="property property-visibility">
                            <Checkbox
                                :model="sceneObject"
                                property="visible"
                                layout="checkbox-right"
                                @change="onChangeProperty"
                                :caption="trans('authoring.asset_initially_visible')"
                            />
                        </div>

                        <!-- Tag list -->
                        <div v-if="hasPropertyTags" class="property property-tags">
                            <TagList
                                class="no-wrap"
                                :suggestions="sceneObject.getParent(UnitData).getTagsFromSceneObjects()"
                                :model="sceneObject"
                                property="tags"
                                @change="onChangeProperty"
                            />
                        </div>

                        <!-- Text Content -->
                        <PanelContentSettings
                            class="property property-content"
                            :content="content"
                            @change="onChangeContent"
                        />
                    </template>
                </Collapsible>

            </div>

            <!-- Object transform -->
            <template v-if="sceneObject.supportsTransform">
                <PanelPlaceableTransform
                    v-if="shouldShowPlaceableTransformComponent"
                    class="panel-transform"
                    :sceneObject="sceneObject"
                    @change="onChangeProperty"
                />
                <PanelTransform
                    v-else
                    class="panel-transform"
                    :transform="sceneObject.transform"
                    @change="onChangeProperty"
                />
            </template>

            <PanelBehaviours
                v-if="hasPropertyBehaviours"
                :sceneObject="sceneObject"
                @change="onChangeProperty"
            />

            <PanelTriggers
                v-if="hasPropertyTriggers"
                :key="'panel-triggers-'+sceneObject.uid"
                :sceneObject="sceneObject"
                @change="onChangeTriggers"
            />
        </div>

        <div class="buttons">

            <!-- Btn: Duplicate Text -->
            <ButtonCustom :disabled="!canDuplicateSceneObject" v-tooltip="'buttons.authoring.asset.duplicate'" icon="icon_duplicate" caption="labels.duplicate" @trigger="onClickDuplicateSceneObject" />

            <!-- Btn: Remove Text -->
            <ButtonCustom v-tooltip="'buttons.authoring.asset.delete'" icon="icon_delete" caption="labels.remove" @trigger="onClickRemoveTextFromSelectedScene" />

        </div>

        <!-- Modal dialog for text remove confirmation -->
        <ModalApplyCancel
            event-type="MODAL_REMOVE_TEXT_FROM_SCENE"
            :title="trans('modals.remove_text_from_scene.title')"
            :description="trans('modals.remove_text_from_scene.description')"
        />

    </div>
</template>

<script>
// Import VueJS components:
import ModalApplyCancel        from '@/Vue/Modals/ModalApplyCancel.vue';
import PanelBehaviours          from '@/Vue/Inspector/PanelBehaviours.vue';
import PanelContentSettings     from '@/Vue/Inspector/PanelContentSettings.vue';
import PanelPlaceableTransform  from "@/Vue/Inspector/PanelPlaceableTransform.vue";
import PanelTransform           from "@/Vue/Inspector/PanelTransform.vue";
import PanelTriggers            from '@/Vue/Inspector/PanelTriggers.vue';

// Import classes:
import { shortId }              from '@/Utility/Helpers';
import EventType                from '@/Utility/EventType';
import OverlayContent           from '@/Models/Unity/OverlayContent';
import { SceneObjectAssetText } from '@/Models/UnitData/SceneObjects/SceneObject';
import PlaceableComponent from "@/Models/Unity/Components/PlaceableComponent";
import UnitData from "@/Models/UnitData/UnitData";

export default {
    name: 'InspectorSceneObjectAssetText',
    emits: [
        'change',
    ],
    components: {
        ModalApplyCancel,
        PanelBehaviours,
        PanelContentSettings,
        PanelPlaceableTransform,
        PanelTransform,
        PanelTriggers,
    },
    props: {
        sceneObject: {
            type: SceneObjectAssetText,
            default: null
        }
    },
    data() {
        return {
            refreshUI: shortId(),                   // Helper for refreshing UI elements
            content: new OverlayContent({
                type: OverlayContent.Type.Text,
                value: {
                    headline: null,
                    text: null
                }
            }),
            shortcuts: new Map([
                ['Duplicate.global.prevent', this.onShortcutDuplicate],
                ['Delete.global', this.onShortcutDelete],
                ['Replace.global.prevent', null],
            ])
        }
    },
    beforeMount() {
        this.content.parent = this.sceneObject;
        this.content.value.headline = this.sceneObject.headline || null;
        this.content.value.text = this.sceneObject.text || null;
    },

    computed: {

        hasPropertyTags() {
            return this.sceneObject.supportsTags;
        },

        hasPropertyTriggers() {
            return (this.sceneObject.supportedTriggerTypes.length > 0);
        },

        hasPropertyBehaviours() {
            return this.sceneObject.supportsBehaviours;
        },

        canDuplicateSceneObject() {
            return !this.sceneObject.hasReachedMaxCount;
        },

        shouldShowPlaceableTransformComponent() {
            return this.sceneObject && this.sceneObject.supportedComponents.includes(PlaceableComponent.type);
        },
    },
    methods: {

        UnitData,

        /**
         * Common property change handler
         */
        onChangeProperty() {
            this.$emit('change', this.sceneObject);
            return this;
        },

        /**
         * Change handler for the content
         *
         * @param {OverlayContent} content
         */
        onChangeContent(content) {
            this.sceneObject.headline = content.value.headline;
            this.sceneObject.text = content.value.text;
            this.$emit('change', this.sceneObject);
            return this;
        },

        /**
         * Update the triggers on the scene object whenever a change happens
         *
         * @param {Trigger[]} triggers      // List of Trigger objects
         */
        onChangeTriggers(triggers) {
            //console.log('Inspector: Triggers have been updated on SceneObject', this.sceneObject);
            this.$globalEvents.emit(EventType.INSPECTOR_SCENE_OBJECT_UPDATED, this.sceneObject);

            // Update scene objectives:
            this.$globalEvents.emit(EventType.INSPECTOR_TRIGGERS_UPDATED, this.sceneObject, triggers);
            return this;
        },

        /**
         * Click handler for scene object duplicate button
         */
        onClickDuplicateSceneObject() {
            this.$globalEvents.emit(EventType.INSPECTOR_DUPLICATE_SCENE_OBJECT, this.sceneObject);
            return this;
        },

        /**
         * Click handler for text remove button
         */
        onClickRemoveTextFromSelectedScene() {
            // Show modal dialog:
            this.$globalEvents.emit(EventType.MODAL_REMOVE_TEXT_FROM_SCENE_SHOW, this.sceneObject);
            return this;
        },

        /**
         * Shortcut handler
         *
         * @param {CustomEvent} e
         */
        onShortcutDelete(e) {
            return this.onClickRemoveTextFromSelectedScene();
        },

        /**
         * Shortcut handler
         *
         * @param {CustomEvent} e
         */
        onShortcutDuplicate(e) {
            // Duplicate selected scene object if allowed:
            return this.canDuplicateSceneObject ? this.onClickDuplicateSceneObject() : this;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>

<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">{{ trans('users.delete.headline') }}</div>

                    <div class="card-body">
                        <div class="form-group row mb-0">
                            <div class="buttons col-md-6 offset-md-4">
                                <ButtonPrimary class="btn-red" caption="labels.delete" @trigger="onClickDelete" :disabled="isSubmitting" />
                                <span class="d-xs-none d-sm-none"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalApplyCancel
            event-type="MODAL_DELETE_USER"
            :title="trans('users.delete.modals.confirm.title')"
            :description="trans('users.delete.modals.confirm.description', {'firstname': user.firstname, 'lastname': user.lastname, 'email': user.email})"
            apply-text="users.delete.modals.confirm.apply"
        />
        <ModalProgress/>
    </div>
</template>

<script>
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
import EventType from '@/Utility/EventType';
import User from '@/Models/User/User';
import {route, shortId, trans} from '@/Utility/Helpers';
import ModalProgress from "@/Vue/Modals/ModalProgress.vue";

export default {
    name: 'DeleteUserForm',
    components: {
        ModalProgress,
        ModalNotification,
        ModalApplyCancel,
    },
    props: {
        user: {
            type: Object,
            default: null
        },
        url: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            uid: shortId('delete-user-form'),
            isSubmitting: false,
        }
    },
    mounted() {
        this.$globalEvents.on(EventType.MODAL_DELETE_USER_APPLY, this.onApplyConfirmDelete);
    },
    beforeUnmount() {
        this.$globalEvents.off(EventType.MODAL_DELETE_USER_APPLY, this.onApplyConfirmDelete);
    },
    methods: {

        /**
         * Click handler for the submit button
         */
        onClickDelete() {
            if (this.isSubmitting)
            {
                return;
            }
            this.$globalEvents.emit(EventType.MODAL_DELETE_USER_SHOW);
            return this;
        },

        /**
         * Event handler for apply button from the overlay
         */
        onApplyConfirmDelete() {

            if (this.isSubmitting)
            {
                return;
            }

            this.isSubmitting = true;
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('users.delete.modals.deleting.title'));

            axios({
                url: this.url,
                method: 'delete',
            })
            .then(({ data }) => {

                const deletedUser = new User(data);

                // Force logout if the current user was deleted, otherwise redirect to users list:
                window.location = route((deletedUser.uid === window.currentUser.uid) ? 'logout' : 'users.index');
            })
            .catch(function (error) {
                this.isSubmitting = false;
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                this.$root.showErrorDialog(error);
            }.bind(this));
        },
    }
}
</script>

<style lang="scss" scoped>

</style>

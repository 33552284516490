<template>
    <Modal
        ref="modal"
        :show-close-button="false"
        :title="trans('tokens.dialogs.view.headline')"
        :user-closable="true"
        event-type="MODAL_ACCESS_TOKEN"
        @hide="onHide"
        @show="onShow"
    >
        <div class="description">
            {{ trans('tokens.dialogs.view.description') }}
        </div>

        <textarea
            v-if="token"
            class="access-token"
            v-text="token.access_token"/>

        <template #buttons>
            <ButtonPrimary
                caption="labels.okay"
                class="btn-okay"
                @trigger="onClickOkay"/>
        </template>
    </Modal>
</template>

<script lang="ts">

import Modal from '@/Vue/Modals/Modal.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {trans} from '@/Utility/Helpers';
import {defineComponent} from 'vue';
import type Token from '@/Models/User/Token';

export default defineComponent({
    components: {
        ButtonPrimary,
        Modal
    },

    data() {
        return {
            token: null as Token | null,
        }
    },

    computed: {
        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    },

    methods: {
        trans,

        onShow(token: Token) {
            this.token = token;
        },

        onHide() {
            this.token = null;
        },

        onClickOkay() {
            this.modal.hide();
        },
    }
});
</script>

<style lang="scss" scoped>

.description {
    margin-bottom: 10px;
}

.access-token {
    width: 100%;
    height: 200px;
    font-size: var(--font-size-small);
    padding: 10px 15px;
    border-top: 2px solid var(--color-anthracite20);
    border-right: 0;
    border-bottom: 2px solid var(--color-anthracite20);
    border-left: 0;
    border-radius: 0;
    background-color: var(--background-color-light);
}

</style>

<template>
    <div class="trigger-property enter-leave-subtype property">
        <RadioButtons
            :model="trigger"
            property="subtype"
            :required="true"
            :buttons="subtypeButtons"
            @change="onChangeSubtype"
        />
    </div>
</template>

<script>

import {trans}                  from "@/Utility/Helpers";
import Trigger                  from "@/Models/UnitData/Triggers/Trigger";
import RadioButtonConfig        from "@/Utility/RadioButtonConfig";
import RadioButtons             from "@/Vue/Common/RadioButtons.vue";
import {TriggerSubtype}         from "@/Models/UnitData/Triggers/TriggerSubtype";

export default {
    name: 'PanelTriggerPropertyEnterLeaveSubtype',
    components: {
        RadioButtons
    },
    emits: [
        'change',
    ],
    props: {
        trigger: Trigger,
    },
    computed: {
        subtypeButtons() {
            return [
                new RadioButtonConfig({
                    value: TriggerSubtype.Collider,
                    caption: trans(`triggers.subtypes.collider`),
                    helpText: trans(`triggers.subtypes.collider_description_${this.trigger.event}`),
                }),
                new RadioButtonConfig({
                    value: TriggerSubtype.Distance,
                    caption: trans(`triggers.subtypes.distance`),
                    helpText: trans(`triggers.subtypes.distance_description_${this.trigger.event}`),
                }),
            ];
        }
    },
    methods: {

        onChangeSubtype() {
            this.$emit('change', this);
            return this;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>

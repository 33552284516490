<template>
    <div
        :style="gridAreaForCell"
        :class="cellClasses"
        @click="onClickThisCell"
    >

        <!-- Global scene object -->
        <template v-if="isGlobal">

            <!-- Wrapper needed for correct positioning -->
            <span class="sticky-wrapper">

                <!-- Caption -->
                <span class="caption">

                    <!-- Icon -->
                    <Icon name="icon_add" />

                    {{ trans('authoring.add_new_global_object') }}
                </span>
            </span>

        </template>

        <!-- Scene object -->
        <template v-else>

            <!-- Icon -->
            <Icon name="icon_add" />

            <!-- Caption -->
            <span class="caption">
                {{ trans('authoring.add_new_object_to_this_scene') }}
            </span>

        </template>

    </div>
</template>

<script>
    // Import classes:
    import TrainingScene            from '@/Models/UnitData/Scenes/TrainingScene';
    import UnitData                 from '@/Models/UnitData/UnitData';

    export default {
        name: 'AddObjectCell',
        emits: [
            'select-cell',
        ],
        props: {
            row: {                          // Row position within the grid
                type: Number,
                default: 0
            },
            parent: {                       // Parent model associated with this cell
                type: [UnitData, TrainingScene],
                default: null
            },
            disabled: {                     // Disabled state
                type: Boolean,
                default: false
            },
            selected: {                     // Selected state
                type: Boolean,
                default: false
            }
        },
        computed: {

            /**
             * Whether this is a global cell spanning across all scenes
             *
             * @returns {Boolean}
             */
            isGlobal() {
                return (this.parent instanceof UnitData);
            },

            /**
             * Grid area for a cell to be used in style attribute
             *
             * @returns {String}
             */
            gridAreaForCell() {
                if (this.isGlobal === true)
                {
                    return `grid-area: ${this.row + 1} / 1 / span 1 / span ${this.parent.scenesCount};`;
                }
                return `grid-area: ${this.row + 1} / ${(this.parent.order + 1)} / span 1 / span 1;`;
            },

            /**
             * CSS classes for the scene object cell
             *
             * @returns {String}
             */
            cellClasses() {
                const classes = ['add-object-cell'];
                if (this.isGlobal === true) { classes[classes.length] = 'global'; }
                if (this.disabled === true) { classes[classes.length] = 'disabled'; }
                if (this.selected === true) { classes[classes.length] = 'selected'; }
                return classes.join(' ');
            }
        },
        methods: {

            /**
             * Click handler for this cell
             *
             * @param {MouseEvent} e
             */
            onClickThisCell(e) {
                if (this.disabled) {
                    return this;
                }

                // Prevent other click handlers from executing:
                e.stopPropagation();

                // Send selection event to the grid if the cell is not selected already:
                if (this.selected === false) {
                    this.$emit('select-cell', this.parent);
                }
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .add-object-cell {
        position: relative;
        flex-grow: 1;
        align-self: center;
        max-width: 100%;
        height: 100%;
        padding: 0 12px 0 12px;
        cursor: pointer;

        // For inner alignments:
        display: flex;
        flex-direction: row;
        align-items: center;

        &.disabled {
            cursor: default !important;
            opacity: 0.2;
        }

        .icon {
            pointer-events: none;
        }

        .caption {
            display: inline-block;
            flex-grow: 1;
            padding: 0 0 0 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            pointer-events: none;
        }

        &.global {
            padding: 0;

            .caption {
                position: sticky;
                padding: 0;
                flex-grow: 0;
                height: 100%;
                line-height: 34px;
                left: 12px;
                vertical-align: middle;

                .icon {
                    margin-right: 9px;
                    margin-bottom: -2px;
                    vertical-align: text-bottom;
                }
            }
        }

        &:hover,
        &.selected,
        &.selected:hover {
            color: var(--color-primary);
            background-color: var(--color-white);
            border-color: var(--color-white);
        }

        &,
        &.disabled,
        &.disabled:hover {
            color: var(--font-color-dark);
            background-color: var(--color-white);
            border-color: var(--color-white);
        }
    }

    .sticky-wrapper {
        position: relative;
        display: block;
        flex-basis: 100%;
        margin-right: -100%;
        height: 100%;
        padding-left: 12px;
        pointer-events: none;
    }

</style>

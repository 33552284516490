<template>
    <div class="command-settings">

        <BetaHint :command-type="CommandType.ControlCharacterAnimation"/>

        <!-- Select character object -->
        <Dropdown
            :model="command"
            property="target"
            :options="get3dObjectTargetDropdownOptions"
            :required="true"
            :deselected-caption="trans('authoring.select_sceneobject')"
            @select="onChangeCommandProperty"
        />

        <!-- Select animation type -->
        <Dropdown
            v-if="command.target"
            :model="command"
            property="animation_type"
            :options="getAnimationTypeDropdownOptions"
            :required="true"
            @select="onChangeCommandProperty"
        />

    </div>
</template>

<script>
    import {getSceneObjectTargetDropdownOptionsForCommand} from "@/Models/UnitData/Commands/CommandHelpers";
    import {ControlCharacterAnimationCommand} from "@/Models/UnitData/Commands/Command";
    import Dropdown from "@/Vue/Common/Dropdown";
    import TextInput from "@/Vue/Common/TextInput";
    import DropdownOption from "@/Utility/DropdownOption";
    import CommandType from "@/Models/UnitData/Commands/CommandType";
    import BetaHint from "@/Vue/Features/BetaHint.vue";

    export default {
        name: "PanelCommandControlCharacterAnimation",
        components: {
            BetaHint,
            TextInput,
            Dropdown
        },
        emits: [
            'change',
        ],
        props: {
            command: {
                type: ControlCharacterAnimationCommand,
                default: null
            }
        },
        computed: {

            CommandType() {
                return CommandType
            },

            /**
             * @returns {DropdownOption[]}
             */
            get3dObjectTargetDropdownOptions() {
                return getSceneObjectTargetDropdownOptionsForCommand(this.command);
            },

            /**
             * @returns {DropdownOption[]}
             */
            getAnimationTypeDropdownOptions() {
                return this.command.possibleAnimations.map(animation => new DropdownOption({
                    caption: animation.caption,
                    value: animation.type,
                }));
            },

        },
        methods: {

            onChangeCommandProperty() {
                this.$emit('change', this.command);
                return this;
            }

        }
    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <TextInput
        :id="'trigger'+trigger.uid"
        :model="trigger"
        property="title"
        @change="onTriggerChanged"
        @click.stop
        :maxlength="50"
        :placeholder="getTriggerTitlePlaceholder"
        :error-msg="trans('errors.triggers.title')"
    />
</template>

<script>
import { trans } from '@/Utility/Helpers';
import Trigger from '@/Models/UnitData/Triggers/Trigger';

export default {
    name: 'PanelTriggerCueCustomTitle',
    emits: [
        'change'
    ],
    props: {
        trigger: Trigger,
        isHelperModule: Boolean,
    },
    computed: {

        /**
         * Get the placeholder text for the edit title input
         *
         * @returns {String}
         */
        getTriggerTitlePlaceholder() {
            return this.isHelperModule ? trans('labels.reaction') : this.trigger.triggerType.title;
        },
    },
    methods: {

        /**
         * Emits the 'change' event
         */
        onTriggerChanged() {
            this.$emit('change', this.trigger);
            return this;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>

<template>
    <Modal
        ref="modal"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="trans('modals.duplicate_unit.title')"
        :user-closable="true"
        event-type="MODAL_DUPLICATE_UNIT"
        @show="onShow"
    >
        <p v-if="unit?.latestRevision"
           class="description"
           v-html="trans('modals.duplicate_unit.description', {title: unit.latestRevision.title})">
        </p>
        <TextInput
            :error-msg="trans('errors.unit.title')"
            :initial-value="newTitle"
            :maxlength="50"
            :placeholder="trans('units.create.unit_title_placeholder')"
            :required="true"
            @change="onChangeTitle"
        />
        <Checkbox
            :caption="trans('modals.duplicate_unit.keep_authors')"
            :initialValue="keepAssignedAuthors"
            layout="checkbox-right"
            @change="onChangeKeepAssignedAuthors"
        />
        <template #buttons>
            <ButtonSecondary
                caption="modals.duplicate_unit.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                :disabled="!isValid"
                caption="modals.duplicate_unit.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import {trans} from '@/Utility/Helpers';
import EventType from '@/Utility/EventType';
import {defineComponent} from 'vue';
import Modal from '@/Vue/Modals/Modal.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import Checkbox from '@/Vue/Common/Checkbox.vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import type Unit from '@/Models/Unit/Unit';

export default defineComponent({
    components: {
        TextInput,
        Checkbox,
        ButtonPrimary,
        ButtonSecondary,
        Modal
    },

    data() {
        return {
            /**
             * Reference to the Unit for which this dialog is being shown
             */
            unit: null as Unit | null,

            /**
             * Title of the duplicated unit
             */
            newTitle: '',

            /**
             * Checkbox state for whether the assigned authors should be duplicated as well
             */
            keepAssignedAuthors: false,
        }
    },

    computed: {
        isValid() {
            return this.newTitle.length > 0 && this.newTitle.length <= 50;
        },

        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },
    },

    methods: {
        trans,

        onShow(unit: Unit) {
            this.unit = unit;

            if (!unit.latestRevision) {
                throw new Error('Given unit has no latest revision. Unit cannot be duplicated.')
            }

            this.newTitle = unit.latestRevision!.title + ' ' + trans('modals.duplicate_unit.copy_suffix');
            this.keepAssignedAuthors = false;
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            if (!this.isValid) {
                return;
            }

            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_DUPLICATE_UNIT_APPLY, this.unit, this.newTitle, this.keepAssignedAuthors);
        },

        onChangeTitle(newTitle: string) {
            this.newTitle = newTitle;
        },

        onChangeKeepAssignedAuthors(checked: boolean) {
            this.keepAssignedAuthors = checked;
        },
    }
});
</script>

<style lang="scss" scoped>

</style>

<template>
    <div id="scenes-authoring-wrapper" v-shortcuts.prevent.stop>

        <div v-if="unitData" id="scenes-authoring-grid" :style="gridLayout">

            <!-- Background cell across all columns for sticky headers (global objects group) -->
            <BackgroundCells
                v-if="unitData.hasScenes"
                class="group-global bg-for-sticky-headers"
                :columnStart="0"
                :columns="unitData.scenesCount"
                :rowStart="0"
                :rows="1"
            />

            <!-- Scenes (header cells above global objects) -->
            <template v-for="scene in unitData.scenes" :key="'headercells'+scene.uid+scene.order+scene.selected">

                <!-- Header cell -->
                <HeaderCell
                    :scene="scene"
                    :selection="selection"
                    ref="scenes"
                    @change-scene-order="onChangeSceneOrder"
                    @select-training-scene="onSelectTrainingScene"
                />

                <!-- Objectives cell -->
                <ObjectivesCell
                    :scene="scene"
                    :selection="selection"
                    @select-cell="selectGridCell"
                />
            </template>

            <!-- Only show global objects if there are scenes in the unit -->
            <template v-if="unitData.hasScenes">

                <!-- Global scene objects cells (global objects group) -->
                <template v-for="(sceneObject, index) in unitData.objects" :key="'globalobjcell'+sceneObject.uid+index">
                    <GroupCell
                        v-if="sceneObject.type === 'group'"
                        :row="rowsData.global[index]"
                        :groupObject="sceneObject"
                        :selection="selection"
                        @select-cell="selectGridCell"
                        @click-visible="onClickSceneObjectChangeVisible"
                        @dragenter="onDragEnterSceneObject"
                        @dragleave="onDragLeaveSceneObject"
                        @drop="onDropSceneObject"
                    />
                    <SceneObjectCell
                        v-else
                        :row="rowsData.global[index]"
                        :sceneObject="sceneObject"
                        :selection="selection"
                        @select-cell="selectGridCell"
                        @click-visible="onClickSceneObjectChangeVisible"
                        @dragenter="onDragEnterSceneObject"
                        @dragleave="onDragLeaveSceneObject"
                        @drop="onDropSceneObject"
                    />
                </template>

                <!-- Button add global object cell (global objects group) -->
                <AddObjectCell
                    :row="rowsData.global.rowLast"
                    :parent="unitData"
                    :selected="selection === 'AddNewGlobalObject'"
                    @select-cell="onClickAddNewObject"
                />

            </template>

            <!-- Scenes (scene objects group) -->
            <template v-for="(scene, sceneIndex) in unitData.scenes" :key="'scenecells'+scene.uid+scene.order+scene.selected">

                <!-- Scene objects cells -->
                <template v-for="(sceneObject, index) in scene.objects" :key="'objcell'+scene.uid+scene.order+sceneObject.uid">
                    <GroupCell
                        v-if="sceneObject.type === 'group'"
                        :row="rowsData.scenes[sceneIndex][index]"
                        :groupObject="sceneObject"
                        :selection="selection"
                        @select-cell="selectGridCell"
                        @dragenter="onDragEnterSceneObject"
                        @dragleave="onDragLeaveSceneObject"
                        @drop="onDropSceneObject"
                    />
                    <SceneObjectCell
                        v-else
                        :row="rowsData.scenes[sceneIndex][index]"
                        :sceneObject="sceneObject"
                        :selection="selection"
                        @select-cell="selectGridCell"
                        @dragenter="onDragEnterSceneObject"
                        @dragleave="onDragLeaveSceneObject"
                        @drop="onDropSceneObject"
                    />
                </template>

                <!-- Button add object cell -->
                <AddObjectCell
                    :row="rowsData.scenes[sceneIndex].rowLast"
                    :parent="scene"
                    :selected="selection === 'AddNewObject' + scene.uid"
                    @select-cell="onClickAddNewObject"
                />

            </template>

            <!-- Cell on the right with button to add a new scene -->
            <div :class="'add-scene-cell' + (!canUserAddNewScene ? ' disabled' : '')" :style="cellPositionAddNewScene">
                <div @click="onClickAddNewScene">
                    <span>
                        <Icon name="icon_add" />
                        <span class="caption">
                            {{ trans('authoring.add_new_scene') }}
                        </span>
                    </span>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

    // Import VueJS components:
    import AddObjectCell            from '@/Vue/Authoring/GridCells/AddObjectCell.vue';
    import BackgroundCells          from '@/Vue/Authoring/GridCells/BackgroundCells.vue';
    import GroupCell                from '@/Vue/Authoring/GridCells/GroupCell.vue';
    import HeaderCell               from '@/Vue/Authoring/GridCells/HeaderCell.vue';
    import ObjectivesCell           from '@/Vue/Authoring/GridCells/ObjectivesCell.vue';
    import SceneObjectCell          from '@/Vue/Authoring/GridCells/SceneObjectCell.vue';

    // Import classes:
    import Clipboard                from '@/Utility/Clipboard';
    import { trans }                from '@/Utility/Helpers';
    import EventType                from '@/Utility/EventType';
    import Asset                    from '@/Models/Asset/Asset';
    import AssetType                from '@/Models/Asset/AssetType';
    import SceneObjectFilters       from '@/Filters/SceneObjectFilters';
    import HotspotStyleType         from '@/Models/UnitData/SceneObjects/HotspotStyleType';
    import SceneObject, { BaseSceneObjectAsset, BaseSceneObjectHotspot, SceneObjectGroup, SceneObjectModuleHelper } from '@/Models/UnitData/SceneObjects/SceneObject';
    import SceneObjectives          from '@/Models/UnitData/Scenes/Objectives/SceneObjectives';
    import SceneObjectType          from '@/Models/UnitData/SceneObjects/SceneObjectType';
    import UnitType                 from '@/Models/UnitData/UnitType';
    import TrainingScene            from '@/Models/UnitData/Scenes/TrainingScene';
    import Trigger                  from '@/Models/UnitData/Triggers/Trigger';
    import UnitData                 from '@/Models/UnitData/UnitData';
    import OverlayContent           from '@/Models/Unity/OverlayContent';
    import AbstractDataObject       from "@/Models/AbstractDataObject";
    import UnitRevision from "@/Models/Unit/UnitRevision";
    import {inject} from "vue";
    import {assetServiceKey} from "@/Vue/Bootstrap/InjectionKeys";

    export default {
        name: 'GridScenes',
        emits: [
            'change',
            'click-outside-cell',
        ],
        components: {
            AddObjectCell,
            BackgroundCells,
            GroupCell,
            HeaderCell,
            ObjectivesCell,
            SceneObjectCell
        },
        props: {
            unitData: {                                 // Data from the revision that was loaded
                type: UnitData,
                default: null
            }
        },
        data() {
            return {
                assetService: inject(assetServiceKey),      // Global AssetService instance
                columnWidth: '318',                         // Grid column cell width in pixels (@NOTE: Must change the SVG for cell backgrounds if you change this value!)
                selection: null,                            // The currently selected object(s)
                filtersForSceneObjects: [                   // Configuration for the scene object filters
                    SceneObjectFilters.AuthoringAll.setActive(true, true),
                    SceneObjectFilters.AuthoringMyLibrary,
                ],
                shortcuts: new Map([
                    ['Copy.global', this.onShortcutCopy],       // Copy the selected TrainingScene/SceneObject to the clipboard
                    // #PRDA-8481 Disabled until we decide how to handle references between objects when cutting
                    //['Cut.global', this.onShortcutCut],         // Cut the selected TrainingScene/SceneObject (e.g. delete and duplicate to the clipboard)
                    ['Paste.global', this.onShortcutPaste],     // Paste TrainingScene/SceneObject from clipboard
                ]),
                events: new Map([
                    [EventType.SIDEPANEL_ASSETS_SELECT, this.onSelectObjectFromSidePanel],
                    [EventType.SIDEPANEL_SCENEOBJECTS_SELECT, this.onSelectObjectFromSidePanel],
                    [EventType.SIDEPANEL_SCENEOBJECTS_CANCEL, this.onCancelSceneObjectSelect],
                    [EventType.SIDEPANEL_TRIGGERS_SELECT, this.selectTriggerForSelectedObject],
                    [EventType.MODAL_REMOVE_ASSET_FROM_SCENE_APPLY, this.removeSceneObject],
                    [EventType.MODAL_REMOVE_GROUP_FROM_SCENE_APPLY, this.removeSceneObject],
                    [EventType.MODAL_REMOVE_HOTSPOT_FROM_SCENE_APPLY, this.removeSceneObject],
                    [EventType.MODAL_REMOVE_TEXT_FROM_SCENE_APPLY, this.removeSceneObject],
                    [EventType.MODAL_REMOVE_UNIT_SCENE_APPLY, this.removeSceneFromUnit],
                    [EventType.MODAL_REMOVE_MODULE_FROM_SCENE_APPLY, this.removeSceneObject],
                    [EventType.MODAL_DUPLICATE_UNIT_SCENE_APPLY, this.duplicateScene],
                    [EventType.INSPECTOR_TRIGGERS_UPDATED, this.onUpdateInspectorTriggers],
                    [EventType.INSPECTOR_DUPLICATE_SCENE_OBJECT, this.onDuplicateInspectorSceneObject],
                ]),
            }
        },
        computed: {

            /**
             * Calculate row indexes and counts for all objects from the revision and its scenes
             *
             * @returns {Object}
             */
            rowsData()
            {
                // Create a simple data object for global objects and scenes:
                const rowsData = {
                    global: [],
                    scenes: [],
                    rowsTotal: 5,               // @NOTE: Row start for scenes + maximum scene objects count + 1 for "Add Object"
                };
                let count = 0;

                // Global objects:
                rowsData.global.rowFirst = 2;   // @NOTE: Adding 2 because of scene header and objectives cells
                this.unitData.objects.forEach(globalObject => {
                    rowsData.global.push(rowsData.global.rowFirst + count);
                    count += 1 + (globalObject.objectsCountCollapsed || 0);
                });
                rowsData.global.rowLast = rowsData.global.rowFirst + count;
                rowsData.global.rowsCount = count;
                rowsData.global.rowsTotal = rowsData.global.rowsCount + rowsData.global.rowFirst + 1;    // @NOTE: Adding 1 for "Add Global Object" button

                // Scenes:
                rowsData.scenes.rowFirst = rowsData.global.rowLast + 2; // @NOTE: Adding 2 for the spacer cells
                rowsData.scenes.rowLast = rowsData.scenes.rowFirst + 1; // @NOTE: Adding 1 for the "Add Object" cell
                rowsData.scenes.rowsCount = 0;
                rowsData.scenes.rowsTotal = 1;  // @NOTE: Adding 1 for the "Add Object" cell
                this.unitData.scenes.forEach((scene, sceneIndex) => {
                    count = 0;
                    rowsData.scenes[sceneIndex] = [];
                    scene.objects.forEach(sceneObject => {
                        rowsData.scenes[sceneIndex].push(rowsData.scenes.rowFirst + count);
                        count += 1 + (sceneObject.objectsCountCollapsed || 0);
                    });
                    rowsData.scenes[sceneIndex].rowFirst = rowsData.scenes.rowFirst;
                    rowsData.scenes[sceneIndex].rowLast = rowsData.scenes.rowFirst + count;
                    rowsData.scenes[sceneIndex].rowsCount = count;
                    rowsData.scenes[sceneIndex].rowsTotal = count + 1;  // @NOTE: Adding 1 for "Add Object" button
                    rowsData.scenes.rowLast = Math.max(rowsData.scenes.rowLast, rowsData.scenes[sceneIndex].rowLast);
                    rowsData.scenes.rowsCount = Math.max(rowsData.scenes.rowsCount, rowsData.scenes[sceneIndex].rowsCount);
                    rowsData.scenes.rowsTotal = Math.max(rowsData.scenes.rowsTotal, rowsData.scenes[sceneIndex].rowsTotal);
                });

                rowsData.rowsTotal = rowsData.scenes.rowLast;
                return rowsData;
            },

            /**
             * Whether the user is allowed to add a new scene
             *
             * @returns {Boolean}
             */
            canUserAddNewScene() {
                return (
                    this.unitData !== null &&
                    this.unitData.scenesCount < this.unitData.scenesCountLimit
                );
            },

            /**
             * Cell position and span for the add-new-scene cell
             *
             * @returns {String}
             */
            cellPositionAddNewScene() {
                const columnIndex = (this.unitData.scenesCount + 1);
                let style = `grid-column-start: ${columnIndex};`;
                style += `grid-column-end: ${columnIndex};`;
                style += 'grid-row-start: 1;';
                style += `grid-row-end: span ${this.rowsData.rowsTotal + 1};`;
                return style;
            },

            /**
             * CSS grid layout (is dependent on the number of scenes)
             *
             * @returns {String}
             */
            gridLayout() {
                return (this.unitData.scenesCount > 0) ? `grid-template-columns: repeat(${this.unitData.scenesCount}, [col-start] ${this.columnWidth}px [col-end]) 90px;` : 'grid-template-columns: 90px;';
            },

            parentUnitRevision() {
                return this.unitData?.getParent(UnitRevision) || null;
            },

            /**
             * Selected TrainingScene object
             *
             * @returns {TrainingScene|Null}
             */
            selectedScene() {
                // @NOTE: Global objects will never have a scene!
                if (typeof this.selection === 'string' && this.selection !== 'AddNewGlobalObject')
                {
                    const sceneUid = this.selection.replace('AddNewObject', '');
                    return this.unitData.scenes.find(scene => scene.uid === sceneUid) || null;
                }
                return (this.selection instanceof TrainingScene) ? this.selection : ((this.selection instanceof AbstractDataObject ? this.selection.getParent(TrainingScene) : null));
            },

            /**
             * Parent target object for the selected object or for adding a new object
             *
             * @returns {TrainingScene|UnitData}
             */
            selectedParentTarget() {
                // @NOTE: TrainingScene for objects that are part of a scene, UnitData for global/other objects
                return this.selectedScene || this.unitData;
            }
        },
        mounted() {
            // Register global events:
            this.events.forEach((value, key) => {
                this.$globalEvents.on(key, value);
            });
            this.$globalEvents.addEvent('click.global.grid-scenes', this.onClickGlobal);
        },
        beforeUnmount() {
            // Unregister global events:
            this.events.forEach((value, key) => {
                this.$globalEvents.off(key, value);
            });
            this.$globalEvents.removeEvent('click.global.grid-scenes', this.onClickGlobal);
        },
        methods: {

            /**
             * Click handler for global events
             *
             * @param {MouseEvent} e
             */
            onClickGlobal(e) {
                // Check if the click was outside a cell:
                if (
                    this.$globalEvents.isEventTargetDescendantOfSelector(e, '#layout-header, #layout-sidemenu, .disabled:not(.selected)', '#layout-inspector, .disabled') === true
                    || e.target.matches('#content, #scenes-authoring-wrapper, #scenes-authoring-grid') === true
                ) {
                    this.deselectGridCell();
                    this.$emit('click-outside-cell', e);
                }
                return this;
            },

            /**
             * Set a scene object (with optional asset) for the currently selected grid cell object
             *
             * @param {SceneObject} sceneObject
             */
            setSceneObjectForSelectedCell(sceneObject) {

                // Add the asset to the revision if it doesn't exist yet (or override if it's outdated):
                if (sceneObject.asset instanceof Asset) {
                    this.parentUnitRevision?.addAssets(sceneObject.asset);
                }

                // Force creation as global object if the type should always be global
                if (sceneObject.sceneObjectType.isGlobalOnly) {
                    this.unitData.deselectScenes();
                    this.selection = 'AddNewGlobalObject';
                }

                // Add the object to the revision or scene if it doesn't exist yet:
                if (this.selection === 'AddNewGlobalObject')
                {
                    if (!this.unitData.hasObject(sceneObject))
                    {
                        this.unitData.addSceneObject(sceneObject, null);
                    }
                }
                else if (this.selectedScene !== null && !this.selectedScene.hasObject(sceneObject))
                {
                    this.selectedScene.addSceneObject(sceneObject, null);
                }

                // Update the selection:
                this.selection = sceneObject;

                // Show the selected object in the inspector:
                this.$globalEvents.emit(EventType.INSPECTOR_INSPECT, this.selection);

                // Hide the assets side panel:
                this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_HIDE);

                return this;
            },

            /**
             * Assign an asset to the selected scene object
             *
             * @param {Asset} asset
             */
            assignAssetToSelectedObject(asset) {

                // Find type for the asset:
                const type = SceneObjectType.findByType(SceneObjectType.TypeOfAsset, asset.type);

                if (type === null)
                {
                    console.error('GridScenes->assignAssetToSelectedObject(): Invalid scene object type for asset', asset);
                    throw new TypeError('GridScenes->assignAssetToSelectedObject(): Invalid scene object type for asset');
                }

                // Replace asset on the selected scene object:
                if (this.selection instanceof BaseSceneObjectAsset)
                {
                    // Do nothing if the same asset has been selected:
                    if (this.selection.asset_uid === asset.uid)
                    {
                        this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_HIDE);
                        return this;
                    }

                    this.replaceAssetReference(this.selection.asset_uid, asset, [this.selection]);
                    this.setSceneObjectForSelectedCell(this.selection);
                }
                else
                {
                    // Create a new scene object:
                    this.setSceneObjectForSelectedCell(
                        SceneObject.createWithType(
                            type,
                            {
                                title: asset.title,
                                asset_uid: asset.uid,
                                asset: asset,
                            },
                            this.selectedParentTarget
                        )
                    );
                }

                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Find all objects referencing a given asset UID
             *
             * @param {String} assetUid
             * @returns {(SceneObject | Command)[]}
             */
            findObjectsWithAssetUid(assetUid, parentObjectOrProperty = this.unitData) {

                if (parentObjectOrProperty instanceof Array) {
                    return parentObjectOrProperty.map(value => this.findObjectsWithAssetUid(assetUid, value)).flat();
                }

                if (parentObjectOrProperty instanceof Object) {
                    const objs = [];
                    Object.values(parentObjectOrProperty).forEach(value => {
                        if (value === assetUid) {
                            objs.push(parentObjectOrProperty);
                        } else if (value instanceof Object) {
                            objs.push(this.findObjectsWithAssetUid(assetUid, value));
                        }
                    });
                    return objs.flat();
                }

                return [];
            },

            /**
             * Replace all occurrences of an asset's UID and add the asset to the revision
             *
             * @param {String} oldAssetUid
             * @param {Asset} newAsset
             * @param {(SceneObject | Command)[] targetObjects}
             */
            replaceAssetReference(oldAssetUid, newAsset, targetObjects) {
                this.parentUnitRevision?.addAssets(newAsset);
                targetObjects.forEach(targetObj => {
                    if (targetObj instanceof BaseSceneObjectAsset) {
                        // Replace object title with the new asset's name (if it hasn't been changed previously by the user)
                        if (targetObj.title === null || targetObj.title === '' || targetObj.title === targetObj.asset.title) {
                            targetObj.title = newAsset.title;
                        }
                        targetObj.asset_uid = newAsset.uid;
                        targetObj.asset = newAsset;
                        targetObj.subtype = newAsset.type;
                    } else {
                        Object.keys(targetObj).forEach(key => {
                            if (targetObj[key] === oldAssetUid) {
                                targetObj[key] = newAsset.uid;
                            }
                        })
                    }
                });
                return this;
            },

            /**
             * Replace the hotspot type of the selected Object. Allows switching between hotspots and transparent shapes
             *
             * @param {SceneObjectType} type
             */
            replaceHotspotOnSelectedObject(type) {

                // Do nothing if no hotspot is selected or if the same type is selected:
                if (
                    !(this.selection instanceof BaseSceneObjectHotspot)
                    || (
                        this.selection.type === type.type
                        && this.selection.subtype === type.subtype
                    )
                ) {
                    this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_HIDE);
                    return this;
                }

                const selectedObjectTypeTitle = this.selection.sceneObjectType?.title;
                const hasUserChangedTitle = this.selection.title && (this.selection.title !== selectedObjectTypeTitle);
                const newTitle = hasUserChangedTitle ? this.selection.title : type.title;

                // Change and update the object (e.g. create a new instance with a different class):
                const updatedSceneObject = SceneObject.createWithType(
                    type,
                    Object.assign(
                        {},
                        this.selection,
                        {
                            title: newTitle,
                        }
                    ),
                    this.selection.parent
                );

                // Refresh object on the parent
                const index = this.selection.parent.objects.findIndex(o => o.uid === this.selection.uid);
                this.selection.parent.removeSceneObject(this.selection, false);
                this.selection.parent.addSceneObject(updatedSceneObject, index);

                this.setSceneObjectForSelectedCell(updatedSceneObject);

                // Fix invalid properties on the hotspot:
                this.selection.cleanUpData();

                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Update handler for changes to triggers in the inspector
             *
             * @param {SceneObject} sceneObject
             */
            onUpdateInspectorTriggers(sceneObject) {
                // Update the objectives on the parent scene:
                const parentScene = (sceneObject instanceof AbstractDataObject) ? sceneObject.getParent(TrainingScene) : null;
                if (parentScene !== null) {
                    parentScene.updateObjectives();
                }
                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Duplicate a scene object
             *
             * @param {SceneObject} sceneObject
             */
            onDuplicateInspectorSceneObject(sceneObject) {
                // Do nothing if the maximum count is reached already:
                if (sceneObject.hasReachedMaxCount)
                {
                    return this;
                }

                // Find the position of the object within the parent's list of objects:
                const index = sceneObject.parent.objects.findIndex(o => o.uid === sceneObject.uid);

                // Duplicate the object:
                const duplicated = sceneObject.duplicate(true);

                // Add the new object to the same parent (UnitData|TrainingScene|SceneObjectGroup):
                if (sceneObject.parent.addSceneObject(duplicated, (index === -1) ? null : (index + 1)))
                {
                    // Duplicate hints on helper module:
                    const helper = sceneObject.getParent(UnitData)?.firstHelperModule;
                    if (helper) {
                        helper.duplicateHintsForNewSceneObject(duplicated);
                    }

                    // Select the duplicated scene object:
                    this.selectGridCell(duplicated);

                    this.$emit('change', this.unitData);
                }

                return this;
            },

            /**
             * Create a new scene object
             *
             * @param {SceneObjectType} type
             */
            createSceneObject(type) {

                switch (type.type)
                {
                    // Asset (Text only, others are created with assignAssetToSelectedObject):
                    case SceneObjectType.TypeOfAsset:
                        this.setSceneObjectForSelectedCell(
                            SceneObject.createWithType(type, null, this.selectedParentTarget)
                        );
                        break;

                    // Group:
                    case SceneObjectType.TypeOfGroup:
                        this.setSceneObjectForSelectedCell(
                            SceneObject.createWithType(type, null, this.selectedParentTarget)
                        );
                        break;

                    // Hotspot:
                    case SceneObjectType.TypeOfHotspot:
                        this.setSceneObjectForSelectedCell(
                            SceneObject.createWithType(
                                type,
                                {
                                    icon: HotspotStyleType.Generic.type,
                                    style: (type.styles instanceof Array && type.styles.length >= 1) ? type.styles[0].type : null,
                                },
                                this.selectedParentTarget
                            )
                        );
                        break;

                    // Module:
                    case SceneObjectType.TypeOfModule:
                        return this.createModuleSceneObject(type);
                }

                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Cancel scene object selection in the side panel
             */
            onCancelSceneObjectSelect() {
                // Deselect the "Add new object" cell:
                if (typeof this.selection === 'string' && (this.selection === 'AddNewGlobalObject' || this.selection.indexOf('AddNewObject') === 0))
                {
                    this.deselectGridCell();
                }
                return this;
            },

            /**
             * Create a new module scene object
             *
             * @param {SceneObjectType} type
             */
            createModuleSceneObject(type) {

                // Create a new scene object:
                const newSceneObject = SceneObject.createWithType(type, null, this.selectedParentTarget);

                // Inject default content elements for certain Module types:
                switch(type.subtype)
                {
                    // Intro:
                    case SceneObjectType.Modules.Intro.subtype:
                        const defaultIntroContent = OverlayContent.newIntroContent;
                        defaultIntroContent.parent = newSceneObject;
                        newSceneObject.contents.push(defaultIntroContent);
                        newSceneObject.setButtons();
                        break;

                    // Outro:
                    case SceneObjectType.Modules.Outro.subtype:
                        newSceneObject.visible = false;
                        const defaultOutroContent = OverlayContent.newOutroContent;
                        defaultOutroContent.parent = newSceneObject;
                        newSceneObject.contents.push(defaultOutroContent);
                        newSceneObject.setButtons();
                        break;

                    // Overlay:
                    case SceneObjectType.Modules.Overlay.subtype:
                        newSceneObject.visible = false;
                        newSceneObject.setButtons();
                        break;

                    // Presenter keypad:
                    case SceneObjectType.Modules.Keypad.subtype:
                        newSceneObject.setDefaultTriggersForKeypadModule();
                        break;
                }

                // Update the selected object:
                this.setSceneObjectForSelectedCell(newSceneObject);

                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Remove a given asset/hotspot/object from a scene or from the revision
             *
             * @param {SceneObject} sceneObject
             */
            removeSceneObject(sceneObject) {
                // Remove helper-related commands:
                if (sceneObject instanceof SceneObjectModuleHelper) {
                    sceneObject.relatedCommands.forEach(c => c.parent.removeCommand(c));
                } else if (sceneObject instanceof SceneObjectGroup) {
                    const helperModule = sceneObject.objectsFlat.find(o => o instanceof SceneObjectModuleHelper) || null;
                    helperModule?.relatedCommands.forEach(c => c.parent.removeCommand(c));
                }

                this.unitData.removeSceneObject(sceneObject, true);
                this.deselectGridCell();
                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Remove a given asset/hotspot/object from a scene
             *
             * @param {TrainingScene} scene
             */
            removeSceneFromUnit(scene) {
                if (scene instanceof TrainingScene)
                {
                    this.unitData.removeScene(scene);
                    this.deselectGridCell();
                    this.$emit('change', this.unitData);
                }
                return this;
            },

            /**
             * Select a training scene by clicking on the header cell
             *
             * @param {TrainingScene} scene
             * @param {MouseEvent} e
             */
            onSelectTrainingScene(scene, e) {
                // Deselect previous cell:
                if (this.selection !== null)
                {
                    this.deselectGridCell(false);
                }
                // Select the entire scene if it's not already selected:
                if (Object.is(scene, this.selectedScene) === false)
                {
                    this.unitData.deselectScenes();
                    this.unitData.selectScene(scene);
                }
                this.selection = scene;
                this.$refs.scenes.find(sc => sc.scene.uid === scene.uid).$el.scrollIntoView({behavior: 'smooth'});
                // Show the selected scene in the inspector:
                this.$emit('click-outside-cell', e);    // Trigger hiding of any side panels which is done in the Authoring component
                this.$globalEvents.emit(EventType.SIDEPANEL_HIDE);
                this.$globalEvents.emit(EventType.INSPECTOR_INSPECT, scene);
                return this;
            },

            /**
             * Select an object in a specific cell
             *
             * @param {SceneObject|SceneObjectives|TrainingScene} selectedObject
             */
            selectGridCell(selectedObject) {

                const parentScene = selectedObject.getParent(TrainingScene);

                // Deselect previous scene selections:
                if ((this.selectedScene === null && this.unitData.selectedScene !== null) || !Object.is(this.selectedScene, parentScene))
                {
                    this.unitData.deselectScenes();
                }

                // Select the entire scene if the object belongs to one:
                if (parentScene instanceof TrainingScene)
                {
                    this.unitData.selectScene(parentScene);
                }

                // Update the reference:
                this.selection = selectedObject;

                // SceneObject:
                if (selectedObject instanceof SceneObject)
                {
                    this.$globalEvents.emit(EventType.INSPECTOR_INSPECT, selectedObject);
                }

                // SceneObjectives:
                else if (selectedObject instanceof SceneObjectives)
                {
                    this.$globalEvents.emit(EventType.INSPECTOR_INSPECT, selectedObject);
                }

                return this;
            },

            /**
             * Deselect any currently selected cell
             *
             * @param {Boolean} deselectScene
             */
            deselectGridCell(deselectScene = true) {
                const alreadyDeselected = (this.selection === null && this.selectedScene === null);

                if (this.selection !== null)
                {
                    this.selection = null;
                    this.$globalEvents.emit(EventType.INSPECTOR_INSPECT, null);  // Deselect objectives
                }

                if (this.selectedScene !== null && deselectScene)
                {
                    this.unitData.deselectScenes();
                    this.selectedScene.selected = false;
                    this.$globalEvents.emit(EventType.INSPECTOR_INSPECT, null); // Deselect scene
                }

                // Select unit revision as default
                if (!alreadyDeselected) {
                    this.$globalEvents.emit(EventType.INSPECTOR_INSPECT, this.parentUnitRevision);
                }

                return this;
            },

            /**
             * Click handler for empty grid cells
             *
             * @param {UnitData|TrainingScene} sceneOrUnitData
             */
            onClickAddNewObject(sceneOrUnitData) {

                // Deselect previous cell:
                this.deselectGridCell();
                this.unitData.deselectScenes();

                // Select the scene if it's a regular scene object:
                if (sceneOrUnitData instanceof TrainingScene)
                {
                    this.unitData.selectScene(sceneOrUnitData);
                    this.selection = 'AddNewObject' + sceneOrUnitData.uid;
                }
                else
                {
                    this.selection = 'AddNewGlobalObject';
                }

                // Set filters:
                this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_FILTERS_SET, this.filtersForSceneObjects, this.selectedParentTarget);

                // Show asset selection side panel for adding a new object:
                this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_SHOW, {
                    callback: this.onSelectObjectFromSidePanel,
                    assetTypes: AssetType.allUploadable,
                });

                return this;
            },

            /**
             * @param {Asset|SceneObjectType} selectedObject
             * @param {Object|Function} callback
             */
            onSelectObjectFromSidePanel(selectedObject, callback = null) {

                // Create/Replace asset
                if (selectedObject instanceof Asset) {

                    // Create new asset
                    if (callback === this.onSelectObjectFromSidePanel) {
                        return this.assignAssetToSelectedObject(selectedObject);
                    }

                    // Replace asset (callback comes from InspectorSceneObjectAsset)
                    if (callback === this.selection && this.selection instanceof BaseSceneObjectAsset) {

                        // Do nothing if the same asset has been selected:
                        if (this.selection.asset_uid === selectedObject.uid)
                        {
                            this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_HIDE);
                            return this;
                        }

                        // Look for other references of the old asset:
                        const oldAssetUid = this.selection.asset_uid;
                        const assetReferences = this.findObjectsWithAssetUid(oldAssetUid);

                        // Show dialog if the asset is being used on multiple objects:
                        if (assetReferences.length >= 2) {
                            this.$globalEvents.emit(EventType.MODAL_REPLACE_ASSET_SHOW,
                                this.selection.asset,
                                assetReferences.length,
                                (option) => {
                                    this.assignAssetToSelectedObject(selectedObject);
                                    if (option === 'all') {
                                        this.replaceAssetReference(oldAssetUid, selectedObject, assetReferences);
                                    }
                                },
                            );
                            return this;
                        }

                        return this.assignAssetToSelectedObject(selectedObject);
                    }
                }

                // Create new object
                if (
                    selectedObject instanceof SceneObjectType
                    && typeof this.selection === 'string'
                    && this.selection.startsWith('AddNew')
                ) {
                    this.createSceneObject(selectedObject);
                    return this;
                }

                // Replace hotspot/transparent shape (callback comes from InspectorSceneObjectHotspot)
                if (
                    callback === this.selection
                    && selectedObject instanceof SceneObjectType
                ) {
                    this.replaceHotspotOnSelectedObject(selectedObject);
                    return this;
                }

                return this;
            },

            /**
             * Click handler for visibility changes on a global scene object
             *
             * @param {SceneObject} sceneObject
             * @param {TrainingScene} scene
             */
            onClickSceneObjectChangeVisible(sceneObject, scene) {
                if (sceneObject.isHiddenInScene(scene) === true)
                {
                    sceneObject.hidden_in_scenes = sceneObject.hidden_in_scenes.filter(s => s !== scene.uid);
                }
                else if (!sceneObject.hidden_in_scenes.includes(scene.uid))
                {
                    sceneObject.hidden_in_scenes.push(scene.uid);
                }
                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Click handler for adding a new scene
             *
             */
            onClickAddNewScene() {

                // Do nothing if we already have the maximum number of scenes or if the user doesn't have permissions:
                if (this.canUserAddNewScene === false)
                {
                    return this;
                }

                // New scene counter:
                const newScenesCount = this.unitData.scenes.filter(s => s.title.toLowerCase().indexOf(trans('labels.scene_new_default_name').toLowerCase()) >= 0).length;

                // Create a new scene:
                const newScene = new TrainingScene({
                    title: trans('labels.scene_new_default_name') + (newScenesCount >= 1 ? ' ' + (newScenesCount + 1) : ''),
                    monoscopic: (this.unitData.type === UnitType.ThreeSixty.type)
                }, this.unitData);

                // Add the scene to the revision:
                this.unitData.addNewScene(newScene, null);

                // Use hidden_in_scenes from previous scene for global objects:
                if (this.unitData.scenes.length >= 2) {
                    const previousScene = this.unitData.scenes[this.unitData.scenes.length - 2];
                    this.unitData.allGlobalObjects.filter(go => go.isHiddenInScene(previousScene)).forEach(go => go.hidden_in_scenes.push(newScene.uid));
                }

                // Scroll the view to the right:
                window.setTimeout(function() {
                    const contentElement = window.document.getElementById('content');
                    const gridElement = window.document.getElementById('scenes-authoring-wrapper');
                    contentElement.scrollLeft = gridElement.getBoundingClientRect().width;
                }, 10);

                this.$emit('change', this.unitData);

                return this;
            },

            /**
             * Update or select a trigger for the selected scene object
             *
             * @param {TriggerType} triggerType
             * @param {Trigger} trigger
             */
            selectTriggerForSelectedObject(triggerType, trigger = null) {

                // Throw exception if no scene object is set:
                if (!(this.selection instanceof SceneObject))
                {
                    console.error('GridScenes->selectTriggerForSelectedObject(): Invalid or non-existent selected scene object');
                    throw new TypeError('GridScenes->selectTriggerForSelectedObject(): Invalid or non-existent selected scene object');
                }

                // Replace the existing trigger:
                if (trigger !== null)
                {
                    const previousIndexOfTrigger = this.selection.triggers.indexOf(trigger);
                    const convertedTrigger = Trigger.createWithType(triggerType, trigger, this.selection);
                    convertedTrigger.cleanUpData();

                    // Replace the previous trigger with the new one
                    this.selection.triggers.splice(
                        previousIndexOfTrigger,
                        1,
                        convertedTrigger
                    );

                    this.selection.cleanUpData();

                } else {
                    // Create a new trigger object for the currently selected scene object:
                    this.selection.triggers.push(Trigger.createWithType(triggerType, null, this.selection));
                }

                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Duplicate the selected scene
             *
             * @param {TrainingScene} scene
             * @param {String} newTitle
             */
            duplicateScene(scene, newTitle) {
                this.unitData.duplicateScene(scene, newTitle);
                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Handler for changing the scene order via drag'n'drop on the header cells
             *
             * @param {MouseEvent} e
             */
            onChangeSceneOrder(e) {
                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * DragEnter handler for scene objects
             *
             * @param {MouseEvent} e
             */
            onDragEnterSceneObject(e) {
                const dropObject = e.dataDraggable?.value || null;
                const targetObject = e.dataDropTarget?.value || null;
                if (dropObject === null || targetObject === null) {
                    console.warn('GridScenes->onDragEnterSceneObject(): Invalid dragging data.', e);
                    e.currentTarget.classList.remove('can-drop', 'drop-before', 'drop-after');
                    return this;
                }

                // Disallow the same object:
                if (Object.is(dropObject, targetObject) || dropObject.uid === targetObject.uid) {
                    e.currentTarget.classList.remove('can-drop', 'drop-before', 'drop-after');
                    return this;
                }

                const allObjectUIDs = this.unitData.allSceneObjects.map(o => o.uid);
                const dropIndex = allObjectUIDs.findIndex(uid => uid === dropObject.uid);
                const targetIndex = allObjectUIDs.findIndex(uid => uid === targetObject.uid);

                // Check if all objects have been found correctly:
                if ([dropIndex, targetIndex].includes(-1)) {
                    console.error('GridScenes->onDragEnterSceneObject(): Invalid object index found');
                    return this;
                }

                const dropIsGroup = dropObject instanceof SceneObjectGroup;
                const targetIsGroup = targetObject instanceof SceneObjectGroup;

                // SceneObject onto Group (will insert into the group if it is empty)
                if (!dropIsGroup && targetIsGroup) {
                    e.stopImmediatePropagation();
                    if (targetObject.objects.length === 0) {
                        // @NOTE: currentTarget is the group cell
                        e.currentTarget.classList.remove('drop-before');
                        e.currentTarget.classList.add('drop-after');
                        return this;
                    }
                    e.currentTarget.classList.add('drag-over');
                    return this;
                }

                // SceneObject onto SceneObject inside a group
                else if (!dropIsGroup && !targetIsGroup && targetObject.isGroupChild) {
                    e.stopImmediatePropagation();
                    e.currentTarget.parentNode.parentNode.classList.add('drag-over');
                    return this;
                }

                // Group onto SceneObject inside a group
                else if (dropIsGroup && targetObject.isGroupChild) {
                    e.currentTarget.classList.remove('can-drop', 'drop-before', 'drop-after');
                    e.currentTarget.parentNode.parentNode.classList.add('drag-over');
                    return this;
                }

                // Group onto Group
                else if (dropIsGroup && targetIsGroup) {
                    e.stopImmediatePropagation();
                    return this;
                }

                return this;
            },

            /**
             * DragLeave handler for scene objects
             *
             * @param {MouseEvent} e
             */
            onDragLeaveSceneObject(e) {
                const dropObject = e.dataDraggable?.value || null;

                if (dropObject === null) {
                    console.warn('GridScenes->onDragLeaveSceneObject(): Invalid dragging data.', e);
                    return this;
                }

                e.stopImmediatePropagation();

                const isLeavingFromGroup = e.currentTarget.matches('.group-cell');
                const isLeavingFromGroupChild = !isLeavingFromGroup && e.currentTarget.matches('.is-group-child');
                const fromGroup = isLeavingFromGroup ? e.currentTarget : (isLeavingFromGroupChild ? e.currentTarget.parentNode.parentNode : null);

                const isLeavingToGroup = e.relatedTarget ? e.relatedTarget.matches('.group-cell') : false;
                const isLeavingToGroupChild = !isLeavingToGroup && e.relatedTarget && e.relatedTarget.matches('.is-group-child');
                const toGroup = isLeavingToGroup ? e.relatedTarget : (isLeavingToGroupChild ? e.relatedTarget.parentNode.parentNode : null);

                const isSameGroup = (fromGroup !== null && toGroup !== null && fromGroup === toGroup);

                if (fromGroup) {
                    fromGroup.classList.add('drag-over');
                }

                if (
                    (isLeavingFromGroupChild && !isSameGroup) ||
                    (isLeavingFromGroupChild && toGroup === null) ||
                    (isLeavingFromGroup && !isSameGroup) ||
                    (isLeavingFromGroup && toGroup === null)
                ) {
                    fromGroup.classList.remove('drag-over');
                    return this;
                }

                return this;
            },

            /**
             * Drop handler for changing the scene object order
             *
             * @param {MouseEvent} e
             */
            onDropSceneObject(e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                const dropObject = e.dataDraggable?.value || null;
                const targetObject = e.dataDropTarget?.value || null;
                const dropIsGroup = dropObject instanceof SceneObjectGroup;
                const targetIsGroup = targetObject instanceof SceneObjectGroup;

                if (dropObject === null || targetObject === null) {
                    console.warn('GridScenes->onDropSceneObject(): Invalid dragging data.', e);
                    return this;
                }

                // Remove leftover drag classes:
                document.querySelectorAll('.drag-over').forEach(e => e.classList.remove('can-drop', 'drag-over', 'drop-before', 'drop-after'));

                // Disallow the same object:
                if (Object.is(dropObject, targetObject) || dropObject.uid === targetObject.uid)
                {
                    return this;
                }

                const allObjectUIDs = this.unitData.allSceneObjects.map(o => o.uid);
                const dropIndex = allObjectUIDs.findIndex(uid => uid === dropObject.uid);
                const dropParentIndex = dropObject.parent.objects.findIndex(o => o.uid === dropObject.uid);
                const targetIndex = allObjectUIDs.findIndex(uid => uid === targetObject.uid);
                const targetParentIndex = targetObject.parent.objects.findIndex(o => o.uid === targetObject.uid);

                // Check if all objects have been found correctly:
                if ([dropIndex, dropParentIndex, targetIndex, targetParentIndex].includes(-1))
                {
                    console.error('GridScenes->onDropSceneObject(): Invalid object index found');
                    return this;
                }

                let offset = null;
                let dropTarget = targetObject;

                // Can drop inside group?
                if (!dropIsGroup && targetIsGroup && targetObject.objects.length === 0)
                {
                    offset = null;
                    dropTarget = targetObject;
                }
                // Drop group before or after another group:
                else if (dropIsGroup && targetObject.isGroupChild)
                {
                    const parentSpliceOffset = (dropIndex < targetIndex) ? 1 : 0;
                    const targetParent = targetObject.getParent(TrainingScene) || targetObject.getParent(UnitData);
                    const groupIndex = targetParent.objects.findIndex(o => o.uid === targetObject.parent.uid);
                    offset = (groupIndex + parentSpliceOffset);
                    dropTarget = targetParent;
                }
                else
                {
                    // Drop before or after:
                    const spliceOffset = (dropIndex < targetIndex && dropObject.parent.uid !== targetObject.parent.uid) ? 1 : 0;
                    offset = targetParentIndex + spliceOffset;
                    dropTarget = targetObject.parent;
                }

                // Remove the object and all references to it and its children:
                dropObject.parent.removeSceneObject(dropObject, false);

                // Add the object to its new parent:
                dropTarget.addSceneObject(dropObject, offset);
                this.$emit('change', this.unitData);
                return this;
            },

            /**
             * Shortcut handler: Copy
             *
             * @param {CustomEvent} e
             */
            onShortcutCopy(e) {
                if (this.selection instanceof SceneObject || this.selection instanceof TrainingScene)
                {
                    // Copy a new instance of the selected object to the clipboard:
                    Clipboard.setClipboardDataAsync(this.selection);
                }
                return this;
            },

            /**
             * Shortcut handler: Cut
             *
             * @param {CustomEvent} e
             */
            onShortcutCut(e) {
                if (this.selection instanceof SceneObject)
                {
                    // Copy a new instance of the selected object to the clipboard:
                    Clipboard.setClipboardDataAsync(this.selection);
                    this.removeSceneObject(this.selection);
                }
                else if (this.selection instanceof TrainingScene)
                {
                    // Copy a new instance of the selected object to the clipboard:
                    Clipboard.setClipboardDataAsync(this.selection);
                    this.removeSceneFromUnit(this.selection);
                }
                return this;
            },

            /**
             * Shortcut handler: Paste
             *
             * @param {CustomEvent} e
             */
            onShortcutPaste(e) {
                // Do nothing if the clipboard is empty:
                const clipboardData = Clipboard.getDataFromClipboardEvent(e.detail.clipboardEvent);
                if (clipboardData === null || clipboardData.isEmpty || !clipboardData.isModel)
                {
                    return this;
                }

                // Get data from the clipboard (and use new instances so we're not modifying the clipboard data):
                const sceneObjectToPaste = (clipboardData.isInstanceOf(SceneObject)) ? SceneObject.fromClipboardData(clipboardData) : null;
                const sceneToPaste = (clipboardData.isInstanceOf(TrainingScene)) ? TrainingScene.fromClipboardData(clipboardData) : null;

                if (sceneObjectToPaste)
                {
                    // Stop bubbling to parent components:
                    e.stopShortcutPropagation();
                    return this.pasteSceneObject(sceneObjectToPaste);
                }
                else if (sceneToPaste)
                {
                    // Stop bubbling to parent components:
                    e.stopShortcutPropagation();
                    return this.pasteScene(sceneToPaste, e);
                }

                return this;
            },

            /**
             * Paste scene object into the selected UnitData/TrainingScene/SceneObject
             *
             * @param {SceneObject} sceneObjectToPaste
             */
            pasteSceneObject(sceneObjectToPaste) {
                // Deny pasting of assets when the user doesn't have access:
                if (sceneObjectToPaste instanceof BaseSceneObjectAsset)
                {
                    // @TODO: Consider asset policies once we have restrictions for them #PRDA-8075
                    sceneObjectToPaste.asset = this.assetService.getAssetByUid(sceneObjectToPaste.asset_uid);
                }

                // Add referenced assets to the revision (if they are really being used is determined by the backend when saving):
                // @TODO: Consider asset policies once we have restrictions for them #PRDA-8075
                this.parentUnitRevision?.addAssets(this.assetService.getReferencedAssetsFromObject(sceneObjectToPaste));

                // Assign missing asset references:
                if (sceneObjectToPaste instanceof SceneObjectGroup)
                {
                    sceneObjectToPaste.objectsFlat.filter(so => so.type === SceneObjectType.TypeOfAsset && so.subtype !== AssetType.Text.type && so.asset === null).forEach(so => so.asset = this.assetService.getAssetByUid(so.asset_uid));
                }

                let insertIndex = null;
                let target = this.selection || this.unitData;

                // Force pasting of always-global objects onto the unit data instead of a scene
                if (
                    (
                        target instanceof TrainingScene
                        || target instanceof SceneObjectives
                        || !target.isGlobal
                    )
                    && sceneObjectToPaste instanceof SceneObject
                    && sceneObjectToPaste.sceneObjectType.isGlobalOnly
                ) {
                    target = this.unitData;

                // Force pasting onto a scene if objectives are selected
                }else if (
                    target instanceof SceneObjectives
                    && sceneObjectToPaste instanceof SceneObject
                ) {
                    target = target.parent;
                }

                const targetIsGroup = target instanceof SceneObjectGroup;
                const targetIsSceneObject = target instanceof SceneObject && !targetIsGroup;
                const targetIsGroupChild = target instanceof SceneObject && target.isGroupChild;
                const soIsGroup = sceneObjectToPaste instanceof SceneObjectGroup;
                const soIsSceneObject = sceneObjectToPaste instanceof SceneObject && !soIsGroup;

                // Prevent nested groups:
                if (soIsGroup && targetIsGroupChild)
                {
                    if (target.isGlobal)
                    {
                        const parentUnitData = target.getParent(UnitData);
                        insertIndex = (parentUnitData.objects.findIndex(o => o.uid === target.parent.uid) + 1) || parentUnitData.objects.length;
                        target = parentUnitData;
                    }
                    else
                    {
                        const parentScene = target.getParent(TrainingScene);
                        insertIndex = (parentScene.objects.findIndex(o => o.uid === target.parent.uid) + 1) || parentScene.objects.length;
                        target = parentScene;
                    }
                }
                // Change target to its parent for scene objects being pasted onto other scene objects:
                else if ((soIsGroup && (targetIsGroup || targetIsSceneObject)) || (soIsSceneObject && targetIsSceneObject))
                {
                    insertIndex = (target.parent.objects.findIndex(o => o.uid === target.uid) + 1) || target.parent.objects.length;
                    target = target.parent;
                }

                // Paste the scene object:
                if (target instanceof TrainingScene || target instanceof UnitData || target instanceof SceneObjectGroup)
                {
                    // Set parent to the target for hasReachedMaxCount to work:
                    sceneObjectToPaste.parent = target;

                    // Duplicate to keep UIDs unique:
                    sceneObjectToPaste = sceneObjectToPaste.duplicate(true);

                    // Adjust visibility when changing between global and non-global:
                    const allScenesUids = (target.getParent(UnitData) || target).scenes.map(s => s.uid);
                    const parentScene = (target.isGlobal && !(target instanceof TrainingScene)) ? null : (target.getParent(TrainingScene) || target);
                    [sceneObjectToPaste].concat(sceneObjectToPaste.objectsFlat || []).forEach(so => {
                        // Insert as global:
                        if (parentScene === null && !so.visible)
                        {
                            so.hidden_in_scenes = allScenesUids.slice();
                            so.visible = true;
                        }
                        // Insert as non-global (e.g. into scene):
                        else if (parentScene && so.visible && so.isHiddenInScene(parentScene))
                        {
                            so.visible = false;
                        }
                    });

                    // Add the scene object to the target:
                    if (target.addSceneObject(sceneObjectToPaste, insertIndex))
                    {
                        // Select the corresponding grid cell:
                        this.selectGridCell(sceneObjectToPaste);

                        this.$emit('change', this.unitData);
                        return this;
                    }
                }
            },

            /**
             * Paste a scene into the revision
             *
             * @param {TrainingScene} sceneToPaste
             * @param {CustomEvent} e
             */
            pasteScene(sceneToPaste, e) {
                // Do nothing if the maximum number of scenes is exceeded already:
                if (this.unitData.scenesCount >= this.unitData.scenesCountLimit)
                {
                    return this;
                }

                // Add referenced assets to the revision (if they are really being used is determined by the backend when saving):
                // @TODO: Consider asset policies once we have restrictions for them #PRDA-8075
                this.parentUnitRevision?.addAssets(this.assetService.getReferencedAssetsFromObject(sceneToPaste));

                // Assign missing asset references:
                sceneToPaste.allSceneObjects.filter(so => so.type === SceneObjectType.TypeOfAsset && so.subtype !== AssetType.Text.type && so.asset === null).forEach(so => so.asset = this.assetService.getAssetByUid(so.asset_uid));

                // Set parent revision before duplicating so related global properties can be duplicated or updated:
                sceneToPaste.parent = this.unitData;

                // Duplicate to keep UIDs unique:
                sceneToPaste = sceneToPaste.duplicate(true);

                // Paste into revision:
                const target = this.selection || this.unitData;
                const insertIndex = (target instanceof TrainingScene) ? (this.unitData.scenes.findIndex(s => s.uid === target.uid) + 1) || null : null;

                if (this.unitData.addNewScene(sceneToPaste, insertIndex)) {
                    this.$nextTick(() => this.onSelectTrainingScene(sceneToPaste, e));

                    this.$emit('change', this.unitData);
                }

                return this;
            }
        },
        watch: {

            /**
             * Watch the unit revision for changes from outside the component
             */
            unitData(unitData) {
                if (unitData instanceof UnitData)
                {
                    // Deselect everything:
                    this.deselectGridCell();
                }
                else
                {
                    console.error('GridScenes->watch->unitData(): Unable to set unit revision because it\'s not instantiated');
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

    #scenes-authoring-wrapper {
        position: relative;
        display: inline-block;
        min-height: 100%;
    }

    #scenes-authoring-grid {
        position: relative;
        display: grid;
        grid-auto-rows: minmax(38px, auto);
        grid-gap: 1px 3px;
        margin: 0 50px 50px 0; // Fix padding from the #content container not working here (bottom works in Chrome, though)
    }

    .add-scene-cell {
        position: relative;
        display: flex;
        flex-direction: column;

        > div {
            flex-grow: 1;
            max-height: 100vh;
            position: sticky;
            top: 0;
            display: block;
            text-align: center;
            cursor: pointer;

            > span {
                display: block;
                position: absolute;
                top: 50%;
                width: 100%;
                margin-top: -25px;

                svg {
                    width: 24px;
                    height: 24px;
                }

                span {
                    display: block;
                    margin: 0 auto;
                    line-height: 1.2;
                }
            }
        }
    }

</style>

<style lang="scss">
    // Fix additional dragenter and dragleave events being fired on child elements
    body.dragging #scenes-authoring-grid .droptarget:not(.dragging) *:not(.dragging, .droptarget) {
        pointer-events: none !important;
    }
</style>

<template>
    <section
        v-if="revisionToDisplay"
        :class="classNames"
        @focus="$emit('focus', unit)"
        @click="$emit('click', unit)"
        @dblclick="$emit('dblclick', unit)"
    >

        <img :src=revisionToDisplay.preview_thumbnail
             :alt="revisionToDisplay.title"
             class="unit-preview">

        <div class="unit-content">
            <h2 class="unit-title">{{ revisionToDisplay.title }}
                <time class="unit-author" :datetime="moment(revisionToDisplay.released_at).format()">{{ releasedDate }}</time>
            </h2>
            <p class="unit-description">{{ revisionToDisplay.description }}</p>
        </div>

        <footer>
            <div class="unit-labels">
                <StatusLabel
                    v-for="labelConfig in labels"
                    :key="'statuslabel'+unit.uid+(labelConfig.type || labelConfig.caption)"
                    :config="labelConfig"
                />
            </div>
            <template v-if="unitHasActions">
                <div class="unit-actions">

                    <!-- Btn: Edit -->
                    <ButtonCircular
                        v-if="unitIsLaunchable"
                        v-tooltip="'buttons.courses.units.launch'"
                        :href="this.$root.route('courses.units.launch', {'course': course.uid,'unit': unit.uid})"
                        icon="icon_play"
                        @trigger="$emit('click-launch', unit)"
                    />

                    <!-- Btn: Remove -->
                    <ButtonCircular
                        v-if="unitIsRemovable"
                        v-tooltip="'buttons.units.remove_from_course'"
                        icon="icon_delete"
                        @trigger="$emit('click-remove', unit)"
                    />
                </div>
            </template>
        </footer>

    </section>
</template>

<script>
    import moment from 'moment';
    import Unit from '@/Models/Unit/Unit';
    import {Permission} from '@/Models/User/Permission';
    import {UnitPermissionPolicyStandard} from '@/Models/Unit/UnitPermissionPolicy';
    import StatusLabelConfig from '@/Utility/StatusLabelConfig';
    import {trans} from '@/Utility/Helpers';
    import ButtonCircular from "@/Vue/Common/ButtonCircular.vue";
    import StatusLabel from "@/Vue/Common/StatusLabel.vue";
    import Course from "@/Models/Course/Course";
    import {inject} from "vue";
    import {featureRepositoryKey} from "@/Vue/Bootstrap/InjectionKeys";
    import {Feature} from "@/Models/Features/Feature";

    export default {
        name: 'UnitListItem',
        components: {StatusLabel, ButtonCircular},

        emits: [
            'click',
            'dblclick',
            'click-launch',
            'click-remove',
            'focus',
        ],

        props: {
            unit: {
                type: Unit,
            },
            currentUnitRevisionUid: {
                type: String,
            },
            unitIsRemovable: {
                type: Boolean,
                default: false,
            },
            course: {
                type: Course,
            },
            selected: {
                type: Boolean,
                default: false,
            },
        },

        data() {
            return {
                featureRepository: inject(featureRepositoryKey),
            }
        },

        computed: {

            /**
             * @returns {string[]}
             */
            classNames() {
                return [
                    'unit-list-item',
                    this.selected ? 'selected' : '',
                    this.unit.is_new ? 'is-new' : '',
                ];
            },

            /**
             * @returns {StatusLabelConfig[]}
             */
            labels() {
                const labels = [];

                // Policy (if non-standard)
                if (this.unit.policy !== UnitPermissionPolicyStandard.type)
                {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'policy',
                            caption: this.unit.policy
                        })
                    );
                }

                // Released or draft
                if (this.unit.isReleased)
                {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'status',
                            caption: 'released'
                        })
                    );
                }
                else if (this.unit.isDraft)
                {
                    labels.push(
                        new StatusLabelConfig({
                            caption: 'draft'
                        })
                    );
                }

                // Unreleased changes
                if (this.unit.hasUnreleasedChanges)
                {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'notification',
                            caption: 'unreleased_changes'
                        })
                    );
                }

                // Incompatible
                if (!this.unit.latestRevision.isCompatible)
                {
                    labels.push(
                        new StatusLabelConfig({
                            type: 'warning',
                            caption: 'incompatible_unit_open',
                            tooltip: 'incompatible_unit_open_tooltip'
                        })
                    );
                }

                return labels;
            },

            /**
             * @returns {null|UnitRevision}
             */
            revisionToDisplay() {
                return this.currentUnitRevision;
            },

            /**
             * @returns {null|UnitRevision}
             */
            currentUnitRevision() {
                return this.unit.revisions.find(unitRevision => unitRevision.uid === this.currentUnitRevisionUid) || null;
            },

            /**
             * @returns {string}
             */
            releasedDate() {
                const revision = this.revisionToDisplay;
                if (revision.isDraft) {
                    return '';
                }
                return `${trans('labels.released_on')} ${moment(revision.released_at).format(trans('courses.unit_list.item_date_format'))}`;
            },

            /**
             * @returns {boolean}
             */
            unitHasActions() {
                return this.unitIsRemovable || this.unitIsLaunchable;
            },

            /**
             * @returns {boolean}
             */
            unitIsEditable() {
                return this.$gate.allows(Permission.ability(Permission.UnitsUpdate()), this.unit);
            },

            /**
             * @returns {boolean}
             */
            unitIsLaunchable() {
                return this.$gate.allows(Permission.ability(Permission.CoursesLaunchUnit()), this.course, this.unit)
                    && this.featureRepository.active(Feature.EntitlementDevicetypeWebapp);
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>

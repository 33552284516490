<template>
    <div class="command-settings">
        <Dropdown
            :model="command"
            property="value"
            @select="onChangeCommand"
            :required="true"
            :options="dropdownOptionsForHelperWaypointValues"
            :deselected-caption="trans('authoring.select_waypoint')"
        />
    </div>
</template>

<script>
// Import helpers and functions:
import { sortArrayByProperty, trans } from '@/Utility/Helpers';

// Import classes:
import DropdownOption from '@/Utility/DropdownOption';
import DropdownOptionGroup from '@/Utility/DropdownOptionGroup';
import {HelperWaypointGoToCommand} from '@/Models/UnitData/Commands/Command';
import CommandType from '@/Models/UnitData/Commands/CommandType';
import {WaypointModeValues} from '@/Models/Unity/WaypointMode';
import TrainingScene from "@/Models/UnitData/Scenes/TrainingScene";
import UnitData from "@/Models/UnitData/UnitData";

export default {
    name: 'PanelCommandHelperWaypointGoTo',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: HelperWaypointGoToCommand,
            default: null
        }
    },
    computed: {
        /**
         * Get the waypoints from the first Helper module grouped by scenes
         *
         * @returns {Object[]}
         */
        waypointsByScenes() {
            const helperModule = this.command.targetObject;
            if (helperModule === null)
            {
                return [];
            }

            const waypoints = [{
                scene: null,
                waypoints: helperModule.waypoints.global
            }];

            const parentTrainingScene = this.command.getParent(TrainingScene);
            this.command.getParent(UnitData).scenes.filter(s => parentTrainingScene === null || s.uid === parentTrainingScene.uid).forEach(s => {
                waypoints.push({
                    scene: s,
                    waypoints: helperModule.waypoints[s.uid] || []
                });
            });

            return waypoints.filter(w => w.waypoints.length >= 1);
        },

        /**
         * Get options for Helper Companion waypoint value dropdown
         *
         * @returns {DropdownOption[]}
         */
        dropdownOptionsForHelperWaypointValues() {

            // WaypointModes
            const waypointModeOptions = WaypointModeValues
                .filter(value => CommandType.HelperWaypointGoTo.possibleValues.includes(value))
                .map(value => new DropdownOption({
                        caption: trans('commands.helper.waypoint_mode.' + value),
                        value: value
                    })
                );
            sortArrayByProperty(waypointModeOptions, 'caption', false);

            // Groups for all scenes
            const waypointsByScenesOptions = this.waypointsByScenes.map(group =>
                new DropdownOptionGroup({
                    caption: (group.scene !== null) ? `${trans('labels.scene')} ${group.scene.indexAndTitleFormatted}` : trans('labels.global'),
                    isSeparator: true,
                    options: group.waypoints.map((w, wi) => new DropdownOption({
                        caption: w.title || trans('labels.waypoint') + ' ' + (wi+1),
                        value: w.uid
                    })),
                    referenceUid: group.scene?.uid || 'no-ref',
                })
            );
            sortArrayByProperty(waypointsByScenesOptions, 'caption', false);

            return [...waypointModeOptions, ...waypointsByScenesOptions];
        },
    },
    methods: {

        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },
    }
}
</script>

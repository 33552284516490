<template>
    <div class="command-settings">
        <Dropdown
            :model="command"
            property="object"
            @select="onSelectSceneObject"
            :options="getSceneObjectTargetDropdownOptionsForCommand"
            :required="true"
            :deselected-caption="trans('authoring.select_sceneobject')"
        />

        <Dropdown
            v-if="shouldShowBehaviourDropdown"
            :key="'behaviour-for-'+command.object"
            :model="command"
            property="behaviour"
            @select="onSelectBehaviour"
            :options="targetOptionsForBehaviours"
            :required="true"
            :deselected-caption="trans('authoring.select_behaviour')"
        />

        <component
            :key="'behaviour-properties-for-'+command.object+'-'+key"
            v-if="behaviour && componentForBehaviour"
            :is="componentForBehaviour"
            :command="command"
            @change="onChangeCommandProperties"
        />
    </div>
</template>

<script>
// Import helpers and functions:
import { trans, shortId } from '@/Utility/Helpers';
import { getSceneObjectTargetDropdownOptionsForCommand }     from '@/Models/UnitData/Commands/CommandHelpers';
import { getBehavioursPropertiesComponentName } from '@/Models/UnitData/Behaviours/BehaviourHelpers';


// Import classes:
import {
    CollidableBehaviour,
    MovableBehaviour,
    PhysicsBehaviour,
    TeleportableBehaviour,
}                               from '@/Models/UnitData/Behaviours/Behaviour';
import Command                  from '@/Models/UnitData/Commands/Command';
import DropdownOption           from '@/Utility/DropdownOption';
import {BehaviourChangeCommand}   from '@/Models/UnitData/Commands/Command';
import SceneObject from "@/Models/UnitData/SceneObjects/SceneObject";
import UnitData from "@/Models/UnitData/UnitData";

export default {
    name: 'PanelCommandBehaviourChange',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: BehaviourChangeCommand,
            default: null
        }
    },

    data() {
        return {
            key: shortId(),
        }
    },

    beforeMount() {
        this.command.cleanUpData();
    },

    computed: {
        /**
         * Should the select behaviour dropdown be visible?
         */
        shouldShowBehaviourDropdown() {
            return (this.command.object !== null && (this.command.behaviour !== null || this.command.targetObject !== null));
        },

        /**
         * Get all valid scene objects with behaviours (current scene and global) as dropdown options.
         */
        getSceneObjectTargetDropdownOptionsForCommand() {
            return getSceneObjectTargetDropdownOptionsForCommand(this.command);
        },

        /**
         * Get the behaviours of the selected scene object as dropdown options
         */
        targetOptionsForBehaviours() {
            return this.behaviours.map(behaviour => new DropdownOption({
                caption: trans('authoring.behaviours.types.' + behaviour.type),
                disabled: false,
                value: behaviour.uid
            }));
        },

        /**
         * Get the name of the component that should be rendered for the selected behaviours properties.
         */
        componentForBehaviour() {
            return getBehavioursPropertiesComponentName(this.behaviour);
        },

        /**
         * The currently selected behaviour
         *
         * @returns {Behaviour|Null}
         */
        behaviour() {
            return this.behaviours.find(b => b.uid === this.command.behaviour) || null;
        },

        /**
         * The available behaviours on the selected scene object
         *
         * @returns {Behaviour[]}
         */
        behaviours() {
            const sceneObject = (this.command.object === Command.TargetSelf) ? this.command.getParent(SceneObject) : this.command.getParent(UnitData)?.findSceneObjectByUid(this.command.object);
            return sceneObject?.behaviours || [];
        },
    },
    methods: {

        /**
         *  Reset properties
         */
        resetProperties() {
            if (this.behaviour === null)
            {
                this.command.properties = new Object(null);
                return this;
            }
            switch (this.behaviour.type)
            {
                case CollidableBehaviour.Type:
                case MovableBehaviour.Type:
                case TeleportableBehaviour.Type:
                    this.command.properties = {
                        enabled: true
                    };
                    break;

                case PhysicsBehaviour.Type:
                default:
                    this.command.properties = new Object(null);
            }
            return this;
        },

        /**
         *  Change handler for the command properties
         *
         * @param {Command} command
         */
        onChangeCommandProperties(command) {
            this.command.properties = command.properties;
            this.$emit('change', this.command);
            this.key = shortId();
            return this;
        },

        /**
         *  Change handler for the command
         */
        onSelectBehaviour(value) {
            // Set the behaviours UID
            this.command.behaviour = value;

            // Reset all the properties that were set on the command because not all behaviours can have the same
            // properties and we only want to save properties that are possible for the behaviour.
            this.resetProperties();

            // Trigger change event for the command
            this.$emit('change', this.command);

            return this;
        },

        /**
         *  Change handler for the command
         */
        onSelectSceneObject(value) {
            // Update the objects uid
            this.command.object = value;

            // Reset the behaviours uid because the object changed
            this.command.behaviour = null;

            // Reset all the properties that were set on the command because not all behaviours can have the same
            // properties and we only want to save properties that are possible for the behaviour.
            this.resetProperties();

            // Trigger change event
            this.$emit('change', this.command);

            return this;
        },
    },
    watch: {
        behaviour: function(newBehaviour, oldBehaviour) {
            if (newBehaviour === null)
            {
                this.resetProperties();
                this.$emit('change', this.command);
            }
        },

        behaviours: function (newBehaviours, oldBehaviours) {
            if (this.behaviour === null || this.command.behaviour !== this.behaviour.uid)
            {
                this.command.behaviour = null;
                this.resetProperties();
                this.$emit('change', this.command);
            }
        },

        targetOptionsForBehaviours: function (oldOptions, newOptions) {
            this.command.cleanUpData();
            this.$emit('change', this.command);
        },
    },
}
</script>

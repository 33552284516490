<template>
    <div class="beta-hint">
        <Icon name="icon_error"/>
        <p v-html="text"/>
    </div>
</template>

<script lang="ts">

    import {defineComponent, PropType} from "vue";
    import Icon from "@/Vue/Common/Icon.vue";
    import {trans} from "@/Utility/Helpers/trans";
    import CommandType from "@/Models/UnitData/Commands/CommandType";

    export default defineComponent({
        components: {
            Icon
        },

        props: {
            commandType: {
                type: Object as PropType<CommandType>,
                required: true,
            },
        },

        computed: {
            text() {
                const commonPart = trans('features.beta.command_text');
                const specialPart = trans(`features.beta.command_text_suffix_${this.commandType.type}`, {}, false, false);

                return specialPart ? `${commonPart}<br/>${specialPart}` : commonPart;
            },
        },

        methods: {
            trans,
        },
    });
</script>

<style lang="scss">
    .beta-hint {
        display: flex;
        flex-direction: row;
        gap: 6px;
        margin-bottom: var(--typo-spacing-default);

        svg.icon {
            flex-basis: 20px;
            width: 20px;
            height: 20px;
            color: var(--color-item-yellow);
        }

        p {
            display: inline-block;
            color: var(--color-item-yellow);
            font-family: var(--font-family-condensed);
            font-size: var(--font-size-small);
            line-height: var(--line-height-small);
        }
    }
</style>

<template>
    <div class="command-settings">

        <!-- Button for adding an asset -->
        <ButtonPrimary v-if="!hasValue" icon="icon_add" class="btn-add-asset" caption="labels.add_asset" @trigger="onClickAddAsset" />

        <!-- Button for creating a new text-to-speech asset -->
        <ButtonCustom v-if="!hasValue" icon="icon_add" class="btn-create-asset btn-white" caption="labels.create_text_to_speech" @trigger="onClickCreateAsset" />

        <!-- The assigned asset -->
        <div v-else class="assigned-asset">
            <div v-if="assignedAsset" class="preview preview-image-pointer" @click="showAssetPreview(assignedAsset)">
                <Icon :name="assignedAsset.assetType.previewIcon" />
                <img :src="assignedAsset.preview" alt="" />
            </div>

            <div class="info">
                <span class="title" :title="getTextForTooltip">
                    {{ (assignedAsset) ? assignedAsset.title : trans('labels.asset_unknown') }}
                </span>
            </div>
            <div class="btn-delete">
                <Icon name="icon_delete" class="icon-delete" @click="onClickRemoveAsset" />
            </div>

            <!-- Delete confirm overlay -->
            <OverlayConfirmRemove v-if="isRemoveConfirmDialogVisible" @confirm="onConfirmAssetRemove" @cancel="onCancelAssetRemove" />
        </div>
    </div>
</template>

<script>
// Import VueJS components:
import OverlayConfirmRemove     from '@/Vue/Inspector/OverlayConfirmRemove.vue';

// Import classes:
import Asset                    from '@/Models/Asset/Asset';
import AssetType                from '@/Models/Asset/AssetType';
import EventType                from '@/Utility/EventType';
import { trans }                from '@/Utility/Helpers';
import {SoundPlayCommand}         from '@/Models/UnitData/Commands/Command';
import UnitRevision from "@/Models/Unit/UnitRevision";

export default {
    name: 'PanelCommandSoundPlay',
    emits: [
        'change',
    ],
    components: {
        OverlayConfirmRemove
    },
    props: {
        command: {                      // The command object to be edited
            type: SoundPlayCommand,
            default: null
        }
    },
    data() {
        return {
            isRemoveConfirmDialogVisible: false,        // Whether the remove confirmation dialog is visible
        }
    },
    computed: {

        /**
         * @returns {CommandTargetType|null}
         */
        commandTargetType() {
            return this.command?.commandType?.targetType || null;
        },

        /**
         * @returns {UnitRevision}
         */
        unitRevision() {
            return this.command?.getParent(UnitRevision) || null;
        },

        /**
         * Get text for tooltip
         *
         * @returns {String|null}
         */
        getTextForTooltip() {
            const text = (this.assignedAsset) ? this.assignedAsset.title : trans('labels.asset_unknown');
            return text.length >= 15 ? text : null;
        },

        /**
         * Does the command have a set value?
         *
         * @returns {Boolean}
         */
        hasValue() {
            return this.command.value !== undefined && this.command.value !== null;
        },

        /**
         * Get data for the assigned asset
         *
         * @returns {Asset|null}
         */
        assignedAsset() {
            if (this.command !== null && this.unitRevision !== null && typeof this.command.value === 'string' && this.unitRevision.assets[this.command.value] instanceof Asset)
            {
                return this.unitRevision.assets[this.command.value];
            }

            return null;
        },
    },
    methods: {
        /**
         * Click handler for add asset button
         */
        onClickAddAsset() {
            this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_FILTERS_SET, this.commandTargetType?.filters);
            this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_SHOW, {
                callback: this.onSelectAsset,
                assetTypes: this.commandTargetType?.assetTypes,
                clearWordFilter: true,
            });
            return this;
        },

        /**
         * Click handler for create asset button
         */
        onClickCreateAsset() {
            this.$globalEvents.emit(EventType.MODAL_CREATE_ASSET_SHOW, {
                callback: this.onCreateAssetForCommand,
                assetTypes: [
                    AssetType.SoundTts
                ],
            });
            return this;
        },

        /**
         * Click handler for remove asset button
         */
        onClickRemoveAsset() {
            this.isRemoveConfirmDialogVisible = true;
            return this;
        },

        /**
         * Confirm handler for asset remove confirmation dialog
         */
        onConfirmAssetRemove() {
            this.isRemoveConfirmDialogVisible = false;
            // Remove the asset:
            this.command.value = null;
            this.$emit('change', this.command);
            return this;
        },

        /**
         * Cancel handler for asset remove confirmation dialog
         */
        onCancelAssetRemove() {
            this.isRemoveConfirmDialogVisible = false;
            return this;
        },

        /**
         * Success handler for creating an asset for the command
         *
         * @param {Asset} asset
         */
        onCreateAssetForCommand(asset) {
            this.onSelectAsset(asset);
            return this;
        },

        /**
         * Select handler for assigning an asset to the command
         *
         * @param {Asset} asset
         */
        onSelectAsset(asset) {
            // Add the selected asset to the unit:
            this.unitRevision.addAssets(asset);
            // Set the command's value:
            this.command.value = asset.uid;
            this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_HIDE);
            this.$emit('change', this.command);
            return this;
        },

        /**
         * Show Asset preview modal for asset
         *
         * @param {Asset} asset
         */
        showAssetPreview(asset) {
            if (asset.supportsPreview)
            {
                this.$globalEvents.emit(EventType.MODAL_ASSET_PREVIEW_SHOW, asset);
            }
            return this;
        },
    }
}
</script>

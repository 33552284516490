import ServiceIsBusyError from '@/Errors/ServiceIsBusyError';
import AxiosRequest from '@/Services/AxiosRequest';
import {route, trans} from '@/Utility/Helpers';
import LocalFeature from "@/Models/Features/Local/LocalFeature";

export default class LocalFeatureService {

    public isLoading: Boolean = false;
    public isSaving: Boolean = false;
    public features: LocalFeature[] = [];
    private request: AxiosRequest | null = null;

    async cancelRequests(): Promise<any> {
        // @NOTE: Only working with a single request at the moment!
        if (this.request !== null) {
            return await this.request.cancel();
        }
        return Promise.resolve('Requests canceled');
    }

    /**
     * Fetch all local features from API.
     */
    async fetchLocalFeatures(): Promise<LocalFeature[]> {
        if (this.isLoading === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isLoading = true;

        this.request = new AxiosRequest();
        return await this.request.get(
            route('api.features.local.index')
        ).then(({data}: any) => {
            this.features = [];
            Object.values(data.data).forEach((featureAttributes: any): void => {
                try {
                    this.features.push(new LocalFeature(featureAttributes));
                } catch (ex) {
                    console.warn('LocalFeatureService->fetchLocalFeatures(): Skipping local feature with invalid or incompatible data.', featureAttributes, ex);
                }
            });
            return Promise.resolve(this.features);
        }).finally((): void => {
            this.isLoading = false;
            this.request = null;
        });
    }

    /**
     * Deletes the local feature with the given uid.
     */
    async deleteLocalFeature(uid: string): Promise<void> {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.delete(
            route('api.features.local.delete', {feature: uid})
        ).then(() => {
            this.features = this.features.filter(feature => feature.uid !== uid);
            return Promise.resolve();
        }).finally((): void => {
            this.isSaving = false;
            this.request = null;
        });
    }

    /**
     * Creates a new local feature.
     */
    async createLocalFeature(name: string, tenantUid: string|null): Promise<LocalFeature> {
        if (this.isSaving === true || this.request !== null && this.request.isBusy === true) {
            throw new ServiceIsBusyError('Fetching is still in progress.');
        }

        this.isSaving = true;

        this.request = new AxiosRequest();
        return await this.request.post(
            route('api.features.local.create'),
            {
                name: name,
                tenant_uid: tenantUid,
            }
        ).then(({data}: any): Promise<LocalFeature> => {
            try {
                const newFeature:LocalFeature = new LocalFeature(data.data);
                this.features.push(newFeature);
                return Promise.resolve(newFeature);
            } catch(ex) {
                console.error('LocalFeatureService->createLocalFeature(): API returned invalid or incompatible local feature data.', data, ex);
                return Promise.reject(trans('errors.local_feature.invalid_data'));
            }
        }).finally((): void => {
            this.isSaving = false;
            this.request = null;
        });
    }
}

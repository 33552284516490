<template>
    <Modal
        id="dialog-create-unit-from-history"
        ref="modal"
        :remove-main-slot-content-when-invisible="true"
        :show-close-button="false"
        :title="trans('modals.create_unit_from_history.title')"
        :user-closable="true"
        event-type="MODAL_UNIT_CREATE_FROM_HISTORY"
        @hide="onHide"
        @show="onShow"
    >
        <p class="description"
           v-html="trans('modals.create_unit_from_history.description', {versiondate: dateFormatted})"></p>
        <TextInput
            ref="titleInput"
            :error-msg="trans('errors.unit.title')"
            :initial-value="newTitle"
            :maxlength="50"
            :placeholder="trans('units.create.unit_title_placeholder')"
            :required="true"
            @change="onChangeTitle"
        />
        <Checkbox
            :caption="trans('modals.create_unit_from_history.keep_authors')"
            :initialValue="keepAssignedAuthors"
            layout="checkbox-right"
            @change="onChangeKeepAssignedAuthors"
        />
        <template #buttons>
            <ButtonSecondary
                caption="modals.create_unit_from_history.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                :disabled="!isValid"
                caption="modals.create_unit_from_history.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import moment from 'moment';
import {trans} from '@/Utility/Helpers';
import EventType from '@/Utility/EventType';
import Modal from '@/Vue/Modals/Modal.vue';
import UnitRevision from '@/Models/Unit/UnitRevision';
import {defineComponent} from 'vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import Checkbox from '@/Vue/Common/Checkbox.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';

type ModalData = {
    unitRevision: UnitRevision | null,
    newTitle: string,
    keepAssignedAuthors: boolean,
};

export default defineComponent({
    components: {
        ButtonPrimary,
        ButtonSecondary,
        Checkbox,
        TextInput,
        Modal
    },
    data(): ModalData {
        return {
            /**
             * Reference to the UnitRevision for which this dialog is being shown
             */
            unitRevision: null as UnitRevision | null,

            /**
             * Title of the new unit
             */
            newTitle: '',

            /**
             * Checkbox state for whether the assigned authors should be duplicated as well
             */
            keepAssignedAuthors: false,
        }
    },
    computed: {

        dateFormatted(): string {
            if (this.unitRevision) {
                const date = moment(this.unitRevision.updated_at);
                return date.format('LL – HH:mm');
            }
            return '';
        },

        isValid(): boolean {
            return this.newTitle.length > 0 && this.newTitle.length <= 50;
        },

        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    },
    methods: {
        trans,

        onShow(unitRevision: UnitRevision) {
            this.unitRevision = unitRevision;
            this.newTitle = this.unitRevision.title + ' ' + trans('modals.create_unit_from_history.copy_suffix');
            this.keepAssignedAuthors = false;
            this.$nextTick(() => (this.$refs.titleInput as typeof TextInput).focus());
        },

        onHide() {
            this.unitRevision = null;
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            if (!this.isValid) {
                return;
            }

            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_UNIT_CREATE_FROM_HISTORY_APPLY, {
                unitRevision: this.unitRevision,
                title: this.newTitle,
                keepAssignedAuthors: this.keepAssignedAuthors
            });
        },

        onChangeTitle(newTitle: string) {
            this.newTitle = newTitle;
        },

        onChangeKeepAssignedAuthors(checked: boolean) {
            this.keepAssignedAuthors = checked;
        }
    }
});
</script>

<style lang="scss" scoped>

#dialog-create-unit-from-history {

    :deep(.description em) {
        white-space: nowrap;
    }
}
</style>

<template>
    <div class="command-settings">
        <Dropdown
            :model="command"
            property="value"
            @select="onChangeCommand"
            :required="true"
            :options="dropdownOptionsForControllerInputStyleValues"
            :deselected-caption="trans('labels.select_type')"
        />
    </div>
</template>

<script>
// Import helpers and functions:
import { sortArrayByProperty } from '@/Utility/Helpers';

// Import classes:
import DropdownOption           from '@/Utility/DropdownOption';
import {InputStyleCommand}        from '@/Models/UnitData/Commands/Command';
import CommandType              from '@/Models/UnitData/Commands/CommandType';

export default {
    name: 'PanelCommandInputStyle',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: InputStyleCommand,
            default: null
        }
    },
    computed: {
        /**
         * Get options for Controller Style value dropdown
         *
         * @returns {DropdownOption[]}
         */
        dropdownOptionsForControllerInputStyleValues() {
            const options = [];

            const commandType = CommandType.InputStyle;
            for (const key in commandType.possibleValues)
            {
                if (commandType.possibleValues.hasOwnProperty(key))
                {
                    options[options.length] = new DropdownOption({
                        caption: commandType.possibleValues[key],
                        disabled: false,
                        value: key
                    });
                }
            }

            return sortArrayByProperty(options, 'caption', false);
        },
    },
    methods: {
        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },
    }
}
</script>

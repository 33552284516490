<template>
    <div class="command-settings">
        <!-- Select scene object -->
        <Dropdown
            :initial-value="command.target"
            @select="onSelectTargetSceneObject"
            :options="getSceneObjectTargetDropdownOptionsForCommand"
            :required="true"
            :deselected-caption="trans('authoring.select_sceneobject')"
        />

        <!-- 3D-Layer Node Paths -->
        <Collapsible v-if="usesSceneObjectModel3d" :initial-collapsed="layersShouldInitiallyBeCollapsed">
            <template #header>
                {{ trans('labels.layers') }}
            </template>
            <template #body>
                <TextInput
                    :key="'nodepaths'+refresh"
                    class="command-node-paths"
                    type="textarea"
                    :initial-value="nodePaths"
                    @change="onChangeNodePaths"
                    :maxlength="10000"
                    :placeholder="br2nl(trans('authoring.command_nodepaths_placeholder'))"
                    :error-msg="trans('errors.commands.node_paths')"
                />
            </template>
        </Collapsible>
    </div>
</template>

<script>
// Import helpers and functions:
import { getSceneObjectTargetDropdownOptionsForCommand, usesSceneObjectModel3d } from '@/Models/UnitData/Commands/CommandHelpers';
import { shortId } from '@/Utility/Helpers';

// Import classes:
import {ShowCommand, HideCommand} from '@/Models/UnitData/Commands/Command';
import UnitData from "@/Models/UnitData/UnitData";

export default {
    name: 'PanelCommandShowHide',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: [ShowCommand, HideCommand],
            default: null
        }
    },
    data() {
        return {
            refresh: '',                                // Helper for refreshing the UI
        }
    },
    computed: {
        /**
         * Get asset layer node paths
         *
         * @returns {String|null}
         */
        nodePaths() {
            return (this.command !== null && this.command.node_paths instanceof Array) ? this.command.node_paths.join('\n') : null;
        },

        /**
         * Get options for target scene object selection dropdown
         *
         * @returns {DropdownOption[]}
         */
        getSceneObjectTargetDropdownOptionsForCommand() {
            return getSceneObjectTargetDropdownOptionsForCommand(this.command);
        },

        /**
         * Uses a 3D model as target scene object?
         *
         * @returns {Boolean}
         */
        usesSceneObjectModel3d() {
            return usesSceneObjectModel3d(this.command, this.command.getParent(UnitData));
        },

        /**
         * @returns {Boolean}
         */
        layersShouldInitiallyBeCollapsed() {
            // Collapse dropdown if node_paths are empty.
            return (this.command.node_paths.length <= 0);
        },
    },
    methods: {
        /**
         * Select handler for target scene object dropdown
         *
         * @param {String} targetUID
         */
        onSelectTargetSceneObject(targetUID) {
            if (targetUID !== this.command.target)
            {
                // Reset node paths if the target is not an asset:
                if (this.usesSceneObjectModel3d === false && this.command.node_paths instanceof Array)
                {
                    this.command.node_paths = [];
                }
                this.command.target = targetUID;
                this.$emit('change', this.command);
            }
            return this;
        },

        /**
         * Change handler for node paths
         *
         * @param {String} text
         * @param {Event|KeyboardEvent|FocusEvent} e
         */
        onChangeNodePaths(text, e) {

            // Only update the actual value when the user leaves the field:
            if (['input', 'keyup'].includes(e.type))
            {
                return this;
            }

            // Only use line breaks:
            text = text.replace(/(\s*[,;]\s*)/g, '\n');

            // Only use forward slashes:
            text = text.replace(/[\\]/g, '/');

            // Remove multiple slashes:
            text = text.replace(/\/+/g, '/');

            this.command.node_paths = text.split('\n').map(p => {

                // Remove illegal characters:
                // @TODO: Remove illegal characters?

                // Remove multiple spaces:
                p = p.replace(/\s+/g, ' ');

                // Remove trailing spaces and slashes:
                p = p.replace(/^[\s\/]+/, '');
                p = p.replace(/[\s\/]+$/, '');

                return p;
            }).filter(p => p !== null && p !== '');
            // Force render update:
            this.refresh = shortId();
            this.$emit('change', this.command);
            return this;
        }
    }
}
</script>

<template>
    <div class="panel panel-rotation">
        <header>{{ labelText }}</header>

        <div
            v-for="axis in ['x', 'y', 'z']"
            class="property rotation-inputs number-input-wrapper"
        >
            <label>{{ trans(`authoring.rotation_${axis}`) }}</label>
            <NumberInput
                :key="axis"
                :maxValue="180"
                :minValue="-180"
                :model="euler"
                :property="axis"
                :step="0.1"
                :precision="precision"
                @change="onChangeAxis"
            />
        </div>
    </div>
</template>

<script>
    import {trans} from '@/Utility/Helpers';
    import Rotation from "@/Models/Unity/Rotation";
    import NumberInput from "@/Vue/Common/NumberInput.vue";
    import {Vector3} from "three";
    import {round} from "lodash";

    export default {
        name: 'PanelRotation',

        emits: [
            'change',
        ],

        components: {
            NumberInput
        },

        props: {
            rotation: {
                type: Rotation,
                default: () => new Rotation(),
            },
            labelText: {
                type: String,
                default: trans('authoring.rotation'),
            },
            precision: {
                type: Number,
                default: 4,
            },
        },

        data() {
            return {
                euler: this.getRoundedUnityVector(),
            }
        },

        methods: {

            onChangeAxis() {
                this.rotation.unityVector3 = this.euler;
                this.$emit('change', this.rotation);
                return this;
            },

            getRoundedUnityVector() {
                const unityVector = this.rotation.unityVector3;
                return new Vector3(
                    round(unityVector.x, this.precision),
                    round(unityVector.y, this.precision),
                    round(unityVector.z, this.precision),
                );
            }
        }
    }
</script>

<style lang="scss" scoped>

    .panel-rotation header {
        font-family: var(--font-family-condensed-demibold);
        margin-bottom: 10px;
    }

    .panel-rotation .rotation-inputs {
        display: flex;
        gap: 12px;
    }

    .rotation-inputs + .rotation-inputs {
        margin-top: 10px;
    }

    .number-input-container {
        flex-basis: 200px;
    }
</style>

<template>
    <div class="panel panel-scale">

        <div class="property scale-inputs number-input-wrapper">
            <label>{{ labelText }}</label>

            <NumberInput
                :maxValue="100"
                :minValue="0.001"
                :model="scale"
                property="x"
                :step="0.001"
                :precision="8"
                @change="onChangeScale"
            />

        </div>

    </div>
</template>

<script>
    import Scale from '@/Models/Unity/Scale';
    import { trans } from '@/Utility/Helpers';

    export default {
        name: 'PanelScale',
        emits: [
            'change',
        ],
        props: {
            scale: {
                type: Scale,
                default: () => new Scale(),
            },
            labelText: {
                type: String,
                default: trans('authoring.scale'),
            },
        },
        methods: {

            onChangeScale(value) {
                this.scale.x = value;
                this.scale.y = value;
                this.scale.z = value;

                this.$emit('change', this.scale);
                return this;
            },

        }
    }
</script>

<style lang="scss" scoped>
    .panel-scale .scale-inputs {
        display: flex;
    }

    .number-input-container {
        flex-basis: 200px;
    }
</style>

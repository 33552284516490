<template>
    <div class="command-settings">
        <ColorHexInput
            :model="command"
            property="value"
            :required="true"
            :label="trans('authoring.glow_color')"
            @change="onChangeCommand"
        />
    </div>
</template>

<script>
// Import helpers and functions:
//

// Import classes:
import {HelperGlowChangeCommand} from '@/Models/UnitData/Commands/Command';

export default {
    name: 'PanelCommandHelperGlowChange',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: HelperGlowChangeCommand,
            default: null
        }
    },
    methods: {
        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },
    }
}
</script>

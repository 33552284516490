<template>
    <Collapsible :disabled="!isCollapsible" class="inspector-panel panel-triggers" v-shortcuts.prevent>

        <!-- @NOTE: Only collapsible on Helper modules for now -->
        <template #header>
            {{ (showAsReactions) ? trans('labels.reactions') : trans('labels.triggers') }}
        </template>

        <template #body>
            <!-- @NOTE: Using all triggers instead of triggersToDisplay for the loop because of the index needed for drag'n'drop -->
            <template v-for="(triggerObject, triggerIndex) in triggers">
                <PanelTrigger
                    v-if="showAsReactions ? isCueTrigger(triggerObject) : (!isHelperModule || !isCueTrigger(triggerObject))"
                    :key="'trigger_'+triggerObject.uid"
                    :trigger="triggerObject"
                    :isHelperModule="isHelperModule"
                    :showAsReactions="showAsReactions"
                    :showConfirmRemovalDialog="(isRemoveConfirmDialogVisible === triggerObject.uid)"
                    @showConfirmRemovalDialogChanged="onCancelTriggerRemove"
                    @change="onChangeTrigger"
                    @remove="onConfirmTriggerRemove"
                    :ref="'trigger_'+triggerObject.uid"
                    :id="'trigger_'+triggerObject.uid"
                    :data-trigger-uid="triggerObject.uid"
                    :drag-value="triggerIndex"
                    :drag-groups="triggersToDisplay.length < 2 ? null : (showAsReactions ? 'panel-reactions' : 'panel-triggers')"
                    @drop="onDropTrigger"
                />
            </template>

            <!-- Only show the button for adding a new trigger -->
            <div v-if="canHaveTriggers" class="panel-triggers no-triggers">

                <!-- Add Reaction -->
                <ButtonPrimary v-if="showAsReactions" icon="icon_add" class="btn-add-trigger" caption="labels.add_reaction" @trigger="onClickAddReaction" />

                <!-- Add Trigger -->
                <ButtonPrimary v-else icon="icon_add" class="btn-add-trigger" caption="labels.add_trigger" @trigger="onClickAddTrigger" />
            </div>
        </template>
    </Collapsible>
</template>

<script>
    // Import VueJS components:
    import OverlayConfirmRemove     from '@/Vue/Inspector/OverlayConfirmRemove.vue';

    // Import classes:
    import EventType                from '@/Utility/EventType';
    import Clipboard                from '@/Utility/Clipboard';
    import Command                  from '@/Models/UnitData/Commands/Command';
    import SceneObject              from '@/Models/UnitData/SceneObjects/SceneObject';
    import SceneObjectType          from '@/Models/UnitData/SceneObjects/SceneObjectType';
    import Trigger                  from '@/Models/UnitData/Triggers/Trigger';
    import TriggerType              from '@/Models/UnitData/Triggers/TriggerType';
    import PanelTrigger             from '@/Vue/Inspector/Triggers/PanelTrigger';
    import UnitRevision from "@/Models/Unit/UnitRevision";
    import {inject} from "vue";
    import {assetServiceKey} from "@/Vue/Bootstrap/InjectionKeys";

    export default {
        name: 'PanelTriggers',
        emits: [
            'change',
        ],
        components: {
            PanelTrigger,
            OverlayConfirmRemove,
        },
        props: {
            sceneObject: {                  // Selected SceneObject that the triggers are assigned to
                type: SceneObject,
                default: null
            },
            showAsReactions: {              // Whether to show triggers (OnCue) as "reactions"
                type: Boolean,
                default: false
            },
            isCollapsible: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                assetService: inject(assetServiceKey),              // Global AssetService instance
                isRemoveConfirmDialogVisible: null,                 // Whether a remove confirmation dialog is visible for a specific object (UID)
                shortcuts: new Map([
                    ['Copy.stop', this.onShortcutCopy],             // Copy the selected trigger/command to the clipboard
                    ['Duplicate.stop', this.onShortcutDuplicate],   // Duplicate the selected trigger/command
                    // #PRDA-8481 Disabled until we decide how to handle references between objects when cutting
                    //['Cut.stop', this.onShortcutCut],               // Cut the selected trigger/command (e.g. delete and duplicate to the clipboard)
                    ['Paste.global', this.onShortcutPaste],         // Paste trigger/command from clipboard
                    ['Delete.stop', this.onShortcutDelete],         // Delete trigger/command
                ]),
            }
        },
        computed: {

            /**
             * @returns {Trigger[]}
             */
            triggers() {
                return (this.sceneObject !== null) ? this.sceneObject.triggers || [] : [];
            },

            /**
             * Get allowed trigger types for the selected scene object
             *
             * @returns {TriggerType[]}
             */
            allowedTriggerTypesForSceneObject() {
                return (this.sceneObject !== null) ? this.sceneObject.supportedTriggerTypes : [];
            },

            /**
             * Whether the scene object can have triggers
             *
             * @returns {Boolean}
             */
            canHaveTriggers() {
                let triggerTypes = this.allowedTriggerTypesForSceneObject;
                if (triggerTypes.length === 0) {return false;}
                const triggers = this.sceneObject.getTriggersSortedByType();
                triggerTypes = triggerTypes.filter(t => {
                    // Exclude cue triggers for Helper modules since they are only being added as "reactions":
                    if (this.showAsReactions === false && t.type === TriggerType.OnCue.type && this.isHelperModule === true) {
                        return false;
                    }
                    return !(
                        t.defaultEnabled === false
                        || (
                            t.maxCountPerSceneObject !== null
                            && t.maxCountPerSceneObject >= 0
                            && triggers[t.type] instanceof Array
                            && triggers[t.type].length >= t.maxCountPerSceneObject
                        )
                    );
                });

                return (triggerTypes.length >= 1 && this.triggers.length < Trigger.MaximumCountPerSceneObject);
            },

            /**
             * Whether the selected scene object is a Helper module
             *
             * @returns {Boolean}
             */
            isHelperModule()
            {
                return (this.sceneObject !== null && this.sceneObject.typeOf(SceneObjectType.Modules.Helper));
            },

            /**
             * List of triggers to display
             *
             * @returns {Array}
             */
            triggersToDisplay() {
                if (this.sceneObject !== null)
                {
                    // Only use cue triggers if the panel should be shown as "reactions":
                    if (this.showAsReactions === true) {
                        return this.triggers.filter(t => t.typeOf(TriggerType.OnCue) === true);
                    }
                    // Exclude cue triggers for Helper Modules since they are already being shown as "reactions" on a separate panel:
                    else if (this.sceneObject.typeOf(SceneObjectType.Modules.Helper)) {
                        return this.triggers.filter(t => t.typeOf(TriggerType.OnCue) === false);
                    }
                }
                return this.triggers;
            }

        },
        mounted() {
            this.$globalEvents.addEvent('click.global.panel-triggers', this.onClickGlobal);
        },
        beforeUnmount() {
            this.$globalEvents.removeEvent('click.global.panel-triggers', this.onClickGlobal);
        },
        methods: {

            /**
             * Check if a trigger is of the OnCue type
             *
             * @param {Trigger} trigger
             * @returns {Boolean}
             */
            isCueTrigger(trigger) {
                return trigger.typeOf(TriggerType.OnCue) === true;
            },

            /**
             * Click handler for reaction add button
             */
            onClickAddReaction() {
                // Check the limit per scene object:
                const type = TriggerType.OnCue;
                if (
                    type.maxCountPerSceneObject !== null
                    && type.maxCountPerSceneObject >= 0
                    && this.triggers.filter(t => t.type === type.type).length >= type.maxCountPerSceneObject
                ) {
                    return this;
                }
                // Add a new trigger:
                this.triggers.push(Trigger.createWithType(type, null, this.sceneObject));
                this.$emit('change', this.triggers);
                return this;
            },

            /**
             * Click handler for trigger add button
             */
            onClickAddTrigger() {
                let triggerTypes = (this.sceneObject !== null) ? this.sceneObject.supportedTriggerTypes : [];
                const triggers = this.sceneObject.getTriggersSortedByType();

                // Don't show cue triggers for helper modules:
                if (this.isHelperModule && !this.showAsReactions) {
                    triggerTypes = triggerTypes.filter(t => t.type !== TriggerType.OnCue.type);
                }

                // Disable any triggers in the side panel if the maximum count is reached on the object:
                triggerTypes.forEach(t => {
                    t.enabled = !(
                        !t.defaultEnabled
                        || (
                            t.maxCountPerSceneObject !== null
                            && t.maxCountPerSceneObject >= 0
                            && triggers[t.type] instanceof Array
                            && triggers[t.type].length >= t.maxCountPerSceneObject
                        )
                    );
                });

                this.$globalEvents.emit(EventType.SIDEPANEL_TRIGGERS_SET, triggerTypes);
                this.$globalEvents.emit(EventType.SIDEPANEL_TRIGGERS_SHOW, null);
            },

            /**
             * Change handler for trigger properties
             *
             * @param {Trigger} trigger
             */
            onChangeTrigger(trigger) {
                // Update the trigger that was modified:
                const triggerIndex = this.triggers.findIndex(t => t.uid === trigger.uid);
                if (triggerIndex >= 0) {
                    this.triggers[triggerIndex] = trigger;
                    this.$emit('change', this.triggers);
                }
                return this;
            },

            /**
             * Click handler for trigger remove button
             *
             * @param {Trigger} trigger
             */
            onClickRemoveTrigger(trigger) {
                this.isRemoveConfirmDialogVisible = trigger.uid;
                return this;
            },

            /**
             * Confirm handler for trigger remove confirmation dialog
             *
             * @param {Trigger} trigger
             */
            onConfirmTriggerRemove(trigger) {
                this.isRemoveConfirmDialogVisible = null;
                const triggerIndex = this.triggers.findIndex(t => t.uid === trigger.uid);
                this.sceneObject.removeTrigger(trigger);

                // Set focus:
                if (this.triggers.length >= 1) {
                    // Focus on the collapsible trigger header of the previous (or next) trigger:
                    this.setFocus(this.triggers[Math.max(0, triggerIndex - 1)]);
                }
                this.$emit('change', this.triggers);
                return this;
            },

            /**
             * Cancel handler for trigger remove confirmation dialog
             *
             * @param {Trigger} trigger
             */
            onCancelTriggerRemove(trigger) {
                this.isRemoveConfirmDialogVisible = null;
                return this;
            },

            /**
             * Click handler for global events
             *
             * @param {MouseEvent} e
             */
            onClickGlobal(e) {
                // Check if the click was outside of a confirmation dialog:
                if (this.$globalEvents.isEventTargetDescendantOfSelector(e, '.panel-triggers .confirm-remove .confirm-dialog, .panel-triggers .icon-delete') === false) {
                    this.isRemoveConfirmDialogVisible = null;
                }
                return this;
            },

            /**
             * Drop handler for triggers
             *
             * @param {MouseEvent} e
             */
            onDropTrigger(e) {
                e.preventDefault();
                if (!(e.dataDraggable instanceof Object) || !(e.dataDropTarget instanceof Object)) {
                    console.warn('PanelTriggers->onDropTrigger(): Invalid dragging data.', e);
                    return this;
                }

                const currentIndex = e.dataDraggable?.value || 0;
                const targetIndex = e.dataDropTarget?.value || 0;

                if (currentIndex !== targetIndex) {
                    [].splice.apply(this.triggers, [targetIndex, 0].concat(this.triggers.splice(currentIndex, 1)));
                    this.$emit('change', this.triggers);
                }
                return this;
            },

            /**
             * Shortcut handler: Copy
             *
             * @param {CustomEvent} e
             */
            onShortcutCopy(e) {
                const { command, trigger } = this.getDataByEvent(e);

                // Do nothing if no trigger is selected or a command is selected:
                if (trigger === null || command !== null) {return this;}

                // Copy a new instance of the selected trigger to the clipboard:
                Clipboard.setClipboardDataAsync(trigger);
                return this;
            },

            /**
             * Shortcut handler: Duplicate
             *
             * @param {CustomEvent} e
             */
            onShortcutDuplicate(e) {
                const { command, trigger } = this.getDataByEvent(e);

                // Do nothing if no trigger is selected or a command is selected or the maximum count is reached already:
                if (trigger === null || command !== null || trigger.hasReachedMaxCount) {return this;}

                // Insert a duplicated instance of the trigger:
                const insertIndex = (this.triggers.findIndex(t => t.uid === trigger.uid) + 1) || this.triggers.length;
                const duplicatedTrigger = trigger.duplicate(true);
                this.triggers.splice(insertIndex, 0, duplicatedTrigger);
                this.sceneObject.cleanUpData();
                this.setFocus(duplicatedTrigger);
                this.$emit('change', this.triggers);
                return this;
            },

            /**
             * Shortcut handler: Cut
             *
             * @param {CustomEvent} e
             */
            onShortcutCut(e) {
                const { command, trigger } = this.getDataByEvent(e);

                // Do nothing if no trigger is selected or a command is selected:
                if (trigger === null || command !== null) {return this;}

                // Copy a new instance of the selected trigger to the clipboard:
                Clipboard.setClipboardDataAsync(trigger);

                // Delete the selected trigger:
                this.onConfirmTriggerRemove(trigger);
                return this;
            },

            /**
             * Shortcut handler: Paste
             *
             * @param {CustomEvent} e
             */
            onShortcutPaste(e) {
                // Do nothing if the clipboard is empty:
                const clipboardData = Clipboard.getDataFromClipboardEvent(e.detail.clipboardEvent);
                if (clipboardData === null || clipboardData.isEmpty || !clipboardData.isModel)
                {
                    return this;
                }

                // Get data from the clipboard (and use new instances so we're not modifying the clipboard data):
                const commandsToPaste = (clipboardData.isInstanceOf(Command)) ? [Command.fromClipboardData(clipboardData)].filter(c => c !== null) : [];
                const triggerToPaste = (clipboardData.isInstanceOf(Trigger)) ? Trigger.fromClipboardData(clipboardData) : null;

                // Get source command and trigger from the shortcut event:
                const { command, trigger } = this.getDataByEvent(e);

                // Prevent pasting commands into non-existent trigger or a trigger into itself:
                if ((commandsToPaste.length === 0 && triggerToPaste === null)       // No valid command or trigger in the clipboard
                    || (commandsToPaste.length > 0 && trigger === null))            // OR commands in clipboard but no trigger selected
                {
                    return this;
                }

                // Stop bubbling to parent components:
                e.stopShortcutPropagation();

                // Add referenced assets to the unit (if they are really being used is determined by the backend when saving):
                if (commandsToPaste.length > 0 || triggerToPaste !== null)
                {
                    // @TODO: Consider asset policies once we have restrictions for them #PRDA-8075
                    this.sceneObject.getParent(UnitRevision)?.addAssets(this.assetService.getReferencedAssetsFromObject([commandsToPaste, triggerToPaste]));
                }

                // Paste commands into the selected trigger:
                if (commandsToPaste.length > 0 && trigger !== null)
                {
                    return this.pasteCommands(commandsToPaste, trigger, command, e);
                }
                // Paste a single trigger (if it's allowed on the scene object):
                else if (triggerToPaste !== null)
                {
                    return this.pasteTrigger(triggerToPaste, trigger, e);
                }
                return this;
            },

            /**
             * Shortcut handler: Delete
             *
             * @param {CustomEvent} e
             */
            onShortcutDelete(e) {
                const { command, trigger } = this.getDataByEvent(e);

                // Do nothing if no trigger is selected or a command is selected:
                if (trigger === null || command !== null) {return this;}

                // Delete the selected trigger:
                return this.onClickRemoveTrigger(trigger);
            },

            /**
             * Paste commands into the selected trigger (optionally after a selected command)
             *
             * @param {Command[]} commandsToPaste
             * @param {Trigger} targetTrigger
             * @param {Command} targetCommand
             * @param {CustomEvent} e
             */
            pasteCommands(commandsToPaste, targetTrigger, targetCommand, e) {
                const mergedCommands = targetTrigger.mergeCommands(commandsToPaste, targetCommand);
                if (mergedCommands.length === 0)
                {
                    return this;
                }

                // Expand the trigger if it is collapsed:
                this.expandTrigger(targetTrigger, e);

                // Set focus on the last of the newly inserted commands:
                this.setFocus(mergedCommands[mergedCommands.length - 1]);

                this.$emit('change', this.triggers);
                return this;
            },

            /**
             * Paste trigger into the selected trigger or scene object
             *
             * @param {Trigger} triggerToPaste
             * @param {Trigger} targetTrigger
             * @param {CustomEvent} e
             */
            pasteTrigger(triggerToPaste, targetTrigger, e) {
                // Get UIDs from existing triggers before the merge:
                const existingTriggerUids = this.triggers.map(t => t.uid);

                // Merge the trigger:
                const mergedTrigger = this.sceneObject.mergeTrigger(triggerToPaste, targetTrigger);
                if (mergedTrigger === null)
                {
                    // Merge failed:
                    // @TODO: Show notification to user?
                    return this;
                }

                // Set focus on the newly pasted trigger:
                if (!existingTriggerUids.includes(mergedTrigger.uid) || !mergedTrigger.hasCommands)
                {
                    this.setFocus(mergedTrigger);
                }
                else
                {
                    // Expand the trigger if it is collapsed:
                    this.expandTrigger(mergedTrigger, e);

                    // Set focus on the last of the newly inserted commands:
                    this.setFocus(mergedTrigger.commands.slice(-1)[0]);
                }
                this.$emit('change', this.triggers);
                return this;
            },

            /**
             * Get models by event
             *
             * @param {CustomEvent} e
             * @returns {Object}
             */
            getDataByEvent(e) {
                // @NOTE: Using parentNode for collapsible elements since the data will be on the parent of the slot!
                const commandUid = e.target.dataset.commandUid || e.target.parentNode.dataset.commandUid || null;
                const triggerUid = e.target.dataset.triggerUid || e.target.parentNode.dataset.triggerUid || null;
                const result = {
                    trigger: null,
                    command: null
                };

                if (triggerUid !== null) {
                    result.trigger = this.triggers.find(t => t.uid === triggerUid) || null;
                    if (result.trigger !== null && commandUid !== null) {
                        result.command = result.trigger.commands.find(c => c.uid === commandUid) || null;
                    }
                }
                return result;
            },

            /**
             * Set focus on a given object
             *
             * @param {Object} object
             */
            setFocus(object) {
                if (object instanceof Command) {
                    this.$nextTick(() => {
                        document.getElementById('command'+object.uid).focus();
                    });
                } else if (object instanceof Trigger) {
                    this.$nextTick(() => {
                        document.getElementById('trigger_'+object.uid).focus();
                    });
                }
                return this;
            },

            /**
             * Expand a trigger
             *
             * @param {Trigger} trigger
             * @param e
             */
            expandTrigger(trigger, e) {
                if (
                    this.$refs['trigger_'+trigger.uid]
                    && this.$refs['trigger_'+trigger.uid][0]
                    && this.$refs['trigger_'+trigger.uid][0].$refs.collapsible.collapsed
                ) {
                    this.$refs['trigger_'+trigger.uid][0].$refs.collapsible.expand(e);
                }
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>

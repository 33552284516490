<template>
    <header>
        <span class="logo">
            <svg><use xlink:href="#logo_3spin_white"></use></svg>
        </span>
        <ButtonPrimary
            caption="lms_xapi.launch_page.header.help"
            :href="helpUrl"
            target="_blank"
        />
    </header>
</template>

<script>
    import ButtonPrimary from "@/Vue/Common/ButtonPrimary";

    export default {
        name: "PageLmsXApiLaunchHeader",
        components: {ButtonPrimary},
        props: {
            helpUrl: {
                type: String,
            },
        },
    }
</script>

<style scoped lang="scss">

    header {
        display: flex;
        justify-content: space-between;
        padding: 24px 32px;
        background-color: var(--color-primary);

        .logo {
            display: block;

            &, svg {
                height: 40px;
                width: 80px;
            }
        }
    }

</style>

<template>

    <nav id="nav-user">
        <ul>

            <!-- guest (when logged out) -->
            <li v-if="isGuest"
                class="nav-item">
                <a :href="route('login')"
                   :title="trans('tooltips.navigation.login')"
                   class="nav-link btn btn-login">
                    {{ trans('labels.login') }}
                </a>
            </li>

            <!-- logged in user -->
            <li v-else
                class="nav-item user-menu">

                <!-- User -->
                <div id="navbarDropdown"
                     :title="trans('tooltips.navigation.user')"
                     aria-expanded="false"
                     aria-haspopup="true"
                     class="nav-link dropdown-toggle"
                     data-bs-toggle="dropdown"
                     role="button"
                     tabindex="0">

                    <!-- Profile image -->
                    <div class="user-image-crop">
                        <img :src="this.user.image" alt="" class="user-image"/>
                    </div>

                    <!-- User name -->
                    <span class="username">{{ this.userName }}</span>

                    <!-- User role -->
                    <div class="user-rank">
                        <span :title="this.tenantNameTooltip">{{ this.tenantName }}</span>
                    </div>

                </div>

                <!-- extended menu items -->
                <div aria-labelledby="navbarDropdown"
                     class="dropdown-menu dropdown-menu-right">

                    <!-- Edit Profile -->
                    <a v-if="canUpdateOwnUser"
                       :href="route('users.updateForm', {'user': this.user.uid})"
                       :title="trans('tooltips.navigation.edit_profile')"
                       class="dropdown-item">
                        {{ trans('labels.edit_profile') }}
                    </a>

                    <!-- Logout -->
                    <a :href="route('logout')"
                       :title="trans('tooltips.navigation.logout')"
                       class="dropdown-item">
                        {{ trans('labels.logout') }}
                    </a>

                </div>

            </li>

        </ul>
    </nav>

</template>

<script>

    // Import classes:
    import {permission, trans} from '@/Utility/Helpers';
    import {Permission} from '@/Models/User/Permission';

    export default {
        name: 'UserNavigationItem',

        computed: {
            isGuest() {
                return !window.currentUser;
            },

            user() {
                return window.currentUser;
            },

            canUpdateOwnUser() {
                return permission(Permission.UsersUpdateOwn());
            },

            userName() {
                return this.user.firstname[0] + '. ' + this.user.lastname;
            },

            tenantName() {
                return this.user.tenant.name;
            },

            tenantRole() {
                return trans('tenant_roles.' + this.user.tenant_role.name);
            },

            tenantNameTooltip() {
                return `${this.tenantName} (${this.tenantRole})`;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>

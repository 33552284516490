import {InjectionKey} from "vue";
import type FeatureRepository from "@/Features/FeatureRepository";
import type TrackingService from "@/Services/TrackingService";
import type ManageService from "@/Services/ManageService";
import type SketchfabService from "@/Services/SketchfabService";
import type StatisticsService from "@/Services/StatisticsService";
import type TenantService from "@/Services/TenantService";
import type UnitLinkService from "@/Services/UnitLinkService";
import type LearningRecordsService from "@/Services/LearningRecordsService";
import type LearningRecordsStatisticsService from "@/Services/LearningRecordsStatisticsService";
import type LocalFeatureService from "@/Services/LocalFeatureService";
import type UserService from "@/Services/UserService";
import type AssetService from "@/Services/AssetService";
import type CourseService from "@/Services/CourseService";
import type TokenService from "@/Services/TokenService";
import type UnitService from "@/Services/UnitService";

// Services
export const assetServiceKey: InjectionKey<AssetService> = Symbol();
export const courseServiceKey: InjectionKey<CourseService> = Symbol();
export const tokenServiceKey: InjectionKey<TokenService> = Symbol();
export const unitServiceKey: InjectionKey<UnitService> = Symbol();
export const localFeatureServiceKey: InjectionKey<LocalFeatureService> = Symbol();
export const trackingServiceKey: InjectionKey<TrackingService> = Symbol();
export const userServiceKey: InjectionKey<UserService> = Symbol();
export const manageServiceKey: InjectionKey<ManageService> = Symbol();
export const sketchfabServiceKey: InjectionKey<SketchfabService> = Symbol();
export const statisticsServiceKey: InjectionKey<StatisticsService> = Symbol();
export const learningRecordsServiceKey: InjectionKey<LearningRecordsService> = Symbol();
export const learningRecordsStatisticsServiceKey: InjectionKey<LearningRecordsStatisticsService> = Symbol();
export const tenantServiceKey: InjectionKey<TenantService> = Symbol();
export const unitLinkServiceKey: InjectionKey<UnitLinkService> = Symbol();

// Repositories
export const featureRepositoryKey: InjectionKey<FeatureRepository> = Symbol();

<template>
    <div class="command-settings">

        <!-- Select 3d object -->
        <Dropdown
            :initial-value="command.target"
            @select="onSelectTarget3dObject"
            :options="get3dObjectTargetDropdownOptionsForCommand"
            :required="true"
            :deselected-caption="trans('authoring.select_sceneobject')"
        />

        <TextInput
            v-if="shouldShowAnimationNameInput"
            class="command-animation-name"
            type="text"
            :model="command"
            property="animation_name"
            @change="onChangeAnimationName"
            :maxlength="400"
            :placeholder="br2nl(trans('authoring.command_animationname_placeholder'))"
        />
    </div>
</template>

<script>
    import {getSceneObjectTargetDropdownOptionsForCommand} from "@/Models/UnitData/Commands/CommandHelpers";
    import {Control3dAnimationCommand} from "@/Models/UnitData/Commands/Command";

    export default {
        name: "PanelCommandControl3dAnimation",
        emits: [
            'change',
        ],
        props: {
            command: {
                type: Control3dAnimationCommand,
                default: null
            }
        },
        data() {
            return {
                refresh: '',                                // Helper for refreshing the UI
            }
        },
        computed: {

            /**
             * Get options for target scene object selection dropdown
             *
             * @returns {DropdownOption[]}
             */
            get3dObjectTargetDropdownOptionsForCommand() {
                return getSceneObjectTargetDropdownOptionsForCommand(this.command);
            },

            shouldShowAnimationNameInput() {
                return (
                    this.command.target !== null
                    || this.command.animation_name
                );
            },

        },
        methods: {

            /**
             * Select handler for target 3d object dropdown
             *
             * @param {String} targetUID
             */
            onSelectTarget3dObject(targetUID) {
                if (targetUID !== this.command.target) {
                    this.command.target = targetUID;
                    this.$emit('change', this.command);
                }
                return this;
            },

            /**
             * Change handler for animation name
             *
             * @param {String} text
             * @param {Event|KeyboardEvent|FocusEvent} e
             */
            onChangeAnimationName(text, e) {
                this.$emit('change', this.command);
                return this;
            }

        }
    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <div id="sidepanel-sceneobjects" class="sidepanel-content" v-if="isVisible" v-shortcuts.global.stop>
        <header>
            <Icon name="icon_doublearrow_right" class="icon-close" @click="onClickCloseSidePanel" />
            {{ trans('labels.objects') }}
        </header>

        <ListFiltersBar
            class="sidepanel-filters"
            ref="sidePanelFilters"
            :categories="filterCategories"
            :filterWords="filterWords"
            @change="applyFilters"
            @cancel="onClickCloseSidePanel"
        />

        <ul
            :class="'selectable-list' + (itemsCountTotal === 0 ? ' no-items' : '')"
            v-not-focusable
            @scroll="onScrollList"
            ref="scrollList"
        >
            {{ void(itemIndex = 0) }}
            <template v-for="filterGroup in filterSections">

                <SidepanelSectionHeadline
                    v-if="shouldFilterShowSectionHeadline(filterGroup, itemIndex)"
                    :text="`${filterGroup.headline} (${filterGroup.entries.length})`"
                />

                <!-- + New Asset button -->
                <li
                    v-if="shouldFilterShowCreateNewAssetButton(filterGroup.category)"
                    @click="onClickCreateNewAsset"
                    data-ug-title="create-new-asset"
                    class="li-create-new-asset"
                >
                    <span class="preview">
                        <Icon name="icon_add_32" />
                    </span>

                    <section class="info">
                        <h4 class="title">{{ trans('labels.new_asset') }}</h4>
                        <!--<p class="description" title=""></p>-->
                    </section>
                </li>

                <template
                    v-for="object in getReducedListForRendering(itemIndex, filterGroup)"
                >
                    <SidepanelSceneObjectsListItem
                        :object="object"
                        @select="selectObject(object)"
                    />
                    {{ void(itemIndex++) }}
                </template>
            </template>
        </ul>
        <template v-if="itemsCountTotal === 0">
            <NoItemsFound v-if="filterWords !== null" />
            <NoItemsAvailable v-else />
        </template>
    </div>
</template>

<script>

    // Import helpers and functions:
    import { sortArrayByProperty, trans }   from '@/Utility/Helpers';

    // Import VueJS components:
    import ListFiltersBar                   from '@/Vue/Common/ListFiltersBar.vue';
    import NoItemsAvailable                 from '@/Vue/Search/NoItemsAvailable';
    import NoItemsFound                     from '@/Vue/Search/NoItemsFound';

    // Import classes:
    import EventType                        from '@/Utility/EventType';
    import Search                           from '@/Utility/Search';
    import Asset                            from '@/Models/Asset/Asset';
    import Category                         from '@/Filters/Category';
    import SceneObjectFilters               from '@/Filters/SceneObjectFilters';
    import SidepanelSceneObjectsListItem    from '@/Vue/Sidepanel/SidepanelSceneObjectsListItem';
    import SidepanelSectionHeadline         from '@/Vue/Sidepanel/SidepanelSectionHeadline';
    import FilterCategory                   from '@/Filters/FilterCategory';
    import MultiFilterCategory              from '@/Filters/MultiFilterCategory';

    export default {
        name: 'SidepanelSceneObjects',
        components: {
            ListFiltersBar,
            NoItemsAvailable,
            NoItemsFound,
            SidepanelSectionHeadline,
            SidepanelSceneObjectsListItem,
        },

        props: {
            isVisible:  {                   // Whether the content is visible (set by the parent component)
                type: Boolean,
                default: false
            },
            objects: {                      // The list of assets and SceneObjectTypes passed from the parent component
                type: Array,
                default() {
                    return [];
                }
            },
            disableVirtualScrolling: {      // Whether to disable virtual scrolling and render all items at once
                type: Boolean,
                default() {
                    return false;
                }
            }
        },
        data() {
            return {
                previousFocusElement: null,             // DOM element that had focus before the dialog was shown
                filterCategories: [         // List of default FilterCategory items
                    SceneObjectFilters.AuthoringAll,
                    SceneObjectFilters.AuthoringMyLibrary,
                ],
                filterSections: [],
                filterOptions: null,        // Filter options
                filterWords: null,          // Filter words for the text input
                callback: null,             // Reference to the target function/model/component for which this component is being shown
                assetTypes: null,           // List of available AssetTypes for creating new assets
                shortcuts: new Map([
                    ['Escape', this.onClickCloseSidePanel]
                ]),
                renderItemsLoaded: 32,      // How many items to display initially
                renderItemsPerPage: 32,     // How many items to add at once when scrolling
            }
        },
        mounted() {
            this.$globalEvents.on(EventType.SIDEPANEL_SCENEOBJECTS_FILTERS_SET, this.setFilters);
            this.$globalEvents.on(EventType.SIDEPANEL_SCENEOBJECTS_SHOW, this.onShow);
        },
        beforeUnmount() {
            this.$globalEvents.off(EventType.SIDEPANEL_SCENEOBJECTS_FILTERS_SET, this.setFilters);
            this.$globalEvents.off(EventType.SIDEPANEL_SCENEOBJECTS_SHOW, this.onShow);
        },
        computed: {

            /**
             * @returns {Number}
             */
            itemsCountTotal() {
                return this.filterSections.reduce((count, section) => count + section.entries.length, 0);
            },
        },
        methods: {

            /**
             * @param {Event} e
             */
            onScrollList(e) {
                // Maximum number of items showing already
                if (this.disableVirtualScrolling || (this.renderItemsLoaded >= this.itemsCountTotal)) {
                    return this;
                }
                const list = e.target;
                if (list.scrollTop >= (list.scrollHeight - list.offsetHeight - (list.offsetHeight * 0.85))) {
                    this.renderItemsLoaded += this.renderItemsPerPage;
                }
                return this;
            },

            /**
             * Click handler for close button
             *
             * @param {CustomEvent|KeyboardEvent|MouseEvent} e
             */
            onClickCloseSidePanel(e) {
                this.$globalEvents.emit(EventType.SIDEPANEL_SCENEOBJECTS_CANCEL);
                return this;
            },

            /**
             * Click handler for creating a new asset
             */
            onClickCreateNewAsset() {
                this.$globalEvents.emit(EventType.MODAL_CREATE_ASSET_SHOW, {
                    callback: this.selectObject,
                    assetTypes: this.assetTypes || [],
                });
                return this;
            },

            /**
             * Is a given object an asset?
             *
             * @param {Object} object
             * @returns {Boolean}
             */
            isAsset(object) {
                return (object instanceof Asset);
            },

            /**
             * @param {Object} filterGroup
             * @param {Number} itemIndex
             * @returns {Boolean}
             */
            shouldFilterShowSectionHeadline(filterGroup, itemIndex) {
                return filterGroup.headline
                    && (
                        filterGroup.entries.length
                        || (
                            this.assetTypes
                            && this.shouldFilterShowCreateNewAssetButton(filterGroup.category)
                        )
                    ) && (
                        this.disableVirtualScrolling
                        || (
                            itemIndex < this.renderItemsLoaded
                        )
                    );
            },

            /**
             * Should filter show "Create new asset" entry
             *
             * @returns {Boolean}
             */
            shouldFilterShowCreateNewAssetButton(filterSectionOrCategory) {
                // List of filters that should have the button
                return this.assetTypes && [
                    SceneObjectFilters.AssetsForAuthoring,
                    SceneObjectFilters.AssetsOfSameType,
                    SceneObjectFilters.Images,
                    SceneObjectFilters.Model3d,
                    SceneObjectFilters.Sounds,
                    SceneObjectFilters.TextToSpeechSounds,
                    SceneObjectFilters.Videos,
                ].map(f => f.callback).includes(filterSectionOrCategory.callback);
            },

            /**
             * Set and apply the filters to be used by the SidepanelFilters component
             *
             * @param {(Category|String)[]} filters  // List of Category items or names
             * @param {any} options                       // Additional options to be passed to the filter
             */
            setFilters(filters, options = null) {
                this.filterCategories = filters instanceof Array ? filters.map(f => f instanceof Category ? f : SceneObjectFilters[f]) : filters || [];

                if (this.filterCategories.length > 0) {
                    // enable first category
                    this.filterCategories[0].setActive(true);
                }

                this.filterOptions = options;
                this.applyFilters();
                return this;
            },

            /**
             * Apply filters
             *
             * @param {FilterCategory|String|null} filterText
             */
            applyFilters(filterText = null) {
                // Store filter text:
                if ((filterText === null || typeof filterText === 'string') && filterText !== this.filterWords)
                {
                    this.filterWords = filterText;
                }

                this.filterSections = [];

                // Apply filter categories:
                this.filterCategories.filter(fc => fc.isActive === true).forEach(category => {

                    // For a simple FilterCategory only one group without headline will be created
                    if (category instanceof FilterCategory)
                    {
                        const filteredList = this.filteredListForCategory(category);
                        this.filterSections.push({entries: filteredList, category: category});
                        return this;
                    }

                    // For MultiFilterCategory a group for each filter with a headline gets created
                    if (category instanceof MultiFilterCategory)
                    {
                        category.sections.forEach(section => {
                            const filteredList = this.filteredListForCategory(section.category);
                            this.filterSections.push({headline: section.title, entries: filteredList, category: section.category});
                        });
                    }

                }, this);

                // Reset scrolling list
                this.renderItemsLoaded = this.renderItemsPerPage;
                if (this.isVisible && this.$refs.scrollList) {
                    this.$refs.scrollList.scrollTop = 0;
                }

                return this;
            },

            /**
             * Creates a new array with all objects that pass the category-filter.
             * They will additionally be filtered by the filterWords and sorted by their title.
             *
             * @param {FilterCategory} filterCategory
             * @returns {Array}
             */
            filteredListForCategory(filterCategory) {

                let objectList = Array.from(this.objects);
                objectList = filterCategory.callback(objectList, this.filterOptions);

                // Apply word filter:
                if (this.filterWords !== null)
                {
                    objectList = Search.filterObjects(objectList, ['title', 'description', 'assetTypeTitle'], this.filterWords);
                }
                // Sort objects by title:
                objectList = sortArrayByProperty(objectList, 'title');
                return objectList;
            },

            /**
             * @param {Number} currentIndex
             * @param {Object} filterGroup
             * @return {Array}
             */
            getReducedListForRendering(currentIndex, filterGroup) {
                if (this.disableVirtualScrolling) {
                    return filterGroup.entries;
                }
                const count = Math.min(this.renderItemsLoaded - currentIndex, filterGroup.entries.length);
                return filterGroup.entries.slice(0, count);
            },

            /**
             * Select a specific asset to be loaded
             *
             * @param {Asset|SceneObject} object
             */
            selectObject(object) {
                if (this.callback instanceof Function) {
                    this.callback(object);
                }
                this.$globalEvents.emit((this.isAsset(object) ? EventType.SIDEPANEL_ASSETS_SELECT : EventType.SIDEPANEL_SCENEOBJECTS_SELECT), object, this.callback);
                return this;
            },

            /**
             * Set any custom properties that may have been send with the SIDEPANEL_SCENEOBJECTS_SHOW event
             *
             * @param {Object} properties
             */
            onShow(properties = null) {
                this.previousFocusElement = document.activeElement;
                properties = (properties instanceof Object) ? properties : {};
                this.eventTypeForSelect = properties.eventTypeForSelect || null;
                this.callback = properties.callback || null;
                this.assetTypes = properties.assetTypes || null;
                // Clear word filter if the parameter was sent with the event:
                if (typeof properties.clearWordFilter === 'boolean' && properties.clearWordFilter === true)
                {
                    this.applyFilters(null);
                }
                // Re-apply focus to search input if the side panel is currently visible (could also be done by re-rendering the child component):
                if (this.isVisible === true)
                {
                    window.setTimeout(() => {
                        this.$refs.sidePanelFilters?.setFocus();
                    }, 1);
                }
                return this;
            }
        },
        watch: {
            isVisible(newValue, oldValue) {
                if (newValue === true)
                {
                    this.previousFocusElement = document.activeElement;
                }
                else
                {
                    if (this.previousFocusElement instanceof Object && this.previousFocusElement.focus instanceof Function)
                    {
                        this.previousFocusElement.focus();
                    }
                    this.previousFocusElement = null;
                }
            },
            objects() {
                // Re-apply the filters:
                this.applyFilters();
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>

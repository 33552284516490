import TriggerType from '@/Models/UnitData/Triggers/TriggerType';

export function getInspectorCustomTitleComponentForTrigger(eventName)
{
    const mapping = new Map ([
        [TriggerType.OnCue.type, 'PanelTriggerCueCustomTitle'],
        [TriggerType.OnKeyPress.type, 'PanelTriggerKeypressCustomTitle'],
        [TriggerType.OnSpectatorKeyPress.type, 'PanelTriggerKeypressCustomTitle'],
    ]);

    return mapping.has(eventName) ? mapping.get(eventName) : null;
}

/*
|--------------------------------------------------------------------------
| Property Components for Trigger
|--------------------------------------------------------------------------
|
*/

const defaultPropertyComponents = [
    {
        component: 'PanelTriggerPropertyRetrigger',
        allowedOnGlobalObjects: true,
    },
    {
        component: 'PanelTriggerPropertyIsObjective',
        allowedOnGlobalObjects: false,
    },
    {
        component: 'PanelTriggerPropertyCommands',
        allowedOnGlobalObjects: true,
    },
];

const propertyComponentsForDistanceTriggers = [
    {
        component: 'PanelTriggerPropertyEnterLeaveSubtype',
        allowedOnGlobalObjects: true,
    },
    {
        component: 'PanelTriggerPropertyDistance',
        allowedOnGlobalObjects: true,
    },
    {
        component: 'PanelTriggerPropertyTags',
        allowedOnGlobalObjects: true,
    },
    {
        component: 'PanelTriggerPropertyRetrigger',
        allowedOnGlobalObjects: true,
    },
    {
        component: 'PanelTriggerPropertyIsObjective',
        allowedOnGlobalObjects: false,
    },
    {
        component: 'PanelTriggerPropertyCommands',
        allowedOnGlobalObjects: true,
    },
];

const propertyComponentsForTriggersMapping = new Map ([
    [
        TriggerType.OnEnter.type,
        propertyComponentsForDistanceTriggers,
    ],
    [
        TriggerType.OnLeave.type,
        propertyComponentsForDistanceTriggers,
    ],
    [
        TriggerType.OnActivate.type,
        [
            {
                component: 'PanelTriggerPropertyRetrigger',
                allowedOnGlobalObjects: true,
            },
            {
                component: 'PanelTriggerPropertyIsObjective',
                allowedOnGlobalObjects: false,
            },
            {
                component: 'PanelTriggerPropertyCancelWhenDeactivated',
                allowedOnGlobalObjects: true,
            },
            {
                component: 'PanelTriggerPropertyCommands',
                allowedOnGlobalObjects: true,
            },
        ],
    ],
    [
        TriggerType.OnDeactivate.type,
        [
            {
                component: 'PanelTriggerPropertyRetrigger',
                allowedOnGlobalObjects: true,
            },
            {
                component: 'PanelTriggerPropertyIsObjective',
                allowedOnGlobalObjects: false,
            },
            {
                component: 'PanelTriggerPropertyCancelWhenActivated',
                allowedOnGlobalObjects: true,
            },
            {
                component: 'PanelTriggerPropertyCommands',
                allowedOnGlobalObjects: true,
            },
        ],
    ],
]);

export function getPropertyComponentsForTrigger(eventName)
{
    return propertyComponentsForTriggersMapping.has(eventName) ? propertyComponentsForTriggersMapping.get(eventName) : defaultPropertyComponents;
}

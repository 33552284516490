<template>
    <Collapsible
        class="inspector-panel panel-transform"
        :initial-collapsed="true"
    >

        <template #header>
            {{ trans('authoring.transform') }}
        </template>

        <template #body>
            <PanelScale
                v-if="showPanelScale"
                :scale="transform.scale"
                @change="onChangeProperty"
            />
            <PanelPosition
                v-if="showPanelPosition"
                :position="transform.position"
                @change="onChangeProperty"
            />
            <PanelRotation
                v-if="showPanelRotation"
                :rotation="transform.rotation"
                @change="onChangeProperty"
            />
        </template>

    </Collapsible>
</template>

<script>
    import Transform from '@/Models/Unity/Transform';
    import PanelPosition from '@/Vue/Inspector/PanelPosition';
    import PanelScale from '@/Vue/Inspector/PanelScale';
    import PanelRotation from "@/Vue/Inspector/PanelRotation.vue";

    export default {
        name: 'PanelTransform',
        emits: [
            'change',
        ],
        components: {
            PanelRotation,
            PanelPosition,
            PanelScale
        },
        props: {
            transform: {
                type: Transform,
                default: () => new Transform(),
            },
            showPanelScale: {
                type: Boolean,
                default: true,
            },
            showPanelPosition: {
                type: Boolean,
                default: true,
            },
            showPanelRotation: {
                type: Boolean,
                default: true,
            },
        },
        methods: {

            onChangeProperty() {
                this.$emit('change', this.transform);
                return this;
            }

        }
    }
</script>

<style lang="scss" scoped>
</style>

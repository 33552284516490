<template>
    <Modal
        id="dialog-release-unit"
        ref="modal"
        :show-close-button="false"
        :title="trans('modals.release_unit.title')"
        :user-closable="true"
        event-type="MODAL_RELEASE_UNIT"
        @show="onShow"
    >
        <p class="description"
           v-html="trans('modals.release_unit.description_intro', { title: unitRevision?.title || '' })"></p>

        <template v-if="isDraftUnit">
            <p v-html="trans('modals.release_unit.description_first_release', { title: unitRevision?.title || '' })"></p>
            <p v-html="trans('modals.release_unit.description_first_changes')"></p>
        </template>
        <template v-else>

            <p class="description" v-html="trans('modals.release_unit.description_learners')"></p>

            <!-- Part of any course -->
            <template v-if="hasCourses">
                <p class="description">{{ trans('modals.release_unit.description_courses') }}</p>
                <ul>
                    <li v-for="course in courses"><b>{{ course.title }}</b></li>
                </ul>
            </template>

        </template>

        <template #buttons>
            <ButtonSecondary
                caption="modals.release_unit.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonPrimary
                caption="modals.release_unit.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import EventType from '@/Utility/EventType';
import TenantListItem from '@/Vue/Tenants/TenantListItem.vue';
import Unit from '@/Models/Unit/Unit';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent} from 'vue';
import UnitRevision from '@/Models/Unit/UnitRevision';
import CourseWithoutRelations from '@/Models/Course/CourseWithoutRelations';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {trans} from '../../Utility/Helpers';

export default defineComponent({
    components: {
        ButtonPrimary,
        ButtonSecondary,
        Modal,
        TenantListItem
    },
    data() {
        return {
            unitRevision: null as UnitRevision | null,
            courses: [] as CourseWithoutRelations[],
        }
    },
    computed: {

        hasCourses(): boolean {
            return this.courses.length > 0;
        },

        isDraftUnit(): boolean {
            return this.unitRevision?.getParent(Unit)?.isDraft || false;
        },

        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        },
    },
    methods: {
        trans,

        onShow(unitRevision: UnitRevision, courses: CourseWithoutRelations[] = []) {
            this.unitRevision = unitRevision;
            this.courses = courses;
        },

        onHide() {
            this.unitRevision = null;
            this.courses = [];
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_RELEASE_UNIT_APPLY, this.unitRevision);
        },
    }
});
</script>

<style lang="scss" scoped>

ul {
    margin-bottom: 20px;
    list-style-position: inside;
}

</style>

<template>
    <component
        v-if="componentName"
        :is="componentName"
        :command="command"
        @change="onChangeTriggerAction"
    />
</template>

<script>
import { getPanelComponentName }    from '@/Models/UnitData/Commands/CommandHelpers';
import Command                      from '@/Models/UnitData/Commands/Command';

export default {
    name: 'PanelCommand',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: Command,
            default: null
        }
    },
    computed: {

        /**
         * Get component name from mapping
         *
         * @returns {String|null}
         */
        componentName() {
            return getPanelComponentName(this.command);
        }
    },
    methods: {

        /**
         * Change handler for the action settings
         *
         * @param {Command} command
         */
        onChangeTriggerAction(command) {
            this.$emit('change', command);
            return this;
        }
    },
}
</script>

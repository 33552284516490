<template>
    <Modal
        id="dialog-restore-unit-from-history"
        ref="modal"
        :show-close-button="false"
        :title="trans('modals.restore_unit_from_history.title')"
        :user-closable="true"
        event-type="MODAL_UNIT_RESTORE_FROM_HISTORY"
        @hide="onHide"
        @show="onShow"
    >
        <p class="description"
           v-html="trans('modals.restore_unit_from_history.description', {versiondate: dateFormatted})"></p>

        <template #buttons>
            <ButtonSecondary
                caption="modals.restore_unit_from_history.cancel"
                class="btn-cancel"
                @trigger="onClickCancel"
            />
            <ButtonSecondary
                caption="modals.restore_unit_from_history.save_as_new"
                class="btn-dismiss"
                @trigger="onClickAsNewUnit"
            />
            <ButtonPrimary
                caption="modals.restore_unit_from_history.apply"
                class="btn-apply"
                @trigger="onClickApply"
            />
        </template>
    </Modal>
</template>

<script lang="ts">

import moment from 'moment';
import EventType from '@/Utility/EventType';
import Modal from '@/Vue/Modals/Modal.vue';
import {defineComponent} from 'vue';
import type UnitRevision from '@/Models/Unit/UnitRevision';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {trans} from '@/Utility/Helpers';

type ModalData = {
    unitRevision: UnitRevision | null,
};

export default defineComponent({
    components: {
        ButtonPrimary,
        ButtonSecondary,
        Modal
    },
    data(): ModalData {
        return {
            /**
             * UnitRevision which to restore from history
             */
            unitRevision: null as UnitRevision | null,
        }
    },
    computed: {

        dateFormatted(): string {
            if (this.unitRevision) {
                const date = moment(this.unitRevision.updated_at);
                return date.format('LL – HH:mm');
            }
            return '';
        },

        modal() {
            return this.$refs.modal as InstanceType<typeof Modal>;
        }
    },
    methods: {
        trans,

        onShow(unitRevision: UnitRevision) {
            this.unitRevision = unitRevision;
        },

        onHide() {
            this.unitRevision = null;
        },

        onClickCancel() {
            this.modal.cancel();
        },

        onClickApply() {
            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_UNIT_RESTORE_FROM_HISTORY_APPLY, this.unitRevision);
        },

        onClickAsNewUnit() {
            this.modal.hide();
            this.$globalEvents.emit(EventType.MODAL_UNIT_RESTORE_FROM_HISTORY_AS_NEW_UNIT, this.unitRevision);
        }
    }
});
</script>

<style lang="scss" scoped>

#dialog-restore-unit-from-history {
    width: min-content;

    .description em {
        white-space: nowrap;
    }
}
</style>

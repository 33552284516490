import {Permission} from '@/Models/User/Permission';
import {permission} from '@/Utility/Helpers';
import UnitPermissionPolicy, {
    UnitPermissionPolicySample,
    UnitPermissionPolicyTemplate
} from '@/Models/Unit/UnitPermissionPolicy';
import {TenantRole} from "@/Models/Tenant/TenantRole";
import type User from "@/Models/User/User";
import type Unit from "@/Models/Unit/Unit";

/**
 * Policy for checking user abilities and permissions.
 * Method names for abilities should map to permissions.
 *
 * e.g. Permission.UnitUpdate() = 'units:update' => update() {}
 *
 * Policies can be registered in Gate instances:
 *
 * e.g. window.gate.policy('Unit', new UnitPolicy);
 *
 * A registered policy can then be used via the gate. The gate chooses which policy to use based on the provided model
 * and the registered policies.
 *
 * e.g. window.gate.allows(Permission.ability(Permission.UnitUpdate()), unit);
 *
 */
export default class UnitPolicy {
    /**
     * Determine whether the user can read the unit.
     */
    read(user: User, unit: Unit): boolean {
        return this.unitIsInScopeForReading(user, unit);
    }

    /**
     * Determine whether the user can update the unit.
     */
    update(user: User, unit: Unit): boolean {
        return (
            this.unitIsInScopeForWriting(user, unit) &&
            permission(Permission.UnitsUpdate())
        );
    }

    /**
     * Determine whether the user can duplicate the unit.
     */
    duplicate(user: User, unit: Unit): boolean {
        const currentUnitPolicy = unit.parsedPolicy;

        const userHasAccessToNewPolicy = UnitPermissionPolicy
            .allAvailablePoliciesForUser(user)
            .includes(currentUnitPolicy.policyForDuplicates);

        return (
            this.unitIsInScopeForReading(user, unit) &&
            (unit.owned_by_tenant === user.tenant?.uid || currentUnitPolicy.canDuplicateInForeignTenant) &&
            permission(Permission.UnitsDuplicate()) &&
            userHasAccessToNewPolicy
        );
    }

    /**
     * Determine whether the user can import the unit as template.
     */
    import_template(user: User, unit: Unit): boolean {
        return (
            this.unitIsInScopeForReading(user, unit) &&
            unit.owned_by_tenant !== user.tenant?.uid &&
            unit.parsedPolicy.canImportAsTemplate &&
            permission(Permission.UnitsImportTemplate())
        );
    }

    /**
     * Determine whether the user can release the unit.
     */
    release(user: User, unit: Unit): boolean {
        return (
            this.unitIsInScopeForWriting(user, unit) &&
            permission(Permission.UnitsRelease()) &&
            (
                unit.isDraft ||
                unit.hasUnreleasedChanges
            )
        );
    }

    /**
     * Determine whether the user can delete the unit.
     */
    delete(user: User, unit: Unit): boolean {
        if (
            unit.isReleased &&
            !permission(Permission.UnitsDeleteReleased())
        ) {
            return false;
        }

        return (
            this.unitIsInScopeForWriting(user, unit) &&
            permission(Permission.UnitsDelete())
        );
    }

    /**
     * Determine whether the user can assign another user to the unit as author.
     */
    assign_authors(user: User, unit: Unit): boolean {
        const isUnitOwnedByUser = user.uid === unit.owned_by;

        const isUnitInPermissionScope =
            permission(Permission.UnitsAssignAuthorsInAny()) ||
            (permission(Permission.UnitsAssignAuthorsInOwn()) && isUnitOwnedByUser);

        return (
            permission(Permission.UnitsAssignAuthors()) &&
            isUnitInPermissionScope &&
            this.unitIsInScopeForWriting(user, unit)
        );
    }

    /**
     * Determine whether the user can restore the unit from an old revision.
     */
    restore(user: User, unit: Unit): boolean {
        return (
            this.unitIsInScopeForWriting(user, unit) &&
            permission(Permission.UnitsRestore())
        );
    }

    /**
     * Determine whether the user can allow the unit to be used in a lms.
     */
    grant_access_via_lms(user: User, unit: Unit): boolean {
        return (
            this.unitIsInScopeForReading(user, unit) &&
            unit.isReleased &&
            unit.parsedPolicy.canBeUsedInLms &&
            permission(Permission.UnitsGrantAccessViaLms())
        );
    }

    /**
     * Determine whether the user can launch the unit.
     * The unit must be released.
     */
    launch(user: User, unit: Unit): boolean {
        return (
            unit.isReleased
            && unit.latestReleasedRevision!.isCompatible
            && permission(Permission.UnitsLaunch())
            && this.unitIsInScopeForReading(user, unit)
        );
    }

    unitIsInScopeForWriting(user: User, unit: Unit): boolean {
        // Deny access to the unit if it is not owned by the current tenant
        if (unit.owned_by_tenant !== user.tenant?.uid) {
            return false;
        }

        const availablePolicies = UnitPermissionPolicy.allAvailablePoliciesForUser(user);
        const userHasAccessToUnitPolicy = availablePolicies.some(policy => policy.type === unit.policy);

        if (!userHasAccessToUnitPolicy) {
            return false;
        }

        // TODO: Replace these tenant-Role checks with a proper Permission
        // Tenant admins and authors can access all units on the tenant
        if (user.tenant_role?.isAnyOf(TenantRole.Name.Author, TenantRole.Name.Admin)) {
            return true;
        }

        if (user.tenant_role?.is(TenantRole.Name.AuthorLimited)) {
            const unitIsOwnedByUser = user.uid === unit.owned_by;

            // Limited Authors can only edit units they are assigned as author to or that they own
            if (unitIsOwnedByUser || unit.authors.some(assignedAuthor => assignedAuthor === user.uid)) {
                return true;
            }
        }

        return false;
    }

    unitIsInScopeForReading(user: User, unit: Unit): boolean {
        // Some users can read released sample units
        if (
            unit.policy === UnitPermissionPolicySample.type &&
            unit.isReleased &&
            permission(Permission.UnitsReadGlobalReleasedSamples())
        ) {
            return true;
        }

        // Some users can read released template units
        if (
            unit.policy === UnitPermissionPolicyTemplate.type &&
            unit.isReleased &&
            permission(Permission.UnitsReadGlobalReleasedTemplates())
        ) {
            return true;
        }

        // Some users can read all units on the tenant
        if (permission(Permission.UnitsReadAny())) {
            return true;
        }

        // Some users can read their own units
        if (permission(Permission.UnitsReadOwn())) {
            const unitIsOwnedByUser = user.uid === unit.owned_by;
            if (unitIsOwnedByUser) {
                return true;
            }
        }

        // Some users can read the units they are assigned to author
        if (permission(Permission.UnitsReadAssignedForAuthoring())) {
            if (unit.authors.some(assignedAuthor => assignedAuthor === user.uid)) {
                return true;
            }
        }

        // Some users can read the units they launched via LMS:
        // This check is not relevant to the frontend, as it will
        // only ever be needed on non-index actions.

        return false;
    }
}

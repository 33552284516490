<template>
    <div id="inspector" class="inspector-content" v-not-focusable>
        <header :class="'inspector-header asset asset-' + asset.type">
            <Icon :name="asset.assetType.icon" class="inspector-panel-header-icon" />
            {{ trans('labels.inspector.asset_properties') }}
        </header>

        <!-- Panels for editing object properties -->
        <div class="panels" v-not-focusable :key="'InspectorPanels' + renderTimestamp">
            <div class="inspector-panel inspector-panel-asset">

                <InspectorItemPreviewImage
                    :src="asset.preview"
                    :editable="asset.supportsPreviewImage && editable"
                    :supports-preview-modal="asset.supportsPreview"
                    :preview-icon="asset.assetType.previewIcon"
                    @click="onClickInspectorPreviewImage"
                    @change="onChangeInspectorPreviewImage"
                />

                <div class="property property-title">
                    <TextInput
                        :disabled="!editable"
                        :model="asset"
                        property="title"
                        @change="onChangeProperty"
                        :required="true"
                        :maxlength="50"
                        :placeholder="trans('assets.forms.title_placeholder')"
                        :error-msg="trans('errors.asset.title')"
                    />
                </div>

                <div class="property property-content-description" v-if="asset.content_description">
                    <TextInput
                        :disabled="true"
                        type="textarea"
                        :label="trans('labels.content_description')"
                        :model="asset"
                        property="content_description"
                    />
                </div>

                <div class="property property-description">
                    <TextInput
                        :disabled="!editable"
                        type="textarea"
                        :label="trans('labels.description')"
                        :model="asset"
                        property="description"
                        @change="onChangeProperty"
                        :required="false"
                        :maxlength="600"
                        :placeholder="trans('assets.forms.description_placeholder')"
                        :error-msg="trans('errors.asset.description')"
                    />
                </div>

                <div class="property property-attribution">
                    <TextInput
                        :disabled="!editable"
                        type="textarea"
                        :label="trans('labels.attribution')"
                        :model="asset"
                        property="attribution"
                        @change="onChangeProperty"
                        :required="false"
                        :maxlength="600"
                        :placeholder="trans('assets.forms.attribution_placeholder')"
                        :error-msg="trans('errors.asset.attribution')"
                    />
                </div>

                <div class="property property-type property-asset-type">
                    <span class="textinput type-text no-wrap has-label disabled">
                        <label>Type</label>
                        <span class="text-readonly"><Icon :name="asset.assetType.icon" />{{ assetType }}</span>
                    </span>
                </div>

                <div class="property property-type property-asset-policy">
                      <span class="textinput type-text no-wrap has-label disabled">
                          <label>{{ trans('labels.policy') }}</label>
                          <span class="text-readonly">{{ trans('asset_policies.' + asset.assetPolicy.type) }}</span>
                      </span>
                </div>

                <div v-if="files.length > 0" class="property property-files">
                    <header>{{ trans('labels.source_files') }}</header>
                    <ul>
                        <li
                            v-for="(file, index) in files"
                            :key="index"
                            class="property-files-list-item"
                            :title="file.filename"
                        >
                            <span class="file-name">{{ file.filename }}</span>
                            <span class="file-size">{{ file.humanReadableFileSize }}</span>
                        </li>
                    </ul>
                </div>

                <div v-if="shouldShowTenantUid" class="property property-tenant">
                    <header>{{ trans('labels.tenant') }}</header>
                    <p>{{ asset.owned_by_tenant }}</p>
                </div>
            </div>
        </div>

        <SidepanelAssetButtons
            :asset="asset"
        />

        <!-- Modal dialog for asset archive confirmation -->
        <ModalApplyCancel
            event-type="MODAL_ARCHIVE_ASSET"
            :title="trans('assets.modals.archive_asset.title')"
            :description="trans('assets.modals.archive_asset.description')"
            :apply-text="trans('assets.modals.archive_asset.apply')"
        />

        <!-- Modal dialog for asset remove confirmation -->
        <ModalApplyCancel
            event-type="MODAL_DELETE_ASSET"
            :title="trans('assets.modals.delete_asset.title')"
            :description="trans('assets.modals.delete_asset.description')"
            :apply-text="trans('assets.modals.delete_asset.apply')"
        />

        <!-- Modal dialog for asset remove confirmation -->
        <ModalApplyCancel
            event-type="MODAL_DELETE_UNUSED_ASSETS"
            :title="trans('assets.modals.delete_all_unused_assets.title')"
            :description="trans('assets.modals.delete_all_unused_assets.description')"
            :apply-text="trans('assets.modals.delete_all_unused_assets.apply')"
        />

        <!-- Modal dialog for asset remove confirmation -->
        <ModalApplyCancel
            event-type="MODAL_REMOVE_ASSET_FROM_LIBRARY"
            :title="trans('assets.modals.remove_asset_from_library.title')"
            :description="trans('assets.modals.remove_asset_from_library.description')"
            :apply-text="trans('assets.modals.remove_asset_from_library.apply')"
        />

        <!-- Modal dialog for saving asset changes -->
        <ModalSaveAssetChanges/>
    </div>
</template>

<script>
import { permission, trans, sortArrayByProperty }   from '@/Utility/Helpers';
import { Permission }                               from '@/Models/User/Permission';
import Asset                                        from '@/Models/Asset/Asset';
import AssetPolicy, {AssetPolicySample, AssetPolicyUseOnly} from '@/Models/Asset/AssetPolicy';
import AssetType                                    from '@/Models/Asset/AssetType';
import DropdownOption                               from '@/Utility/DropdownOption';
import InspectorItemPreviewImage                    from '@/Vue/Inspector/Items/InspectorItemPreviewImage.vue';
import SidepanelAssetButtons                        from '@/Vue/Assets/Sidepanel/SidepanelAssetButtons';
import ModalApplyCancel                            from '@/Vue/Modals/ModalApplyCancel.vue';
import ModalSaveAssetChanges                       from '@/Vue/Modals/ModalSaveAssetChanges.vue';
import EventType from "@/Utility/EventType";

export default {
    name: 'SidepanelAsset',
    emits: [
        'change',
    ],
    components: {
        SidepanelAssetButtons,
        InspectorItemPreviewImage,
        ModalApplyCancel,
        ModalSaveAssetChanges,
    },

    props: {
        asset: {
            type: Asset,
        }
    },

    data() {
        return {
            renderTimestamp: null,                          // Helper for forcing re-rendering
        }
    },

    computed: {
        files() {
            return this.asset.fileList.sort((file1, file2) => file1.filename.localeCompare(file2.filename));
        },

        assetType() {
            return AssetType.getByTypeName(this.asset.type).title;
        },

        editable() {
            return this.$gate.allows(Permission.ability(Permission.AssetsUpdate()), this.asset);
        },

        shouldShowTenantUid() {
            const isCrossTenantPolicy = [
                AssetPolicyUseOnly.type,
                AssetPolicySample.type,
            ].includes(this.asset.assetPolicy.type)

            return (
                this.$gate.allows(Permission.TenantsReadAny())
                && isCrossTenantPolicy
                && this.asset.owned_by_tenant !== window.currentUser.tenant.uid
            );
        },
    },

    methods: {

        onClickInspectorPreviewImage(e) {
            this.$globalEvents.emit(EventType.MODAL_ASSET_PREVIEW_SHOW, this.asset, this.asset.title);
        },

        onChangeInspectorPreviewImage(data) {
            this.asset.preview = data.result;
            this.asset.previewImageForUpload = (data.files instanceof FileList && data.files.length >= 1) ? data.files[0] : null;
            this.$emit('change', this.asset);
        },

        /**
         * Change handler for properties
         */
        onChangeProperty() {
            this.$emit('change', this.asset);
            return this;
        },
    },
}
</script>

<style lang="scss" scoped>
    .property-asset-type .icon {
        width: 18px;
        height: 18px;
        vertical-align: baseline;
        margin: 0 3px -3px 0;
    }
</style>

<template>
    <Draggable
        :groups="dragGroups"
        :style="gridAreaForCell"
        :class="cellClasses"
        :disabled="disabled"
        :value="groupObject"
        :data-uid="groupObject.uid"
        :data-children-uids="groupObject.objectsFlat.map(o => o.uid) || []"
        :title="titleAttribute"
        @click="onClickThisCell"
        @dragstart="onDragStart"
        @dragend="onDragEnd"
    >

        <!-- Global scene object -->
        <template v-if="isGlobal">

            <!-- Wrapper needed for correct positioning -->
            <span class="sticky-wrapper">

                <!-- Caption -->
                <span class="caption">

                    <!-- Icon -->
                    <Icon name="icon_arrow-collapsible" class="icon-arrow" @click.stop="onClickArrow" />

                    {{ groupObject.title }} ({{ groupObject.objectsCount }})

                    <!-- Invalid State Icon -->
                    <Icon v-if="groupObject.collapsed && !groupObject.isValid" name="icon_invalid" class="icon-invalid" />

                </span>
            </span>

            <!-- Individual cells for each scene column -->
            <span v-for="(scene, index) in unitData.scenes" :key="'togglevis'+groupObject.uid+index" :class="columnCellClasses(scene)"></span>

        </template>

        <!-- Scene object -->
        <template v-else>

            <!-- Icon -->
            <Icon name="icon_arrow-collapsible" class="icon-arrow" @click.stop="onClickArrow" />

            <!-- Caption -->
            <span class="caption">
                {{ groupObject.title }} ({{ groupObject.objectsCount }})
            </span>

            <!-- Invalid State Icon -->
            <Icon v-if="groupObject.collapsed && !groupObject.isValid" name="icon_invalid" class="icon-invalid" />

        </template>

        <!-- Children -->
        <div v-if="!groupObject.collapsed" class="children">
            <template v-for="(sceneObject, index) in groupObject.objects">
                <GroupCell
                    v-if="sceneObject.type === 'group'"
                    :key="'groupcell'+sceneObject.uid+index+((index === groupObject.objects.length - 1) ? 'is-last' : '')"
                    :row="row + index + 1"
                    :groupObject="sceneObject"
                    :selection="selection"
                    @select-cell="$emit('select-cell', sceneObject, row + index + 1)"
                    @click-visible="onClickSceneObjectChangeVisible"
                    @dragenter="$attrs.onDragenter"
                    @dragleave="$attrs.onDragleave"
                    @drop="$attrs.onDrop"
                />
                <SceneObjectCell
                    v-else
                    :key="'objcell'+sceneObject.uid+index+((index === groupObject.objects.length - 1) ? 'is-last' : '')"
                    :row="row + index + 1"
                    :sceneObject="sceneObject"
                    :selection="selection"
                    :class="(index === groupObject.objects.length - 1) ? 'is-last' : ''"
                    @select-cell="$emit('select-cell', sceneObject, row + index + 1)"
                    @click-visible="onClickSceneObjectChangeVisible"
                    @dragenter="$attrs.onDragenter"
                    @dragleave="$attrs.onDragleave"
                    @drop="$attrs.onDrop"
                />
            </template>
        </div>

    </Draggable>
</template>

<script>
    // Import VueJS components:
    import SceneObjectCell          from '@/Vue/Authoring/GridCells/SceneObjectCell.vue';

    // Import classes:
    import EventType                from '@/Utility/EventType';
    import { SceneObjectGroup }     from '@/Models/UnitData/SceneObjects/SceneObject';
    import TrainingScene from "@/Models/UnitData/Scenes/TrainingScene";
    import UnitData from "@/Models/UnitData/UnitData";

    export default {
        name: 'GroupCell',
        emits: [
            'click-visible',
            'select-cell',
        ],
        components: {
            SceneObjectCell
        },
        props: {
            row: {                          // Row position within the grid
                type: Number,
                default: 0
            },
            groupObject: {                  // SceneObjectGroup model associated with this cell
                type: SceneObjectGroup,
                default: null
            },
            disabled: {                     // Disabled state
                type: Boolean,
                default: false
            },
            selection: {                    // Selected object(s) from the parent component
                type: [Object, String],
                default: null
            }
        },
        computed: {

            /**
             * @returns {TrainingScene|null}
             */
            trainingScene() {
                return this.groupObject.getParent(TrainingScene);
            },

            /**
             * @returns {UnitData}
             */
            unitData() {
                return this.groupObject.getParent(UnitData);
            },

            /**
             * @returns {Boolean}
             */
            isGlobal() {
                return this.groupObject.isGlobal;
            },

            /**
             * Draggable groups (revision UID for global objects and scene UID for scenes)
             *
             * @returns {string[]}
             */
            dragGroups() {
                return [this.trainingScene ? this.trainingScene.uid : this.unitData.uid];
            },

            /**
             * Grid area for a cell to be used in style attribute
             *
             * @returns {String}
             */
            gridAreaForCell() {
                if (this.isGlobal === true)
                {
                    return `grid-area: ${this.row + 1} / 1 / span ${this.groupObject.objectsCountCollapsed + 1} / span ${this.unitData.scenesCount};`;
                }
                return `grid-area: ${this.row + 1} / ${(this.trainingScene.order + 1)} / span ${this.groupObject.objectsCountCollapsed + 1} / span 1;`;
            },

            /**
             * CSS classes for the scene object cell
             *
             * @returns {String}
             */
            cellClasses() {
                const classes = ['group-cell'];
                if (this.isGlobal === true) { classes[classes.length] = 'global'; }
                if (this.groupObject.isGroupChild) { classes[classes.length] = 'is-group-child'; }
                if (!this.groupObject.isValid) { classes[classes.length] = 'is-invalid'; }
                if (this.groupObject.collapsed === true) { classes[classes.length] = 'collapsed'; }
                if (this.disabled === true) { classes[classes.length] = 'disabled'; }
                if (this.selected === true) { classes[classes.length] = 'selected'; }
                return classes.join(' ');
            },

            /**
             * Selected state
             *
             * @returns {Boolean}
             */
            selected() {
                return this.selection instanceof SceneObjectGroup && this.selection.uid === this.groupObject.uid;
            },

            /**
             * Get title attribute for tooltips
             *
             * @returns {String}
             */
            titleAttribute() {
                return this.groupObject.title.length > 18 ? this.groupObject.title : null;
            }
        },
        methods: {

            /**
             * CSS classes for the column cells within a global scene object
             *
             * @param {TrainingScene} scene
             * @returns {String}
             */
            columnCellClasses(scene) {
                const classes = ['cell-column'];
                if (scene.order === 0) { classes[classes.length] = 'is-first'; }
                if (scene.order === this.unitData.scenesCount - 1) { classes[classes.length] = 'is-last'; }
                return classes.join(' ');
            },

            /**
             * Click handler for the arrow icon
             *
             * @param {MouseEvent} e
             */
            onClickArrow(e) {
                this.groupObject.collapsed = !this.groupObject.collapsed;
                return this;
            },

            /**
             * Click handler for this cell
             *
             * @param {MouseEvent} e
             */
            onClickThisCell(e) {
                if (this.disabled === false)
                {
                    // Prevent other click handlers from executing:
                    e.stopPropagation();

                    // Close triggers side panel (if it was opened by another grid cell):
                    if (this.$globalEvents.isEventTargetDescendantOfSelector(e, '.dropdown, .checkbox') === false)
                    {
                        this.$globalEvents.emit(EventType.SIDEPANEL_TRIGGERS_HIDE);
                    }

                    // Send selection event to the grid if the cell is not selected already:
                    if (this.selected === false)
                    {
                        this.$emit('select-cell', this.groupObject);
                    }
                }
                return this;
            },

            /**
             * Click handler for visibility changes on a global scene object
             *
             * @param {SceneObject} sceneObject
             * @param {TrainingScene} scene
             */
            onClickSceneObjectChangeVisible(sceneObject, scene) {
                // Pass the event to the parent:
                this.$emit('click-visible', sceneObject, scene);
                return this;
            },

            onDragStart(e) {
                window.document.body.classList.add('is-dragging-group-cell');
                return this;
            },

            onDragEnd(e) {
                window.document.body.classList.remove('is-dragging-group-cell');
                return this;
            }
        }
    }
</script>

<style lang="scss" scoped>

    .group-cell {
        position: relative;
        flex-grow: 1;
        align-self: flex-start;
        max-width: 100%;
        height: 100%;
        margin-top: 2px;    // needed since the alignment is flex-start and the grid row height is 4px larger than the cell height
        padding: 0 12px 0 12px;
        cursor: pointer;

        // For inner alignments:
        display: flex;
        flex-direction: row;
        align-items: center;

        &.disabled {
            cursor: default !important;
            opacity: 0.2;
        }

        .icon-arrow {
            pointer-events: auto;
            transition: color .1s, background-color .1s, border-color .1s, opacity 0s, transform .1s !important;
        }

        // Fix icon and caption position when background is stretched over the full height of the group
        &:not(.global) {
            align-items: flex-start;

            .icon-arrow {
                margin-top: 5px;
            }
            .caption {
                margin-top: 7px;
            }
        }

        &.collapsed .icon-arrow {
            transform: rotateZ(-90deg);
        }

        .caption {
            position: relative;
            display: inline-block;
            //flex-grow: 1; // @NOTE: Disabled for the invalid state icon to appear right next to the caption
            padding: 0 0 0 12px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            pointer-events: none;
        }

        .children {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            row-gap: 5px;
            position: absolute;
            width: auto;
            height: auto;
            top: 39px;
            left: 8px;
            right: 0;

            & > div {
                width: 100%;
                align-items: center;
            }
        }

        &.global {
            padding: 0;

            .caption {
                position: sticky;
                padding: 0;
                flex-grow: 0;
                line-height: 34px;
                left: 12px;
                align-self: flex-start;

                .icon-arrow {
                    margin-right: 9px;
                }
            }
        }

        &:hover,
        &.selected,
        &.selected:hover {
            background-color: var(--color-item-lightgrey-hover);
            border-color: var(--color-item-lightgrey-hover);
        }

        &.drag-over,
        &.can-drop.drop-after {
            // Stretch the background across all cells
            height: calc(100% - 2px) !important;
        }

        &,
        &.disabled,
        &.disabled:hover {
            color: var(--font-color-dark);
            background-color: var(--color-item-lightgrey);
            border-color: var(--color-item-lightgrey);
        }

        // Prevent group from dragging onto its child elements
        &.dragging {
            .children,
            .children * {
                pointer-events: none !important;
            }
        }
    }

    .sticky-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        flex-basis: 100%;
        height: 100%;
        max-width: 100%;
        margin-right: -100%;
        padding-left: 12px;
        pointer-events: none;

        .caption {
            display: flex;
            align-items: center;
            max-width: 100%;
        }
    }

    .cell-column {
        position: relative;
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 0;
        padding: 0 2px 0 1px;   // @NOTE: To "fix" the visible gap in background colors
        height: 100%;
        overflow: visible;
        vertical-align: middle;
        pointer-events: none;

        // For inner alignments:
        display: flex;
        flex-direction: row;
        align-items: center;

        &.is-first {
            padding-left: 0;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &.is-last {
            padding-right: 0;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &:not(.is-last):after {
            content: '';
            pointer-events: none;
            position: absolute;
            right: 0;
            margin-right: -1px;
            display: inline-block;
            height: 100%;
            width: 1px;
            border-right: 2px dotted rgba(255, 255, 255, 0.5);
        }
    }

</style>

<style lang="scss">
    body.is-dragging-group-cell .group-cell {
        // Stretch the background across all cells
        height: calc(100% - 2px) !important;

        // Prevent group from dragging onto its child elements
        .children,
        .children * {
            pointer-events: none !important;
        }
    }
</style>

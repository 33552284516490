<template>
    <div class="trigger-property property">
        <Dropdown
            class="no-wrap"
            :label="trans('authoring.when_retriggered')"
            :model="trigger"
            property="when_retriggered"
            :options="getRetriggerOptions"
            @select="onTriggerChanged"
        />
    </div>
</template>

<script>

import Trigger                  from '@/Models/UnitData/Triggers/Trigger';
import DropdownOption           from '@/Utility/DropdownOption';
import RetriggerType            from '@/Models/UnitData/Triggers/RetriggerType';

export default {
    name: 'PanelTriggerPropertyRetrigger',
    emits: [
        'change',
    ],
    props: {
        trigger: Trigger,
    },
    computed: {

        getRetriggerOptions() {
            const options = [];
            const retriggerTypes = RetriggerType.all;

            retriggerTypes.forEach(t => {
                options.push(new DropdownOption({
                    caption: t.title,
                    disabled: false,
                    value: t.type
                }));
            });

            return options;
        },
    },
    methods: {

        /**
         * Change handler for trigger properties
         *
         */
        onTriggerChanged() {
            this.$emit('change', this.trigger);
            return this;
        },

    },
}
</script>

<style lang="scss" scoped>

</style>

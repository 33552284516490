<template>
    <main id="layout-main" :data-loading="isLoading" :data-saving="isSaving" v-shortcuts.prevent>
        <PageHeader
            :page-title="trans('units.index.headline')"
            :buttons="headerButtons"
        />

        <div id="layout-content">
            <div id="content" ref="content" @scroll="onScrollList" v-not-focusable>
                <div class="container list-filters-wrapper">
                    <ListFiltersBar
                        ref="listFiltersBar"
                        :categories="tabFilterCategories"
                        :filters="Object.values(filterCategories)"
                        :sorting-filters="sortingFilters"
                        :sort-descending="sortDescending"
                        :filter-words="searchKeywords"
                        :show-search-button="true"
                        @change="applyFilters"
                        @reset="onResetFilters"
                        @trigger-search="onTriggerSearch"
                    >
                        <template v-slot:buttons>
                            <ButtonPrimary
                                v-tooltip="'buttons.units.create'"
                                v-if="canCreateNewUnit"
                                :href="route('units.createForm')"
                                caption="units.index.btn_create"
                                class="btn-add-new"
                                icon="icon_add"
                            />
                        </template>
                    </ListFiltersBar>
                </div>

                <!-- List of units -->
                <div class="container unit-list">

                    <UnitListItem
                        v-focusable
                        v-for="unit in unitsToRender"
                        :key="'unit_' + unit.uid + unit.latest_revision_uid"
                        :unit="unit"
                        :selected="(selectedUnit && selectedUnit.uid === unit.uid)"
                        @focus="onClickUnit"
                        @click="onClickUnit"
                        @dblclick="onClickUnitEdit"
                        @click-edit="onClickUnitEdit"
                        @click-duplicate="onClickUnitDuplicate"
                        @click-import-template="onClickUnitImportTemplate"
                        @click-delete="onClickUnitDelete"
                    />

                    <template v-if="!isLoading && unitsToRender.length === 0">
                        <NoItemsFound v-if="searchKeywords" />
                        <NoItemsAvailable v-else />
                    </template>

                </div>

                <!-- Pagination -->
                <Pagination
                    v-if="units.length > 0"
                    :currentPage="pagingMetadata.current_page"
                    :numberOfPages="pagingMetadata.last_page"
                    @click="onPageClicked"
                />

            </div>

            <aside id="layout-inspector" :class="{ 'open': selectedUnit }">
                <SidepanelViewUnit
                    v-if="selectedUnit"
                    :key="'panel-unit-'+selectedUnit.uid"
                    :unit="selectedUnit"
                    :showAuthorAssignment="true"
                />
            </aside>

            <aside :class="{ 'layout-sidepanel': true, 'open': showSidePanel }">
                <SidepanelUsers
                    :is-visible="showUsers"
                    :users="unassignedAuthors"
                    headlineLabel="labels.authors"
                />
            </aside>

            <!-- Modal dialog for deleting a unit -->
            <ModalApplyCancel
                event-type="MODAL_DELETE_UNIT"
                :title="trans('modals.delete_unit.title')"
                :description="descriptionTextDelete"
                :applyText="trans('modals.delete_unit.apply')"
                :cancelText="trans('modals.delete_unit.cancel')"
            />

            <!-- Model dialog for duplicating a unit -->
            <ModalDuplicateUnit/>

            <!-- Model dialog for importing a unit as template -->
            <ModalImportTemplateUnit/>

            <!-- Modal dialog for removing all assigned users -->
            <ModalApplyCancel
                event-type="MODAL_REMOVE_AUTHORS_FROM_UNIT"
                :title="trans('modals.remove_authors_from_unit.title')"
                :description="trans('modals.remove_authors_from_unit.description')"
            />

            <ModalProgress/>
            <ModalNotification/>
        </div>
    </main>
</template>

<script>

    // Import VueJS components:
    import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
    import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
    import ModalDuplicateUnit from '@/Vue/Modals/ModalDuplicateUnit.vue';
    import UnitListItem from '@/Vue/Units/UnitListItem.vue';
    import SidepanelUsers from '@/Vue/Sidepanel/SidepanelUsers.vue';
    import SidepanelViewUnit from '@/Vue/Sidepanel/SidepanelViewUnit.vue';
    import ListFiltersBar from "@/Vue/Common/ListFiltersBar.vue";
    import NoItemsAvailable from "@/Vue/Search/NoItemsAvailable.vue";
    import NoItemsFound from "@/Vue/Search/NoItemsFound.vue";
    import ModalImportTemplateUnit from "@/Vue/Modals/ModalImportTemplateUnit.vue";

    // Import classes:
    import AuthorizationError from '@/Errors/AuthorizationError';
    import EventType from '@/Utility/EventType';
    import {permission, route, trans, sortArrayByProperty} from '@/Utility/Helpers';
    import {Permission} from '@/Models/User/Permission';
    import Unit from '@/Models/Unit/Unit';
    import MultiFilterCategory from "@/Filters/MultiFilterCategory";
    import SortingFilters from "@/Filters/SortingFilters";
    import FilterSection from "@/Filters/FilterSection";
    import UnitType from "@/Models/UnitData/UnitType";
    import UnitFilters from "@/Filters/UnitFilters";
    import {UnitPermissionPolicySample} from "@/Models/Unit/UnitPermissionPolicy";
    import {inject} from "vue";
    import {unitServiceKey, userServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
    import PageHeaderButton from "@/Utility/PageHeaderButton";
    import ModalProgress from "@/Vue/Modals/ModalProgress.vue";

    export default {
        name: 'PageUnitList',
        components: {
            ModalProgress,
            ModalImportTemplateUnit,
            NoItemsFound,
            NoItemsAvailable,
            ListFiltersBar,
            SidepanelUsers,
            SidepanelViewUnit,
            ModalApplyCancel,
            ModalDuplicateUnit,
            ModalNotification,
            UnitListItem,
        },
        data() {
            return {

                /**
                 * The current list of units from the service
                 * @type Unit[]
                 */
                units: [],

                unitService: inject(unitServiceKey),
                userService: inject(userServiceKey),

                /**
                 * Whether the users are visible in the side panel
                 * @type boolean
                 */
                showUsers: false,

                /**
                 * @type Unit
                 */
                selectedUnit: null,

                /**
                 * Content scroll position for restoring after save
                 * @type Object
                 */
                scrollPos: {
                    x: 0,
                    y: 0
                },

                /**
                 * Whether the side panel is visible
                 * @type Boolean
                 */
                showSidePanel: false,

                /**
                 * List of assigned authors for the selected unit
                 */
                assignedAuthors: [],

                /**
                 * List of unassigned authors for the selected unit
                 */
                unassignedAuthors: [],

                /**
                 * Shortcut mapping to methods
                 * @type Map
                 */
                shortcuts: new Map([
                    ['Save.global.prevent', null],              // Prevent browser saving
                    ['Backspace.global.prevent', null],         // Prevent going back in browser history
                    ['Enter', this.onShortcutEnter],            // Open unit
                    ['Delete', this.onShortcutDelete],          // Delete unit
                    ['Duplicate', this.onShortcutDuplicateOrImport], // Duplicate/import unit
                ]),

                /**
                 * Helpers for showing the unit title in generic modal dialogs
                 */
                descriptionTextDelete: trans('modals.delete_unit.description'),

                /**
                 * How many items to display initially
                 */
                renderItemsLoaded: 20,

                /**
                 * How many items to add at once when scrolling
                 */
                renderItemsPerPage: 20,

                /**
                 * Filter categories for navigation tabs
                 *
                 * @var {FilterCategory[]}
                 */
                tabFilterCategories: [
                    UnitFilters.PolicyStandard.setActive(true, true),
                    UnitFilters.PolicySample,
                    UnitFilters.PolicyTemplate,
                ],

                /**
                 * Filter categories
                 *
                 * @var {Object<MultiFilterCategory>}
                 */
                filterCategories: {
                    type: new MultiFilterCategory(
                            trans('labels.type'),
                            this.filterSectionsByType(),
                            null
                        ).setActive(true, true),
                    status: new MultiFilterCategory(
                            trans('labels.status'),
                            this.filterSectionsByStatus(),
                            null
                        ).setActive(true, true)
                },

                /**
                 * Filters for sorting
                 */
                sortingFilters: [
                    SortingFilters.Alphabetical,
                    SortingFilters.CreatedAt,
                    SortingFilters.UpdatedAt.setActive(true, true),
                ],

                /**
                 * Sorting order
                 */
                sortDescending: true,

                /**
                 * Search keywords from the search input
                 * @type String
                 */
                searchKeywords: null,
            }
        },
        mounted() {

            // Fetch users:
            if (this.canAccessUsers)
            {
                this.userService.fetchUsers().then(this.onSuccessFetchUsers).catch(this.onErrorApi);
            }

            // Add global events:
            this.$globalEvents.addEvent('click.global.unit-list', this.onClickGlobal);
            this.$globalEvents.on(EventType.MODAL_DUPLICATE_UNIT_APPLY, this.onApplyDuplicateUnit);
            this.$globalEvents.on(EventType.MODAL_IMPORT_TEMPLATE_UNIT_APPLY, this.onApplyImportTemplateUnit);
            this.$globalEvents.on(EventType.MODAL_DELETE_UNIT_SHOW, this.onShowDeleteUnit);
            this.$globalEvents.on(EventType.MODAL_DELETE_UNIT_APPLY, this.onApplyDeleteUnit);
            this.$globalEvents.on(EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload);
            this.$globalEvents.on(EventType.AUTHOR_ASSIGNMENT_AUTHORS_CHANGED, this.onChangeAuthorAssignment);
            this.$globalEvents.on(EventType.SIDEPANEL_USERS_ASSIGN, this.onAssignAuthorsToUnit);
            this.$globalEvents.on(EventType.SIDEPANEL_USERS_SHOW, this.onShowUsersSidePanel);
            this.$globalEvents.on(EventType.SIDEPANEL_USERS_HIDE, this.onHideUsersSidePanel);
            this.$globalEvents.on(EventType.SIDEPANEL_USERS_CANCEL, this.onHideUsersSidePanel);
        },
        beforeUnmount() {
            // Remove global events:
            this.$globalEvents.removeEvent('click.global.unit-list', this.onClickGlobal);
            this.$globalEvents.off(EventType.MODAL_DUPLICATE_UNIT_APPLY, this.onApplyDuplicateUnit);
            this.$globalEvents.off(EventType.MODAL_IMPORT_TEMPLATE_UNIT_APPLY, this.onApplyImportTemplateUnit);
            this.$globalEvents.off(EventType.MODAL_DELETE_UNIT_SHOW, this.onShowDeleteUnit);
            this.$globalEvents.off(EventType.MODAL_DELETE_UNIT_APPLY, this.onApplyDeleteUnit);
            this.$globalEvents.off(EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload);
            this.$globalEvents.off(EventType.AUTHOR_ASSIGNMENT_AUTHORS_CHANGED, this.onChangeAuthorAssignment);
            this.$globalEvents.off(EventType.SIDEPANEL_USERS_ASSIGN, this.onAssignAuthorsToUnit);
            this.$globalEvents.off(EventType.SIDEPANEL_USERS_SHOW, this.onShowUsersSidePanel);
            this.$globalEvents.off(EventType.SIDEPANEL_USERS_HIDE, this.onHideUsersSidePanel);
            this.$globalEvents.off(EventType.SIDEPANEL_USERS_CANCEL, this.onHideUsersSidePanel);
        },
        computed: {

            /**
             * @returns {Boolean}
             */
            canAccessUsers() {
                return permission(Permission.UsersRead());
            },

            /**
             * @returns {Boolean}
             */
            canCreateNewUnit() {
                return permission(Permission.UnitsCreate());
            },

            /**
             * @returns {Boolean}
             */
            canImportUnits() {
                return permission(Permission.UnitsImport());
            },

            /**
             * @returns {Boolean}
             */
            canExportUnits() {
                return permission(Permission.UnitsExport());
            },

            /**
             * List of button configurations for the page header
             */
            headerButtons() {
                const buttons = {};

                if (this.canImportUnits) {
                    buttons.importUnits = new PageHeaderButton({
                        disabled: this.isLoading || this.isSaving,
                        visible: this.canImportUnits,
                        caption: trans('labels.import'),
                        icon: 'icon_import',
                        href: route('units.imports.index'),
                    });
                }

                if (this.canExportUnits) {
                    buttons.exportUnits = new PageHeaderButton({
                        disabled: this.isLoading || this.isSaving,
                        visible: this.canExportUnits,
                        caption: trans('labels.export'),
                        icon: 'icon_export',
                        href: route('units.showUnitsForExport'),
                    });
                }

                return buttons;
            },

            /**
             * @return {PagingMetadata} the current metadata about the page from the service
             */
            pagingMetadata() {
                return this.unitService.unitPage.pagingMetadata;
            },

            /**
             * @returns {Boolean}
             */
            isLoading() {
                if (this.unitService.isLoading || this.userService.isLoading)
                {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            },

            /**
             * @returns {Boolean}
             */
            isSaving() {
                if (this.unitService.isSaving || this.userService.isSaving)
                {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            },

            /**
             * @returns {Unit[]}
             */
            unitsToRender() {
                return this.units.slice(0, this.renderItemsLoaded);
            }
        },
        methods: {

            /**
             * @param {Unit} unit
             * @returns {Boolean}
             */
            canDeleteUnit(unit) {
                return this.$gate.allows(Permission.ability(Permission.UnitsDelete()), unit);
            },

            /**
             * @param {Unit} unit
             * @returns {Boolean}
             */
            canDuplicateUnit(unit) {
                return this.$gate.allows(Permission.ability(Permission.UnitsDuplicate()), unit);
            },

            /**
             * @param {Unit} unit
             * @returns {Boolean}
             */
            canImportUnitAsTemplate(unit) {
                return this.$gate.allows(Permission.ability(Permission.UnitsImportTemplate()), unit);
            },

            /**
             * @param {Unit} unit
             * @returns {Boolean}
             */
            canUpdateUnit(unit) {
                return this.$gate.allows(Permission.ability(Permission.UnitsUpdate()), unit);
            },

            /**
             * Fetch units for the current user from API
             * @param {number} page
             */
            fetchUnitsPage(page = 1) {
                const filters = Object.fromEntries(
                    Object.entries(this.filterCategories).map(
                        ([k, v]) => [k, v.activeSections.map(s => s.category.paramName)]
                    )
                );
                filters.policy = this.tabFilterCategories.filter(f => f.isActive).map(f => f.paramName);
                const orderBy = this.sortingFilters.find(f => f.active)?.paramName || null;
                this.unitService
                    .fetchUnits(page, null, filters, this.searchKeywords, orderBy, this.sortDescending)
                    .then(this.onSuccessFetchUnits)
                    .catch(this.onErrorApi);
                return this;
            },

            /**
             * Success handler for loading units
             * @param {UnitPage} unitPage
             */
            onSuccessFetchUnits(unitPage) {
                //console.log('onSuccessFetchUnits', unitPage);

                // Reset scrolling list
                this.renderItemsLoaded = this.renderItemsPerPage;

                // Scroll to top:
                this.$refs.content.scrollTo(0,0);

                this.units = unitPage.unitList;

                // Reselect the current unit (if one is selected, and it is on the current page)
                if (this.selectedUnit !== null && this.unitsToRender.some(u => u.uid === this.selectedUnit.uid)) {
                    this.selectUnit(this.selectedUnit);
                }

                // Select the first unit by default because it's the latest:
                else if (this.units.length >= 1) {
                    this.selectUnit(this.units[0]);

                // Deselect any unit
                } else {
                    this.selectedUnit = null;
                }

                return this;
            },

            /**
             * Error handler for API errors
             * @param {String} error
             */
            onErrorApi(error) {
                // Force logout for authorization errors:
                if (error instanceof AuthorizationError) {
                    error.callback = this.$root.forceLogout;
                }
                this.$root.showErrorDialog(error);
                return this;
            },

            /**
             * Called when the user triggered a page change.
             * @param {number} page
             */
            onPageClicked(page) {
                this.fetchUnitsPage(page);
                return this;
            },

            /**
             * Click event handler for unit
             * @param {Unit} unit
             */
            onClickUnit(unit) {
                this.selectUnit(unit);
                return this;
            },

            /**
             * Click event handler for unit deleting
             * @param {Unit} unit
             */
            onClickUnitDelete(unit) {
                if (this.canDeleteUnit(unit)) {
                    this.$globalEvents.emit(EventType.MODAL_DELETE_UNIT_SHOW, unit);
                }
                return this;
            },

            /**
             * Click event handler for unit duplicating
             * @param {Unit} unit
             */
            onClickUnitDuplicate(unit) {
                if (this.canDuplicateUnit(unit)) {
                    this.$globalEvents.emit(EventType.MODAL_DUPLICATE_UNIT_SHOW, unit);
                }
                return this;
            },

            /**
             * Click event handler for importing template units
             * @param {Unit} unit
             */
            onClickUnitImportTemplate(unit) {
                if (this.canImportUnitAsTemplate(unit)) {
                  this.$globalEvents.emit(EventType.MODAL_IMPORT_TEMPLATE_UNIT_SHOW, unit);
                }
                return this;
            },

            /**
             * Opens the given unit for editing (if allowed)
             * @param {Unit} unit
             */
            onClickUnitEdit(unit) {
                if (this.canUpdateUnit(unit)) {
                    window.location.href = this.$root.route('units.edit', {'unit': unit.uid});
                }
                return this;
            },

            /**
             * Select a specific unit for editing in the side panel
             * @param {Unit} unit
             */
            selectUnit(unit) {
                // Only select the unit if it's not already selected:
                if (this.selectedUnit === null || this.selectedUnit.uid !== unit.uid) {
                    this.selectedUnit = new Unit(unit);    // @NOTE: Creating a new instance so we can later revert any changes
                    this.setAuthorAssignments();
                    this.$globalEvents.emit(EventType.INSPECTOR_INSPECT, this.selectedUnit);
                }
                return this;
            },

            /**
             * Update the users side panel
             */
            setAuthorAssignments() {
                if (this.selectedUnit !== null)
                {
                    this.assignedAuthors = this.userService.getAssignedAuthorsForUnit(this.selectedUnit);
                    this.unassignedAuthors = this.userService.getUnassignedAuthorsForUnit(this.selectedUnit);
                }
                else
                {
                    this.assignedAuthors = [];
                    this.unassignedAuthors = [];
                }
                return this;
            },

            /**
             * Assign authors to selected unit
             *
             * @param {User[]} users
             */
            onAssignAuthorsToUnit(users) {
                this.onHideUsersSidePanel();
                // authors will be added in {PanelAuthorAssignment} component
                return this;
            },

            /**
             * Success handler for loading users
             *
             * @param {User[]} users
             */
            onSuccessFetchUsers(users) {
                //console.log('onSuccessFetchUsers', users);
                // Update the author assignments:
                this.setAuthorAssignments();
                return this;
            },

            /**
             * @param {Event} e
             */
            onScrollList(e) {
                // Maximum number of items showing already
                if (this.renderItemsLoaded >= this.units.length) {
                    return this;
                }
                const list = e.target;
                if (list.scrollTop >= (list.scrollHeight - list.offsetHeight - (list.offsetHeight * 0.85))) {
                    this.renderItemsLoaded += this.renderItemsPerPage;
                }
                return this;
            },

            /**
             * Show the users in the side panel
             */
            onShowUsersSidePanel() {
                this.showUsers = true;
                this.showSidePanel = true;
                return this;
            },

            /**
             * Hide the users side panel
             */
            onHideUsersSidePanel() {
                if (this.showUsers === true) {
                    this.showUsers = false;
                    this.showSidePanel = false;
                }
                return this;
            },

            /**
             * User assignment on the currently selected training has changed.
             */
            onChangeAuthorAssignment() {
                // Update list of assigned users:
                this.setAuthorAssignments();
            },

            /**
             * Apply handler for duplicating a unit
             *
             * @param {Unit} unit
             * @param {String} newTitle
             * @param {Boolean} keepAssignedAuthors
             */
            onApplyDuplicateUnit(unit, newTitle, keepAssignedAuthors) {
                this.unitService
                    .duplicateUnit(unit, newTitle, keepAssignedAuthors)
                    .then(this.onDuplicateUnitSuccess)
                    .catch(this.onErrorApi);
                return this;
            },

            /**
             * Apply handler for importing a unit as template
             *
             * @param {Unit} unit
             * @param {String} newTitle
             */
            onApplyImportTemplateUnit(unit, newTitle) {
                this.unitService
                    .importUnitAsTemplate(unit, newTitle)
                    .then(this.onImportTemplateUnitSuccess)
                    .catch(this.onErrorApi);
                return this;
            },

            /**
             * @param {Unit} unit
             */
            onDuplicateUnitSuccess(unit) {
                if (this.selectedUnit.policy === UnitPermissionPolicySample.type) {
                    this.$toast.success(trans('units.import.from_template_success'));
                }
                // Load the first page since the newly duplicated unit will be the latest
                this.fetchUnitsPage(1);
                return this;
            },

            /**
             * @param {Unit} unit
             */
            onImportTemplateUnitSuccess(unit) {
                this.$toast.success(trans('units.import.from_template_success'));
                // Load the first page since the newly duplicated unit will be the latest
                this.fetchUnitsPage(1);
                return this;
            },

            /**
             * Show handler for deleting a unit
             *
             * @param {Unit} unit
             */
            onShowDeleteUnit(unit) {
                this.descriptionTextDelete = trans('modals.delete_unit.description', {title: unit.latestRevision.title});
                return this;
            },

            /**
             * Apply handler for deleting a unit
             *
             * @param {Unit} unit
             */
            onApplyDeleteUnit(unit) {
                this.unitService
                    .deleteUnit(unit)
                    .then(this.onDeleteUnitSuccess)
                    .catch(this.onErrorApi);
                return this;
            },

            /**
             * Success handler for deleting the selected unit
             *
             * @param {Unit} unit
             */
            onDeleteUnitSuccess(unit) {
                // Refresh the unit list
                const pageToLoad = this.units.length > 1 // Are there more units on the current page?
                    ? this.pagingMetadata.current_page // Yes -> Load updated units for the current page
                    : this.pagingMetadata.current_page > 0 // No -> Are there still more pages?
                        ? this.pagingMetadata.current_page - 1 // Yes -> Load previous page
                        : 1; // No -> Load first page
                this.fetchUnitsPage(pageToLoad);
                return this;
            },

            /**
             * Click handler for global events
             *
             * @param {MouseEvent} e
             */
            onClickGlobal(e) {
                // Close users side panel:
                if (
                    this.showUsers === true
                    && !e.target.matches('.btn.assign-users')
                    && !document.getElementById('sidepanel-users').contains(e.target)
                )
                {
                    this.onHideUsersSidePanel();
                }
                return this;
            },

            /**
             * Shortcut handler for deleting a unit
             *
             * @param {CustomEvent} e
             */
            onShortcutDelete(e) {
                if (
                    this.selectedUnit !== null
                    && this.canDeleteUnit(this.selectedUnit)
                    && document.getElementById('content').contains(document.activeElement)
                )
                {
                    this.$globalEvents.emit(EventType.MODAL_DELETE_UNIT_SHOW, this.selectedUnit);
                }
                return this;
            },

            /**
             * Shortcut handler for duplicating/importing a unit.
             */
            onShortcutDuplicateOrImport() {
                if (this.selectedUnit !== null && document.getElementById('content').contains(document.activeElement)) {
                    if (this.canDuplicateUnit(this.selectedUnit)) {
                        this.$globalEvents.emit(EventType.MODAL_DUPLICATE_UNIT_SHOW, this.selectedUnit);
                    } else if (this.canImportUnitAsTemplate(this.selectedUnit)) {
                        this.$globalEvents.emit(EventType.MODAL_IMPORT_TEMPLATE_UNIT_SHOW, this.selectedUnit);
                    }
                }
            },

            /**
             * Shortcut handler for opening a unit
             *
             * @param {CustomEvent} e
             */
            onShortcutEnter(e) {
                // Only open the unit if the focus is on the list:
                if (
                    this.selectedUnit !== null
                    && this.canUpdateUnit(this.selectedUnit)
                    && document.activeElement.matches('.unit-list-item')
                )
                {
                    this.onClickUnitEdit(this.selectedUnit);
                }
                return this;
            },

            onResetFilters() {
                this.searchKeywords = null;
                this.sortDescending = true;
                this.$refs.listFiltersBar.setFocus();
                this.fetchUnitsPage(1);
                return this;
            },

            filterSectionsByType() {
                return sortArrayByProperty(
                    UnitType.all.map(
                        t => new FilterSection(t.title, t.filterCategory.setActive(true, true))
                    ),
                    'title'
                );
            },

            filterSectionsByStatus() {
                return [
                    UnitFilters.Draft,
                    UnitFilters.Released,
                    UnitFilters.HasUnreleasedChanges,
                ].map(f => new FilterSection(f.title, f.setActive(true, true)));
            },

            /**
             * Apply filters
             *
             * @param {FilterCategory|String|Object|Null} filterObj
             */
            applyFilters(filterObj = undefined) {
                // Store filter keywords text:
                if ((filterObj === null || typeof filterObj === 'string') && filterObj !== this.searchKeywords)
                {
                    this.searchKeywords = filterObj;
                }

                // Order by ascending/descending:
                if (typeof filterObj?.descending === 'boolean') {
                    this.sortDescending = filterObj.descending;
                }

                // Trigger the search:
                if (filterObj instanceof Object)
                {
                    this.fetchUnitsPage(1);
                }

                return this;
            },

            onTriggerSearch(e, keywords) {
                this.searchKeywords = keywords;
                this.fetchUnitsPage(1);
                return this;
            },

            /**
             * Before unload handler
             *
             * @param {BeforeUnloadEvent} e
             */
            onBeforeUnload(e) {
                this.unitService.cancelRequests();
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return this;
            },
        },
        watch: {
            selectedUnit(newUnit, oldUnit) {
                // Refresh the selected unit in the UnitPage to update the UI
                const index = this.units.findIndex(u => u.uid === this.selectedUnit?.uid);
                if (index >= 0)
                {
                    this.units.splice(index, 1, this.selectedUnit);
                }
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <span class="panel-trigger-keypress-custom-title">
        <span @click.stop class="caption">
            {{ title }}
        </span>
        <span class="keypress-input">
            <input
               type="text"
               :placeholder="trans('labels.key')"
               :value="getKeyPressTriggerValue"
               @click.stop=""
               @keydown="onKeyDownTriggerKeyPress($event)"
               @keypress="onKeyDownTriggerKeyPress($event)"
            />
        </span>
    </span>
</template>

<script>
import Trigger      from '@/Models/UnitData/Triggers/Trigger';
import KeyboardKey  from '@/Utility/KeyboardKey';

export default {
    name: 'PanelTriggerKeypressCustomTitle',
    emits: [
        'change',
    ],
    props: {
        trigger: Trigger,
        title: String,
    },
    computed: {

        /**
         * Get trigger key value
         *
         * @returns {String}
         */
        getKeyPressTriggerValue() {
            return (this.trigger.value !== null && this.trigger.value instanceof KeyboardKey) ? this.trigger.value.key : '';
        },
    },
    methods: {

        /**
         * KeyDown handler for KeyPress trigger
         *
         * @param {KeyboardEvent} keyboardEvent
         */
        onKeyDownTriggerKeyPress(keyboardEvent) {
            const key = KeyboardKey.findByEvent(keyboardEvent);
            // Only allow specific keys:
            if (
                key !== null
                &&
                (
                    KeyboardKey.KeySets.TriggerKeyPress.find(k => key.code === k.code) === undefined
                    ||
                    (
                        key.code.indexOf('Numpad') === 0
                        && keyboardEvent.getModifierState('NumLock') === false
                    )
                )
            )
            {
                keyboardEvent.preventDefault();
                keyboardEvent.stopPropagation();
                return this;
            }

            this.trigger.value = (key !== null && key.isUnidentified === false) ? new KeyboardKey(key) : null;
            // Make sure keypress event is used if no valid key is found on the keydown event:
            if (key === null || key.isUnidentified === false || keyboardEvent.type === 'keypress')
            {
                keyboardEvent.preventDefault();
            }
            // Update the input value:
            keyboardEvent.currentTarget.value = this.trigger.value || '';
            this.$emit('change', this);
            return this;
        },
    }
}
</script>

<style lang="scss" scoped>
    .panel-trigger-keypress-custom-title {
        display: flex;
        align-items: center;
        flex-grow: 1;
    }
</style>

<template>
    <Collapsible
        class="inspector-panel panel-start-position panel-transform"
        :initial-collapsed="false"
        :key="'panel_start_position_'+ refreshUI"
    >

        <template #header>
            {{ trans('labels.start_position') }}
        </template>

        <template #body>
            <PanelPosition
                v-if="transform"
                :position="transform.position"
                @change="onChangeProperty"
            />
            <PanelRotation
                v-if="transform"
                :rotation="transform.rotation"
                @change="onChangeProperty"
            />

            <ButtonPrimary v-if="startPoint === null" icon="icon_add" class="btn-set-start-position" caption="labels.start_position_set" @trigger="onAddStartPoint" />
            <ButtonPrimary v-else-if="showResetButton" class="btn-reset-start-position" caption="labels.start_position_reset" @trigger="onRemoveStartPoint" />
        </template>

    </Collapsible>
</template>

<script>
    // Import VueJS components:
    import ButtonPrimary from "@/Vue/Common/ButtonPrimary";
    import PanelPosition from '@/Vue/Inspector/PanelPosition';

    // Import classes:
    import StartPointComponent from "@/Models/Unity/Components/StartPointComponent";
    import TrainingScene from "@/Models/UnitData/Scenes/TrainingScene";
    import {shortId} from "@/Utility/Helpers";
    import PanelRotation from "@/Vue/Inspector/PanelRotation.vue";

    export default {
        name: 'PanelStartPosition',
        emits: [
            'change',
        ],
        components: {
            ButtonPrimary,
            PanelPosition,
            PanelRotation,
        },
        props: {
            scene: {
                type: TrainingScene,
                default: null,
            },
        },

        data() {
            return {
                refreshUI: shortId(),
            };
        },

        computed: {

            showResetButton() {
                return !this.startPoint?.transform.isDefault;
            },

            startPoint() {
                return this.scene && this.scene.getComponentsByType(StartPointComponent.type)[0] || null;
            },

            transform() {
                return this.startPoint?.transform || null;
            },
        },

        methods: {
            addStartPoint() {
                this.scene.components.push(new StartPointComponent());
            },

            removeStartPoint() {
                this.scene.components = this.scene.components.filter(component => component.type !== StartPointComponent.type);
            },

            onChangeProperty() {
                if (this.scene) {
                    this.$emit('change', this.scene);
                }

                return this;
            },

            onAddStartPoint() {
                this.addStartPoint();
                this.onChangeProperty();
                this.refreshUI = shortId();
            },

            onRemoveStartPoint() {
                this.removeStartPoint();
                this.addStartPoint();
                this.onChangeProperty();
                this.refreshUI = shortId();
            },
        }
    }
</script>

<style lang="scss" scoped>
    .btn-set-start-position,
    .btn-reset-start-position {
        width: 100%;
    }
</style>

<template>
    <div class="command-settings">
        <Dropdown
            :initial-value="command.value.type"
            @select="onSelectFadeValue"
            :options="dropdownOptionsForFadeValues"
            :required="true"
            :deselected-caption="trans('authoring.select_fade')"
        />
    </div>
</template>

<script>
// Import helpers and functions:
import { sortArrayByProperty } from '@/Utility/Helpers';

// Import classes:
import DropdownOption           from '@/Utility/DropdownOption';
import {FadeCommand}              from '@/Models/UnitData/Commands/Command';

export default {
    name: 'PanelCommandFade',
    emits: [
        'change',
    ],
    props: {
        command: {                      // The command object to be edited
            type: FadeCommand,
            default: null
        }
    },
    computed: {
        /**
         * Get options for fade value dropdown
         *
         * @returns {DropdownOption[]}
         */
        dropdownOptionsForFadeValues() {
            const options = Object.entries(this.command?.commandType?.possibleValues || {})
                .map(
                    keyValuePair => new DropdownOption({
                        caption: keyValuePair[1],
                        disabled: false,
                        value: keyValuePair[0]
                    }
                )
            );
            return sortArrayByProperty(options, 'caption', false);
        },
    },
    methods: {
        /**
         * Select handler for fade value dropdown
         *
         * @param {String} value
         */
        onSelectFadeValue(value) {
            if (this.command.value === null || typeof this.command.value !== 'object' || value !== this.command.value.type)
            {
                this.command.value = {
                    type: value,
                    color: '000000FF'   // @TODO: Implement a color picker
                };
                this.$emit('change', this.command);
            }

            return this;
        }
    }
}
</script>

<template>
    <article class="statistics-card">

        <div v-if="isLoading" class="loading-indicator-wrapper">
            <LoadingIndicator/>
        </div>

        <slot v-else/>

    </article>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import LoadingIndicator from "@/Vue/Common/LoadingIndicator.vue";

export default defineComponent({
    name: "StatisticsCard",
    components: {LoadingIndicator},

    props: {
        isLoading: {
            type: Boolean,
            required: true,
        },
    },
});

</script>

<style lang="scss" scoped>

.statistics-card {
    background-color: var(--background-color-white);
    border-radius: var(--card-border-radius-small);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    .loading-indicator-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}

</style>

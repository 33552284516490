export default class InputConfiguration
{
    /**
     * Constructor
     *
     * @param {Object} attributes         // Properties data
     */
    constructor(attributes = {})
    {
        // Make sure attributes is always an object:
        attributes = (attributes instanceof Object && attributes instanceof Array === false) ? attributes : {};

        // Populate the model:
        this.style = (typeof attributes.style === 'string') ? attributes.style : 'controllers';          // Input style (e.g. "controllers")
    }

    /**
     * Duplicate
     *
     * @returns {InputConfiguration}
     */
    duplicate() {
        return new InputConfiguration(this);
    }
}

<template>
    <li @click="$emit('select')" :data-ug-title="object.title">
        <span v-if="supportsAssetPreviewModal" class="preview preview-image-pointer" @click.stop.prevent="showAssetPreview">
            <Icon :name="object.assetType.previewIcon" />
            <img :src="object.preview" alt="" />
        </span>
        <span v-else class="preview">
            <img :src="object.preview" alt="" />
        </span>

        <section class="info">
            <h4 class="title">{{ object.title }}</h4>
            <p class="description" :title="objectDescriptionTooltip">{{ objectDescription }}</p>
        </section>
    </li>
</template>

<script>

import { trans }                from '@/Utility/Helpers';
import EventType                from '@/Utility/EventType';
import Asset                    from '@/Models/Asset/Asset';
import SceneObjectType          from '@/Models/UnitData/SceneObjects/SceneObjectType';

export default {
    name: 'SidepanelSceneObjectsListItem',
    emits: [
        'select',
    ],
    props: {
        object: {
            type: [Asset, SceneObjectType],
            default() {
                return {};
            }
        }
    },

    computed: {
        /**
         * Is a given object an asset?
         *
         * @returns {Boolean}
         */
        isAsset() {
            return (this.object instanceof Asset);
        },

        /**
         * Get description substring
         *
         * @returns {String|Null}
         */
        objectDescription() {
            const maxlength = 100;
            let description = (typeof this.object.description === 'string' && this.object.description.length > maxlength) ? this.object.description.substr(0, maxlength) + '...' : this.object.description;

            if (this.isAsset) {
                return (typeof description === 'string') ? trans('assets.types.' + this.object.type) + ' - ' + description : trans('assets.types.' + this.object.type);
            }

            return description;
        },

        /**
         * Get description substring
         *
         * @returns {String|Null}
         */
        objectDescriptionTooltip() {
            const maxlength = 100;

            if (this.isAsset) {
                return (typeof this.object.description === 'string' && this.object.description.length > maxlength) ? trans('assets.types.' + this.object.type) + ' - ' + this.object.description : null;
            }

            return (typeof this.object.description === 'string' && this.object.description.length > maxlength) ? this.object.description : null;
        },

        /**
         * Does the asset support a preview modal
         *
         * @returns {Boolean}
         */
        supportsAssetPreviewModal() {
            return this.isAsset && (this.object.supportsPreview || this.object.hasPreviewImage);
        },
    },

    methods: {
        /**
         * Show an asset preview modal if the object supports it.
         */
        showAssetPreview() {
            if (this.supportsAssetPreviewModal)
            {
                this.$globalEvents.emit(EventType.MODAL_ASSET_PREVIEW_SHOW, this.object);
            }
            return this;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>

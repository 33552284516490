<template>
    <div class="learning-record-command-object">
        <div class="learning-record-command-object-dropdown">
            <Dropdown
                v-if="preset !== 'custom'"
                :key="'PanelCommandLearningRecord_object_preset_'+key"
                class="no-wrap"
                :model="this"
                property="preset"
                @select="onChangePreset"
                :required="true"
                :options="dropdownOptionsForLearningRecordObject"
            />

            <TextInput
                v-if="preset === 'custom'"
                class="no-wrap"
                :model="command.statement.object.definition.name"
                property="en-US"
                :required="true"
                :maxlength="100"
                :placeholder="trans('commands.learning_record.labels.object_placeholder')"
                @change="onChangeObject"
            />

            <ButtonCircular
                v-if="preset === 'custom'"
                class="small"
                icon="icon_delete"
                @trigger="resetObject"
            />

            <ButtonCircular
                v-tooltip="command.statement.object.id"
                class="small icon-copy"
                icon="icon_copy"
                @trigger="copyObjectId"
            />
        </div>

        <TextInput
            v-tooltip="trans('commands.learning_record.labels.object_id_tooltip')"
            v-if="preset === 'custom'"
            class="no-wrap learning-record-command-object-id"
            :readOnly="preset !== 'custom'"
            :key="'PanelCommandLearningRecord_object_id_'+key"
            :model="command.statement.object"
            property="id"
            :required="!command.isValid"
            :maxlength="100"
            :label="trans('commands.learning_record.labels.object_id')"
            :placeholder="trans('commands.learning_record.labels.object_id_placeholder')"
            @change="onChangeObject"
        />
    </div>
</template>

<script>
import {shortId, sortArrayByProperty, trans} from '@/Utility/Helpers';
import DropdownOption from '@/Utility/DropdownOption';
import Dropdown from "@/Vue/Common/Dropdown.vue";
import ButtonCircular from "@/Vue/Common/ButtonCircular.vue";
import TextInput from "@/Vue/Common/TextInput.vue";
import CommandType from "@/Models/UnitData/Commands/CommandType";
import {cloneDeep} from "lodash";
import {LearningRecordCommand} from "@/Models/UnitData/Commands/Command";

export default {
    name: 'PanelCommandLearningRecordObject',
    components: {TextInput, ButtonCircular, Dropdown},
    emits: [
        'change',
        'changeProperties',
    ],
    props: {
        command: {
            type: LearningRecordCommand,
            default: null
        }
    },
    data() {
        return {
            key: shortId(),
            preset: this.getInitialPreset(),
            object: CommandType.LearningRecord.defaultValue.object.id,
        };
    },
    computed: {
        isCurrentObjectPreset() {
            return this.isObjectPreset(this.command.statement.object.id);
        },

        /**
         * Get options for Learning Record object dropdown
         *
         * @returns {DropdownOption[]}
         */
        dropdownOptionsForLearningRecordObject() {
            const options = [
                new DropdownOption({
                    caption: 'This Unit',
                    disabled: false,
                    value: CommandType.LearningRecord.defaultValue.object.id,
                }),
            ];

            return [
                ...sortArrayByProperty(options, 'caption', false),
                new DropdownOption({
                    isSeparator: true,
                }),
                new DropdownOption({
                    caption: trans('commands.learning_record.labels.custom'),
                    disabled: false,
                    value: 'custom',
                })
            ];
        },
    },
    methods: {
        copyObjectId(trigger) {
            navigator.clipboard.writeText(this.command.statement.object.id);
            trigger.target.blur();
        },

        getInitialPreset() {
            return this.isObjectPreset(this.command.statement.object.id) ? this.command.statement.object.id : 'custom';
        },

        isObjectPreset(objectId) {
            return CommandType.LearningRecord.defaultValue.object.id === objectId;
        },

        onChangePreset(preset) {
            if (preset !== 'custom') {
                this.command.statement.object = this.getObjectForPreset(preset);
            } else {
                this.command.statement.object = {
                    id: "",
                    definition: {
                        name: {
                            "en-US": ""
                        },
                    },
                };
            }

            this.onChangeCommandProperties(this.command);

            return this;
        },

        onChangeObject() {
            this.preset = this.isCurrentObjectPreset ? this.command.statement.object.id : 'custom';

            if (this.preset !== 'custom') {
                this.onChangePreset(this.preset);
                return this;
            }

            this.onChangeCommand(this.command);

            return this;
        },

        getObjectForPreset(preset) {
            // There is only one preset at the moment
            switch (preset) {
                default:
                    return cloneDeep(CommandType.LearningRecord.defaultValue.object);
            }
        },

        resetObject() {
            this.preset = CommandType.LearningRecord.defaultValue.object.id;
            this.command.statement.object = cloneDeep(CommandType.LearningRecord.defaultValue.object)
            this.key = shortId();
            this.onChangeObject();
        },

        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },

        /**
         * Change handler for the command properties
         *
         * @param {LearningRecordCommand} command
         */
        onChangeCommandProperties(command) {
            this.$emit('changeProperties', this.command);
            this.key = shortId();
            return this;
        },
    },
}
</script>

<style scoped lang="scss">

</style>

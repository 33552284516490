<template>
    <main id="layout-main" :data-loading="isLoading">

        <PageHeader
            :page-title="trans('users.index.headline')"
            :buttons="headerButtons"
            @button-click="onHeaderButtonClick"
        />

        <div id="layout-content">
            <div id="content" v-not-focusable>
                <div class="container user-list-filters-wrapper">
                    <ButtonPrimary
                        v-if="canCreateUsers"
                        :href="route('users.createForm')"
                        caption="labels.create_user"
                        class="btn-add-new"
                        icon="icon_add"
                    />
                </div>
                <div class="container">
                    <table class="users-list" width="100%">

                        <tbody>
                        <UserListItem v-for="user in users" :key="user.uid" :user="user"/>
                        </tbody>
                    </table>
                </div>
            </div>

            <ModalProgress/>
            <ModalNotification/>

            <ModalImportUserCsv/>
            <ModalImportUsers/>

        </div>
    </main>
</template>

<script lang="ts">

    import EventType from '@/Utility/EventType';
    import AuthorizationError from '@/Errors/AuthorizationError';
    import {permission, route, trans} from '@/Utility/Helpers';
    import {Permission} from '@/Models/User/Permission';
    import {defineComponent, inject} from 'vue';
    import {userServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
    import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
    import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
    import PageHeader from '@/Vue/Common/PageHeader.vue';
    import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
    import UserListItem from '@/Vue/Users/UserListItem.vue';
    import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
    import PageHeaderButton from '@/Utility/PageHeaderButton';
    import ModalImportUserCsv from '@/Vue/Modals/ModalSelectImportUserCsv.vue';
    import ModalImportUsers from '@/Vue/Modals/ModalImportUsers.vue';

    export default defineComponent({

        components: {
            ModalImportUsers,
            ModalImportUserCsv,
            ModalProgress,
            UserListItem,
            ButtonPrimary,
            PageHeader,
            ModalNotification,
            ModalApplyCancel,
        },

        data() {
            return {
                userService: inject(userServiceKey)!,
            }
        },

        mounted() {
            this.fetchUsers();
        },

        computed: {

            canCreateUsers() {
                return permission(Permission.UsersCreate());
            },

            canExportUsers() {
                return permission(Permission.UsersExport());
            },

            canImportUsers() {
                return permission(Permission.UsersImport());
            },

            users() {
                return this.userService.users;
            },

            isLoading(): boolean {
                if (this.userService.isLoading) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            },

            /**
             * List of button configurations for the page header
             */
            headerButtons() {
                return {
                    importUsers: new PageHeaderButton({
                        disabled: this.isLoading,
                        visible: this.canImportUsers,
                        caption: trans('labels.import'),
                        icon: 'icon_import',
                        style: 'icon+text',
                        callback: this.importUserCSV,
                    }),
                    exportUsers: new PageHeaderButton({
                        disabled: this.isLoading,
                        visible: this.canExportUsers,
                        caption: trans('labels.export'),
                        icon: 'icon_export',
                        style: 'icon+text',
                        callback: this.downloadExportedUsersCSV,
                    }),
                };
            },
        },

        methods: {
            trans,
            route,

            fetchUsers() {
                this.userService
                    .fetchUsers()
                    .catch(this.onErrorApi);
            },

            onErrorApi(error: any) {
                // Force logout for authorization errors:
                if (error instanceof AuthorizationError) {
                    error.callback = this.$root?.forceLogout;
                }

                this.$root?.showErrorDialog(error);
            },

            onHeaderButtonClick(buttonConfig: PageHeaderButton) {
                buttonConfig.callback?.call(this, buttonConfig);
            },

            importUserCSV() {
                this.$globalEvents.emit(EventType.MODAL_SELECT_IMPORT_USER_CSV_SHOW);
            },

            downloadExportedUsersCSV() {
                if (this.canExportUsers) {
                    this.userService
                        .downloadExportedUsersCSV()
                        .catch(this.onErrorApi);
                }
            },
        }
    });
</script>

<style lang="scss" scoped>

</style>

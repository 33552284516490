<template>
    <div class="learning-record-command-verb">
        <div class="learning-record-command-verb-dropdown">
            <Dropdown
                v-if="preset !== 'custom'"
                :key="'PanelCommandLearningRecord_verb_preset_'+key"
                class="no-wrap"
                :label="trans('commands.learning_record.labels.verb_label')"
                :model="this"
                property="preset"
                @select="onChangePreset"
                :required="true"
                :options="dropdownOptionsForLearningRecordVerb"
            />

            <TextInput
                v-if="preset === 'custom'"
                class="no-wrap"
                :label="trans('commands.learning_record.labels.verb_label')"
                :model="command.statement.verb.display"
                property="en-US"
                :required="true"
                :maxlength="100"
                :placeholder="trans('commands.learning_record.labels.verb_placeholder')"
                @change="onChangeVerb"
            />

            <ButtonCircular
                v-if="preset === 'custom'"
                class="small"
                icon="icon_delete"
                @trigger="resetVerb"
            />

            <ButtonCircular
                v-tooltip="command.statement.verb.id"
                class="small icon-copy"
                icon="icon_copy"
                @trigger="copyVerbId"
            />
        </div>

        <TextInput
            v-if="preset === 'custom'"
            class="no-wrap learning-record-command-verb-id"
            :readOnly="preset !== 'custom'"
            :key="'PanelCommandLearningRecord_verb_id_'+key"
            :model="command.statement.verb"
            property="id"
            :required="true"
            :maxlength="100"
            :label="trans('commands.learning_record.labels.verb_id')"
            :placeholder="trans('commands.learning_record.labels.verb_id_placeholder')"
            @change="onChangeVerb"
        />
    </div>
</template>

<script>

import TextInput from "@/Vue/Common/TextInput.vue";
import ButtonCircular from "@/Vue/Common/ButtonCircular.vue";
import Dropdown from "@/Vue/Common/Dropdown.vue";
import {LearningRecordCommand} from "@/Models/UnitData/Commands/Command";
import {shortId, sortArrayByProperty, trans} from "@/Utility/Helpers";
import {LearningRecordVerbs} from "@/Models/UnitData/LearningRecords/LearningRecordVerb";
import DropdownOption from "@/Utility/DropdownOption";
import CommandType from "@/Models/UnitData/Commands/CommandType";
import {cloneDeep} from "lodash";

export default {
    name: 'PanelCommandLearningRecordVerb',
    components: {
        TextInput,
        ButtonCircular,
        Dropdown,
    },
    emits: [
        'change',
        'changeProperties',
    ],
    props: {
        command: {
            type: LearningRecordCommand,
            default: null
        }
    },

    data() {
        return {
            key: shortId(),
            preset: this.getInitialPreset(),
        }
    },

    computed: {
        isCurrentVerbPreset() {
            return this.isVerbPreset(this.command.statement.verb.id);
        },

        /**
         * Get options for Learning Record verb dropdown
         *
         * @returns {DropdownOption[]}
         */
        dropdownOptionsForLearningRecordVerb() {
            const defaultVerbs = Object
                .values(LearningRecordVerbs)
                .map(verb =>
                    new DropdownOption({
                        caption: verb.caption,
                        disabled: false,
                        value: verb.id,
                    })
                );

            return [
                new DropdownOption({
                    isSeparator: true,
                    caption: trans('commands.learning_record.labels.verb'),
                }),
                ...sortArrayByProperty(defaultVerbs, 'caption', false),
                new DropdownOption({
                    isSeparator: true,
                }),
                new DropdownOption({
                    caption: trans('commands.learning_record.labels.custom'),
                    disabled: false,
                    value: 'custom',
                })
            ];
        },
    },

    methods: {
        copyVerbId(trigger) {
            navigator.clipboard.writeText(this.command.statement.verb.id);
            trigger.target.blur();
        },

        getInitialPreset() {
            return this.isVerbPreset(this.command.statement.verb.id) ? this.command.statement.verb.id : 'custom';
        },

        isVerbPreset(verbId) {
            return Object.values(LearningRecordVerbs).find(verb => verb.id === verbId) !== undefined;
        },

        onChangePreset(preset) {
            if (preset !== 'custom') {
                const verb = Object.values(LearningRecordVerbs).find(verb => verb.id === preset);
                this.command.statement.verb.id = verb.id;
                this.command.statement.verb.display = verb.display;
            } else {
                this.command.statement.verb = {
                    id: "",
                    display: {
                        "en-US": ""
                    },
                };
            }

            this.onChangeCommandProperties(this.command);

            return this;
        },

        onChangeVerb() {
            this.preset = this.isCurrentVerbPreset ? this.command.statement.verb.id : 'custom';
            this.onChangeCommand(this.command);

            return this;
        },

        resetVerb() {
            this.preset = CommandType.LearningRecord.defaultValue.verb.id;
            this.command.statement.verb = cloneDeep(CommandType.LearningRecord.defaultValue.verb)
            this.key = shortId();
            this.onChangeVerb();
        },

        /**
         *  Change handler for the command
         */
        onChangeCommand() {
            this.$emit('change', this.command);
            return this;
        },

        /**
         * Change handler for the command properties
         *
         * @param {LearningRecordCommand} command
         */
        onChangeCommandProperties(command) {
            this.$emit('changeProperties', this.command);
            this.key = shortId();
            return this;
        },
    },

    watch: {
        isCurrentVerbPreset: function(newValue, oldValue) {
            this.preset = newValue === true ? this.command.statement.verb.id : 'custom';

            this.onChangeCommand(this.command);
        },
    },
}


</script>

<style scoped lang="scss">

</style>

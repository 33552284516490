<template>
    <div class="panel panel-position">

        <header>{{ labelText }}</header>

        <div
            v-for="axis in ['x', 'y', 'z']"
            class="property position-inputs number-input-wrapper"
        >
            <label class="text-uppercase">{{ axis }}</label>
            <NumberInput
                :key="axis"
                :maxValue="9999"
                :minValue="-9999"
                :model="position"
                :property="axis"
                :step="1"
                :precision="8"
                @change="onChangeAxis"
            />
        </div>

    </div>
</template>

<script>
    import Position from '@/Models/Unity/Position';
    import { trans } from '@/Utility/Helpers';

    export default {
        name: 'PanelPosition',
        emits: [
            'change',
        ],
        props: {
            position: {
                type: Position,
                default: () => new Position(),
            },
            labelText: {
                type: String,
                default: trans('authoring.position'),
            },
        },
        methods: {

            onChangeAxis() {
                this.$emit('change', this.position);
                return this;
            },

        }
    }
</script>

<style lang="scss" scoped>
    .panel-position header {
        font-family: var(--font-family-condensed-demibold);
        margin-bottom: 10px;
    }

    .panel-position .position-inputs {
        display: flex;
        gap: 12px;
    }

    .position-inputs + .position-inputs {
        margin-top: 10px;
    }

    .number-input-container {
        flex-basis: 200px;
    }
</style>

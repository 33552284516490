import * as keys from "@/Vue/Bootstrap/InjectionKeys";
import AssetService from '@/Services/AssetService';
import CourseService from '@/Services/CourseService';
import TenantService from '@/Services/TenantService';
import UnitLinkService from "@/Services/UnitLinkService";
import TokenService from '@/Services/TokenService';
import UnitService from '@/Services/UnitService';
import UserService from '@/Services/UserService';
import LocalFeatureService from "@/Services/LocalFeatureService";
import TrackingService from "@/Services/TrackingService";
import LearningRecordsService from "@/Services/LearningRecordsService";
import LearningRecordsStatisticsService from "@/Services/LearningRecordsStatisticsService";
import ManageService from "@/Services/ManageService";
import SketchfabService from "@/Services/SketchfabService";
import ThirdPartyAuthService from "@/Services/ThridPartyAuth/ThirdPartyAuthService";
import StatisticsService from "@/Services/StatisticsService";
import type {App} from "vue";

export function registerServices(app: App) {
    app.provide(keys.assetServiceKey, new AssetService());
    app.provide(keys.courseServiceKey, new CourseService());
    app.provide(keys.tokenServiceKey, new TokenService());
    app.provide(keys.unitServiceKey, new UnitService());
    app.provide(keys.trackingServiceKey, new TrackingService());
    app.provide(keys.userServiceKey, new UserService());
    app.provide(keys.localFeatureServiceKey, new LocalFeatureService());
    app.provide(keys.manageServiceKey, new ManageService());
    app.provide(keys.sketchfabServiceKey, new SketchfabService(new ThirdPartyAuthService()));
    app.provide(keys.statisticsServiceKey, new StatisticsService());
    app.provide(keys.tenantServiceKey, new TenantService());
    app.provide(keys.unitLinkServiceKey, new UnitLinkService());
    app.provide(keys.learningRecordsServiceKey, new LearningRecordsService());
    app.provide(keys.learningRecordsStatisticsServiceKey, new LearningRecordsStatisticsService());
}

<template>
    <div class="behaviour-properties" v-shortcuts.stop>
        <div class="property behaviour-property">
            <Dropdown
                :label="trans('authoring.initial_state')"
                class="no-wrap"
                :model="behaviour"
                property="enabled"
                @select="onChangeBehaviour"
                :options="targetOptionsForPropertyEnabled"
                :deselected-caption="trans('authoring.enable_behaviour')"
            />
        </div>

        <div class="property behaviour-property">
            <Dropdown
                :label="trans('labels.material_characteristics')"
                class="no-wrap"
                :model="behaviour"
                property="material_characteristics"
                @select="onChangeMaterialCharacteristics"
                :options="targetOptionsForPropertyMaterialCharacteristics"
                :deselected-caption="trans('labels.material_characteristics')"
            />
        </div>

        <div class="property behaviour-property behaviour-property-input number-input-wrapper">
            <label>{{ trans('labels.weight') }} <span class="wait-unit">(kg)</span></label>
            <NumberInput
                :model="behaviour"
                property="mass"
                @change="onChangeMass"
                :minValue="0.001"
                :maxValue="999999"
                :step="0.001"
                :precision="3"
                :placeholder="trans('labels.weight')"
            />
        </div>
    </div>
</template>

<script>
import { targetOptionsForPropertyEnabled, targetOptionsForPropertyMaterialCharacteristics, getPhysicsMaterialCharacteristicsProperties }     from '@/Models/UnitData/Behaviours/BehaviourHelpers';

import Behaviour                    from '@/Models/UnitData/Behaviours/Behaviour';

export default {
    name: 'PanelBehaviourPhysics',
    emits: [
        'change',
    ],
    props: {
        behaviour: {                      // The behaviour object to be edited
            type: Behaviour,
            default: null
        }
    },
    data() {
        return {
            shortcuts: new Map([
                ['Backspace', null],                    // Allow backspace key
            ])
        }
    },
    computed: {
        targetOptionsForPropertyEnabled() {
            return targetOptionsForPropertyEnabled();
        },

        targetOptionsForPropertyMaterialCharacteristics() {
            return targetOptionsForPropertyMaterialCharacteristics();
        },
    },
    methods: {
        /**
         *  Change handler for the behaviour
         */
        onChangeBehaviour() {
            this.$emit('change', this.behaviour);

            return this;
        },

        /**
         *  Change handler for the material dropdown
         */
        onChangeMaterialCharacteristics(value) {
            const properties = getPhysicsMaterialCharacteristicsProperties(value);

            Object.keys(properties).forEach((property) => {
                this.behaviour[property] = properties[property];
            })

            this.$emit('change', this.behaviour);

            return this;
        },

        /**
         *  Change handler for the mass input
         */
        onChangeMass() {
            this.$emit('change', this.behaviour);
            return this;
        }
    }
}
</script>

<style lang="scss" scoped>

</style>

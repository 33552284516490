<template>
    <div v-if="isVisible" class="trigger-property enter-leave-distance-input property">
        <label>{{ trans('labels.distance') }} <span class="distance-unit">(cm)</span></label>
        <!-- TODO: replace with NumberInput when moved to its own component -->

        <input
            type="number" min="10" max="999999" step="10"
            @keydown="onKeyDownDistanceInput"
            @change="onChangeDistanceInput($event)"
            :value="typeof trigger.distance === 'number' ? parseInt('' + trigger.distance * 100, 10) : ''"
            :placeholder="trans('labels.distance')"
            :title="trans('triggers.enter_distance_input_title')"
            :model="trigger"
            property="distance"
            v-shortcuts="shortcutsForDistanceInput"
        />
    </div>
</template>

<script>

import KeyboardKey              from '@/Utility/KeyboardKey';
import Trigger                  from '@/Models/UnitData/Triggers/Trigger';
import {TriggerSubtype}         from "@/Models/UnitData/Triggers/TriggerSubtype";

export default {
    name: 'PanelTriggerPropertyDistance',
    emits: [
        'change',
    ],
    props: {
        trigger: Trigger,
    },
    data() {
        return {
            shortcutsForDistanceInput: new Map([
                ['Duplicate.prevent.stop', null],       // Prevent browser behaviour
                ['Save.prevent', null],                 // Prevent browser behaviour
                ['Any.stop', null]                      // Allow any other shortcut but stop propagation
            ])
        }
    },
    computed: {
        isVisible() {
            return (this.trigger.subtype === TriggerSubtype.Distance);
        }
    },
    methods: {

        /**
         * KeyDown handler for Enter/Leave Distance input
         *
         * @param {KeyboardEvent} e
         */
        onKeyDownDistanceInput(e) {
            // Prevent incorrect input:
            const key = KeyboardKey.findByEvent(e);
            const allowedControlKeys = KeyboardKey.KeySets.Controls.map(k => k.code);
            const allowedKeyCodes = KeyboardKey.KeySets.Digits.map(k => k.code);
            if (
                key === null
                || (
                    allowedControlKeys.indexOf(key.code) === -1
                    && allowedKeyCodes.indexOf(key.code) === -1
                )
            ) {
                e.preventDefault();
            }
            let maxValue = parseInt(e.currentTarget.getAttribute('max'), 10);
            maxValue = (isNaN(maxValue) === true) ? null : maxValue;

            if (maxValue !== null && e.currentTarget.value >= maxValue) {
                e.currentTarget.value = maxValue;
                if (allowedControlKeys.indexOf(key.code) === -1) {
                    e.preventDefault();
                }
            }

            if (key === KeyboardKey.Enter) {
                e.target.blur();
            }
            return this;
        },

        /**
         * Change handler for distance input
         *
         * @param {KeyboardEvent} e
         */
        onChangeDistanceInput(e) {
            const minValue = 1;
            const defaultValue = 50;
            let fallbackValue = parseInt('' + this.trigger.distance * 100.0, 10);
            fallbackValue = isNaN(fallbackValue) ? defaultValue : fallbackValue;

            let valueInCentimeters = parseInt(e.currentTarget.value, 10);
            valueInCentimeters = isNaN(valueInCentimeters) ? fallbackValue : valueInCentimeters;
            valueInCentimeters = valueInCentimeters < minValue ? minValue : valueInCentimeters;

            // Make sure we only have valid values in the input
            e.currentTarget.value = valueInCentimeters;

            let valueInMeters = valueInCentimeters / 100.0;

            if (valueInMeters !== this.trigger.distance) {
                e.currentTarget.value = valueInCentimeters;
                this.trigger.distance = valueInMeters;
                this.$emit('change', this);
            }

            return this;
        },

    },
}
</script>

<style lang="scss" scoped>

</style>
